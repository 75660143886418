import React, {useMemo} from 'react'
import {useHistory} from 'react-router'

import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {useTranslation} from 'react-i18next'

import {addCredential, updateLocalCredentials} from 'src/newcore/redux/modules/userManagement'

import {ACCESS_LEVEL, CREDENTIALS_TYPES} from 'ca-common/constants'
import {parseFormPermissions, getServicesByWhiteList} from 'ca-common/utils/usersManagement'
import {permittedWhiteList} from 'src/newcore/utils/permissions'

import {UserPageForm} from './UserForm'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {useDynamics365Enabled} from 'ca-common/features/Flags'
import {SOURCE} from 'ca-common/common/enum'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

const dumbNewUser = dumbBem('ca-newUser-page')
const CANewUser = tx(dumbNewUser)('div')

const USERS_PAGE = '/settings/users'

export const NewUserPage = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const history = useHistory()
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const isDynamics365Enabled = useDynamics365Enabled()
    const serviceListRaw = getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(userInfo?.permissions))

    const serviceList = useMemo(() => {
        if (isDynamics365Enabled) {
            return serviceListRaw
        }

        return {...serviceListRaw, sub: serviceListRaw.sub.filter(({name}) => name !== SOURCE.DYNAMICS365)}
    }, [isDynamics365Enabled, serviceListRaw])

    const generalList = getGeneralList(companyInfo, userInfo)
    const tasksList = getTasksList()
    const ORIGINAL_LIST = [serviceList, generalList, tasksList]

    const submitForm = originalList => values => {
        const data = {
            type: values.credentials.type,
            email: values.credentials.email.toLowerCase(),
            password: values.credentials.password,
            permissions: parseFormPermissions(values, originalList)
        }

        return dispatch(addCredential(data))
            .unwrap()
            .then(response => {
                openSuccessNotification(t('userManagementToast:credentialAdded'))
                dispatch(updateLocalCredentials(response))
                history.push(USERS_PAGE)
            })
            .catch(err => {
                openErrorModal(`${t('userManagementToast:addFailed')} ${err.status}`)
            })
    }

    return (
        <CANewUser>
            <UserPageForm
                onSubmit={submitForm(ORIGINAL_LIST)}
                initialValues={{
                    credentials: {
                        role: ACCESS_LEVEL.CUSTOM,
                        type: CREDENTIALS_TYPES.EMAIL
                    }
                }}
                groups={{
                    services: serviceList,
                    tasks: tasksList,
                    general: generalList
                }}
                isFinra={userInfo?.isFinra}
                disableOAuth={!companyInfo.isCloudally}
            />
        </CANewUser>
    )
}
