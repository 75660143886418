import React, {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import dumbBem from 'dumb-bem'
import tx from 'transform-props-with'
import {useTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {
    getPermissions,
    setPermissions,
    clearPermissions,
    fetchCredentials
} from 'src/newcore/redux/modules/userManagement'
import {
    parseFormPermissions,
    parseExistPermission,
    getRole,
    getServicesByWhiteList
} from 'ca-common/utils/usersManagement'
import {permittedWhiteList, shrinkPermissionsByServiceWhiteList} from 'src/newcore/utils/permissions'

import {UserPageForm} from './UserForm'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {SOURCE} from 'ca-common/common/enum'
import {useDynamics365Enabled} from 'ca-common/features/Flags'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

const dumbEditUser = dumbBem('ca-editUser-page')
const CAEditUser = tx(dumbEditUser)('div')

const USERS_PAGE = '/settings/users'

export const EditUserPage = props => {
    const {
        match: {
            params: {id}
        }
    } = props
    const dispatch = useAppDispatch()

    const {t} = useTranslation()
    const history = useHistory()
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const credentials = useAppSelector(state => state.credentials)
    const permission = useAppSelector(state => state.permission)
    const isDynamics365Enabled = useDynamics365Enabled()
    const serviceListRaw = getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(userInfo?.permissions))

    const serviceList = useMemo(() => {
        if (isDynamics365Enabled) {
            return serviceListRaw
        }

        return {...serviceListRaw, sub: serviceListRaw.sub.filter(({name}) => name !== SOURCE.DYNAMICS365)}
    }, [isDynamics365Enabled, serviceListRaw])

    const [userPermission, setUserPermission] = useState({})
    const [stateFilled, setStateFilled] = useState(false)

    useEffect(() => {
        if (credentials.status !== 'fulfilled') {
            dispatch(fetchCredentials())
        }

        dispatch(getPermissions({id}))
            .then(response => {
                setStateFilled(true)
                setUserPermission(shrinkPermissionsByServiceWhiteList(response.payload))
            })
            .catch(data => {
                if (!data.success) {
                    openErrorModal(data.status)
                }
            })

        return () => dispatch(clearPermissions())
    }, [])

    const getCurrentCredential = (credentials, id) => credentials.response.data.find(cred => cred.id === id)

    const isDataLoaded = (stateFilled, permission, credentials) => {
        return stateFilled && [permission.status, credentials.status].every(status => status === 'fulfilled')
    }

    const submitForm = originalList => values => {
        const data = {
            type: values.credentials.type,
            email: values.credentials.email.toLowerCase(),
            permissions: parseFormPermissions(values, originalList)
        }

        return dispatch(setPermissions(data))
            .unwrap()
            .then(() => {
                openSuccessNotification(t('userManagementToast:permissionsUpdated'))
                history.push(USERS_PAGE)
            })
            .catch(err => openErrorModal(err.status))
    }

    if (!isDataLoaded(stateFilled, permission, credentials)) {
        return <Spinner modifier="page" />
    }

    const currentCredential = getCurrentCredential(credentials, id)
    const generalList = getGeneralList(companyInfo, userInfo)
    const tasksList = getTasksList()
    const ORIGINAL_LIST = [serviceList, generalList, tasksList]

    return (
        <CAEditUser>
            <UserPageForm
                onSubmit={submitForm(ORIGINAL_LIST)}
                isNew={false}
                initialValues={{
                    permissions: parseExistPermission(userPermission, ORIGINAL_LIST),
                    credentials: {
                        ...currentCredential,
                        role: getRole(permission.response.accessLevel),
                        name: currentCredential?.name || ''
                    }
                }}
                groups={{
                    services: serviceList,
                    tasks: tasksList,
                    general: generalList
                }}
                isFinra={userInfo.isFinra}
            />
        </CAEditUser>
    )
}
