import styled from 'styled-components'
import Icon from '@ant-design/icons/lib/components/Icon'
import {CheckCircleFilled, SyncOutlined, ExclamationCircleFilled} from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    font-family: ${variables.publicPageFont};
    justify-content: center;
    flex-flow: column;
`
export const StyledBorder = styled.div`
    border: 1px dashed ${variables.navyBorder};
    border-radius: 50%;
    margin-bottom: 40px;
    position: relative;
`

export const StyledPersonIcon = styled(Icon)`
    font-size: 160px;
    padding: 40px;
`

export const StyledDynamicIcon = styled(Icon)`
    font-size: 52px;
    position: absolute;
    left: 132px;
    top: 24px;
`

export const StyledReloadWrapperAzure = styled.div`
    position: absolute;
    left: calc(100% - 6px);
    top: 40%;
    white-space: nowrap;
`

export const StyledReloadWrapperUser = styled.div`
    position: absolute;
    left: calc(100% - 6px);
    top: 49%;
    white-space: nowrap;
`

export const StyledCheckCircleFilled = styled(CheckCircleFilled)`
    color: ${variables.green};
    margin-right: ${variables.gutterXs};
`
export const StyledSyncOutlined = styled(SyncOutlined)`
    margin-right: ${variables.gutterXs};
`
export const StyledDisabledSyncOutlined = styled(SyncOutlined)`
    margin-right: ${variables.gutterXs};
    color: ${variables.lightGray};
    background-color: ${variables.white};
`
export const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
    color: ${variables.red};
    margin-right: ${variables.gutterXs};
`
export const StyledErrorMessage = styled.p`
    color: ${variables.red};
    font-style: italic;
`
