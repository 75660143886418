import {Modal} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledDeleteModal = styled(Modal)`
    top: 30%;
    padding: ${variables.gutterMd} 0px 0px 0px;

    border-radius: ${variables.borderRadiusBase} ${variables.borderRadiusBase} 0px 0px;
    .ant-modal-content {
        border-radius: ${variables.gutterXs};
    }
`

export const StyledHeader = styled.div`
    padding: ${variables.headingFontSize} ${variables.headingFontSize};
    font-weight: 500;
    font-size: ${variables.headingFontSize};
    font-family: ${variables.accentFont};
`
export const StyledBody = styled.div`
    font-size: ${variables.fontSizeBase};
    margin-left: 51px;
    margin-top: -2%;
    font-family: ${variables.publicPageFont};
    font-weight: 400;
    margin-bottom: ${variables.gutterMd};
    p {
        padding-top: ${variables.gutterMd};
    }
`

export const StyledFooter = styled.div`
    border-top: 1px solid ${variables.navyBorder};
    display: flex;
    justify-content: flex-end;
    padding: ${variables.gutterSm};
    gap: ${variables.gutterMd};
    .ant-btn-link {
        font-family: ${variables.accentFont};
        color: ${variables.navyBlueBorder};
        margin-right: -${variables.gutterMd};
    }
    .ant-btn-primary {
        padding: ${variables.borderRadiusBase} ${variables.fontSizeBase} ${variables.borderRadiusBase}
            ${variables.fontSizeBase};
    }
`

export const StyledModal = styled.div`
    margin: -${variables.gutterMd};
    word-break: break-all;
`
export const StyledDeleteTitle = styled.span`
    margin-left: ${variables.borderRadiusBase};
    position: absolute;
    margin-top: 2px;
    font-family: ${variables.publicPageFont};
}
`
