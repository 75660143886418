import React from 'react'
import {formatUTCDateTime} from 'ca-common/utils/datetime'
import {PopoverContentRow} from 'src/newcore/features/ActivityTable/atoms/PopoverContentRow'
import {DefaultPopoverProps, PopoverDescription} from 'src/newcore/features/ActivityTable/types'
import {Content} from './StyledPopoverContent'

export const DefaultPopoverContent = ({description, date}: DefaultPopoverProps<PopoverDescription>): JSX.Element => {
    const {text, countEntities, performedBy, dataSize, dynamicsEnvironment} = description
    return (
        <Content>
            <PopoverContentRow label="execTime" rowText={formatUTCDateTime(date)} />
            <PopoverContentRow label="dynamicsEnvironment" rowText={dynamicsEnvironment} />
            <PopoverContentRow label="dataSize" rowText={dataSize} />
            <PopoverContentRow label="numberOfRecords" rowText={countEntities} />
            <PopoverContentRow label="initiator" rowText={performedBy} />
            <PopoverContentRow label="description" rowText={text} textWidth={304} />
        </Content>
    )
}
