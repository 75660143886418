import styled from 'styled-components'
import variables from 'ca-common/variables'

export const EntitiesRawWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 2px solid ${variables.blueLight};
    margin-bottom: 10px;
    :last-child {
        margin-bottom: 18px;
    }
`
export const Content = styled.div`
    margin-top: ${variables.gutterSm};

    & > div:first-child {
        padding-bottom: 2px;
    }
`
export const SingleEntityWrapper = styled.div<{isDynamics?: boolean}>`
    min-height: ${props => (props.isDynamics ? '42px' : '58px')};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`
