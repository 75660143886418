import {Modal, Button} from 'antd'
import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledModal = styled(Modal)`
    border-radius: ${variables.gutterXs};
    box-shadow: 0px ${variables.fontSizeSecondary} 28px 0px ${variables.offBoxShadow};
    .ant-form-item {
        margin-bottom: ${variables.gutterSm};
    }
    .ant-modal-title {
        line-height: ${variables.gutterLg};
        height: ${variables.fontSizeSecondary};

        margin-top: -${variables.gutterSm};
    }
    .ant-modal-header {
        border-radius: ${variables.gutterXs};
    }
    .ant-modal-content {
        border-radius: ${variables.gutterXs};
    }
    .ant-modal-footer button {
        width: 69px;
        height: ${variables.gutterLg};
        padding: ${variables.borderRadiusBase} ${variables.gutterXs} ${variables.borderRadiusBase} ${variables.gutterXs};
        font-family: ${variables.accentFont};
    }
`
export const StyledTitle = styled.div`
    font-family: ${variables.accentFont};
    color: ${variables.text};
    font-size: ${variables.headingFontSize};
    font-weight: 400;
`
export const StyledInfo = styled.div`
    font-family: ${variables.publicPageFont};
    color: ${variables.ligthBlack};
    font-size: ${variables.fontSizeSecondary};
    display: inline-table;
    margin-left: 1%;
    font-weight: 400;
`
export const StyledKeyDetails = styled.div`
    width: 495px;
    height: ${variables.headerHeight};
    margin: 44px;
    .ant-col {
        font-weight: 400;
        font-size: ${variables.fontSizeBase};
        padding-right: 38px;
        font-family: ${variables.mainFont};
        color: ${variables.text};
    }
    .ant-row {
        margin-top: ${variables.gutterSm};
    }
`
export const StyledNotiication = styled.div`
    width: 100%;
    height: 56px;
    border: 1px solid ${variables.orange};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.fontSizeSecondary};
    background: ${variables.lightorange};
    font-size: ${variables.fontSizeSecondary};
    margin-top: ${variables.fontSizeSecondary};
`
export const StyledCLientId = styled.div`
    border-bottom: 1px solid ${variables.navyBorder};
    width: 316px;
    font-size: ${variables.fontSizeBase};
    margin-left: ${variables.fontSizeSecondary};
    color: ${variables.navyBlue};
    font-family: ${variables.mainFont};
    font-weight: 400;
    span {
        position: absolute;
        left: 280px;
    }
    img {
        position: absolute;
        margin-left: 28px;
        margin-top: 2px;
        cursor: pointer;
    }
`
export const StyledCLientSecret = styled.div`
    border-bottom: 1px solid ${variables.navyBorder};
    width: 316px;
    font-size: ${variables.fontSizeBase};
    color: ${variables.navyBlue};
    font-family: ${variables.mainFont};
    font-weight: 400;
    .secret {
        position: absolute;
        left: 76%;
    }

    margin-left: -${variables.fontSizeBase};
    img {
        margin-right: ${variables.borderRadiusBase};
        cursor: pointer;
    }
`
export const StyledButton = styled(Button)``
export const StyledCopiedData = styled.div`
    width: 250px;
    height: ${variables.gutterLg};
    border: 1px solid ${variables.systemGreen};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.borderRadiusBase} ${variables.gutterXs} ${variables.borderRadiusBase} ${variables.gutterXs};
    background: ${variables.lightgreen};
    margin-left: 18%;
    margin-top: ${variables.subTitleFontSize};
    text-align: center;
`
