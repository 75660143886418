import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Tooltip} from 'antd'

import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {TODO_ANY} from 'ca-common/types'

import {deleteLocalBackupTasks} from '../../../redux'
import {ACTIONS} from '../../../utils/constants'
import {account, id, source} from 'src/newcore/features/Homepage/types'
import {TaskActionsItem} from 'src/newcore/features/Homepage/atoms/TaskActionsItem'
import {ActionDeleteConfirmation} from 'src/newcore/molecules'
import {DisabledButtonWrapper} from 'src/newcore/features/Homepage/organisms/BackupActions/StyledBackupActions'

type DeleteProps = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
        sendAction: any
        source: source
        isFinra: boolean
        id: id
        name: string
        account: account
        disabled: boolean
        environment: string
    }

const DeleteRaw = ({
    sendAction,
    source,
    isFinra,
    id,
    name,
    companyInfo,
    account,
    actions,
    disabled,
    environment
}: DeleteProps): JSX.Element => {
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Tooltip title={isFinra && t('finra:taskTooltip')}>
                <DisabledButtonWrapper disabled={disabled}>
                    <TaskActionsItem action={ACTIONS.DELETE} onClick={() => setShowModal(true)} disabled={disabled} />
                </DisabledButtonWrapper>
            </Tooltip>
            <ActionDeleteConfirmation
                name={name}
                account={account}
                onDelete={confirmDeleting}
                onCancel={() => setShowModal(false)}
                visible={showModal}
                supportEmail={companyInfo.supportEmail}
                source={source}
                environment={environment}
            />
        </>
    )
    function confirmDeleting() {
        sendAction(ACTIONS.DELETE)
            .then(() => {
                openSuccessNotification(
                    <Trans>{t(`backups:modal:delete:gracePeriodNotification:text`)}</Trans>,
                    <Trans>{t(`backups:modal:delete:gracePeriodNotification:title`)}</Trans>
                )
                actions.deleteLocalBackupTasks({id})
                setShowModal(false)
            })
            .catch((error: any) => {
                setShowModal(false)
                openErrorModal(t(`backups:failedDelete`) + (error ? error.status : ''))
            })
    }
}

const mapStateToProps = (state: TODO_ANY) => ({
    companyInfo: state.companyInfo.response
})

const mapDispatchToProps = (dispatch: TODO_ANY) => ({
    actions: bindActionCreators(
        {
            deleteLocalBackupTasks
        },
        dispatch
    )
})

export const DeleteItem = connect(mapStateToProps, mapDispatchToProps)(DeleteRaw)
