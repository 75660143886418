import React, {useState} from 'react'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'
import {AUTH_TYPE} from 'ca-common/common/enum/AuthType'
import {RadioButton, RadioGroup} from 'ca-common/ui-lib/components/RadioBox'
import {useFeatureFlag} from 'ca-common/features/Flags'
import {FEATURE_FLAGS} from 'ca-common/features/Flags/constants'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody, FlexDiv} from '../StyledTemplates'

export const SharepointREST = ({
    source,
    backupSettings,
    onSubmit,
    onCancel,
    loading,
    form
}: BackupAuthTemplate): JSX.Element => {
    const {t} = useTranslation()
    const isCredentialsType = useFeatureFlag(FEATURE_FLAGS.IS_SP_CREDENTIALS_AUTH)
    const isNew = isEmpty(backupSettings)
    const [isOauth, setIsOauth] = useState(true)
    const initialValues = {
        alias: backupSettings.alias,
        account: backupSettings.account,
        authType: AUTH_TYPE.OAUTH,
        sharePointUrl: backupSettings.endpoint
    }
    return (
        <StyledBackupAuthForm
            initialValues={initialValues}
            layout="horizontal"
            form={form}
            onFinish={(values: any) => {
                if ((isNew || backupSettings.oauth) && !isCredentialsType) {
                    values.authType = initialValues.authType
                    values.account = initialValues.account
                }
                onSubmit(values)
            }}
        >
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    {(isCredentialsType || (!isNew && !backupSettings.oauth)) && (
                        <Col>
                            <Form.Item name="authType">
                                <RadioGroup onChange={e => setIsOauth(e.target.value === AUTH_TYPE.OAUTH)}>
                                    <RadioButton value={AUTH_TYPE.OAUTH} key={AUTH_TYPE.OAUTH}>
                                        {t('forms:backupAuth:fields:authType:oauth:label')}
                                    </RadioButton>
                                    <RadioButton value={AUTH_TYPE.CREDENTIAL} key={AUTH_TYPE.CREDENTIAL}>
                                        {t('forms:backupAuth:fields:authType:credential:label')}
                                    </RadioButton>
                                </RadioGroup>
                            </Form.Item>
                        </Col>
                    )}
                    <Col>
                        <p>{t('forms:backupAuth:text:SharepointOauthInfo')}</p>
                    </Col>
                    {(isCredentialsType || !backupSettings.oauth) &&
                        (isOauth ? (
                            <>
                                {!isNew && backupSettings.oauth && (
                                    <Col>
                                        <TaskCreatedAccount account={backupSettings.account} />
                                    </Col>
                                )}
                            </>
                        ) : (
                            <>
                                <FlexDiv>
                                    <Col span={12}>
                                        <Form.Item
                                            name="account"
                                            label={t('forms:backupAuth:fields:SharepointAccount:label')}
                                            colon={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('forms:common:fields:email:validation:required')
                                                },
                                                {
                                                    type: 'email',
                                                    message: t('forms:common:fields:email:validation:invalid')
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="password"
                                            label={t('forms:backupAuth:fields:SharepointPassword:label')}
                                            colon={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('forms:common:fields:password:validation:required')
                                                }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </FlexDiv>
                                <Col span={24}>
                                    <Form.Item
                                        name="sharePointUrl"
                                        label={t('forms:backupAuth:fields:SharepointUrl:label')}
                                        colon={false}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('forms:common:validation:required')
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </>
                        ))}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('forms:common:actions:authenticate')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
