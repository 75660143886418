import styled from 'styled-components'
import {Radio} from 'antd'

import variables from 'ca-common/variables'

export const StyledRecentlyDeletedTaskSource = styled.div`
    padding: ${variables.gutterXs} ${variables.gutterSm};
    display: flex;
    align-items: center;
    border: 1px solid ${variables.interaction};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: ${variables.borderRadiusBase};
`

export const StyledRadioGroup = styled(Radio.Group)`
    display: flex;
    align-items: center;
    margin-left: auto;

    .ant-radio-inner {
        border-color: ${variables.navyBlue};
    }

    label > span:last-child {
        padding-left: 4px;
    }
`

export const StyledSourceName = styled.div`
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.black};
    margin-left: 2px;
`

export const StyledLabel = styled.span<{checked?: boolean; bothUnchecked?: boolean}>`
    color: ${props =>
        props.bothUnchecked ? variables.navyBlue : props.checked ? variables.navyBlue : variables.navyAsphalt};
`
