import React from 'react'

import {i18n} from 'ca-common/locales'
import Refresh from 'ca-common/icons/integrations/SplunkLoading.svg'

import {StyledLoader, StyledSection, StyledIcon} from './StyledSplunkLoader'

export const SplunkLoader = () => {
    return (
        <StyledLoader>
            <StyledIcon component={Refresh} />
            <StyledSection>{i18n.t('integrations:splunkLoading')}</StyledSection>
        </StyledLoader>
    )
}
