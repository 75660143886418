import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {Button} from 'src/ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'src/ca-common/components/Modal'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {ApplicationUserCreationStatus} from 'src/newcore/features/DynamicsWizard/organisms/CreateApplicationUserStatus/ApplicationUserCreationStatus'
import {createTask} from 'src/newcore/features/DynamicsWizard/lib/api'
import {
    previousStep,
    closeWizard,
    changeProgress,
    wizardStepSelector,
    wizardDataSelector,
    changeData
} from 'src/newcore/redux/modules/wizard'
import {authenticate} from 'src/newcore/features/DynamicsWizard/lib/api'

export const CreateApplicationUserStatus = () => {
    const {t} = useTranslation()
    const currentStep = useAppSelector(wizardStepSelector)
    const wizardData = useAppSelector(wizardDataSelector)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const onCancel = () => {
        dispatch(closeWizard())
        history.replace(history.location.pathname)
    }

    return (
        <>
            <ApplicationUserCreationStatus />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    {wizardData.isUserAuthenticationFailed ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                authenticate(wizardData.environment, wizardData.alias)
                            }}
                        >
                            {t('modals:buttons:authenticate')}
                        </Button>
                    ) : wizardData.appUserCreationError ? (
                        <Button type="primary" onClick={() => authenticate(wizardData.environment, wizardData.alias)}>
                            {t('modals:buttons:retry')}
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            disabled={!wizardData.appUserCreated}
                            onClick={() => createTask(dispatch)}
                        >
                            {t('modals:buttons:done')}
                        </Button>
                    )}
                </LinkWrapper>
                <BackLink
                    onClick={() => {
                        dispatch(changeData({isBackStep: true}))
                        dispatch(previousStep())
                        dispatch(changeProgress(currentStep - 1))
                    }}
                />
            </ModalFooter>
        </>
    )
}
