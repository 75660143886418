import styled, {css} from 'styled-components'
import {Button} from 'ca-common/ui-lib/atoms'
import variables from 'ca-common/variables'

export const StyledHelpButton = styled(Button)<{isDrawerOpen: boolean}>`
    background: transparent;

    &:hover,
    &:active {
        background: ${variables.brandDarkHover};
        border-color: ${variables.defaultBorder};
    }

    &:focus {
        background: transparent;
        border-color: ${variables.defaultBorder};
    }

    svg {
        font-size: 18px;
    }

    ${props =>
        props.isDrawerOpen &&
        css`
            background: ${variables.brandDarkHover};
            border-color: ${variables.defaultBorder};
        `}
`
