import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {PAGES} from 'ca-common/constants'
import {makeFormFieldRequest} from 'ca-common/utils/form'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'

import {BackupAuth} from 'src/newcore/features/BackupAuth'
import {createBackupAuth, continueCreateOauthTask} from 'src/newcore/redux/modules/backups'
import {GracePeriodNotification} from 'src/newcore/features/AddBackup/organisms'

import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {SOURCE, SOURCE_INFO} from 'ca-common/common/enum'
import type {BackupSettings} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'
import {AppState, useAppDispatch} from 'src/newcore/components/Root'

type AddBackupProps = {
    source: SOURCE
    toggleVisible: () => void
    visible: boolean
    companyName: string
    isCloudally: boolean
    dynamicsAuthenticationFailed?: boolean
}

export const AddBackupRaw = ({
    source,
    toggleVisible,
    visible,
    companyName,
    isCloudally,
    dynamicsAuthenticationFailed
}: AddBackupProps): JSX.Element => {
    const [showGracePeriodNotification, setShowGracePeriodNotification] = useState(false)
    const [formFields, setFormFields] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const checkURLIsGracePeriodTask = () => {
        const {isGracePeriodTask} = queryString.parse(location.search)

        if (isGracePeriodTask) {
            setShowGracePeriodNotification(true)
        }
    }

    const onCreationSuccess = (taskId: string) => {
        history.push(`/${PAGES.BACKUPS}/${taskId}?isNew=true`)

        if (SOURCE_INFO[source].hasSubAccounts) {
            openSuccessNotification(
                t('backups:backupActivatedMulti', [getSvcAccountsDescription(source, true).toLowerCase()])
            )
        }
    }

    const createTask = ({...data}: any) => {
        return dispatch(
            createBackupAuth({
                source,
                ...data
            })
        )
            .unwrap()
            .then((response: any) => {
                if (response.isGracePeriodTask) {
                    setShowGracePeriodNotification(true)
                    setFormFields(data.fields)
                } else {
                    if (response.needRedirect) {
                        window.location = response.redirectUrl
                    }

                    if (response.created && response.taskId) {
                        onCreationSuccess(response.taskId)
                    }
                }
            })
            .catch((error: any) => {
                if (error.status) {
                    openErrorModal(error.status)
                }
            })
    }

    const createOauthTask = ({...data}: any) => {
        return dispatch(continueCreateOauthTask(data))
            .then((response: any) => {
                const responseData = _.get(response, 'payload', {})

                if (responseData.created && responseData.taskId) {
                    onCreationSuccess(responseData.taskId)
                }
            })
            .catch((error: any) => {
                if (error.status) {
                    openErrorModal(error.status)
                }
            })
    }

    const handleAddBackup = (values: Record<string, unknown>) => {
        const fields = makeFormFieldRequest(values)

        return createTask({source, fields}).then(() => {
            toggleVisible()
        })
    }

    const handleCreateGracePeriodTask = (data: any) => {
        const {isGracePeriodTask} = queryString.parse(location.search)

        if (isGracePeriodTask) {
            return createOauthTask({...data})
        }

        return createTask({...data, fields: formFields})
    }

    useEffect(() => {
        checkURLIsGracePeriodTask()
    }, [location.search])

    return (
        <>
            <BackupAuth
                backupSettings={{} as BackupSettings}
                isCloudally={isCloudally}
                source={source}
                visible={visible}
                onSubmit={handleAddBackup}
                onCancel={toggleVisible}
                companyName={companyName}
                dynamicsAuthenticationFailed={dynamicsAuthenticationFailed}
            />
            <GracePeriodNotification
                showModal={showGracePeriodNotification}
                closeModal={() => setShowGracePeriodNotification(false)}
                createTask={(data: any) => handleCreateGracePeriodTask({...data})}
            />
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    companyName: state.companyInfo.status === 'fulfilled' ? state.companyInfo.response.name : '',
    isCloudally: state.companyInfo.status === 'fulfilled' && state.companyInfo.response.isCloudally
})

export const AddBackup = connect(mapStateToProps)(AddBackupRaw)
