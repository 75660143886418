import React from 'react'
import {useTranslation} from 'react-i18next'

import {getCompareDateFromLocalNew, COMPARE_FORMAT_NEW} from 'ca-common/utils/datetime'

import {InfoColumn} from 'src/newcore/features/Jobs/atoms'
import {JobFields} from 'src/newcore/features/Jobs/types'

import {DetailedInfo} from './StyledJobDetailedInfo'
import {TASK_TYPE} from 'src/ca-common/common/enum'
import {siteFormatter} from 'ca-common/utils/entity'

type JobDetailedInfoProps = Pick<
    JobFields,
    'startTime' | 'initiator' | 'destination' | 'totalItems' | 'size' | 'backupDate' | 'taskType'
>

export const JobDetailedInfo = ({
    startTime,
    initiator,
    destination,
    totalItems,
    size,
    backupDate,
    taskType
}: JobDetailedInfoProps): JSX.Element => {
    const {t} = useTranslation()

    return (
        <DetailedInfo>
            <InfoColumn
                label={t('JobsPage:jobTile:columns:label:startedAt')}
                info={getCompareDateFromLocalNew(startTime, COMPARE_FORMAT_NEW)}
            />
            <InfoColumn
                label={t('JobsPage:jobTile:columns:label:backupDate')}
                info={
                    backupDate === 0 ? t<string>('jobs:na') : getCompareDateFromLocalNew(backupDate, COMPARE_FORMAT_NEW)
                }
            />
            <InfoColumn label={t('JobsPage:jobTile:columns:label:initiator')} info={initiator} withTooltip />
            <InfoColumn
                label={t('JobsPage:jobTile:columns:label:destination')}
                info={destination}
                shortInfo={siteFormatter(destination)}
                withTooltip
            />
            <InfoColumn label={t('JobsPage:jobTile:columns:label:totalItems')} info={totalItems} />
            {taskType === TASK_TYPE.EXPORT && (
                <InfoColumn label={t('JobsPage:jobTile:columns:label:size')} info={size} />
            )}
        </DetailedInfo>
    )
}
