import React from 'react'
import {useTranslation} from 'react-i18next'

import {SourceIcon} from 'ca-common/components/SourceIcon'
import {getSourceName} from 'ca-common/utils/sources'
import {Site} from 'src/newcore/components/BackupEntities/CellModifier/Site'

import {JobFields} from 'src/newcore/features/Jobs/types'

import {
    IconRadiusWrapper,
    InfoBlock,
    SourceName,
    StyledHeaderTaskInfo,
    TaskName,
    UserEmail,
    StyledJobLabel,
    StyledDescription,
    StyledSection
} from './StyledHeaderTaskInfo'

type HeaderTaskInfoProps = Pick<JobFields, 'source' | 'alias' | 'account' | 'jobLabel'>

export const HeaderTaskInfo = ({source, alias, account, jobLabel}: HeaderTaskInfoProps): JSX.Element => {
    const {t} = useTranslation()
    return (
        <div>
            <StyledHeaderTaskInfo>
                <IconRadiusWrapper>
                    <SourceIcon source={source} />
                </IconRadiusWrapper>
                <InfoBlock>
                    <TaskName title={alias}>{alias}</TaskName>
                    <SourceName>{getSourceName(source)}</SourceName>
                    <UserEmail>
                        <Site site={account} />
                    </UserEmail>
                </InfoBlock>
            </StyledHeaderTaskInfo>
            {jobLabel && (
                <StyledSection>
                    <StyledJobLabel>{t(`recovery:wizard:jobLabel:title`)}</StyledJobLabel>
                    <StyledDescription>{jobLabel}</StyledDescription>
                </StyledSection>
            )}
        </div>
    )
}
