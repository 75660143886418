import styled from 'styled-components'
import {Radio} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledWrapper = styled.section<{isSelective: boolean}>`
    max-width: 410px;
    width: 100%;
`

export const StyledRadio = styled(Radio)<{checked: boolean}>`
    display: block;
    margin-bottom: ${variables.gutterSm};
    color: ${props => (props.checked ? variables.text : variables.asphaltLight)};
`

export const InfoIcon = styled(Icon)`
    margin-left: ${variables.gutterXs};
    width: 12px;
    font-size: ${variables.fontSizeSecondary};
    cursor: pointer;
`
export const StyledRestoreSubtitle = styled.div`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};

    margin-bottom: ${variables.gutterXs};
`
