import {i18n} from 'ca-common/locales'

export const columns = [
    {
        title: i18n.t('integrations:apiDetails:clientDetails:clientId'),
        dataIndex: 'clientId',
        key: 'clientId'
    },
    {
        title: i18n.t('integrations:apiDetails:clientDetails:clientSecret'),
        dataIndex: 'clientSecret',
        key: 'clientSecret'
    },
    {
        title: i18n.t('integrations:apiDetails:clientDetails:createdOn'),
        dataIndex: 'createdOn',
        key: 'createdOn'
    },
    {
        title: i18n.t('integrations:apiDetails:clientDetails:createdBy'),
        dataIndex: 'createdBy',
        key: 'createdBy'
    }
]
export const DEFAULT_SECRET = '****************'
export const CLIENT_ID = 'clientID'
export const CLIENT_SECRET = 'clientSecret'
export const TIMER_MS = 5000
export const HTTP_ERROR_STATUS_CODE = 404
