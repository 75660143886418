import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'

import {REGION} from 'ca-common/common/enum'
import {OAUTH_SERVICES_LINK, PUBLIC_PAGES} from 'ca-common/constants'
import {getPortalSignUpOAuthServices} from 'ca-common/utils/oauth'

import {fetchSignUp} from 'src/newcore/redux/modules/sign'
import {SignUpFormBlock} from 'src/newcore/features/SignUp/organisms/SignUpFormBlock'
import {DescriptionBlock} from 'src/newcore/features/SignUp/organisms/SignUpDescriptionBlock'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'

import {Content, ContentWrapper} from './StyledSignUp'

type FormValues = {
    firstName: string
    lastName: string
    email: string
    password: string
    location: string
}

export const SignUp = () => {
    const history = useHistory()
    const companyInfo = useAppSelector(companyInfoSelector)
    const {msgType, status, field, NetSuiteID: netSuiteId} = queryString.parse(history.location.search)
    const [error, setError] = useState<string | null | string[]>(null)
    const [isEmailError, setIsEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (msgType === 'error') {
            setError(status)
        }
        if (field === 'email') {
            setIsEmailError(true)
        }
    }, [msgType, status, field])

    const handleSignUp = (values: FormValues, queryParams: string) => {
        setLoading(true)

        const Pid = companyInfo?.partnerId
        const {firstName, lastName, email, password, location} = values
        const data = {
            displayName: `${firstName} ${lastName}`,
            email,
            password,
            region: location,
            partnerId: Pid,
            netSuiteId,
            queryParams
        }

        dispatch(fetchSignUp(data))
            .unwrap()
            .then((response: any) => {
                const userId = response.status
                const params = {
                    email,
                    userId
                }
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'signup'
                })
                history.push(`${PUBLIC_PAGES.THANK_YOU}?${queryString.stringify(params)}`)
            })
            .catch((errorFromFetch: any) => {
                setLoading(false)
                if (errorFromFetch.field === 'email') {
                    setIsEmailError(true)
                }
                setError(errorFromFetch.status)
            })
    }

    const updateLinks = (links: typeof OAUTH_SERVICES_LINK.PARTNER_PORTAL) => {
        const Pid = companyInfo?.partnerId
        const region = companyInfo?.partnerRegion || REGION.US_EST
        const parsedParams = queryString.stringify({
            signUp: true,
            Pid: Pid || '',
            region,
            netSuiteId,
            ...queryString.parse(history.location.search)
        })

        return _.reduce(
            links,
            (link: Record<string, any>, value, key) => {
                link[key] = `${value}?${parsedParams}`
                return link
            },
            {}
        )
    }
    return (
        <ContentWrapper>
            <Content>
                {companyInfo.status === 'fulfilled' ? (
                    <>
                        <DescriptionBlock companyInfo={companyInfo} />
                        <SignUpFormBlock
                            error={error}
                            companyInfo={companyInfo}
                            onSubmit={handleSignUp}
                            services={getPortalSignUpOAuthServices(
                                updateLinks(OAUTH_SERVICES_LINK.PORTAL) as typeof OAUTH_SERVICES_LINK.PORTAL
                            )}
                            loading={loading}
                            isEmailError={isEmailError}
                            setError={setError}
                            setIsEmailError={setIsEmailError}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Content>
        </ContentWrapper>
    )
}
