import styled from 'styled-components'
import {Button, Tooltip} from 'antd'
import Icon from '@ant-design/icons'
import {rgba} from 'polished'

import variables from 'ca-common/variables'

export const StyledIntegrationCard = styled.div`
    padding: ${variables.gutterSm};
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${variables.white};
    width: 342px;
    margin: ${variables.gutterSm};

    &:first-child {
        margin-left: 0;
    }
`

export const StyledHeader = styled.div`
    margin-bottom: ${variables.gutterXs};
`

export const StyledLogo = styled.div`
    margin-bottom: ${variables.gutterSm};
    display: inline-block;
`
export const StyledActiveButton = styled.div<{hasError?: boolean}>`
    display: inline-block;
    float: right;
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    font-family: ${variables.mainFont};
    border-radius: ${variables.gutterSm};
    padding: 2px 12px;
    color: ${props => (props.hasError ? variables.red : variables.systemGreen)};
    background-color: ${props => (props.hasError ? rgba(variables.red, 0.1) : rgba(variables.systemGreen, 0.1))};
    cursor: ${props => (props.hasError ? 'pointer' : 'text')};
`

export const StyledTitle = styled.a`
    font-weight: 700;
    text-decoration: underline !important;
    font-size: ${variables.fontSizeBase};
`

export const StyledDetails = styled.div`
    color: ${variables.navyBlue};
    margin: ${variables.gutterSm} 0px;
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.mainFont};
`

export const StyledActiveDetails = styled.div`
    color: ${variables.navyBlue};
    padding: ${variables.gutterXs} 0px;
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.mainFont};
    font-weight: 600;
    border: none;
    cursor: text;
    height: ${variables.gutterXxl};
`

export const StyledButton = styled(Button)`
    color: ${variables.navyBlue} !important;
    background: ${variables.white} !important;
    font-family: ${variables.accentFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 700;

    :hover {
        background: ${variables.navyBlue} !important;
        color: ${variables.white} !important;
    }
`

export const StyledIcon = styled(Icon)`
    margin-left: ${variables.gutterXs};
`

export const StyledLogoIcon = styled(Icon)<{isSmallFont: boolean; isAutoTask: boolean}>`
    svg {
        height: 61px;
        font-size: ${props => (props.isSmallFont ? '151px' : '222px')};
        margin-left: ${props => (props.isAutoTask ? `-${variables.gutterXs}` : '0px')};
    }
`
