import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {RadioChangeEvent, Spin} from 'antd'
import {CheckCircleFilled} from '@ant-design/icons'

import {useAppSelector} from 'src/newcore/components/Root'
import {changeData, wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {Environment} from 'src/newcore/features/DynamicsWizard/lib/types'
import {getAllEnvironments} from 'src/newcore/features/DynamicsWizard/lib/api'

import {
    StyledHeader,
    StyledRadioGroup,
    StyledContent,
    StyledRadio,
    StyledBackupContent,
    StyledDynamicsListDiv,
    StyledModalContainer
} from './StyledChooseDynamicsEnvironment'

export const ChooseDynamicsEnvironment = (): JSX.Element => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const wizardData = useAppSelector(wizardDataSelector)
    const compareByEnvironmentName = (dynamicsEnvFirst: Environment, dynamicsEnvSecond: Environment) => {
        return dynamicsEnvFirst.friendlyName.localeCompare(dynamicsEnvSecond.friendlyName)
    }
    const environments = wizardData.allEnvironments?.slice().sort(compareByEnvironmentName)
    const onChange = (event: RadioChangeEvent) => {
        environments?.map((environment: Environment) => {
            if (environment.friendlyName === event.target.value) {
                dispatch(changeData({environment: environment}))
            }
        })
    }

    useEffect(() => {
        getAllEnvironments(dispatch, wizardData.isBackStep, wizardData.environment?.friendlyName)
    }, [])

    return (
        <Spin spinning={wizardData.isLoading}>
            <StyledHeader>
                <h2>{t('forms:backupAuth:text:DynamicsEnvironment')}</h2>
                <p>{t('forms:backupAuth:text:DynamicsEnvironmentInfo')}</p>
                <div>{wizardData.isAllEnvsBackedUp && t('forms:backupAuth:text:DynamicsBackedUpInfo')}</div>
            </StyledHeader>
            <StyledDynamicsListDiv>
                <StyledRadioGroup onChange={onChange} value={wizardData.environment?.friendlyName}>
                    {environments?.map((environment: Environment) => {
                        return (
                            <StyledContent
                                key={environment.friendlyName}
                                isSelected={wizardData?.environment?.environmentId === environment?.environmentId}
                            >
                                <StyledRadio value={environment.friendlyName} disabled={environment.isPresent}>
                                    {environment.friendlyName}
                                </StyledRadio>
                                {environment.isPresent && (
                                    <StyledBackupContent>
                                        <CheckCircleFilled />
                                        {t('forms:backupAuth:text:DynamicsBackedUp')}
                                    </StyledBackupContent>
                                )}
                            </StyledContent>
                        )
                    })}
                </StyledRadioGroup>
            </StyledDynamicsListDiv>
        </Spin>
    )
}
