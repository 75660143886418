import React, {useEffect, useState} from 'react'
import {Trans} from 'react-i18next'
import {useRouteMatch} from 'react-router'

import {openErrorModal} from 'ca-common/utils/modals'

import {useAppDispatch} from 'src/newcore/components/Root/newAppStore'
import {getDownloadUrl} from 'src/newcore/redux/modules/recovery/recoveries'
import {callReload} from 'src/newcore/utils/logout'

import {StyledBlock, StyledLink} from './StyledDownload'

export const Download = () => {
    const dispatch = useAppDispatch()
    const {
        params: {id}
    } = useRouteMatch<{id: string}>()
    const [link, setLink] = useState()

    useEffect(() => {
        dispatch(getDownloadUrl({taskId: id}))
            .unwrap()
            .then((response: any) => {
                const linkURL = response.data
                setLink(linkURL)
                setTimeout(() => {
                    // Popup blocking hack https://javascript.info/popup-windows#popup-blocking
                    window.open(linkURL, '_blank')
                }, 100)
            })
            .catch((error: any) => {
                if (error.statusCode === 403) callReload('', '?msgType=custom&modalName=AccessDenied')
                else openErrorModal(error.status)
            })
    }, [])

    return (
        <StyledBlock>
            {link && (
                <Trans i18nKey="notifications:downloadFallback">
                    <StyledLink data-auto-download href={link}>
                        here
                    </StyledLink>
                </Trans>
            )}
        </StyledBlock>
    )
}
