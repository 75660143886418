import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import {SOURCE} from 'src/ca-common/common/enum'
import {DeepTaskStatus} from 'ca-common/atoms/DeepTaskStatus'
import {SourceIcon} from 'ca-common/components/SourceIcon'
import {Task} from 'src/newcore/features/Homepage/types'
import {PausedStatus} from 'src/newcore/features/Homepage/atoms/PausedStatus'
import {isTaskPaused} from 'src/newcore/features/Homepage/utils/utils'
import {RowWrapper} from 'src/newcore/features/Homepage/molecules/BackupTitleList/StyledBackupTitleList'
import {ConnectStatus} from 'src/newcore/features/Homepage/atoms/ConnectStatus'
import {EditName} from 'src/newcore/features/Homepage/atoms/TaskView'
import {
    IconBorder,
    TaskTitle,
    SourceAndStatusWrapper,
    SourceName,
    TaskNameWrapper,
    TextWrapper,
    TitleBar,
    EnvironmentName
} from './StyledBackupTitle'

type BackupTitleProps = Task & {
    friendlyName: string
    toggleVisible: () => void
}

export const BackupTitle = ({task, friendlyName, toggleVisible}: BackupTitleProps): JSX.Element => {
    const {source, alias, authenticated, deepTaskStatus, status} = task
    const {t} = useTranslation()
    return (
        <div data-inlinemanual="InlineManual-homepage-taskInfo">
            <TitleBar>
                <IconBorder>
                    <SourceIcon source={source} />
                </IconBorder>
                <TextWrapper>
                    <SourceAndStatusWrapper>
                        <TaskNameWrapper>
                            <Tooltip title={alias}>
                                <TaskTitle>{alias}</TaskTitle>
                            </Tooltip>
                            <EditName
                                onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                                    e.preventDefault()
                                    toggleVisible()
                                }}
                            />
                        </TaskNameWrapper>
                        <ConnectStatus isAuthenticated={authenticated} />
                    </SourceAndStatusWrapper>
                    <Tooltip title={friendlyName}>
                        <SourceName>{friendlyName}</SourceName>
                    </Tooltip>
                    {source === SOURCE.DYNAMICS365 && <EnvironmentName>{task.dynamicsEnvironment}</EnvironmentName>}
                </TextWrapper>
            </TitleBar>

            <RowWrapper>
                <DeepTaskStatus statusText={t(`homepage:taskStatus:${deepTaskStatus}`)} status={deepTaskStatus} />
                {isTaskPaused(status) && <PausedStatus />}
            </RowWrapper>
        </div>
    )
}
