import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button, Tooltip} from 'antd'

import {IntegrationServices} from 'ca-common/features/Integrations/config'
import ErrorFilled from 'ca-common/icons/errorFilled.svg'
import variables from 'ca-common/variables'
import SplunkIcon from 'ca-common/icons/integrations/Splunk.svg'
import AutoTaskIcon from 'ca-common/icons/integrations/Autotask.svg'
import BackupRadarIcon from 'ca-common/icons/integrations/BackupRadar.svg'

import {
    StyledIntegrationCard,
    StyledHeader,
    StyledTitle,
    StyledDetails,
    StyledButton,
    StyledActiveButton,
    StyledLogo,
    StyledActiveDetails,
    StyledIcon,
    StyledLogoIcon
} from './StyledIntegration'

type IntegrationProps = {
    value: {
        description: string
        link: string
        name: string
        type: string
        isActive: boolean
        isFailed: boolean
        lastFailure: string
    }
    selectIntegration: (type: string) => any
}

const tooltipOverlayInnerStyle = {
    fontSize: variables.fontSizeSecondary,
    width: '298px',
    fontFamily: variables.publicPageFont,
    fontWeight: 400,
    padding: variables.gutterXs,
    'margin-top': '-4%'
}

export const Integration = (props: IntegrationProps) => {
    const {t} = useTranslation()
    const {name, link, description, type, isActive, isFailed, lastFailure} = props.value
    const {selectIntegration} = props

    const selectOption = () => {
        selectIntegration(type)
    }

    const getLogoImage = () => {
        switch (type) {
            case IntegrationServices.BACKUP_RADAR:
                return BackupRadarIcon
            case IntegrationServices.AUTO_TASK:
                return AutoTaskIcon
            case IntegrationServices.SPLUNK:
                return SplunkIcon
        }
    }

    const showStatusButton = () => {
        if (isFailed) {
            return (
                <Tooltip title={lastFailure} placement="bottom" overlayInnerStyle={tooltipOverlayInnerStyle}>
                    <StyledActiveButton hasError={true}>
                        {t('securityPage:indicatorFailed')}
                        <StyledIcon component={ErrorFilled} />
                    </StyledActiveButton>
                </Tooltip>
            )
        } else if (isActive) {
            return <StyledActiveButton>{t('securityPage:indicatorActive')}</StyledActiveButton>
        }
    }

    return (
        <StyledIntegrationCard>
            <StyledHeader>
                <StyledLogoIcon
                    component={getLogoImage()}
                    isSmallFont={type === IntegrationServices.SPLUNK}
                    isAutoTask={type === IntegrationServices.AUTO_TASK}
                />
                {showStatusButton()}
            </StyledHeader>
            <StyledTitle href={link} target="_blank">
                {name}
            </StyledTitle>
            <StyledDetails>{description}</StyledDetails>
            {!isActive ? (
                <StyledButton size="large" block type="primary" onClick={() => selectOption()}>
                    {t('modals:buttons:connect')}
                </StyledButton>
            ) : type !== IntegrationServices.SPLUNK ? (
                <StyledActiveDetails>{t('integrations:activatedInstruction')}</StyledActiveDetails>
            ) : (
                <StyledButton size="large" block type="primary" onClick={() => selectOption()}>
                    {t('modals:buttons:edit')}
                </StyledButton>
            )}
        </StyledIntegrationCard>
    )
}
