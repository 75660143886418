import React, {useState} from 'react'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation, Trans} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import {Button} from 'ca-common/ui-lib/atoms'
import {AUTH_TYPE} from 'ca-common/common/enum/AuthType'
import {RadioButton, RadioGroup} from 'ca-common/ui-lib/components/RadioBox'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName, TaskCreatedAccount} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody, FlexDiv} from '../StyledTemplates'

export const MS365 = ({source, backupSettings, onSubmit, onCancel, loading, form}: BackupAuthTemplate): JSX.Element => {
    const {t} = useTranslation()
    const isNew = isEmpty(backupSettings)
    const [isOauth, setIsOauth] = useState(true)
    const initialValues = {alias: backupSettings.alias, account: backupSettings.account, authType: AUTH_TYPE.OAUTH}

    return (
        <StyledBackupAuthForm initialValues={initialValues} form={form} layout="horizontal" onFinish={onSubmit}>
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>
                        <Form.Item name="authType">
                            <RadioGroup onChange={e => setIsOauth(e.target.value === AUTH_TYPE.OAUTH)}>
                                <RadioButton value={AUTH_TYPE.OAUTH} key={AUTH_TYPE.OAUTH}>
                                    {t('forms:backupAuth:fields:authType:oauth:label')}
                                </RadioButton>
                                <RadioButton
                                    value={AUTH_TYPE.CREDENTIAL}
                                    key={AUTH_TYPE.CREDENTIAL}
                                    disabled={!backupSettings.endpoint}
                                >
                                    {t('forms:backupAuth:fields:authType:credential:label')}
                                </RadioButton>
                            </RadioGroup>
                        </Form.Item>
                    </Col>
                    {isOauth ? (
                        <>
                            <Col>
                                <p>
                                    <Trans i18nKey="forms:backupAuth:text:MS365OauthInfo"></Trans>
                                </p>
                            </Col>
                            {!isNew && backupSettings.oauth && (
                                <Col>
                                    <TaskCreatedAccount account={backupSettings.account} />
                                </Col>
                            )}
                        </>
                    ) : (
                        <FlexDiv>
                            <Col span={12}>
                                <Form.Item
                                    name="account"
                                    label={t('forms:backupAuth:fields:MS365Account:label')}
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('forms:common:fields:email:validation:required')
                                        },
                                        {
                                            type: 'email',
                                            message: t('forms:common:fields:email:validation:invalid')
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="password"
                                    label={t('forms:backupAuth:fields:MS365Password:label')}
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('forms:common:fields:password:validation:required')
                                        }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </FlexDiv>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                {isOauth ? (
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {t('forms:common:actions:authenticate')}
                    </Button>
                ) : (
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {t('forms:common:actions:authenticate')}
                    </Button>
                )}
            </Footer>
        </StyledBackupAuthForm>
    )
}
