import _ from 'lodash'
import i18n from 'i18next'
import {isEmptyOrOnlyWhitespaces} from './form'
import {CURRENCIES} from '../constants'

export const passwordPatternValidation = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(.)*$/

export const passwordSpecialCharValidation = /^((?![<>+]).)*$/

export const trailingOrLeadingSpace = /^\S.+\S$/

export const phonePattern = /^[\s()+-.]*([0-9][\s()+-.]*){6,20}$/

export const isEmail = (email: string): boolean => {
    return /^[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
        email
    )
}
export const lessThan = (value: Record<any, any> | string, length: number): boolean => value.length < length
export const moreThan = (value: Record<any, any> | string, length: number): boolean => value.length > length
export const atLeastOneNumAndChar = (value: string): boolean => passwordPatternValidation.test(value)
export const specialCharValidation = (value: string): boolean => passwordSpecialCharValidation.test(value)
export const trailingSpaceValidation = (value: string): boolean => trailingOrLeadingSpace.test(value)
export const isPhone = (value: string): boolean => phonePattern.test(value)
export const isTwoFACode = (value: string): boolean => /^\d{6}$/.test(value)
export const isPositiveInt = (value: string): boolean => /^\+?([1-9]\d*)$/.test(value)
export const isOneDriveUrl = (value: string): boolean => /^[0-9a-zA-Z][\w-]+-my\.sharepoint\.com$/.test(value)
export const isSharepointUrl = (value: string): boolean => /^[0-9a-zA-Z][\w-]+\.sharepoint\.com$/.test(value)

export const LIMITS = {
    NAME: {
        MIN: 2,
        MAX: 60
    },
    PASSWORD: {
        MIN: 8,
        MAX: 60
    },
    ZIP: {
        MAX: 10
    },
    JOB_LABEL: {
        MAX: 50
    },
    PAYMENT_FIELD: {
        MAX: 250
    },
    ORDER: {
        MAX: 50
    }
}

export const jobLabelValidate = (value: string): string | undefined => {
    if (value && value.length >= 1 && value.trim().length === 0) {
        return i18n.t('forms:oldUI:recovery:invalidSpaceError')
    }

    if (value && moreThan(value, LIMITS.JOB_LABEL.MAX)) {
        return i18n.t('validation:maxJobLabelLength')
    }
    return undefined
}

export const nameValidate = (name: string): string | undefined => {
    if (lessThan(name, LIMITS.NAME.MIN)) {
        return `Must be at least ${LIMITS.NAME.MIN} characters long`
    }

    if (moreThan(name, LIMITS.NAME.MAX)) {
        return `Must be less than ${LIMITS.NAME.MAX} characters long`
    }

    return undefined
}

export const emailValidate = (email: string): string | undefined => {
    return !isEmail(email) ? 'Please enter a valid Email address.' : undefined
}

export const amexValidate = (currency: string): string | undefined => {
    return currency !== CURRENCIES.USD ? i18n.t('validation:amex') : undefined
}

export const passwordValidate = (value: string): string | undefined => {
    if (lessThan(value, LIMITS.PASSWORD.MIN)) {
        return i18n.t('validation:minPassword', [LIMITS.PASSWORD.MIN])
    }

    if (moreThan(value, LIMITS.PASSWORD.MAX)) {
        return i18n.t('validation:maxPassword', [LIMITS.PASSWORD.MAX])
    }

    if (!specialCharValidation(value)) {
        return i18n.t('validation:specialCharValidation')
    }

    if (!trailingSpaceValidation(value)) {
        return i18n.t('validation:trailingOrLeadingSpace')
    }

    if (!atLeastOneNumAndChar(value)) {
        return i18n.t('validation:atLeastOneNumAndChar')
    }

    return undefined
}

export const passwordMatchingValidate = (password: string, matchingPassword: string): string | undefined => {
    let errorName
    if (password !== matchingPassword) {
        errorName = "Passwords don't match"
    }

    return errorName
}

export const passwordNotMatchingValidate = (password: string, matchingPassword: string): string | undefined => {
    let errorName
    if (password === matchingPassword) {
        errorName = 'New password should not match the old one'
    }

    return errorName
}

export const phoneNumberValidate = (value: string): string | undefined => {
    let errorName
    if (!_.isEmpty(value)) {
        if (!isPhone(value)) {
            errorName = i18n.t('validation:incorrectPhone')
        }
    }

    return errorName
}

export const requiredValidate = (value: string): string | undefined => {
    return isEmptyOrOnlyWhitespaces(value) ? 'Required field' : undefined
}

export const discountValidate = (value: string): string | undefined => {
    let errorName
    const discount = _.toNumber(value)
    if (discount > 100 || discount < 0) {
        errorName = 'Discount should be between 0 and 100'
    }

    return errorName
}

export const isOdin = (value: string): boolean => {
    return _.isString(value) && value.length > 1 && value[1] === 'O'
}

export const purchaseOrderNumber = (value: string): string | undefined => {
    let errorName
    if (!_.isEmpty(value) && moreThan(value, LIMITS.ORDER.MAX)) {
        errorName = 'Invalid purchase order number'
    }

    return errorName
}

export const twoFaCodeValidate = (value: string): string | undefined => {
    return !isTwoFACode(value) ? 'Invalid code' : undefined
}

export const zipCodeValidate = (value: string): string | undefined => {
    return !_.isEmpty(value) && moreThan(value, LIMITS.ZIP.MAX)
        ? i18n.t('validation:maxLength', {length: LIMITS.ZIP.MAX})
        : undefined
}

export const maxBillingFieldLength = (value: string): string | undefined => {
    return !_.isEmpty(value) && moreThan(value, LIMITS.PAYMENT_FIELD.MAX)
        ? i18n.t('validation:maxLength', {length: LIMITS.PAYMENT_FIELD.MAX})
        : undefined
}

export const validatePositiveInt = (value: string): string | undefined => {
    return !isPositiveInt(value) ? i18n.t('validation:onlyPositiveInt') : undefined
}

export const validateDaysToAccountDeactivation = (value: string, values: Record<any, any>): string | undefined => {
    if (values.isDaysToAccountDeactivation) {
        return validatePositiveInt(value)
    }

    return undefined
}

export const validateSharePointUrl = (value: string): string | undefined => {
    return value && !isSharepointUrl(value) ? i18n.t('validation:invalidSharePointUrl') : undefined
}

export const validateOneDriveUrl = (value: string): string | undefined => {
    return value && !isOneDriveUrl(value) ? i18n.t('validation:invalidOneDriveUrl') : undefined
}

export const validateImage = (imageList: Record<any, any>): string | undefined => {
    if (imageList) {
        if (imageList.length > 1) {
            return i18n.t('validation:image:oneImageUpload')
        } else if (imageList.length === 1) {
            const selectedImage = imageList[0]
            if (!selectedImage.type.match('image.*')) {
                return i18n.t('validation:image:allowedImage')
            } else if (selectedImage.size > 1048576) {
                return i18n.t('validation:image:maxSizeImage')
            }
        }
    }
    return undefined
}

export const dateValidate = (value: string | number): string | undefined => {
    let errorName
    const date = +value
    if (date > 31 || date < 1) {
        errorName = i18n.t('validation:dateRange')
    }

    return errorName
}

export const expiredDateValidatValidate = (value: string | number): string | undefined => {
    let errorName
    const date = +value
    if (date > 365 || date < 1) {
        errorName = i18n.t('validation:expiredDate')
    }

    return errorName
}
