import {PARTNER_TYPE} from 'ca-common/constants'
import {BackupRadarInfo, IntegrationServices, AutoTaskInfo, SplunkInfo} from './config'

export const selectVisibleOption = (id: string) => {
    switch (id) {
        case IntegrationServices.BACKUP_RADAR:
            return BackupRadarInfo
        case IntegrationServices.AUTO_TASK:
            return AutoTaskInfo
        case IntegrationServices.SPLUNK:
            return SplunkInfo
        default:
            return {}
    }
}

export const formValueUpdate: any = (
    changedFields: Record<string, any>,
    configureValues: [],
    initialFormData: Record<string, any>
) => {
    let updatedValue
    changedFields.forEach((field: Record<string, any>) => {
        updatedValue =
            configureValues
                .map(value => {
                    if (field?.name[0] === value && field?.value !== initialFormData[value]) {
                        return true
                    }
                    return false
                })
                .find(item => item === true) || false
    })
    return updatedValue
}

export const isRestAPIVisible = (type: string) => {
    switch (type) {
        case undefined:
            return true
        case PARTNER_TYPE.DIRECT_RESELLER:
            return true
        case PARTNER_TYPE.DISTRIBUTOR:
            return true
        case PARTNER_TYPE.INDIRECT_RESELLER:
            return false
        default:
            return false
    }
}
