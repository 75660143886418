import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import Delete from 'ca-common/images/Integrations/Delete.svg'
import variables from 'ca-common/variables'
import {
    StyledFooter,
    StyledBody,
    StyledModal,
    StyledHeader,
    StyledDeleteModal,
    StyledDeleteTitle
} from './StyledApiKeyDeleteModal'

type Props = {
    visible: boolean
    onDelete: () => void
    onCancel: () => void
    deleteLoader: boolean
}

export const ApiKeyDeleteModal = ({onDelete, onCancel, visible = false, deleteLoader}: Props): JSX.Element => {
    const {t} = useTranslation()
    return (
        <StyledDeleteModal width={610} open={visible} footer={null} closable={true} onCancel={onCancel}>
            <StyledModal>
                <StyledHeader>
                    <img src={Delete} />
                    <StyledDeleteTitle>
                        <Trans i18nKey="integrations:apiDetails:clientDetails:modal:delete:title" />
                    </StyledDeleteTitle>
                </StyledHeader>
                <StyledBody>
                    <Trans i18nKey="integrations:apiDetails:clientDetails:modal:delete:desc" />

                    <p>
                        <Trans i18nKey="integrations:apiDetails:clientDetails:modal:delete:confimText" />
                    </p>
                </StyledBody>
                <StyledFooter>
                    <Button onClick={onCancel} type="link" loading={deleteLoader}>
                        {t('backups:modal:delete:buttonCancel')}
                    </Button>
                    <Button type="primary" color={variables.lightred} onClick={onDelete} loading={deleteLoader}>
                        {t('backups:modal:delete:buttonDelete')}
                    </Button>
                </StyledFooter>
            </StyledModal>
        </StyledDeleteModal>
    )
}
