import React from 'react'
import {Input, Row, Col, Form} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const Yahoo = ({source, backupSettings, onSubmit, onCancel, form}: BackupAuthTemplate) => {
    const {t} = useTranslation()

    return (
        <StyledBackupAuthForm
            initialValues={{alias: backupSettings.alias, account: backupSettings.account}}
            form={form}
            layout="horizontal"
            onFinish={values => onSubmit(values)}
        >
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>
                    <Col>
                        <Form.Item
                            name="account"
                            label={t('forms:backupAuth:fields:yahooMail:label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:fields:email:validation:required')
                                },
                                {
                                    type: 'email',
                                    message: t('forms:common:fields:email:validation:invalid')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="password"
                            label={t('forms:backupAuth:fields:yahooPassword:label')}
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('forms:common:fields:password:validation:required')
                                }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:common:actions:apply')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
