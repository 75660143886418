import React, {useState, useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Spin, Col, Row} from 'antd'
import InfoIcon from 'ca-common/images/Integrations/InfoIcon.svg'
import Copy from 'ca-common/images/Integrations/Copy.svg'
import Shown from 'ca-common/images/Integrations/Shown.svg'
import Hidden from 'ca-common/images/Integrations/Hidden.svg'
import Success from 'ca-common/images/Integrations/Success.svg'
import {SingleFooterButton} from 'ca-common/features/Integrations/atoms/SingleFooterButton'
import {
    StyledModal,
    StyledTitle,
    StyledNotiication,
    StyledKeyDetails,
    StyledCLientId,
    StyledCLientSecret,
    StyledCopiedData,
    StyledInfo
} from './StyledApiKeyDetailsModal'
import {DEFAULT_SECRET, CLIENT_ID, CLIENT_SECRET, TIMER_MS} from '../ApiKeyDetailsCommon'

type ModalProps = {
    modalVisible: boolean
    closeModal: () => any
    modalData: Record<string, any>
    loading: boolean
    showSecret: boolean
    setShowSecret: React.Dispatch<React.SetStateAction<boolean>>
}

export const ClientKeyDetailsModal = (props: ModalProps) => {
    const {t} = useTranslation()
    const {modalVisible, closeModal, modalData, loading, showSecret, setShowSecret} = props
    const {secretId, secretData} = modalData
    const [secretCopied, setSecretCopied] = useState(false)
    const [copiedMessage, setCopiedMessage] = useState('')
    const [successImgLoaded, setSuccessImgLoaded] = useState(false)
    const handleCopy = (secret: string, clientType: string) => () => {
        navigator.clipboard
            .writeText(secret)
            .then(() => {
                setSecretCopied(true)

                setCopiedMessage(
                    clientType === CLIENT_ID
                        ? t('integrations:apiDetails:clientDetails:clientIdCopied')
                        : t('integrations:apiDetails:clientDetails:clientSecretCopied')
                )
            })
            .catch(() => {
                setCopiedMessage(t('integrations:apiDetails:clientDetails:copyFailure'))
            })
        const timer = setTimeout(() => {
            setSecretCopied(false)
        }, TIMER_MS)

        return () => clearTimeout(timer)
    }

    return (
        <StyledModal
            centered
            closeIcon={false}
            closable={false}
            width={566}
            open={modalVisible}
            destroyOnClose={true}
            onCancel={() => closeModal()}
            title={<StyledTitle>{t('integrations:apiDetails:clientDetails:modal:header')}</StyledTitle>}
            footer={<SingleFooterButton onCancel={() => closeModal()} />}
        >
            {loading ? (
                <Spin style={{display: 'inherit'}} />
            ) : (
                <>
                    <StyledNotiication>
                        <img src={InfoIcon} />
                        <StyledInfo>
                            <Trans i18nKey="integrations:apiDetails:clientDetails:modal:info"></Trans>
                        </StyledInfo>
                    </StyledNotiication>
                    <StyledKeyDetails>
                        <Row>
                            <Col> {t('integrations:apiDetails:clientDetails:clientId')} </Col>
                            <Col>
                                <StyledCLientId>
                                    {secretId}
                                    <span>
                                        <img src={Copy} onClick={handleCopy(secretId, CLIENT_ID)} />
                                    </span>
                                </StyledCLientId>
                            </Col>
                        </Row>
                        <Row>
                            <Col> {t('integrations:apiDetails:clientDetails:modal:clientSecret')}</Col>
                            <Col>
                                <StyledCLientSecret>
                                    <span>
                                        {showSecret ? secretData : DEFAULT_SECRET}
                                        <span className="secret">
                                            <img
                                                src={!showSecret ? Shown : Hidden}
                                                onClick={() => {
                                                    setShowSecret(!showSecret)
                                                }}
                                            />
                                            <img src={Copy} onClick={handleCopy(secretData, CLIENT_SECRET)} />
                                        </span>
                                    </span>
                                </StyledCLientSecret>
                            </Col>
                        </Row>

                        {secretCopied && (
                            <StyledCopiedData>
                                <img
                                    src={Success}
                                    onLoadStart={() => {
                                        setSuccessImgLoaded(false)
                                    }}
                                    onLoad={() => {
                                        setSuccessImgLoaded(true)
                                    }}
                                />
                                {!successImgLoaded ? <Spin /> : copiedMessage}
                            </StyledCopiedData>
                        )}
                    </StyledKeyDetails>
                </>
            )}
        </StyledModal>
    )
}
