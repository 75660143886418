import _ from 'lodash'
import queryString from 'query-string'
import {createAction, createReducer} from '@reduxjs/toolkit'

import {getUrlParams, navigate} from 'ca-common/utils/navigation'

export const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS'

export const updateQueryParams = createAction(UPDATE_QUERY_PARAMS, () => ({payload: getUrlParams()})) //is it usefull?
export const queryParamsReducer = createReducer({_: ''}, {[UPDATE_QUERY_PARAMS]: (state, action) => action.payload})

const EXPLORE_FOLDER = 'EXPLORE_FOLDER'

function _exploreFolder(entityId, itemName, history, isOldApp, isMDFolder) {
    const params = getUrlParams()
    const ids = []
    const names = []
    if (params.folderid) {
        ids.push(decodeURIComponent(params.folderid))
        names.push(decodeURIComponent(params.foldername))
    }
    ids.push(entityId)
    names.push(itemName)
    const folderPathById = ids.join('/')
    const folderPathByName = names.join('/')
    let localParams = _.pick(
        params,
        'source',
        'id',
        'account',
        'alias',
        'entity',
        'backupDate',
        'service',
        'beginDate',
        'endDate',
        'endpointURI',
        'index',
        'extid',
        'isTeam',
        'indexUpdatedByIdField',
        'canDeleted'
    )
    localParams = _.assign(localParams, {
        folderid: encodeURIComponent(folderPathById),
        foldername: encodeURIComponent(folderPathByName),
        isMDFolder: !!isMDFolder
    })

    if (isOldApp) {
        navigate('restoreSnapshotResults', localParams)
    } else {
        const search = queryString.stringify(localParams)
        history.push({pathname: history.location.pathname, search})
    }

    return {payload: undefined}
}

export function exploreSnapshot(data, history, isOldApp) {
    const params = getUrlParams()
    let localParams = _.pick(
        params,
        'source',
        'id',
        'account',
        'alias',
        'entity',
        'backupDate',
        'service',
        'beginDate',
        'endDate',
        'endpointURI',
        'index',
        'extid',
        'isTeam',
        'canDeleted'
    )
    localParams = _.assign(localParams, data)

    if (isOldApp) {
        navigate('restoreSnapshotResults', localParams)
    } else {
        const search = queryString.stringify(localParams)
        history.push({pathname: history.location.pathname, search})
    }
}

export const exploreFolder = createAction(EXPLORE_FOLDER, _exploreFolder)
