import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledBlock = styled.div`
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.mainFont};
    font-weight: 400;
`

export const StyledText = styled.div``

export const StyledInstructions = styled.div`
    padding: ${variables.gutterSm};
    margin-left: ${variables.gutterXs};

    ol li ul {
        margin-left: ${variables.gutterSm};
        list-style-type: lower-alpha;
    }
`

export const StyledLink = styled.a<{isPartnerPortal: boolean}>`
    color: ${props => (props.isPartnerPortal ? variables.linkBlue : variables.navyBlue)} !important;
    text-decoration: underline !important;
`
