import styled from 'styled-components'

import {Form, Input} from 'antd'

import variables from 'ca-common/variables'

const {Item: FormItem} = Form

export const StyledSubtitle = styled.div<{fullWidth?: boolean}>`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    margin-bottom: ${variables.gutterXs};
    display: flex;
    justify-content: space-between;

    max-width: ${props => (props.fullWidth ? '100%' : '330px')};
    span {
        font-weight: 400;
        font-style: italic;
        color: ${variables.navyAsphalt};
    }
`

export const StyledSection = styled.span`
    font-weight: 400;
    color: ${variables.navyAsphalt};
    font-style: unset !important;
`

export const StyledFormItem = styled(FormItem)<{fullWidth?: boolean}>`
    width: ${props => (props.fullWidth ? '100%' : '330px')};
`

export const StyledInput = styled(Input)`
    ::placeholder {
        color: ${variables.black};
        opacity: 0.5;
    }
`
