import styled from 'styled-components'
import {Tabs} from 'antd'
import variables from 'ca-common/variables'

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        &::before {
            border-color: #e8e8e8;
        }

        .ant-tabs-tab {
            padding: 12px ${variables.gutterSm};
        }

        .ant-tabs-tab .ant-tabs-tab-btn {
            font-family: ${variables.accentFont};
            font-size: ${variables.headingFontSize};
            color: ${variables.asphalt};
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${variables.navyBlue};
            font-weight: 600;
        }
        .ant-tabs-ink-bar {
            background-color: ${variables.navyBlue};
        }
    }
`
