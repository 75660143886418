import React from 'react'

import GrayPerson from 'ca-common/icons/GrayPerson.svg'
import Dynamics365 from './Dynamics365.png'

import {useAppSelector} from 'src/newcore/components/Root'
import {wizardDataSelector} from 'src/newcore/redux/modules/wizard'

import {DynamicsHeader} from 'src/newcore/features/DynamicsWizard/atoms'

import {StyledContent, StyledBorder, StyledPersonIcon, StyledDynamicIcon} from './StyledApplicationUserCreation'

export const ApplicationUserCreation = () => {
    const wizardData = useAppSelector(wizardDataSelector)
    return (
        <>
            <DynamicsHeader isForAuth friendlyName={wizardData.environment?.friendlyName} />
            <StyledContent>
                <StyledBorder>
                    <StyledPersonIcon component={GrayPerson} />
                    <StyledDynamicIcon>
                        <img src={Dynamics365} width={50} height={50} />
                    </StyledDynamicIcon>
                </StyledBorder>
            </StyledContent>
        </>
    )
}
