import i18n from 'i18next'

import {PAGES, ZENDESK_LOGIN, INTEGRATION_TYPE, USER_STATUS, ZIX_SUPPORT, PORTAL_BASENAME} from 'ca-common/constants'
import {validatePageAccess} from 'ca-common/utils/permissions'
import {UserInfoDataType, CompanyInfoType, SideMenuItem} from 'ca-common/types'
import {prepareMenu} from 'ca-common/utils/sideMenu'

import Recovery from 'ca-common/icons/sidebar/Recovery.svg'
import Account from 'ca-common/icons/sidebar/Account.svg'
import Settings from 'ca-common/icons/sidebar/Settings.svg'
import Support from 'ca-common/icons/sidebar/Support.svg'
import Wallet from 'ca-common/icons/sidebar/Wallet.svg'
import Homepage from 'ca-common/icons/sidebar/Homepage.svg'
import Jobs from 'ca-common/icons/sidebar/Jobs.svg'

import Audit from 'ca-common/icons/old/uEA54-homescreen-security-audit.svg'
import Activity from 'ca-common/icons/old/uEA3F-system-activity.svg'

import SubmenuSettings from 'ca-common/icons/old/uEA1D-homescreen-settings.svg'
import ManagementSettings from 'ca-common/icons/old/uEA19-homescreen-management-settings.svg'
import Security from 'ca-common/icons/old/uEA1C-homescreen-security.svg'
import NotificationSettings from 'ca-common/icons/old/uEA1A-homescreen-notification-settings.svg'

import Payment from 'ca-common/icons/sidebar/billing/billing_payment.svg'
import Clock from 'ca-common/icons/old/uEA17-homescreen-clock.svg'
import Status from 'ca-common/icons/sidebar/billing/billing_status.svg'
import IntegrationMenu from 'ca-common/icons/integrations/menu.svg'

export const preparePortalMenuLink = (link: string, isOldApp: boolean) => {
    if (!isOldApp) {
        return link
    }

    return `/${PORTAL_BASENAME}${link}`
}

export const getPortalSideMenu = ({
    companyInfo,
    userInfo,
    isOldApp = false
}: {
    companyInfo: CompanyInfoType
    userInfo: UserInfoDataType
    isOldApp?: boolean
}): SideMenuItem[] => {
    if (!userInfo.permissions) {
        return []
    }

    const isNewRecoveryPage = /\/recovery\/.+\/.+/.test(window.location.href)

    const temporary = [
        {
            name: PAGES.BACKUPS,
            icon: Homepage,
            hide: !validatePageAccess({page: PAGES.BACKUPS, userInfo, companyInfo}),
            title: i18n.t(`sideMenu:buttons:${PAGES.BACKUPS}`),
            link: preparePortalMenuLink(`/${PAGES.BACKUPS}`, isOldApp),
            extraLinksForHighlight: [`/${PAGES.RECOVERY}`]
        },
        {
            name: PAGES.JOBS,
            link: preparePortalMenuLink(`/${PAGES.JOBS}`, isOldApp),
            icon: Jobs,
            hide: !validatePageAccess({page: PAGES.JOBS, userInfo, companyInfo}),
            title: i18n.t(`sideMenu:buttons:${PAGES.JOBS}`)
        },
        isNewRecoveryPage && !isOldApp
            ? {
                  name: PAGES.RESTORE,
                  icon: Recovery,
                  pureLink: false,
                  link: '/restore',
                  hide: !validatePageAccess({page: PAGES.RESTORE, userInfo, companyInfo}),
                  title: i18n.t(`sideMenu:buttons:${PAGES.RESTORE}`)
              }
            : {
                  name: PAGES.RESTORE,
                  icon: Recovery,
                  pureLink: true,
                  link: '/App/restore',
                  hide: !validatePageAccess({page: PAGES.RESTORE, userInfo, companyInfo}),
                  title: i18n.t(`sideMenu:buttons:${PAGES.RESTORE}`),
                  className: isOldApp ? 'active-menu-item' : ''
              },
        {
            name: PAGES.BILLING,
            link: preparePortalMenuLink(`/${PAGES.BILLING}`, isOldApp),
            icon: Wallet,
            hide: !validatePageAccess({page: PAGES.BILLING, userInfo, companyInfo}),
            title: i18n.t(`sideMenu:buttons:${PAGES.BILLING}`),
            submenu: [
                {
                    icon: Status,
                    name: PAGES.STATUS,
                    link: preparePortalMenuLink(`/${PAGES.BILLING}/${PAGES.STATUS}`, isOldApp),
                    title: i18n.t('billing:status:title'),
                    hide: !validatePageAccess({page: PAGES.STATUS, userInfo, companyInfo})
                },
                {
                    icon: Clock,
                    name: PAGES.HISTORY,
                    link: preparePortalMenuLink(`/${PAGES.BILLING}/${PAGES.HISTORY}`, isOldApp),
                    title: i18n.t('billing:history:title'),
                    hide:
                        userInfo.status === USER_STATUS.TRIAL ||
                        !validatePageAccess({page: PAGES.HISTORY, userInfo, companyInfo})
                },
                {
                    icon: Payment,
                    name: PAGES.PAYMENTS_DETAILS,
                    link: preparePortalMenuLink(`/${PAGES.BILLING}/${PAGES.PAYMENTS_DETAILS}`, isOldApp),
                    title: i18n.t(`billing:${PAGES.PAYMENTS_DETAILS}:title`),
                    hide:
                        userInfo.status === USER_STATUS.TRIAL ||
                        !validatePageAccess({page: PAGES.PAYMENTS_DETAILS, userInfo, companyInfo}),
                    extraLinksForHighlight: [`/${PAGES.BILLING}/${PAGES.ANNUAL_SUBSCRIPTION}`]
                }
            ]
        },
        {
            name: PAGES.ACCOUNT_ACTIVITY,
            link: preparePortalMenuLink(`/${PAGES.ACCOUNT_ACTIVITY}`, isOldApp),
            icon: Account,
            hide: !validatePageAccess({page: PAGES.ACCOUNT_ACTIVITY, userInfo, companyInfo}),
            title: i18n.t(`sideMenu:buttons:${PAGES.ACCOUNT_ACTIVITY}`),
            submenu: [
                {
                    icon: Activity,
                    name: PAGES.ACTIVITY_SYSTEM,
                    title: i18n.t('accountActivity:system:title'),
                    link: preparePortalMenuLink(`/${PAGES.ACCOUNT_ACTIVITY}/${PAGES.ACTIVITY_SYSTEM}`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.SYSTEM_ACTIVITY, userInfo, companyInfo})
                },
                {
                    icon: Audit,
                    name: PAGES.ACTIVITY_SECURITY,
                    title: i18n.t('accountActivity:security:title'),
                    link: preparePortalMenuLink(`/${PAGES.ACCOUNT_ACTIVITY}/${PAGES.ACTIVITY_SECURITY}`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.ACCOUNT_SECURITY_AUDIT, userInfo, companyInfo})
                }
            ]
        },
        {
            name: PAGES.SETTINGS,
            link: preparePortalMenuLink(`/${PAGES.SETTINGS}`, isOldApp),
            icon: Settings,
            title: i18n.t(`sideMenu:buttons:${PAGES.SETTINGS}`),
            hide: !validatePageAccess({page: PAGES.SETTINGS, userInfo, companyInfo}),
            submenu: [
                {
                    name: PAGES.ACCOUNT,
                    title: i18n.t('settings:menu:account:name'),
                    icon: SubmenuSettings,
                    link: preparePortalMenuLink(`/${PAGES.SETTINGS}/${PAGES.ACCOUNT}`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.ACCOUNT, userInfo, companyInfo})
                },
                {
                    name: PAGES.USERS,
                    title:
                        userInfo.integrationType === INTEGRATION_TYPE.ZIX
                            ? i18n.t('settings:menu:users:zixName')
                            : i18n.t('settings:menu:users:name'),
                    icon: ManagementSettings,
                    link: preparePortalMenuLink(`/${PAGES.SETTINGS}/users`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.USERS, userInfo, companyInfo})
                },
                {
                    name: PAGES.SECURITY,
                    title: i18n.t('settings:menu:security:name'),
                    icon: Security,
                    link: preparePortalMenuLink(`/${PAGES.SETTINGS}/security`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.SECURITY, userInfo, companyInfo})
                },
                {
                    name: PAGES.NOTIFICATIONS,
                    title: i18n.t('settings:menu:notifications:name'),
                    icon: NotificationSettings,
                    link: preparePortalMenuLink(`/${PAGES.SETTINGS}/${PAGES.NOTIFICATIONS}`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.NOTIFICATIONS, userInfo, companyInfo})
                },
                {
                    name: PAGES.INTEGRATION,
                    title: i18n.t('settings:menu:integrations:name'),
                    icon: IntegrationMenu,
                    link: preparePortalMenuLink(`/${PAGES.SETTINGS}/${PAGES.INTEGRATION}`, isOldApp),
                    hide: !validatePageAccess({page: PAGES.INTEGRATION, userInfo})
                }
            ]
        },
        !companyInfo.whiteLable
            ? {
                  name: PAGES.SUPPORT,
                  icon: Support,
                  link: userInfo.integrationType === INTEGRATION_TYPE.ZIX ? ZIX_SUPPORT : ZENDESK_LOGIN,
                  pureLink: true,
                  target: '_blank',
                  bottom: true, // divider top from bottom sidemenu items
                  hide: !validatePageAccess({page: PAGES.SUPPORT, userInfo, companyInfo}),
                  title: i18n.t(`sideMenu:buttons:${PAGES.SUPPORT}`)
              }
            : {
                  name: PAGES.SUPPORT,
                  icon: Support,
                  bottom: true,
                  hide: !validatePageAccess({page: PAGES.SUPPORT, userInfo, companyInfo}),
                  title: i18n.t(`sideMenu:buttons:${PAGES.SUPPORT}`),
                  link: preparePortalMenuLink(`/${PAGES.SUPPORT}`, isOldApp)
              }
    ]

    return prepareMenu(temporary)
}
