import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'

import Info from 'ca-common/icons/Info.svg'
import {StyledSubtitle, StyledFormItem, StyledInput, StyledSection} from './StyledJobLabel'

type Props = {
    setJobLabel: any
    jobLabel: string
    fullWidth?: boolean
}
export const JobLabel = ({jobLabel, setJobLabel, fullWidth}: Props) => {
    const {t} = useTranslation()
    return (
        <>
            <StyledSubtitle fullWidth={fullWidth}>
                <div>
                    {t(`recovery:wizard:jobLabel:title`)}
                    <span>{t(`recovery:wizard:jobLabel:subtitle`)}</span>
                    <Tooltip title={t(`recovery:wizard:jobLabel:tooltip`)} placement="bottom">
                        <Icon component={Info} />
                    </Tooltip>
                </div>
                <StyledSection>{t(`recovery:wizard:jobLabel:validation`)}</StyledSection>
            </StyledSubtitle>

            <StyledFormItem
                name="jobLabel"
                colon={false}
                fullWidth={fullWidth}
                rules={[
                    {
                        required: false,
                        whitespace: true,
                        message: t('forms:oldUI:recovery:invalidSpaceError')
                    }
                ]}
            >
                <StyledInput
                    placeholder={t(`recovery:wizard:jobLabel:placeholder`)}
                    maxLength={50}
                    onChange={e => {
                        setJobLabel(e.target.value)
                    }}
                />
            </StyledFormItem>
        </>
    )
}
