import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'

import {LineSeparator} from 'ca-common/ui-lib/atoms/LineSeparator'
import {Terms} from 'ca-common/components/PublicSite/Terms'
import {SignService} from 'ca-common/components/SignService'
import {CompanyInfoType, OAuthServiceType} from 'ca-common/types'

import {SignUpForm} from 'src/newcore/features/SignUp/molecules/SignUpForm'

import {FormTitle, SignUpFormSection, ContentWrapper} from './StyledFormBlock'

type Props = {
    services: OAuthServiceType[]
    companyInfo: CompanyInfoType
    error: string | null | string[]
    onSubmit: (arg0: any, arg1: string) => void
    loading: boolean
    isEmailError: boolean
    setError: React.Dispatch<React.SetStateAction<string | null | string[]>>
    setIsEmailError: React.Dispatch<React.SetStateAction<boolean>>
}

export const SignUpFormBlock = ({services, companyInfo, onSubmit, ...rest}: Props): JSX.Element => {
    const params = useLocation()
    const {t} = useTranslation()
    const {isCloudally, zix, name} = companyInfo
    return (
        <SignUpFormSection>
            <ContentWrapper>
                <FormTitle>{zix ? t('sign:up:noTrial', {CompanyName: name}) : t('sign:up:trial')}</FormTitle>
                <SignUpForm queryParams={params.search} companyInfo={companyInfo} onSubmit={onSubmit} {...rest} />
                {isCloudally && !zix && (
                    <Fragment>
                        <LineSeparator text={t('sign:up:separator')} />
                        <SignService services={services} isSignUp isNew />
                    </Fragment>
                )}
                <Terms isCloudally={isCloudally} />
            </ContentWrapper>
        </SignUpFormSection>
    )
}
