import styled from 'styled-components'

import variables from 'ca-common/variables'
import {Alert} from 'ca-common/ui-lib/atoms/Alert'

export const StyledAlert = styled(Alert)`
    margin-bottom: ${variables.gutterSm};
`
export const StyledPreferncesTitle = styled.div`
    font-size: ${variables.gutterSm};
    font-family: ${variables.publicPageFont};
    font-weight: 400;
    padding-bottom: ${variables.gutterXs};
`
