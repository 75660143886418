import React, {useCallback, useEffect, useState} from 'react'
import {Form, Input, Radio, Select, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import {RadioChangeEvent} from 'antd/lib/radio'
import {FULL_RECOVERY_ERRORS, RecoveryErrorType, WizardRestoreOptions} from 'src/newcore/features/RecoveryWizard/lib'
import {InlineErrorMessage} from 'src/newcore/features/RecoveryWizard/atoms/InlineErrorMessage'
import {RestoreOptions} from 'src/newcore/features/RecoveryWizard/atoms/RestoreOptions'
import {JobLabel} from 'src/newcore/features/RecoveryWizard/atoms/JobLabel'

import {InfoIcon, StyledRadio, StyledWrapper, StyledRestoreSubtitle} from './StyledRestoreDestinationSelection'

import Info from 'ca-common/icons/Info.svg'

const {Option} = Select

export type RestoreDestinationSelectionProps = {
    account: string
    targetAccount: string | undefined
    setTargetAccount: React.Dispatch<React.SetStateAction<string>>
    allEntities: {entity: string}[]
    error?: RecoveryErrorType
    restoreOptions: WizardRestoreOptions
    setRestoreOptions: (payload: WizardRestoreOptions) => void
    isSelective: boolean
    isMsEndUser: boolean
    jobLabel: string
    setJobLabel: any
}

export const RestoreDestinationSelection = ({
    account,
    targetAccount,
    setTargetAccount,
    allEntities,
    error,
    setRestoreOptions,
    restoreOptions,
    isSelective,
    jobLabel,
    setJobLabel,
    isMsEndUser
}: RestoreDestinationSelectionProps): JSX.Element => {
    const [isDifferentAccount, setIsDifferentAccount] = useState(account !== targetAccount)
    const [searchValue, setSearchValue] = useState('')
    const handleRadioClick = useCallback((e: RadioChangeEvent) => setIsDifferentAccount(e.target.value), [])
    const handleSelectChange = useCallback((value: string) => setTargetAccount(value), [])
    const {t} = useTranslation()
    const [form] = Form.useForm()

    useEffect(() => {
        if (!isDifferentAccount) setTargetAccount(account)
    }, [isDifferentAccount])

    return (
        <StyledWrapper isSelective={isSelective}>
            {!isMsEndUser && (
                <Form form={form}>
                    <JobLabel jobLabel={jobLabel} setJobLabel={setJobLabel}></JobLabel>
                    <StyledRestoreSubtitle>
                        {t(`recovery:wizard:restoreDestinationSelection:restoreDestination`)}
                    </StyledRestoreSubtitle>
                    <Radio.Group onChange={handleRadioClick} value={isDifferentAccount}>
                        <StyledRadio checked={!isDifferentAccount} value={false}>
                            {t(`recovery:wizard:restoreDestinationSelection:originalAccount`)}
                        </StyledRadio>
                        <StyledRadio checked={isDifferentAccount} value={true}>
                            {t(`recovery:wizard:restoreDestinationSelection:anotherAccount`)}
                            <Tooltip title={t(`recovery:wizard:restoreDestinationSelection:tooltip`)}>
                                <InfoIcon component={Info} />
                            </Tooltip>
                        </StyledRadio>
                    </Radio.Group>
                </Form>
            )}
            {isDifferentAccount && (
                <Select
                    getPopupContainer={t => t.parentElement}
                    showSearch
                    value={targetAccount}
                    style={{width: isSelective ? 260 : 340}}
                    onChange={handleSelectChange}
                    placeholder={t(`recovery:wizard:restoreDestinationSelection:placeholder`)}
                    loading={!allEntities}
                    onMouseLeave={() => (searchValue != '' ? setTargetAccount(searchValue) : '')}
                    onSearch={(value: string) => setSearchValue(value)}
                >
                    {allEntities?.map(entity => (
                        <Option key={entity.entity} value={entity.entity}>
                            {entity.entity}
                        </Option>
                    ))}
                </Select>
            )}
            {!!Object.keys(restoreOptions).length && (
                <RestoreOptions setRestoreOptions={setRestoreOptions} restoreOptions={restoreOptions} />
            )}
            {error?.fullRecoveryErrorType === FULL_RECOVERY_ERRORS.INVALID_PRINCIPAL && (
                <InlineErrorMessage status={error.status} />
            )}
        </StyledWrapper>
    )
}
