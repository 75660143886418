import React from 'react'

import {Trans} from 'react-i18next'

import EnabledDelete from 'ca-common/icons/Delete.svg'

import {
    StyledFooter,
    StyledButton,
    StyledDeleteButton,
    StyledIcon,
    StyledSection,
    StyledText,
    StyledFooterText,
    StyledAlignButton
} from './StyledMultipleButtonFooter'

type MultipleButtonFooter = {
    onCancel: () => any
    onSubmit: () => any
    isSplunkDisabled: boolean
    selectDeletion: () => any
    isEditable: boolean
}

export const MultipleButtonFooter = (props: MultipleButtonFooter) => {
    const {onCancel, onSubmit, isSplunkDisabled, selectDeletion, isEditable} = props

    return (
        <StyledFooter>
            {isEditable && (
                <StyledAlignButton>
                    <StyledDeleteButton onClick={() => selectDeletion()}>
                        <StyledIcon component={EnabledDelete} />
                    </StyledDeleteButton>
                    <StyledFooterText onClick={() => selectDeletion()}>
                        <Trans i18nKey="modals:buttons:delete" />
                    </StyledFooterText>
                </StyledAlignButton>
            )}
            <StyledSection>
                <StyledText onClick={() => onCancel()}>
                    <Trans i18nKey="modals:buttons:cancel" />
                </StyledText>
                <StyledButton type="primary" htmlType="submit" onClick={() => onSubmit()} disabled={isSplunkDisabled}>
                    <Trans i18nKey="modals:buttons:save" />
                </StyledButton>
            </StyledSection>
        </StyledFooter>
    )
}
