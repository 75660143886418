import styled from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'

export const StyledIcon = styled(Icon)<{isMultiLine?: boolean}>`
    display: flex;
    font-size: ${props => (props.isMultiLine ? '42px' : '55px')};
    color: ${variables.old.gray};
`

export const StyledLink = styled(CALink)`
    display: flex;
    flex: 0 0 25%;
    flex-basis: 258px;
    text-decoration: none;

    @media (max-width: ${variables.screenMd}) {
        flex: 0 0 50%;
    }

    @media (max-width: ${variables.screenSm}) {
        flex: 0 0 100%;
    }
`

export const Footer = styled.div`
    display: flex;
    align-items: center;

    color: ${variables.navyAsphalt};
    text-align: center;
`

export const Content = styled.div<{isMultiLine?: boolean}>`
    display: flex;
    align-items: center;
    margin-top: ${props => (props.isMultiLine ? '10%' : '0px')};
`

export const Title = styled.h4`
    display: flex;
    align-items: flex-start;
`
