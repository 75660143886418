export default {
    ACCOUNT_TYPE: {
        PRODUCTION: 'Production',
        SANDBOX: 'Sandbox'
    },
    BackupStatus: {
        BACKUP: 'Backup',
        CANCEL: 'Cancelling',
        FINALIZE: 'Finalizing',
        PREPARE: 'Preparing'
    },
    IN_PLACE_RESTORE_OPTION: {
        DEFAULT: {
            BYPASS: 'Bypass: Existing data will be bypassed and not restored.',
            DUPLICATE: 'Duplicate: Existing data will be duplicated.',
            REPLACE: 'Replace: Restore will overwrite existing live data.'
        }
    },
    REGION: {
        AFRICA_CAPE_TOWN: 'Africa (Cape Town)',
        ASIA_CHINA: 'China (Beijing)',
        ASIA_MUMBAI: 'Asia Pacific (Mumbai)',
        ASIA_SIDNEY: 'Asia Pacific (Sydney)',
        ASIA_SINGAPORE: 'Asia Pacific (Singapore)',
        ASIA_TOKYO: 'Asia Pacific (Tokyo)',
        CANADA: 'Canada (Central)',
        EU: 'Europe (Ireland)',
        EU_FRANKFURT: 'Europe (Frankfurt)',
        EU_PARIS: 'Europe (Paris)',
        LONDON: 'United Kingdom (London)',
        SOUTH_AMERICA: 'South America (Sao Paulo)',
        US_EST: 'US East (Northern Virginia)',
        US_WEST_CAL: 'US West (Northern California)',
        US_WEST_OREGON: 'US West (Oregon)'
    },
    RESTORE_OPTION: {
        DEFAULT: {
            IN_PLACE: 'In-Place: Data will be restored to the live site.',
            NON_DESTRUCTIVE: 'Non-destructive: Data will be restored to a sub-site.'
        },
        DROPBOX: {
            IN_PLACE: 'In-Place: Restore will be performed to the original folder. Existing data will be duplicated.',
            NON_DESTRUCTIVE: 'Non-destructive: Data will be restored to a new folder.'
        }
    },
    STORAGE_TYPE: {
        AMAZON_S3: 'to your Amazon S3',
        AMAZON_S3_COMPATIBLE: 'AWS S3 Compatible',
        AZURE: 'to your Azure Blob',
        BOX: 'to your Box.com',
        CLOUDALLY_S3: 'for download',
        DROPBOX: 'to your Dropbox',
        GOOGLE_CLOUD_PLATFORM: 'Google Cloud Platform'
    },
    accountActivity: {
        error: 'Internal error, please try again later. If the problem persists, contact Support.',
        exportActivatedSuccessfully: {
            SECURITY_AUDIT:
                'Export Security Audit task has been scheduled to run. Once ready you will receive an email notification.',
            SYSTEM_ACTIVITY:
                'Export System Activity task has been scheduled to run. Once ready you will receive an email notification.'
        },
        exportButton: 'Export',
        security: {
            caption: 'Check users actions performed in the system',
            title: 'Security Audit'
        },
        status: {
            partial: 'Partial',
            success: 'Success'
        },
        system: {
            caption: 'Search for activities performed by the system',
            title: 'System Activity'
        }
    },
    accountPage: {
        confirmationSent:
            'Confirmation was sent to your new email <0>{{newEmail}}</0> and is waiting for your approval.',
        discardMessage:
            'Are you sure you want to cancel the email change from<br /> <0>{{email}}</0> to <0>{{newEmail}}</0>?',
        emailExtra: 'If you change your email address, we will send an email to your new address for confirmation. ',
        emailExtraBold: 'The new address will not become active until it is confirmed',
        newEmail: 'New Email - Waiting for Approval',
        note: 'Note:',
        resend: 'Resend Email Confirmation',
        resendMessage: 'Resend link will appear again in <0>{{timer}}</0> seconds'
    },
    backups: {
        ActiveDirectory: {
            description: 'Activate by Active Directory settings'
        },
        GOOGLEOU: {
            description: 'Activate by Google Organizational Units'
        },
        MS365Groups: {
            activationFailed: 'Failed to run bulk',
            description: 'Activate by MS365 Groups'
        },
        actions: {
            BACKUP_NOW: 'Backup Now',
            CANCEL: 'Cancel Backup',
            DELETE: 'Delete Backup',
            PAUSE: 'Pause Backup',
            RECOVERY: 'Recovery',
            RESUME: 'Resume Backup',
            activate: 'Activate',
            edit: 'Edit',
            tooltips: {
                awaitingFirstRun: 'Awaiting First Run',
                backupRunning: 'Backup Running',
                cancel: 'Cancel Backup',
                noBackup: 'No backup to recover'
            },
            update: 'Settings updated'
        },
        activate: {
            error: {
                false: 'pause',
                true: 'activate'
            },
            success: {
                false: 'paused',
                true: 'activated'
            }
        },
        activateAll: 'Activate All',
        activateAllSuccess: 'Tasks successfully {{0}}',
        activateBackup: 'Your new backup has been activated and the initial backup scheduled.',
        activateFailed: 'Failed to {{0}} task, ',
        activateSuccess: 'Task successfully {{0}}',
        activateTasksFailed: 'Error failed to activate all tasks:',
        activateTitle: 'Activate your preferred {{- 0}} {{- 1}}',
        activated: 'activated',
        activatedSuccessfully: '{{succeed}} out of {{total}} has been activated',
        activeBackup: {
            text: {
                activated: 'Active',
                archived: 'Archived',
                licensed: 'Licensed',
                paused: 'Paused',
                totalOf: 'Total of {{actionName}}<br>{{- entityName}}'
            }
        },
        add: 'Add',
        addModalTitle: 'Add {{0}}',
        addSiteFailed: 'Failed to add site',
        addSiteModalTitle: 'Add Site',
        addUserFailed: 'Failed to add user',
        addUserModalMessage: 'to your {{0}} {{1}} list',
        addUserModalTitle: 'Add User',
        adminDetailsTitle: 'Enter your admin details',
        alias: 'Friendly Name',
        all: 'All',
        backupActions: {
            ACTIVATE: {
                responseFailed: 'Failed to activate task',
                responseSuccess: 'Task successfully activated'
            },
            CANCEL: {
                confirm: 'Are you sure you want to cancel backup process?',
                responseFailed: 'Error canceling the task',
                responseSuccess: 'The backup task has been cancelled'
            },
            DELETE: {
                confirm: 'Are you sure you want to delete backup?',
                responseFailed: 'Failed to delete task',
                responseSuccess: 'Task successfully deleted'
            },
            PAUSE: {
                responseFailed: 'Failed to pause task',
                responseSuccess: 'Task successfully paused'
            },
            RUN: {
                responseFailed: 'Failed to schedule the task for run',
                responseSuccess: 'The backup task has been scheduled to run'
            },
            disabledTooltip: 'Select {{- entityName}} first',
            title: 'Action'
        },
        backupActivated: 'New backup task has been created and scheduled to run at {{0}}:00 UTC.',
        backupActivatedMulti: 'New backup task has been created. Choose and activate backup for desired {{0}}.',
        backupFrequency: 'Backup frequency',
        backupHour: 'Backup hour (UTC)',
        backupPreferencesTitle: 'Select your backup preferences',
        bulkActivation: {
            Google: {
                DisableOUtooltip:
                    'This Organizational Unit can’t be selected, because the Google Workspace Marketplace App doesn’t have access to this Organizational Unit.',
                msg: 'You can use one-time activation for selected users or create an activation rule',
                popOverHeader:
                    'Please note, “Automatically activate new users” is enabled in Settings.Do you want to disable it and automatically activate only users that match conditions set in bulk activation rules?',
                title: 'Bulk Activation',
                tooltipInfoGoogleOU:
                    'In order to use bulk activation, please click Grant Access for the Backup App in the Google Workspace Marketplace Apps list. Click here for instructions '
            },
            accountListHeaders: {
                email: 'Email',
                group: 'Group',
                name: 'Account Name',
                site: 'Site'
            },
            accountToActivate: '{{count}} mailbox to activate',
            accountsToActivate: '{{count}} mailboxes to activate',
            action: 'Run Bulk activation',
            activate: 'Perform one-time activation',
            activateRule: 'Create an activation rule',
            choice: {
                ACTIVE_DIRECTORY: {
                    description: 'Use Active Directory settings to activate groups',
                    header: 'Activate by Active Directory settings',
                    newAccounts:
                        'New mailboxes that match to one or more of the listed properties will be activated automatically',
                    title: 'Active Directory Settings',
                    type: 'Active Directory properties'
                },
                CSV: {
                    description: 'Import CSV file in following format: single Account Email column',
                    header: 'Import list of Accounts'
                },
                GOOGLE_OU: {
                    GROUPS: {
                        access_ou: {
                            description:
                                "The Google Workspace Marketplace App doesn't have access to the selected organizational units: {{BlockedOus}} .<br><br>Please provide access in the Google Admin Console or deselect these organizational units.",
                            tooltipDescription:
                                'Note: The Google Workspace Marketplace App no longer has access to {{BlockedOus}}. Users in these OU will not be automatically activated. Please provide access in the Google Admin Console to these organizational units.'
                        },
                        newAccounts:
                            'Users that belong to one or more of the listed organizational units will be activated automatically',
                        title: 'Google Organizational Units',
                        type: 'Google Organizational Units'
                    },
                    description:
                        'Use Google Organizational Units to perform one-time activation or create an activation rule',
                    header: 'Activate by Google Organizational Units'
                },
                GROUPS: {
                    description: 'Use MS365 Groups to activate groups',
                    header: 'Activate by MS365 Groups',
                    newAccounts:
                        'New mailboxes that belong to one or more of the listed groups will be activated automatically',
                    title: 'Microsoft Groups',
                    type: 'MS365 groups'
                },
                SHAREPOINT_URL: {
                    description: 'Use SharePoint URL to perform one-time activation or create an activation rule',
                    header: 'Activate by SharePoint URL'
                },
                URL_CONDITIONS: {
                    newAccounts: 'Sites that match listed conditions will be activated automatically.',
                    title: 'SharePoint URL'
                },
                comingSoon: 'Coming Soon',
                selectType: 'Select Type'
            },
            deactivation: {
                label: 'Deactivation rule for mailboxes removed from rules',
                tooltips: {
                    DELETE: 'Mailboxes removed from groups in activation rules will be deleted.',
                    MAINTAIN_CURRENT_STATUS:
                        'Mailboxes removed from groups in activation rules will maintain current statuses, active mailboxes will continue to backup.',
                    PAUSE: 'Mailboxes removed from groups in activation rules will be paused.'
                },
                types: {
                    DELETE: 'Delete',
                    MAINTAIN_CURRENT_STATUS: 'Maintain current status',
                    PAUSE: 'Pause'
                }
            },
            deactivationRule: 'Deactivation rule for mailboxes removed from rules',
            disabled:
                'Please use OAuth authentication for the backup.\nThis option is available if you use Oauth authentication.',
            fields: {
                searchGroup: {
                    placeholder: 'Search Group'
                }
            },
            groupsValidationError:
                'Activation rules for the following {{type}} were already created: <0>{{- items}}</0>',
            modal: {
                content:
                    'If you enable this option, all activation rules will be deleted.<br />Do you want to continue?',
                title: 'Are you sure you want to enable “Automatically activate new {{- entityName}}”?'
            },
            msg: 'You can choose and activate backup for selected {{- entityName}} or use Bulk Activation',
            noData: 'No Data',
            notification: {
                alert:
                    'Please contact support or your sales representative if you have any questions before changing the backup frequency.',
                description: 'Please make sure you’re aware of cost change before changing backup frequency.',
                headerTitle: '3x daily backups might be subject to additional charge.',
                noteHeader: 'Attention: ',
                title: 'Cost change alert'
            },
            property: 'Property',
            propertyPlaceholder: 'Search property',
            sharepoint: {
                preview: {
                    DOES_NOT_END_WITH: 'URL does not end with {{value}}',
                    DOES_NOT_START_WITH: 'URL does not begin with {{value}}',
                    ENDS_WITH: 'URL ends with {{value}}',
                    STARTS_WITH: 'URL begins with {{value}}'
                },
                url: {
                    conditions: {
                        DOES_NOT_END_WITH: 'Does not end with',
                        DOES_NOT_START_WITH: 'Does not begin with',
                        ENDS_WITH: 'Ends with',
                        STARTS_WITH: 'Begins with'
                    },
                    fields: {
                        condition: {
                            error: 'Filter must not be empty.',
                            placeholder: 'Choose filter'
                        },
                        value: {
                            error: 'The value cannot be empty.',
                            placeholder: 'Fill value'
                        }
                    },
                    text: {
                        or: 'Or',
                        url: 'URL'
                    },
                    tooltips: {
                        DOES_NOT_END_WITH: '.../sites/site name<does not end with>',
                        DOES_NOT_START_WITH: '.../sites/<does not begin with>site name',
                        ENDS_WITH: '.../sites/site name<ends with>',
                        STARTS_WITH: '.../sites/<begins with>site name'
                    }
                }
            },
            sitesListHeaders: {
                site: 'Site'
            },
            title: 'Bulk Activation and Management',
            validationError:
                'Activation rules for the following {{type}} were already created:<br /><0>{{field}}: {{- items}}</0>'
        },
        bulkCondition: {
            delete: 'Are you sure to delete this condition?',
            field: 'Field: '
        },
        byCondition: {
            ALL: 'Continue to Automatically activate new {{- entityName}}',
            BY_RULE: 'Activate only by conditions set through Bulk Activation',
            checkboxLabel: 'Automatically activate new {{- entityName}} that match this condition',
            popoverHeader:
                'Please note, "Automatically activate new {{- entityName}}" is enabled in Settings. Do you want to disable it and automatically activate only {{- entityName}} that match conditions set in bulk activation rules?'
        },
        changeSettings: 'Backup task settings have been updated',
        comingSoon: 'Coming Soon',
        compare: {
            buttons: {
                downloadAll: 'Download ALL',
                restore: 'Restore'
            },
            listHeader: {
                recordCount_one: '{{count}} Changed Record',
                recordCount_other: '{{count}} Changed Records'
            },
            tooltips: {
                download: 'download'
            }
        },
        deactivated: 'deactivate',
        deleteSuccessMsg: 'Service was successfully deleted',
        detectNew: 'Automatically start a backup when a new {{- 0}} is added',
        detectNewInfo: 'Check this box to detect new accounts and start backups automatically.',
        duplicatePopover: {
            openTask: 'Open Task',
            text: 'This <0>{{type}}</0> is backed-up in the following task <0>{{alias}}</0>'
        },
        errorCancelingTask: 'Error in canceling the task:',
        failedDelete: 'Failed to delete service. ',
        failedTo: 'Failed to {{0}} task, ',
        failedToSchedule: 'Failed to schedule the task for run, ',
        howItWorks: 'How it works?',
        indexAll: 'Index all archived data',
        indexText: 'To support searching for specific items to restore/download, your data needs to be indexed.',
        indexTitle: 'Index archived data',
        licenseStatus: {
            LICENSED: 'Licensed',
            NA: 'N/A',
            UNLICENSED: 'Unlicensed'
        },
        licensingTooltip: 'Reflects whether the Exchange mailbox has a Microsoft license or is unlicensed',
        modal: {
            GoogleReAuth: 'To reestablish authentication with Google please contact support',
            cancel: {
                text: 'Are you sure you want to cancel backup process?',
                title: 'Confirm Cancel'
            },
            delete: {
                buttonCancel: 'Cancel',
                buttonDelete: 'Delete',
                gracePeriodNotification: {
                    text:
                        'The backup task and all associated backups have been marked as deleted.<br><br>The data of the backups will be kept for a grace period of 7 days in which you can undelete the backups by contacting Support.<br><br>After 7 days all the data of the backups will be permanently deleted.',
                    title: '7 Day Grace Period'
                },
                sharePointText:
                    "Please note that the backup task you are going to delete may have contained sites also present in other tasks.<br>Such sites are excluded from other backup tasks to prevent duplication of backup data, and they can't be activated for backup in those tasks.<br>After removal of this backup, the sites will not be available for backup in existing backup tasks.<br>If you want to back them up after this task is deleted, please contact <0>{{supportEmail}}</0>.<br><br>Do you want to continue?",
                text: 'Are you sure you wish to delete the backup task of {{- name}} / {{account}}?',
                title: 'Confirm Delete'
            }
        },
        ms365EntityType: {
            group: 'Group',
            team: 'Team'
        },
        new: 'Add Backup Task',
        newActions: {
            ACTIVATE: 'Activate',
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            PAUSE: 'Pause',
            RUN: 'Backup Now'
        },
        newTaskStatus: {
            ARCHIVED: 'Archived',
            DUPLICATE: 'Duplicate',
            FAILED: 'Failed',
            IN_PROCESS: 'In Process',
            NOT_ACTIVE: 'Not Active',
            PARTIAL: 'Partial',
            PAUSED: 'Paused',
            PENDING: 'Pending',
            SCHEDULED: 'Scheduled',
            SUCCESS: 'Success'
        },
        noActiveBackups:
            '<0>Note:</0> You have no active backups. Please select one or more {{entityName}} and activate their backup using the “Action > Activate” menu option',
        notice: 'Notice',
        noticeContent:
            'The automatic addition/deletion of an account, site, domain, table or drive can affect your monthly or annual billing amount',
        officeUserAdded: 'Microsoft 365 user added successfully',
        onedriveSiteAdded: 'OneDrive site added successfully',
        pauseEntityError: 'Failed to {{0}} task, ',
        pauseEntitySuccess: 'Task successfully {{0}}',
        refresh: 'Refresh',
        retentionPeriod: 'Retention Period',
        search: 'Search',
        searchFor: 'Search For',
        seats: 'Seats <0>{{seats}}</0>',
        selectStatus: 'Select status ...',
        sharepointSiteAdded: 'SharePoint site added successfully',
        status: 'Status',
        statusChange: 'The backup task has been {{0}}',
        storageRegion: 'Data Center Location',
        taskCanceled: 'The backup task has been cancelled',
        taskScheduled: 'The backup task has been scheduled to run',
        taskStatus: {
            ACTIVE: 'Active',
            ARCHIVED: 'Archived',
            DISPATCHED: 'Executing',
            INPROCESS: 'In process',
            IN_ANOTHER: 'Duplicate',
            NOTACTIVE: 'Not active',
            PAUSED: 'Paused',
            PENDING: 'Scheduled',
            QUEUED: 'Queued',
            SKIPPED: 'Executing'
        },
        title_one: '{{count}} Backup',
        title_other: '{{count}} Backups',
        totalEntities: '{{total}} Total {{- entityName}}',
        totalOfEntities: '{{affectedByFilters}} of {{total}} Total {{- entityName}}',
        typeConnection: {
            connected: 'Connected',
            disconnected: 'Disconnected'
        },
        unsubscribedErrorMessage: 'Please subscribe in order to resume {{companyName}} services.',
        uploadCSV: {
            activateButton: 'Activate',
            bulkActivationFailed: 'task of bulk activation is failed',
            bulkActivationSuccess: 'task of bulk activation is succeed',
            failedToProcess: 'Failed to process file',
            fileAdded: 'File added',
            fileFormatingWrong: 'File formating doesnt match to requirement format. <0>Download Sample</0>',
            fileSizeInvalid: 'File size exceeds the maximum limit (5Mb)',
            selectHint: 'CSV file with one column - email address, see <0>Sample</0>',
            selectText: 'Drag & drop file or click in this area',
            tryAnotherButton: 'Try Another File',
            uploadButton: 'Upload',
            uploadTitleDescription: 'Upload File With List Of Accounts',
            uploadingButton: 'Uploading'
        }
    },
    billing: {
        activateCard: {
            tooltip: 'To activate a card, first save changes to your billing details.'
        },
        addNewCardDetails: {
            title: 'Credit Card Details'
        },
        alert: {
            emailSentSuccessfulText:
                'An email was sent to <0>{{email}}</0> with your invoice and an option to pay online using a credit card or PayPal',
            emailSentSuccessfulTitle: 'Invoice was sent via email',
            portalSubscribe: 'Choose your subscription plan:',
            portalSubscribeUnavailableCurrency:
                'Currently, monthly subscription is not available for the selected currency.',
            subscribe:
                'Please choose how to be charged for your monthly subscription customers — by Credit Card or by sending manual Invoice.\nPlease note Annual subscriptions will be charged individually by invoice'
        },
        cardDeleteConfirmation: {
            annotationOfCancel: 'Before deleting, add a new credit card to keep the subscription.',
            annotationOfUnsubscribe: 'Warning! After deleting the last credit card, you will be unsubscribed.',
            unsubscribe: 'Unsubscribe'
        },
        cardDetails: {
            cardHodersName: "Card Holder's Name:",
            cardNumber: 'Card Number:'
        },
        history: {
            description: 'View history of payments',
            title: 'History'
        },
        labels: {
            forecastForNextPayment: 'Forecast for next payment',
            nextRenewalDate: 'Next renewal date',
            paymentStatus: 'Payments status',
            subscription: 'Subscription',
            updateCreditCard: 'Update <0>credit card details</0>'
        },
        monthlyInvoice:
            'Monthly invoice subscription: your monthly invoice with link to payment gateway is sent to your email address',
        note:
            '<0>Note:</0> The billing status is usually updated within a few minutes after the payment is complete. If the status has not updated after 24 hours, please contact support.',
        paymentDetails: {
            description: 'Change your payment details',
            title: 'Payment Details'
        },
        status: {
            DUE: 'Amount Due',
            NO_BILLS: 'No bills have been issued yet',
            OVERDUE: 'Amount Due',
            PAID: 'Paid',
            UNPAID: 'Amount Due',
            description: 'Billing status of clients',
            discount: 'Discount',
            paymentForecast: {
                dueOn: 'Due on',
                nextPayment: 'next payment:',
                overdue: 'unpaid',
                paymentForecast: 'Payment forecast:',
                previousPaymentTitle: 'Previous payments:',
                renewalDate: 'Renewal Date:',
                upcoming: 'paid'
            },
            previousUnpaidPayments: 'Previous payments: {{count}} unpaid',
            title: 'Status',
            total: 'Total'
        },
        statusDecoding: {
            DUE: 'Invoice for payment',
            OVERDUE: 'Overdue'
        },
        subscriptionType: {
            annual: 'Annual',
            credit: 'Credit Card',
            invoice: 'Invoice',
            monthly: 'Monthly',
            trial: 'Trial'
        },
        subscriptionTypeDecoding: {
            ANNUAL: 'Annual',
            MONTHLY: 'Monthly'
        },
        tableTitle: {
            DUE: 'Open Invoices',
            NO_BILLS: 'Payment Forecast',
            OVERDUE: 'Overdue Invoices',
            PAID: 'Payment Forecast',
            UNPAID: 'Unpaid Bills'
        },
        toasts: {
            addNewPartnersAccount: {
                error: 'Account was not added. Error: {{errorDescription}}',
                success: 'Account was added'
            },
            annualSubscriptions: {
                success: 'The account has been subscribed.',
                successHeader: 'Account subscribed'
            },
            setPartnerPaymentDetails: {
                distributorSuccess: 'Partner payment details were successfully updated',
                error: 'Internal error, please try again later. If the problem persists, contact Support.',
                success: 'Your billing information has been saved.'
            },
            setPaymentDetails: {
                error: 'Internal error, please try again later. If the problem persists, contact Support.',
                success: 'Your Billing details had been updated'
            },
            whiteLabel: {
                error: 'Internal error, please try again later. If the problem persists, contact Support.',
                success: 'You have been successfully subscribed.'
            }
        },
        tooltips: {
            emailInvoice: 'Resend your invoice with an option to pay online using PayPal or credit card',
            payInvoice: 'Pay Invoice',
            payInvoiceTooltip:
                "The payment is done on payment provider's side and we don't store credit card details. By clicking on the button you will be redirected to the payment gateway."
        },
        trialMessage: {
            annual: {
                link: 'monthly subscription page',
                message:
                    'fill in the billing details to start annual plan. If you want to sign up to the monthly subscription',
                title: 'Complete subscription'
            },
            monthly: {
                link: 'annual subscription page',
                message:
                    'fill in the billing and credit card details to start monthly subscription. If you want to sign up to the annual plan',
                title: 'Complete subscription'
            }
        },
        trialUserMessage: {
            annual: {
                link: 'monthly subscription page',
                message:
                    'fill in the billing details to start annual plan. If you want to sign up to the monthly subscription',
                title: 'Complete subscription'
            },
            monthly: {
                link: 'annual subscription page',
                message:
                    'fill in the billing and credit card details to start monthly subscription. If you want to sign up to the annual plan',
                title: 'Complete subscription'
            }
        },
        unpaidBills: {
            consolidatedPayment: {
                button: 'Update Credit Card',
                description:
                    'We were unable to charge your credit card. Please update your credit card to resolve the issue.',
                title: 'Consolidated Monthly Payment'
            },
            description: 'Due and Overdue payments',
            failedCreditCard: {
                notification: 'Payment failed for this account',
                title: 'Failed Credit Card Payments',
                tooltip:
                    'To resolve this issue, log in to the account of {{accountName}} and update its credit card details',
                total: '{{total}} payments'
            },
            notifications: {
                invoiceByEmail: {
                    message:
                        'An email was sent to <0>{{email}}</0> with your invoice and an option to pay online using a credit card or PayPal',
                    title: 'Invoice was sent via email'
                }
            },
            openInvoices: {
                title: 'Open Invoices',
                total: '{{total}} payments'
            },
            paymentStatus: {
                due: 'Due:',
                noBills: 'There are no unpaid bills to display',
                overdue: 'Overdue:',
                title: 'Payment status',
                total: 'Total Amount:',
                totalDue: 'Amount Due:',
                totalOverdue: 'Amount Overdue:'
            },
            title: 'Unpaid Bills'
        },
        zoozActions: {
            activateCard: 'Activate card',
            addCard: 'Add new card',
            billCard: 'Bill this card',
            completeSubscriptions: 'Complete Subscription',
            deleteCard: 'Delete card',
            saveInformation: 'Save information',
            subscribe: 'Subscribe',
            updateBillingDetails: 'Update billing details'
        },
        zoozMessages: {
            amex:
                'The selected payment method (AMEX) is incompatible with the billing currency of your account.<0/> American Express can only be used with USD as billing currency. You can change the billing currency by contacting Support at <1>{{supportEmail}}</1>. Alternatively you can use Visa of Mastercard for the payment method.',
            amexPartnerPortal: {
                alternative: ' Alternatively you can use Visa of Mastercard for the payment method.',
                description:
                    'American Express can only be used with USD as billing currency. You can change the billing currency by contacting Support',
                preview: 'The selected payment method (AMEX) is incompatible with the billing currency of your account.'
            },
            billingDetailsUpdated: 'Billing details are updated',
            cardIsExpired: 'Card is expired, try another one or contact Support',
            cardIsInvalid: 'Card is invalid, try another one or contact Support',
            cardWasActivated: 'Card has been activated',
            cardSavedMessage:
                'Credit card was saved. Your new credit card will be charged within 24 hours if there is an outstanding payment.',
            cardWasAdded: 'Card has been added',
            cardWasAddedAndSubscribed: 'Card has been added, you are subscribed now',
            cardWasDeleted: 'Card has been deleted',
            errors: {
                262416: 'Sorry, but there is a problem with the card number. Please check the number and try again'
            },
            fillFormFields: 'Please complete the fields marked in red',
            invalidPartnerSubscription:
                'We were unable to create subscription due to missing data. <br>Please contact Support <0>{{mail}}</0>',
            missingSubscription: 'Your account can not be subscribed at this time. <br>Please contact Support',
            subscriptionAlreadyExist: 'Your account is already subscribed',
            subscriptionFailed: 'Subscription has not been activated, please try again',
            unexpectedSubscriptionException:
                'Unexpected exception has happened. <br>Please contact Support <0>{{mail}}</0>'
        }
    },
    breadcrumb: {
        homepage: 'Homepage',
        jobs: {
            downloadReport: 'Download File(s)',
            mainPage: 'Jobs',
            tooltip:
                'Overview and Drill-down view of Restores/Exports: A new dashboard with both single-pane high-level and drill-down views of recent restores and exports. Sort jobs by date or use filters to quickly search for them. Monitor the status and progress with a direct link to the related activity log entries.'
        },
        newbackup: 'New Backup',
        securityAudit: 'Security Audit',
        userManagement: {
            new: 'New'
        }
    },
    common: {
        buttons: {
            continue: 'Continue'
        },
        show: 'Show',
        test: 'Test',
        title: 'App | {{companyName}}',
        uiLib: {
            alert: {
                date: 'Updated on: ',
                link: 'View '
            }
        }
    },
    exportUsage: {
        exportButtonTooltip: 'Export Usage Report',
        notification:
            'Export Usage Report task has been scheduled to run. Once it is ready, an email notification will be sent to<br> <0>{{email}}</0>.',
        text:
            'The report will include a list of all the backed up entities (mailboxes, sites, teams etc.)<br> from all the backed up services.<br>The report will be sent via email to <0>{{email}}</0>.',
        title: 'Confirm Export of Usage Report',
        title_many: 'Confirm Export of Usage Report for {{selectedCount}} Accounts',
        title_one: 'Confirm Export of Usage Report for {{count}} Account'
    },
    footer: {
        aws: 'Powered by AWS Cloud Computing',
        company: 'Company',
        companyLinks: {
            aboutUs: 'About Us',
            careers: 'Careers',
            events: 'Events',
            management: 'Managment',
            news: 'News & Press',
            support: 'Support'
        },
        copyright: '©Copyright CloudAlly™️ {{year}}',
        emailUs: 'Email Us',
        emails: {
            info: 'General Inqureries',
            sales: 'Sales',
            support: 'Customer Support'
        },
        footerLinks: {
            cookie: 'Cookie Policy',
            gdpr: 'GDPR',
            privacy: 'Privacy Policy',
            security: 'Security',
            sla: 'SLA',
            terms: 'Terms & Conditions'
        },
        giveUs: 'Give us a call',
        poweredBy: 'powered by',
        pricingLinks: {
            blog: 'Blog',
            contact: 'Contact',
            partners: 'Partners',
            pricing: 'Pricing',
            supportHub: 'Support Hub'
        },
        products: 'Products',
        productsLinks: {
            box: 'Box Backup',
            dropbox: 'Dropbox Backup',
            gSuit: 'Google Workspace Backup',
            office365: 'Microsoft 365 Backup',
            oneDrive: 'OneDrive Backup',
            sfc: 'SalesForce Backup',
            sharePoint: 'SharePoint Backup'
        },
        resources: 'Resources',
        resourcesLinks: {
            brochures: 'Brochures',
            disaster: 'Disaster Recovery',
            faq: 'FAQ',
            gdpr: 'GDPR',
            knowledge: 'Knowledge Base',
            videos: 'Videos',
            why: 'Why Backup'
        }
    },
    forecastCard: {
        annualGrowth: 'growth included',
        annualGrowthBold: '25% annual',
        annualSubtitle: 'With one-time annual invoice payment',
        annualSubtitleCC: 'With recurring credit card payments<br> or one-time invoice',
        annualTitle: 'Yearly',
        currency: 'Currency:',
        discountAnnual: 'on annual plan',
        discountAnnualBold: 'Save {{discountAnnual}}/Year',
        monthlyNote:
            'based on current {{totalMonthly}}/month forecast<br> actual monthly fee can fluctuate depending<br> on backup scope at the billing date',
        monthlySubtitle: 'With monthly recurring credit card payments',
        monthlyTitle: 'Monthly',
        subscribeButtonText: 'SUBSCRIBE',
        subscriptionNotAvailable:
            'Currently monthly subscription is not available for selected currency. <br><br>Please change the currency or proceed with annual subscription.'
    },
    forms: {
        addEntity: {
            response: {
                added: '{{0}} {{1}} added successfully',
                error: '<strong>Error,</strong> Failed to add {{entityName}}'
            }
        },
        addNewAccount: {
            AMAZON_S3: {
                s3bucket: {
                    label: 'Amazon S3 bucket name'
                },
                s3key: {
                    label: 'Amazon S3 key'
                },
                s3region: {
                    label: 'Amazon S3 region',
                    placeholder: 'Select your location'
                },
                s3secret: {
                    label: 'Amazon S3 secret key'
                }
            },
            AMAZON_S3_COMPATIBLE: {
                s3bucket: {
                    label: 'S3 compatible bucket name'
                },
                s3endpoint: {
                    label: 'S3 compatible endpoint',
                    placeholder: 'Endpoint (mandatory, free text) *'
                },
                s3key: {
                    label: 'S3 compatible key'
                },
                s3region: {
                    label: 'Region:',
                    placeholder: 'Select your location'
                },
                s3secret: {
                    label: 'S3 compatible secret key'
                }
            },
            AZURE: {
                s3bucket: {
                    label: 'Container name'
                },
                s3key: {
                    label: 'Account Name'
                },
                s3region: {
                    label: 'Storage region'
                },
                s3secret: {
                    label: 'Account Key'
                }
            },
            GOOGLE_CLOUD_PLATFORM: {
                s3bucket: {
                    label: 'GCP bucket name'
                },
                s3key: {
                    label: 'Storage key'
                },
                s3region: {
                    label: 'Storage region',
                    placeholder: 'Select your location'
                },
                s3secret: {
                    label: 'Storage secret key'
                }
            },
            accountEmail: {
                label: 'Account email'
            },
            accountName: {
                label: 'Account name'
            },
            archiveLocation: {
                label: 'Data Center Location'
            },
            partnerId: {
                label: 'Partner ID'
            },
            password: {
                label: 'Password'
            },
            retypePassword: {
                label: 'Retype password'
            }
        },
        advancedSearch: {
            actions: {
                submit: {
                    label: 'ok'
                }
            },
            fields: {
                createdById: {
                    label: 'Created By Id',
                    placeholder: 'Enter a term that matches a part of the file name'
                },
                dateFrom: {
                    label: 'Date from',
                    placeholder: 'From initial backup'
                },
                dateTo: {
                    label: 'Date to',
                    placeholder: 'Today'
                },
                from: {
                    label: 'From',
                    placeholder: 'Enter a name or an email address'
                },
                isDeleted: {
                    label: 'Is Deleted'
                },
                itemName: {
                    label: 'Item name',
                    placeholder: 'Enter a term that matches a part of the file name'
                },
                owner: {
                    label: 'Author',
                    placeholder: 'Enter an email address'
                },
                source: {
                    label: 'Source'
                },
                subject: {
                    label: 'Subject',
                    placeholder: 'Enter a term that matches a part of the mail subject'
                },
                table: {
                    label: 'Table'
                },
                to: {
                    label: 'To',
                    placeholder: 'Enter a name or an email address'
                },
                what: {
                    label: 'Description',
                    placeholder: 'Enter a text that matches a part of the event description'
                },
                who: {
                    label: 'Owner',
                    placeholder: 'Enter a name or an email address'
                }
            },
            label: 'Search for:'
        },
        awsS3Compatible: {
            fields: {
                email: {
                    label: 'Account email:'
                },
                location: {
                    label: 'Data Center Location:',
                    placeholder: 'Select your location'
                },
                name: {
                    label: 'Account name:'
                },
                partnerId: {
                    label: 'Partner ID:'
                },
                password: {
                    label: 'Password:'
                },
                retypePassword: {
                    label: 'Retype password:'
                },
                s3bucket: {
                    label: 'S3 compatible bucket name:'
                },
                s3endpoint: {
                    label: 'S3 compatible endpoint:',
                    placeholder: 'Endpoint (mandatory, free text) *'
                },
                s3key: {
                    label: 'S3 compatible key:'
                },
                s3region: {
                    label: 'Region:',
                    placeholder: 'Select your location'
                },
                s3secret: {
                    label: 'S3 compatible secret key:'
                }
            },
            title: 'AWS S3 Compatible'
        },
        azure: {
            fields: {
                container: {
                    label: 'Container name'
                },
                key: {
                    label: 'Account Key'
                },
                name: {
                    label: 'Account Name'
                },
                region: {
                    label: 'Storage region'
                }
            },
            title: 'Customer Azure blob'
        },
        backupAuth: {
            fields: {
                DropboxBusiness: {
                    label: 'Dropbox'
                },
                DropboxProfessional: {
                    label: 'Dropbox Professional'
                },
                IMAPName: {
                    label: 'User Name'
                },
                IMAPPassword: {
                    label: 'Password'
                },
                IMAPUrl: {
                    label: 'Server Name'
                },
                MS365Account: {
                    label: 'Microsoft 365 admin Mail address'
                },
                MS365Password: {
                    label: 'Microsoft 365 admin Mail password'
                },
                OneDriveAccount: {
                    label: 'User Name'
                },
                OneDrivePassword: {
                    label: 'Password'
                },
                OneDriveUrl: {
                    label: 'OneDrive URL'
                },
                SFCBackupProduction: {
                    label: 'Production backup'
                },
                SFCBackupSandbox: {
                    label: 'Sandbox backup'
                },
                SharepointAccount: {
                    label: 'User Name'
                },
                SharepointPassword: {
                    label: 'Password'
                },
                SharepointUrl: {
                    label: 'SharePoint URL'
                },
                authType: {
                    credential: {
                        disabled: {
                            MS365:
                                "Microsoft will be deprecating Basic Authentication for O365 services prior to it's removal on October 13, 2020. As a result, you will need to re-authorize using OAuth, a token based authorization process that eliminates the use of usernames and passwords."
                        },
                        label: 'Use credential based authorization'
                    },
                    oauth: {
                        label: 'Use OAuth based authorization'
                    }
                },
                backupName: {
                    label: 'Backup Name',
                    placeholder: 'Enter your backup name'
                },
                ddbAccessKey: {
                    label: 'DynamoDB Access Key'
                },
                ddbRegion: {
                    label: 'DynamoDB Region'
                },
                ddbSecretKey: {
                    label: 'DynamoDB Secret Key'
                },
                sdbAccessKey: {
                    label: 'SimpleDB Access Key'
                },
                sdbRegion: {
                    label: 'SimpleDB Region'
                },
                sdbSecretKey: {
                    label: 'SimpleDB Secret Key'
                },
                yahooMail: {
                    label: 'Yahoo Mail address'
                },
                yahooPassword: {
                    label: 'Yahoo Mail password'
                }
            },
            howTo: {
                MS365: {
                    text:
                        'How to ensure that impersonation is configured in your Microsoft 365: <br /><br />In the Classic Admin Center:<br /> <ol> <li> Open your Microsoft 365 dashboard. Select the <strong>"Admin"</strong> menu and then click the <strong>"Exchange"</strong> menu item. </li> <li> Go to the <strong>"Permissions"</strong> and double click the <strong>"Discovery Management..."</strong> </li> <li> Add the <strong>"ApplicationImpersonation"</strong> Role to this roles group. </li> <li> Go to the <strong>"Permissions"</strong> and double click the <strong>"Organization Management..."</strong> </li> <li> Add the <strong>"ApplicationImpersonation"</strong> Role to this roles group. </li> </ol> <br /> In the New Admin Center: <br /> <ol> <li> Open your Microsoft 365 dashboard. Select the <strong>Admin</strong> menu and then click the Exchange menu item. </li> <li> Click on Roles → Admin Roles </li> <li> Select the <strong>Discovery Management</strong> role group. </li> <li> Add the <strong>ApplicationImpersonation</strong> Role to this role group. </li> <li> Select the <strong>Organization Management</strong> role group </li> <li> Add the <strong>ApplicationImpersonation</strong> Role to this roles group. </li> </ol> Please wait 5-10 minutes for changes to be applied.',
                    title: 'Application Impersonation'
                },
                MS365GROUPS: {
                    text:
                        'How to ensure that impersonation is configured in your Microsoft 365: <br /><br />In the Classic Admin Center:<br /> <ol> <li> Open your Microsoft 365 dashboard. Select the <strong>"Admin"</strong> menu and then click the <strong>"Exchange"</strong> menu item. </li> <li> Go to the <strong>"Permissions"</strong> and double click the <strong>"Discovery Management..."</strong> </li> <li> Add the <strong>"ApplicationImpersonation"</strong> Role to this roles group. </li> <li> Go to the <strong>"Permissions"</strong> and double click the <strong>"Organization Management..."</strong> </li> <li> Add the <strong>"ApplicationImpersonation"</strong> Role to this roles group. </li> </ol> <br /> In the New Admin Center: <br /> <ol> <li> Open your Microsoft 365 dashboard. Select the <strong>Admin</strong> menu and then click the Exchange menu item. </li> <li> Click on Roles → Admin Roles </li> <li> Select the <strong>Discovery Management</strong> role group. </li> <li> Add the <strong>ApplicationImpersonation</strong> Role to this role group. </li> <li> Select the <strong>Organization Management</strong> role group </li> <li> Add the <strong>ApplicationImpersonation</strong> Role to this roles group. </li> </ol> Please wait 5-10 minutes for changes to be applied.',
                    title: 'How to configure Application Impersonation'
                },
                SFC: {
                    text:
                        'In the following form you will be asked to define the type of backup you wish to create and provide your backup preferences. Before our company\'s application can access your Salesforce account you need to enable API access in your Salesforce user profile:<br><ol><li>Login to your Salesforce account</li><li>Click <strong>"Setup"</strong> at the top of the page</li><li>Under <strong>"Administration Setup"</strong> expand <strong>"Manage Users"</strong>, then click <strong>"Profiles"</strong></li><li>Click <strong>"Edit"</strong> for the appropriate Profile</li><li>Under <strong>"Administrative Permissions"</strong> check <strong>"API Enabled"</strong>, <strong>"Modify All Data"</strong> and <strong>"Modify Metadata Through Metadata API Functions"</strong></li></ol>After selecting your preferred backup type, you will be redirected to Salesforce for authentication',
                    title: 'How to enable API access'
                }
            },
            techUserAddConfirmation: {
                additionalText:
                    'Please note that the service connects to your tenant via OAuth using backup task admin user and during backup creation process the admin user will be added to all public teams.',
                additionalText2: 'It’s recommended to use a dedicated technical user for backup purposes only.',
                checkboxLabel: 'Allow the backup admin user to be added to all public teams.',
                linkToUserGuide: 'Read More'
            },
            text: {
                BoxInfo: 'You will be redirected to Box for authentication.',
                BoxSubmittingText: 'Authenticate in Box',
                DropboxInfo: 'You will be redirected to Dropbox for authentication.',
                DropboxProfessionalTitle: 'Enter your user details',
                DropboxSubmittingText: 'Authentication',
                DynamicsAppAuthorization: 'Azure app authorization',
                DynamicsBackedUp: 'Backed up',
                DynamicsBackedUpInfo:
                    'Backup tasks for all the environments already exist. If you would like to continue creating a Dynamics 365 backup task, please authenticate with a different user.',
                DynamicsCreateUser: 'Create Application User',
                DynamicsCreateUserErrorInfo: 'Error during application user creation. Please try again',
                DynamicsCreateUserInfo: {
                    pressAuth:
                        'To initiate the application user creation process for <0>{{env}}</0> environment <br> click on the authenticate button',
                    pressDone:
                        'Processing the application user creation for <0>{{env}}</0> environment <br> click on the “Done” button when processing is over'
                },
                DynamicsEnvironment: 'Choose Dynamics Environment',
                DynamicsEnvironmentInfo: 'Which environment would you like to backup?',
                DynamicsInfo: 'Microsoft Dynamics 365 Backup',
                DynamicsOauthErrorInfo: 'Error during authentication process with Microsoft. Please authenticate again',
                DynamicsOauthInfo: 'You will be redirected to Microsoft for authentication.',
                DynamicsRecentlyDeleted:
                    'The selected Dynamics environment had a backup task which was recently deleted. The data of that backup task is not lost yet and can be reinstated. Please choose one of the options below:',
                DynamicsRecentlyDeletedNewOption: 'Create New Task',
                DynamicsRecentlyDeletedNewTooltip:
                    'To create a new backup of the specified Dynamics environment. The backup task will have no access to the data of the old backup, backed data collection will begin as new. The old backup data will be deleted 7 days after the backup task has been deleted.',
                DynamicsRecentlyDeletedReinstateOption: 'Reinstate',
                DynamicsRecentlyDeletedReinstateTooltip:
                    'To reinstate the old task with all the backed up data. The backup process will continue for this task and you can perform recovery of data from any of the collected backup point.',
                DynamicsRecentlyDeletedTitle: 'Backup Tasks Have Recently Been Deleted',
                GSuiteInfo:
                    '<0>Make sure to sign in to your Google Workspace administrator account and then click the "Sign in with Google" button. On the next step you will need to select the correct Google account to proceed with.</0><1>Use of information received from Google APIs as part of the cloud-to-cloud backup operation, adheres to <1>Google API Services User Data Policy</1>, including the Limited Use requirements.</1>',
                GoogleAccountInfo:
                    'By pressing the start button below, you will be redirected to Google for authentication using your Google user and will be asked to enable access to backup your account.',
                GoogleAccountSubmittingText: 'Authenticate in Google Account',
                GoogleIntegrationFailed: {
                    button: 'Get Started',
                    list: [
                        'Click on Get Started to be redirected to Google Workspace Marketplace where you can install the application manually.',
                        'Try again using Google Workspace administrator account. If you use multiple Google accounts in the same browser, make sure to pick the correct administrator account at the previous step or use incognito/private mode in your browser with 3rd party cookies enabled.'
                    ],
                    text:
                        'Only Google Workspace administrator users can authorize the integration. Please do one of the following:'
                },
                MS365Agreement: 'I have configured <0>Application Impersonation.</0>',
                MS365GroupsOauthInfo:
                    'Please note that our authorization process uses OAuth and requires a Global Admin account.',
                MS365OauthInfo:
                    'Please note that our authorization process uses OAuth and requires a Global Admin account.',
                OneDriveOauthInfo: 'Our authorization process uses OAuth and requires a Global Admin account.',
                SFCInfo:
                    'You will be redirected to Salesforce for authentication. Your details will not be saved in {{companyName}} system.',
                SFCSubmittingText: 'Authenticate in Salesforce',
                SharepointOauthInfo: 'Our authorization process uses OAuth and requires a Global Admin account.',
                TeamDriveInfo:
                    '<0>Make sure to sign in to your Google Workspace administrator account and then click the "Sign in with Google" button. On the next step you will need to select the correct Google account to proceed with.</0><1>Use of information received from Google APIs as part of the cloud-to-cloud backup operation, adheres to <1>Google API Services User Data Policy</1>, including the Limited Use requirements.</1>',
                account: 'Account',
                modalTitle: 'Enter your admin details',
                modalTitleFailed: 'Integration failed'
            }
        },
        backupSettings: {
            disabledButtons: {
                compare: {
                    tooltip: 'The compare function is not available because there are no backups yet.'
                },
                replicate: {
                    tooltip: 'The replicate function is not available because there are no backups yet.'
                },
                smartAlerts: {
                    tooltip: 'The smart alerts function is not available because there are no backups yet.'
                }
            },
            fieldChanged: 'To make changes take effect, click save',
            fields: {
                backupFrequency: {
                    daily3TimesTooltip:
                        'This option is available at an additional charge. To enable it, please contact support.',
                    highBackupTitle:
                        'This backup task contains high number of individual backups,<br /> Therefore configuring high frequency backups could lead to performance issues. <br /> Please contact support to check if high frequency backups can be enabled for this task.',
                    highBackupTooltip:
                        'Your backup task contains high number of individual backups which could lead to performance issues. We have reduced backup frequency. Please contact support for more details and possible options.',
                    label: 'Backup Frequency',
                    tooltip:
                        'You have enabled high frequency backups. To disable it and reduce backup frequency to daily or weekly jobs please contact Support.'
                },
                backupHour: {
                    label: 'Backup Hour (UTC)',
                    tooltip:
                        'The daily backup had to be split during the day by the system for optimization, due to the large size of the backup. To change the backup hours please call support.'
                },
                backupUnlicensed: {
                    label: 'Backup Unlicensed accounts'
                },
                daysToAccountDeactivation: {
                    label: {
                        postfix: 'days before deleting',
                        prefix: 'Retain auto-archived backups for '
                    },
                    tooltip:
                        'Backups will be auto-archived when a license is removed or a site is deleted. Enter the number of days to retain the auto-archived backups, or leave this option unchecked to retain them indefinitely. This will not affect the manually paused backups.'
                },
                detectNewAccounts: {
                    label: 'Automatically activate new {{- entityName}}',
                    tooltip: 'Check this box to detect new {{- entityName}} and start backups automatically.'
                },
                indexStatus: {
                    label: 'Index all data for Search',
                    tooltip:
                        'Indexing backups allows you searching the data for specific items to restore/export. To disable it please submit a support ticket.'
                },
                retentionPeriod: {
                    label: 'Retention Period',
                    tooltip:
                        'This field is display only. We provide unlimited retention of your daily backups for as long as you maintain your account subscription, but you can specify a backup retention limit if needed by contacting Support.'
                },
                storageRegion: {
                    label: 'Backup Datacenter',
                    tooltip: 'Backup location selected when you setup your account.'
                },
                throughput: {
                    label: 'Throughput',
                    tooltip: 'Daily API Usage Limit'
                }
            },
            response: {
                activated: 'Backup Task activated',
                updated: 'Settings updated'
            },
            text: {
                noticeContent:
                    'The automatic addition/deletion of an account, site, domain, table or drive can affect your monthly or annual billing amount',
                noticeContentDropboxProfessional:
                    'The addition/deletion of an account, site, domain, table or drive can affect your monthly or annual billing amount'
            },
            title: 'Backup Settings'
        },
        backupTags: {
            actions: {
                apply: 'Apply',
                create: 'Create New Tag'
            },
            applyResponse: {
                success: 'Tags were successfully applied'
            },
            fields: {
                search: {
                    placeholder: 'Search tags'
                },
                text: {
                    label: 'Please enter a new tag name'
                }
            },
            filter: 'Filter by tag',
            limitedAccess: 'Limited Access',
            limitedAccessModal: {
                header: 'Are you sure you want to add/remove Limited Access tag? ',
                message:
                    'Only the primary administrator of the account has permission to browse and recover data that has a Limited Access tag.'
            },
            limitedAccessTooltip:
                'Only the primary administrator of the account has permission to browse and recover data that has a Limited Access tag. ',
            new: 'New',
            popupTitle: 'Tag as',
            response: {
                success: 'Tag has been added.'
            },
            text: {
                modalTitle: 'New Tag'
            },
            title: 'Tags'
        },
        backupTitle: {
            response: {
                nameChanged: 'Backup name has been updated'
            },
            text: {
                modalTitle: 'Edit Backup Name'
            }
        },
        backupUnlicensedAccounts: {
            fields: {
                continue: {
                    info: 'Continue to backup, but do not activate new Unlicensed accounts',
                    label: 'Continue to Backup'
                },
                delete: {
                    info: 'Delete backup of Unlicensed accounts',
                    label: 'Delete'
                },
                pause: {
                    info: 'Pause backup of active Unlicensed accounts',
                    label: 'Pause'
                }
            },
            text:
                'Please note, this backup task may already backup Unlicensed accounts. What would you like to do with these accounts?',
            title: 'Unlicensed accounts'
        },
        cards: {
            details: 'Add new card details',
            name: 'Credit Card Details'
        },
        changePassword: {
            fields: {
                confirmPassword: {
                    label: 'Confirm New Password:',
                    placeholder: 'Confirm New Password'
                },
                email: {
                    label: 'Email:',
                    placeholder: 'Email'
                },
                error: {
                    newOldNotMatch: 'New password should not match the old one',
                    passNotMatch: "Passwords don't match"
                },
                newPassword: {
                    label: 'New Password:',
                    placeholder: 'New Password'
                },
                password: {
                    label: 'Current Password:',
                    placeholder: 'Current Password'
                },
                token: {
                    label: 'Token:',
                    placeholder: 'Token'
                }
            }
        },
        changePasswordExpired: {
            fields: {
                confirmPassword: {
                    label: 'Confirm New Password'
                },
                days: {
                    label: 'Password expires after (days):'
                },
                expiredPassword: {
                    label: 'Password Expiration:'
                },
                newPassword: {
                    label: 'New Password'
                },
                password: {
                    label: 'Current Password'
                }
            }
        },
        common: {
            actions: {
                activate: 'Activate',
                add: 'Add',
                apply: 'Apply',
                authenticate: 'Authenticate',
                authenticateInDynamics: 'Authenticate in Dynamics',
                back: 'Back',
                cancel: 'Cancel',
                confirm: 'Confirm',
                create: 'Create',
                discard: 'Discard',
                edit: 'Edit',
                filter: 'Filter',
                more: 'More',
                next: 'Next',
                ok: 'Ok',
                reAuthenticateInDynamics: 'Re Authenticate',
                reset: 'Reset',
                save: 'Save',
                send: 'Send',
                verify: 'Verify'
            },
            fields: {
                activityStatus: {
                    label: 'Status:'
                },
                addressLine: {
                    label: 'Address Line 1:',
                    placeholder: ''
                },
                addressLine2: {
                    label: 'Address Line 2:',
                    placeholder: ''
                },
                addressLine3: {
                    label: 'Address Line 3:',
                    placeholder: ''
                },
                agreement: {
                    validation: {
                        required: 'Please agree to terms and conditions'
                    }
                },
                backupUnlicensed: {
                    label: 'Backup unlicensed accounts'
                },
                billingEmail: {
                    label: 'Billing Email:',
                    placeholder: ''
                },
                billingStatus: {
                    label: 'Billing:',
                    placeholder: ''
                },
                cards: {
                    label: 'Card Number:',
                    placeholder: ''
                },
                city: {
                    label: 'City:',
                    placeholder: ''
                },
                common: {
                    validation: {
                        required: 'Fill the required fields properly'
                    }
                },
                companyAddress: {
                    label: 'Company Address:',
                    placeholder: ''
                },
                companyName: {
                    label: 'Company Name:',
                    placeholder: ''
                },
                country: {
                    label: 'Country:',
                    placeholder: 'Start to type...'
                },
                currency: {
                    label: 'Currency:',
                    placeholder: 'Select your currency'
                },
                email: {
                    placeholder: 'Email',
                    validation: {
                        errorFromServer: 'Internal error, please contact support',
                        invalid: 'Please enter a valid Email address',
                        required: 'Please input your email'
                    }
                },
                emailInvoiceType: {
                    label: 'Email Invoice/Receipt:'
                },
                entities: {
                    label: 'Entities:'
                },
                firstName: {
                    label: 'First Name:',
                    placeholder: 'First Name',
                    validation: {
                        required: 'Please input your first name'
                    }
                },
                from: {
                    label: 'From:'
                },
                items: {
                    label: 'Items:'
                },
                lastBackups: {
                    label: 'Last backups'
                },
                lastLogin: {
                    label: 'Last logins'
                },
                lastMonth: {
                    label: 'Last month'
                },
                lastName: {
                    label: 'Last Name: ',
                    placeholder: 'Last Name',
                    validation: {
                        required: 'Please input your last name'
                    }
                },
                location: {
                    placeholder: 'Location'
                },
                optionalName: {
                    label: 'Name (Optional): ',
                    placeholder: ''
                },
                password: {
                    placeholder: 'Password',
                    validation: {
                        required: 'Please input your password'
                    }
                },
                passwordConfirm: {
                    placeholder: 'Confirm Password',
                    validation: {
                        invalid: "Passwords don't match",
                        required: 'Please retype your password'
                    }
                },
                paymentDay: {
                    label: 'Payment Day:'
                },
                phone: {
                    label: 'Phone number:',
                    placeholder: ''
                },
                profit: {
                    label: 'Non Profit or Education'
                },
                purchafesOrderNumber: {
                    label: 'Purchase Order Number',
                    placeholder: ''
                },
                query: {
                    placeholder: 'Search For'
                },
                search: {
                    label: 'Search:'
                },
                service: {
                    label: 'Service:',
                    placeholder: 'Select Service'
                },
                show: {
                    label: 'Show:'
                },
                startingDate: {
                    label: 'Subscription Date',
                    placeholder: ''
                },
                state: {
                    label: 'State:',
                    placeholder: 'N/A'
                },
                subscriptionType: {
                    label: 'Subscription:',
                    placeholder: ''
                },
                taskStatus: {
                    label: 'Task:',
                    placeholder: 'Select Task'
                },
                taxID: {
                    label: 'Company Tax Id:',
                    placeholder: ''
                },
                title: {
                    label: 'Title:'
                },
                to: {
                    label: 'To:'
                },
                token: {
                    validation: {
                        required: 'Please input your token'
                    }
                },
                twoFACode: {
                    placeholder: 'Insert verify code here'
                },
                userActivity: {
                    label: 'User Activity:'
                },
                userStatus: {
                    cardHoldersName: {
                        label: "Card Holder's Name"
                    },
                    cvv: {
                        label: 'CVV'
                    },
                    expirationDate: {
                        label: 'Expiration Date'
                    },
                    label: 'Status:',
                    placeholder: ''
                },
                view: {
                    label: 'View:'
                },
                zip: {
                    label: 'Zip Code/Postal Code:',
                    placeholder: ''
                }
            },
            optionsAll: 'All',
            validation: {
                dynamicsRequired: 'Please enter your backup name',
                minLength: 'At least {{0}} characters',
                required: 'Please provide an input for the field',
                shortRequired: 'Required field'
            }
        },
        compareSnapshots: {
            fields: {
                backupTask: {
                    label: 'Backup Task',
                    message: 'Enter the backup task',
                    messageSameTask: 'This is the same task date. Please choose another date or task.'
                },
                currentBackupTask: {
                    label: 'Backup Task'
                },
                currentSnapShotDate: {
                    label: 'Snapshot date',
                    message: 'Enter the snapshot date'
                },
                includeChildObjects: {
                    label: 'Include child objects',
                    tooltip:
                        'If «Include child objects» is set, then all objects that depend on the chosen object will be compared as well'
                },
                snapShotDate: {
                    label: 'Snapshot date',
                    message: 'Enter the snapshot date'
                },
                time: {
                    label: 'Snapshot time'
                },
                useSandbox: {
                    label: 'Use Sandbox'
                }
            }
        },
        customS3: {
            fields: {
                email: {
                    label: 'Account email:'
                },
                location: {
                    label: 'Data Center Location:',
                    placeholder: 'Select your location'
                },
                name: {
                    label: 'Account name:'
                },
                partnerId: {
                    label: 'Partner ID:'
                },
                password: {
                    label: 'Password:'
                },
                retypePassword: {
                    label: 'Retype password:'
                },
                s3bucket: {
                    label: 'Amazon S3 bucket name:'
                },
                s3key: {
                    label: 'Amazon S3 key:'
                },
                s3region: {
                    label: 'Amazon S3 region:',
                    placeholder: 'Select your location'
                },
                s3secret: {
                    label: 'Amazon S3 secret key:'
                }
            },
            title: 'Customer AWS S3'
        },
        export: {
            account: 'You are about to export items from the archive of {{account}}',
            fields: {
                deletedLabelText: {
                    label: 'All deleted items'
                },
                exportBucketName: {
                    label: 'Bucket Name:'
                },
                exportBucketNameAzure: {
                    label: 'Container Name:'
                },
                exportFormat: {
                    default: 'Standard in .{{format}}',
                    outlook: 'Outlook compatible .PST'
                },
                exportToKey: {
                    label: 'Access Key:'
                },
                exportToKeyAzure: {
                    label: 'Account Name:'
                },
                exportToRegion: {
                    label: 'Region:',
                    placeholder: 'Select your S3 region'
                },
                exportToSecretKey: {
                    label: 'Secret Key:'
                },
                exportToSecretKeyAzure: {
                    label: 'Account Key:'
                },
                includeDeleted: {
                    label: 'Export deleted items'
                },
                includeLegalHold: {
                    label: 'Legal holds'
                },
                includeMetadata: {
                    label: 'Metadata'
                },
                preferSinglePST: {
                    label: 'Use 20GB chunks for .pst instead of 5GB'
                },
                jobLabel: {
                    label: 'Job Label:',
                    placeholder: 'Enter Job Label'
                },
                storageType: {
                    label: 'Storage Type:',
                    placeholder: 'Select your custom storage'
                }
            },
            name: '',
            text: {
                dropboxNotification:
                    'Dropbox limits the API calls used in our restore process to 1,000,000 per month for many of their business plans. Once the limit is reached, no other items can be restored / uploaded to Dropbox until the following month. You can still export and download backup data for recovery purposes, and then upload to Dropbox with the native application. Please contact <1>DropBox</1> directly if you have any questions about your API limits.',
                emailNotification: 'A notification Email with a download link will be sent to you upon completion.',
                exportFormat:
                    'The downloaded file(s) type would be according your selection: emails in EML format, Contacts in VCF format and Calendars in ICS format',
                exportFormatTitle: 'Export format:',
                exportTargetTitle: 'Export in .zip format:',
                optionalInlude: 'Optionally include:',
                optionallyIncludeTitle: 'Optionally include:',
                spaceLimit:
                    'If there is insufficient storage space to complete the export, the data will be exported locally and available for download.'
            }
        },
        gcp: {
            fields: {
                bucket: {
                    label: 'GCP bucket name:'
                },
                key: {
                    label: 'Storage key:'
                },
                region: {
                    label: 'Storage region:',
                    placeholder: 'Select your location'
                },
                secret: {
                    label: 'Storage secret key:'
                }
            },
            title: 'Google Cloud Platform'
        },
        oldUI: {
            recovery: {
                adminUser: 'Admin user',
                anotherTenant: 'Restore to another tenant / Perform a cross site recovery',
                creationDisabled: 'This restore method is disabled because sub site creation is not enabled',
                creationNotSupported: 'Restore is not supported because sub site creation is not enabled',
                deprecatedMethod:
                    'This restore method is disabled because Microsoft no longer supports sub-site creation in OneDrive. You can perform a non-destructive restore to a SharePoint sub-site using “Restore to another tenant/Perform a cross site recovery”',
                endpoint: 'Endpoint',
                endpointTooltip: 'Restore endpoint URL',
                infoTip: '*You can restore the data to a different {{restoreText}}',
                invalidTargetAccountMessage: 'Invalid restore destination',
                invalidJobLabel: 'Please use less than 50 characters for Job Label',
                invalidSpaceError: 'Blank spaces are not allowed',
                jobLabel: 'Job Label',
                jobLabelTooltip:
                    'You can add a customized label to describe your Restore and Export jobs, which will also display on the Jobs page.',
                restoreTo: 'Restore to',
                targetAccountTooltip: 'MS Account with read/write permissions to the restore endpoint'
            }
        },
        paymentDetails: {
            billingContact: 'Billing contact',
            billingPreferences: {
                billingPreferencesBody:
                    'You can set up accounts to be billed monthly or annually. Monthly billing is always ',
                billingPreferencesBodyDetails: ' Annual billing can either be ',
                billingPreferencesHeader: 'Annual billing preferences',
                billingPreferencesTooltip: {
                    AnnualBillingconsolidated:
                        'The consolidated bill contains a single amount for all new annual subscriptions that began that month, and all renewing subscriptions for the following month. Depending on your payment preferences, each month we either charge your credit card, or send you a consolidated invoice which is due in 30 days.',
                    MonthlyBillingconsolidated:
                        'Your monthly subscriptions are consolidated in one bill. Depending on your payment preferences, each month we either charge your credit card or send you an invoice.',
                    consolidated: 'consolidated',
                    individual: 'individual',
                    individualText:
                        'Each account that is set up to be billed annually is billed individually. Depending on your payment preferences, we either charge your credit card, or issue an invoice for each account on the day that the user subscribed. For renewing subscriptions, we will charge your credit card at the date of renewal, or issue an invoice 30 days before the renewal date.'
                },
                consolidatedbill: 'Consolidated bill',
                individualbills: 'Individual bills',
                or: ' or '
            },
            contactSupport: {
                contactSupport: 'Contact support:',
                contactSupportText: 'To modify your preferences, please contact support.'
            },
            paymentFailure: {
                title: 'Update credit card:',
                text: 'Unable to charge your credit card. Please update your details.'
            },
            name: 'Billing Details',
            paymentMethod: 'Select your payment method:',
            paymentMethodType: {
                creditCard: 'Pay with ',
                invoice: 'an invoice',
                invoicePayment: 'Send me ',
                payment: 'credit card'
            },
            paymentPreferences: {
                paymentPreferencesBody: 'You can pay for your customers’ subscriptions with a ',
                paymentPreferencesBodyDetails: ', or request that we send you an ',
                paymentPreferencesChoice: 'Select your preferences and fill in your details:',
                paymentPreferencesHeader: 'Payment preferences',
                paymentPreferencesTooltip: {
                    creditCard: 'credit card',
                    creditCardTooltip: {
                        footer:
                            'If you prefer individual annual billing, we will charge separately for each new account on the day that the subscription began, and we will charge for renewing accounts on the annual subscription renewal date.',
                        header: 'We charge your credit card on the payment day each month for: ',
                        option1: 'the total monthly subscriptions, plus',
                        option2:
                            'all new annual subscriptions that began that month, and all renewing subscriptions for the following month.'
                    },
                    invoice: 'invoice',
                    invoiceText:
                        'We provide a consolidated invoice for monthly subscriptions, and a consolidated or individual invoices for annual subscriptions. Invoices are due in 30 days and payable by credit card, PayPal, or bank transfer. The invoice is sent by email, and includes a payment link to the PayPal payment gateway.  If you choose consolidated billing, your email will also contain a report of the billing details for each subscription.'
                }
            }
        },
        recovery: {
            account: 'You are about to restore items from the archive of account',
            actions: {
                DOWNLOAD: 'Export',
                RESTORE: 'Restore'
            },
            chooseAll: 'Choose all items on all pages',
            fields: {
                accountType: {
                    label: 'Account type'
                },
                addUsersToSiteGroups: {},
                domain: {
                    label: 'Domain'
                },
                endpointUri: {
                    label: 'Endpoint'
                },
                includePermissions: {
                    label: 'Restore permissions and add users to site groups'
                },
                password: {
                    label: 'Password'
                },
                region: {
                    label: 'Region'
                },
                restoreTo: {
                    label: 'Restore to',
                    placeholder: 'Select...'
                },
                secretKey: {
                    label: 'Secret key'
                },
                table: {
                    label: 'Table'
                }
            },
            objectType: 'Object Type',
            objectTypes: {
                FOLDER: 'Data',
                MD_FOLDER: 'Metadata'
            },
            search: 'Search by Name...',
            table: {
                chooseCalendar: 'Choose a calendar to<br/> see its content',
                chooseFolder: 'Choose a folder to see<br/> its content',
                empty: 'No data found'
            },
            text: {
                accountType: 'Account type:',
                emailNotification: 'A confirmation Email will be sent to you upon completion.',
                inPlaceRecoveryMethod: 'Please select how you want to handle existing data:',
                infoRestoreText:
                    'Notice: If the endpoint and source are in different domains - permissions, site groups and users will not be restored.',
                recoveryMethod: 'Please select your restore method:',
                restoreDataTo: 'You can restore the data to a different ',
                restoreTo: 'You can restore the data to a different account'
            },
            totalObjects: '{{total}} Total Objects',
            totalRecords: '{{total}} Total Records'
        },
        signIn: {
            actions: {
                signIn: 'Sign in'
            },
            fields: {
                email: {
                    placeholder: 'Your Email'
                },
                password: {
                    placeholder: 'Your Password'
                }
            }
        },
        signInTwoFA: {
            fields: {
                twoFaCode: {
                    placeholder: 'Two-Factor Authentication code',
                    validation: {
                        required: 'Please input your two-factor authentication code'
                    }
                },
                twoFaRecoveryCode: {
                    placeholder: 'Two-Factor Recovery code',
                    validation: {
                        required: 'Please input your two-factor recovery code'
                    }
                }
            }
        }
    },
    frequency: {
        daily: 'Daily',
        every3: 'Every 3 days',
        threeTimesADay: '3 times a day',
        weekly: 'Weekly'
    },
    header: {
        billingAlertConfigurePaymentsLinkTitle: 'Go to billing.',
        billingAlertConfigurePaymentsMessage: 'Please set up your billing details.',
        billingAlertConfigurePaymentsTitle: 'Configure Payment',
        billingAlertLinkPPTitle: 'Unpaid Bills',
        billingAlertLinkTitle: 'billing status',
        billingAlertTooltip:
            'The billing status is usually updated within a few minutes after the payment is complete.',
        configurePayment: {
            configurePayment: 'Configure Payment: ',
            configurePaymentBody: 'Please set up your billing details. ',
            goToBilling: 'Go to billing.'
        },
        billingAlert: {
            paymentFailed: 'Payment failed ',
            paymentFailedMessage: 'Unable to charge your credit card. Go to ',
            paymentFailedText: ' to resolve this issue.',
            paymentProcessing: 'Payment is being processed',
            paymentProcessingMesssage: 'Your new credit card will be charged within 24 hours.'
        },
        finra: {
            protected: 'Protected Mode',
            sensitiveAccount: '<0>Protected Mode</0> - Immutable Object Lock Activated',
            tooltip: 'Immutable Object Lock Activated'
        },
        logout: 'Logout',
        menu: {
            blog: 'Blog',
            company: 'Company',
            contact: 'Contact',
            freeTrial: 'Free Trial',
            home: 'Home',
            login: 'Login',
            partners: 'Partners',
            pricing: 'Pricing',
            products: 'Products',
            resources: 'Resources',
            toCloudally: 'Back',
            toZix: 'Back'
        },
        portalTitle: 'SaaS Data Protection Platform CE 24.4',
        ppTitle: 'Partner Portal CE 24.4',
        subscribe: {
            button: 'Subscribe',
            externalUnsubscribed: 'Please contact Support to subscribe again',
            trialDaysLeft: 'You have {{days}} days left on your free trial',
            trialExpired: 'The trial period has been expired '
        },
        title: 'Core Cloud-to-Cloud Backup ™ CE 24.4'
    },
    help: {
        gettingStarted: 'Getting Started',
        news: {
            allReleaseNotes: 'All Release Notes',
            latestReleaseUpdates: 'Latest Release Updates'
        },
        readOurUserGuides: 'Read our <0>User Guides</0>',
        seeAll: 'See all',
        title: 'Help',
        video: {
            howToCreateBackupTask: 'Watch How to Create a Backup Task ',
            howToRecoverData: 'Watch How to Recover Backed Up Data'
        },
        whatsNew: 'What’s New'
    },
    integrations: {
        activatedInstruction: 'To modify your integration, contact Support.',
        apiDetails: {
            api: 'API',
            apiDescription:
                'Integrate with a variety of backup, export, and user management functions with our accessible API. Customers who wish to use our API can create their own API key - Client ID and Client Secret - for authentication.',
            apiDescriptionPP:
                'Integrate with a variety of backup, export, and user management functions with our accessible API. Distributors or Partners who wish to use our API can create their own API key - Client ID and Client Secret - for authentication.',
            apiDocumentation: 'API Documentation',
            apiDocumentationLink: 'https://api.cloudally.com/',
            clientDetails: {
                clientId: 'Client ID',
                clientIdCopied: 'Client ID copied.',
                clientSecret: 'Client secret',
                clientSecretCopied: 'Client secret copied.',
                copyFailure: 'Failed to copy text',
                createdBy: 'Created by',
                createdOn: 'Created on',
                createdOnNaTooltipText: 'The date is not available for client IDs created prior to release 24.4.1.',
                modal: {
                    clientSecret: 'Client Secret',
                    delete: {
                        confimText: 'Are you sure you want to <b>delete your API key?</b> ',
                        desc:
                            "If you delete an API key that is still being used, you'll experience integration failures.<br>This action cannot be undone.",
                        success: 'API Key deleted successfully.',
                        title: '<b>Confirm Deletion of API key</b>'
                    },
                    header: 'Client ID and Secret',
                    info:
                        'Ensure that you copy the “Client Secret” before you click on <b>Close.</b><br>If you do not copy it, you will not be able to see it again and will have to create a new one.',
                    success: 'API Key created. Client ID and secret have been generated successfully.'
                }
            },
            descDetails: ' for a list of all API calls and clear guidance on how to implement them.',
            keyButtonText: '+  Create key',
            noApiKeyDesc: 'No API key created yet. Click “Create key” to add a new one.',
            notAvailable: 'N/A',
            referTo: 'Refer to the ',
            restAPi: 'REST API',
            restApiKey: 'REST API Key'
        },
        autoTaskDescription:
            'Send your daily backup reports to Autotask in order to centralize business operations and make data-driven decisions.',
        autoTaskDetails:
            "<ol> <li>Subscribe to <0>{{name}}</0> </li> <li> <1>Open a support ticket.</1> Send us: <ul><li>Your Autotask ID</li> <li>Your Autotask Password</li> <li>The email address you'd like your reports to be sent to.</li> </ul> </li> </ol>",
        autoTaskModal: {
            affirmation:
                'Once the support representative has enabled Autotask, we will send any backup task failure reports to the email address provided, starting from the next backup cycle.',
            header: 'Autotask Integration'
        },
        autoTaskName: 'Autotask',
        backupRadarDescription:
            "Your consolidated task-level reports and customers' back up health statuses will be seamlessly integrated with Backup Radar.",
        backupRadarDetails:
            '<ol> <li>Subscribe to <0>{{name}}</0> </li> <li><1>Open a support ticket.</1></li> <li>Send us your unique Backup Radar email address, where your reports will be sent to.</li> </ol>',
        backupRadarModal: {
            affirmation:
                'Once the support representative has enabled Backup Radar reporting, we will send a report to the email address provided, starting from the next backup cycle.',
            header: 'Backup Radar Integration'
        },
        backupRadarName: 'Backup Radar',
        multipleEntityTitle: 'To enable the {{name}} integration please follow these steps:',
        rmmTitle: 'RMM/PSA',
        siemTitle: 'SIEM',
        splunkDeleteModal: {
            description:
                'Your Splunk SIEM integration settings will be deleted and events will no longer be sent to your Splunk account. <br/> <br/> Are you sure you want to continue?',
            header: 'Delete Splunk SIEM Integration'
        },
        splunkDescription:
            'Send security events and account related activities to Splunk through the Splunk HTTP Event Collector (HEC).',
        splunkLoading: 'Please wait while we check and establish a connection...',
        splunkModal: {
            activityEvents: 'Send Activity Events',
            auditLog: 'Send Audit Log',
            certificate: 'Splunk SSL Certificate.',
            certificateTooltip:
                'Splunk Cloud protects the HEC endpoint by default with a self-signed HTTPS certificate. Please verify that you have set up an SSL certificate issued by a commonly accepted certificate authority (CA) on the Splunk HEC endpoint.',
            eventCollector: 'Splunk HTTP Event Collector URL(HEC)',
            eventCollectorPlaceholder: 'Eg: https://<my-splunk.com>:<port>/services/collector/event',
            eventSource: 'Event Source (Optional)',
            eventSourcePlaceholder: 'Enter your event source',
            header: 'Splunk SIEM Integration',
            partnerEvents: 'Include Partner Account Events',
            splunkSetup: 'Splunk HEC Setup',
            splunkToken: 'Splunk Token',
            splunkTokenPlaceholder: 'Enter token',
            urlFormatError: 'URL syntax is incorrect. Please enter a valid URL.',
            verify: 'Please verify  '
        },
        splunkName: 'Splunk',
        splunkNotifications: {
            addSuccessDescription: 'Connection to Splunk was successful.',
            deleteSuccessDescription: 'Splunk Integration has been successfully deleted.',
            deleteTitle: 'Splunk Integration deleted',
            updateSuccessDescription: 'Integration settings were successfully updated.'
        }
    },
    ipRestrictions: {
        activateModal: {
            activate: {
                text:
                    'Upon confirmation, users will only be able to access this account<br />from the IP address(es) or range(s) you specified in the trusted IP addresses table.<br /><br />Please verify that the IP addresses of all of the users of this account are listed,<br />so they will be able to log in.',
                title: 'Are you sure you want to activate IP address restrictions?'
            },
            deactivate: {
                text:
                    'Upon confirmation, the IP address restrictions will be turned off for this account<br />and users will be able to log in from any IP address.',
                title: 'Are you sure you want to stop IP address restrictions?'
            },
            validationErrorHeader: 'Trusted IP Addresses validation error'
        },
        addAddress: {
            buttonAdd: 'Add IP Address',
            cidr: {
                address: 'Address',
                cidr: 'CIDR',
                example: 'Example 192.168.10.0 / 24',
                header: 'Trusted IP Address Range Using CIDR Notation'
            },
            formHeader: 'Add New IP Address',
            list: {
                advice: 'Separate multiple IP addresses with “,”.',
                example: 'Example 192.168.10.5',
                header: 'Trusted IP Address(es)'
            },
            radioGroupLabel: 'You can add one or more IP addresses, or a range of IP addresses:',
            range: {
                example: 'Example 192.168.10.0 - 192.168.10.255',
                from: 'From',
                header: 'Trusted IP Address Range',
                to: 'To'
            }
        },
        description: {
            header: 'Enter IP Address description',
            optional: '(optional)',
            placeholder: 'eg. Office'
        },
        editIpAddressHeader: 'Edit IP Address',
        headerYouIp: 'Your IP Address: <0>{{yourIpAddress}}</0>',
        introduction:
            'IP Address restrictions allow you to control who can access your account based on their IP address.<br />Once you configure a set of trusted IP addresses and enable IP Address restrictions, any user attempting to log in via an IP address that is not trusted will be blocked.<br />You can define a single IP address or a range of IP addresses. Only IPv4 is accepted.<br /><br /><i>CAUTION: Make sure to add your own IP address and to use static IP addresses. Setting up wrong IP addresses may result in being locked out of your account.</i>',
        introductionHeader: 'IP Address Restrictions Settings',
        limitExceededHeader: 'Trusted IP Addresses Limit Exceeded',
        removeConfirmationHeader: 'The selected IP Address will be deleted - please confirm.',
        switcher: {
            disableToast: {
                text: 'Your account can now be accessed without IP restrictions.',
                title: 'IP Restrictions Disabled'
            },
            enableToast: {
                text: 'Access to your account is now restricted to trusted IP addresses.',
                title: 'IP Restrictions Enabled'
            },
            label: 'Trusted IP Addresses',
            tooltip: 'IP address restrictions cannot be enabled while the trusted IP addresses table is empty.'
        },
        table: {
            columns: {
                actions: 'Actions',
                address: 'IP Address',
                desc: 'IP Description'
            },
            emptyText: 'No IP restrictions yet. Add allowed IP address/range to view them here.'
        }
    },
    logout: {
        loginLink: 'Login',
        logoutText: 'Thank you for using our backup service,<br/>see you soon!',
        logoutTitle: 'Thanks!'
    },
    modals: {
        accessDenied: {
            firstParagraph: 'Sorry, you don’t have access to this page.',
            secondParagraph:
                'Please request access from the primary account user or open a support ticket if you need any assistance.',
            title: 'Access Denied'
        },
        accountDeleted: {
            firstParagraph:
                'Sorry, the account you are trying to view activity logs for has been deleted from the system.',
            secondParagraph: 'Please contact support if you have any questions or need assistance.',
            title: 'Account Deleted'
        },
        addEntity: {
            emailModalFieldLabel: 'Email',
            ms365ModalTitle: 'Add user to your Microsoft Exchange users list',
            onedriveModalTitle: 'Add site to your OneDrive sites list',
            sharepointModalTitle: 'Add site to your SharePoint sites list',
            siteModalFieldLabel: 'Site'
        },
        addNewAccount: {
            title: 'Add New'
        },
        archiveLocation: {
            email: 'Specify an email that should receive system notifications',
            emailPlaceholder: 'Email',
            location: 'Select your preferred {{- companyName}} archives storage location',
            namePlaceholder: 'Display Name',
            noAdmins:
                'This account does not have any administrators assigned to it yet. <br />Please make sure there is at least one administrator assigned before attempting to activate the account',
            placeholder: 'Select Data Center Location',
            title: 'User Settings',
            zixLocation: 'Select your preferred archives storage location'
        },
        backupUnlicensedModal: {
            groups: {
                CONTINUE: 'Continue to backup, but do not activate new Unlicensed accounts',
                DELETE: 'Delete backup of Unlicensed accounts',
                PAUSE: 'Pause backup of active Unlicensed accounts'
            },
            text:
                'Please note, this backup task may already backup Unlicensed accounts. What would you like to do with these accounts?',
            title: 'Unlicensed accounts'
        },
        buttons: {
            authenticate: 'Authenticate',
            back: 'Back',
            cancel: 'Cancel',
            clear: 'Clear',
            close: 'Close',
            confirm: 'Confirm',
            connect: 'Connect',
            continue: 'Continue',
            delete: 'Delete',
            done: 'Done',
            edit: 'Edit',
            go: 'Go',
            next: 'Next',
            no: 'No',
            ok: 'Ok',
            retry: 'Retry',
            save: 'Save',
            yes: 'Yes'
        },
        detailsModal: {
            account: '{{source}} account ({{account}})',
            dateInitiated: '{{taskType}} date initiated:',
            destination: 'Destination:',
            object: '{{taskType}} object:',
            source: 'Source:',
            status: 'Status:',
            taskType: 'Task type:',
            title: '{{taskType}} {{source}} account ({{account}}) details'
        },
        editModal: {
            title: 'Please confirm the edit request'
        },
        errorModal: {
            text:
                'There was an internal error updating this email. Refresh page and try again. If the error persists, please, contact Support.'
        },
        exportModal: {
            postfix: 'A notification Email with a download link will be sent to you upon completion.',
            prefix: 'You are about to export items from the archive of <strong> {{entity}} </strong> <br /><br />',
            successMessage: 'Export process has been successfully initiated.',
            successMessageWithPostfix: '{{message}}<br />You can check the status on the <0>Jobs Page</0>',
            title: 'Please confirm the export request'
        },
        deleteModal: {
            successMessage: 'Delete process has been successfully initiated.',
            errorMessage:
                'The user cannot delete all child records in an object without expanding the child records. You need to expand the object with child records to view and delete them.'
        },
        performAction: {
            limitExceeded:
                'The system supports maximum {{- maxEntities}} in simultaneous backup. Currently there are {{- inProcessEntities}} in process and you choose {{availableEntities}} to run. Please choose accordingly and rerun.',
            statusResult: {
                ACTIVATE:
                    '{{availableEntities}} out of chosen {{sum}} {{- entityName}} will be activated, please confirm',
                DELETE:
                    '{{availableEntities}} out of selected {{sum}} {{- entityName}} backups will be deleted. Please confirm.',
                PAUSE: '{{availableEntities}} out of chosen {{sum}} {{- entityName}} will be paused, please confirm',
                RUN:
                    '{{availableEntities}} out of chosen {{sum}} {{- entityName}} will be backed up immediately, please confirm'
            }
        },
        removeModal: {
            note:
                'Note: Ensure that the item you wish to remove is already removed from the source. If it is not, it will continue to be backed up, even if you deleted it using the Delete button',
            postfix: 'A notification email with a delete will be sent to you upon completion.',
            prefix: 'You are about to delete items from the archive of ',
            prefixTitle: 'You are about to delete items from the archive of <strong> {{entity}} </strong> <br /><br />',
            title: 'Please confirm the delete request',
            notificationMessage:
                'We are processing your deletion request. You will receive a confirmation email upon deletion, which will be reflected in the application within 12 hours after the email is sent.'
        },
        restoreModal: {
            accountType: {
                production: 'Production',
                sandbox: 'Sandbox'
            },
            accountTypeTitle: 'Account type',
            additionalAlert:
                '<p>Please ensure that the user that authenticated your backup task in our system has full admin permissions in Salesforce.</p> <p>Please temporary disable workflows, triggers and validation rules in Salesforce.</p><br />',
            disableTriggersHint:
                'Mark this checkbox if you would like the restore process to automatically disable all Validation Rules, Workflows, Triggers and Processes in the restored data. This doesn’t affect the triggers in the existing data at the destination organization.',
            disableTriggersLabel: 'Disable triggers in restore',
            metadataSFC: 'Metadata cannot be restored for backups created prior to {{0}}',
            postfix: 'A notification Email with a report will be sent to you upon completion.',
            prefix: 'You are about to restore items from the archive of <strong> {{entity}}</strong> <br /> <br />',
            restoreOptionHint:
                'Please choose the restore mode: Replace, Bypass, or Duplicate. The restore process compares the records in the existing sObjects with the records in restored ones using the unique fields. The restore mode defines the way the restore process treats the records with identical unique fields (except the automatically generated id). Please see the Salesforce Backup User Guide for more details.',
            restoreOptionLabel: 'Restore mode for identical records',
            restoreOptions: {
                bypass: 'Existing data will be bypassed and not restored.',
                duplicate: 'Existing data will be duplicated.',
                replace: 'Restore will overwrite existing live data.'
            },
            restoreTo: '<strong>Restore to *</strong>',
            restoreToHelp: '* You can restore the data to a different organization',
            secondSubmitDialog: {
                BYPASS: 'This restore will bypass existing live data. Continue?',
                DUPLICATE: 'This restore will duplicate existing live data. Continue?',
                REPLACE:
                    'This restore will be in-place. Files with the same version will be skipped and duplicated otherwise.',
                SFC: {
                    BYPASS:
                        'The Restore process will be performed in «Bypass» mode. The restored records identical to the records in the sObjects existing in live data will be skipped. Continue?',
                    DUPLICATE:
                        'The Restore process will be performed in «Duplicate» mode. The restored records identical to the records in the sObjects existing in live data will be restored with new ID. Continue?',
                    REPLACE:
                        'The Restore process will be performed in «Replace» mode. The existing records of the sObjects in the destination organization will be overwritten. Continue?'
                },
                SP: {
                    BYPASS: 'This restore will bypass existing live data in the SharePoint / OneDrive site. Continue?',
                    DUPLICATE:
                        'This restore will duplicate existing live data in the SharePoint / OneDrive site. Continue?',
                    REPLACE:
                        'This restore will be in-place and overwrite live data in the SharePoint / OneDrive site. Continue?'
                }
            },
            startInactiveUsersHint:
                'Mark this checkbox if you would like the restore process to automatically attempt to activate inactive users in order to restore the associated data using their accounts. The users will be deactivated after the restore process. If the checkbox is left unmarked, the restore will attempt to insert the data as the current user.',
            startInactiveUsersLabel: 'Activate inactive users',
            successMessage:
                'Recovery process has been successfully initiated.<br />You can check the status on the <0>Jobs Page</0>',
            title: 'Please confirm the restore request'
        },
        systemActivityDescription: {
            countEntities: 'Number of entities:',
            dataSize: 'Data Size:',
            description: 'Description:',
            execTime: 'Execution Time:',
            performedBy: 'Performed By:'
        },
        title: {
            error: 'Error',
            info: 'Info',
            success: 'Success',
            warning: 'Warning'
        },
        types: {
            error: 'Error',
            warning: 'Warning'
        }
    },
    notifications: {
        addFailed: 'Failed to add email, ',
        addNew: 'Add new recipient',
        autoDiscoveryHeader: 'Auto Discovery\nNotification',
        autoDiscoveryHeaderTooltip: 'Send notification on auto discovery items activation/archive/deactivation',
        dayOptions: {
            Friday: 'Friday',
            Monday: 'Monday',
            Saturday: 'Saturday',
            Sunday: 'Sunday',
            Thursday: 'Thursday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday'
        },
        displayName: 'Display Name',
        downloadFallback: 'Download will start shortly, if not please click <0>here</0>.',
        email: 'Email',
        emailAdded: 'Email has been successfully added',
        emailReport: {
            OnceADay: 'Once A Day',
            OnceAMonth: 'Once A Month',
            OnceAWeek: 'Once A Week'
        },
        emailUpdated: 'Settings have been successfully updated',
        entityLevelReportFormatLabel: 'Backup failure details',
        entityLevelReportFormatTooltip:
            'The report contains detailed information per each backup failure, grouped by backup task. Full account report, including both failed and successful backups is available in the attached .csv file.',
        exceptionHeader: 'Exceptions\nNotification',
        exceptionHeaderTooltip: 'Send error/exception notifications during Backup or Integration processes',
        exceptionNotification: 'Exceptions Notification',
        failureOnlyReport: 'Send the report only in case of backup failure',
        headerAction: 'Action',
        headerName: 'Display Name',
        notifications: 'Notifications',
        preview: 'Preview',
        provideType: 'Provide as:',
        provideTypes: {
            CSVAttachment: 'CSV Attachment',
            DownloadLink: 'Download Link'
        },
        recoveryHeader: 'Recovery\nNotification',
        recoveryHeaderTooltip: 'Send notification upon successful completion of Recovery or Export activity',
        recoveryNotification: 'Recovery Notification',
        removeConfirmation: 'This user will stop receiving notifications, are you sure?',
        removeFailed:
            'There was an internal error deleting this email. Refresh the notifications page and try again. If the error persists, please, contact Support.',
        removedSuccessfully: 'Email {{0}} has been successfully deleted',
        reportFormTitle: 'Summary Report',
        reportFreq: 'Report Frequency:',
        reportType: 'Report format:',
        reportTypes: {
            consolidatedReport: 'Consolidated file - all customers in a single file',
            individualReport: 'Individual file per each customer'
        },
        smartAlertsHeader: 'Smart Alerts',
        smartAlertsHeaderTooltip:
            'The notification feature enables receiving email alert of changes in your data, predefined with smart alerts.',
        statusReport: 'Auto Discovery Notification',
        summaryReport: 'Summary Report',
        summaryReportHeader: 'Summary\nReport',
        tableHeader: 'Send Notifications To:',
        taskLevelReportFormatLabel: 'Backup tasks overview',
        taskLevelReportFormatTooltip:
            'The report provides a high level overview of all your backup tasks, authentication status as well as number of failed/partially completed backups. Detailed information is available in the attached .csv file.',
        trialAccountsReport: 'Trial Accounts Report',
        trialAccountsReportTooltip: 'Send report summarizing active and expiring customer trials',
        updateFailed:
            'There was an internal error updating this email. Refresh the notifications page and try again. If the error persists, please, contact Support.',
        userInfo: 'User Info'
    },
    pageStatus: {
        noInvoices: 'No invoices yet',
        statusMessages: {
            nobill: 'There are no bills',
            overdue: 'Some payments overdue',
            upcoming: 'All bills on track'
        },
        tasks: {
            complete: 'All Tasks Successful',
            failed: 'Backup Problem',
            noAccounts: 'No Accounts Yet',
            noTasks: 'No Tasks Yet'
        },
        trialAccountDeletion: 'Account will be deleted in {{trialAccountDeletionDaysLeft}} days',
        trialDaysLeft: 'Trial days left: {{days}}',
        trialExpired: 'The trial period has been expired'
    },
    pagination: {
        next: 'Next',
        prev: 'Previous'
    },
    partnerPortalSite: {
        contract: {
            buttons: {
                accept: 'Accept',
                request: 'Request for Changes'
            },
            requestAlert: 'Thank you! CloudAlly sales representative will contact you shortly.'
        },
        partnerAccess: '(for access from the Partner Portal)',
        partnerAccount: 'Partner Account',
        signIn: {
            changePasswordFailed: "Password hasn't been changed. Please contact Support",
            changePasswordSuccess: 'Password was changed successfully',
            changeTemporaryPassword: 'Please change your temporary password',
            error: 'Internal server error. Try again or contact Support',
            fillCode: 'Enter Two-Factor Authentication code',
            fillRecoveryCode: 'Enter Two-Factor Recovery code',
            lostDeviceLink: 'Lost your device?',
            oAuthMessage: 'Or, you can also sign-in using your Google, Facebook or Azure credentials',
            title: 'Sign in to your CloudAlly partner account',
            twoFactorAdvice: '* Open the Two-Factor Authentication app on your device to view your authentication code'
        }
    },
    passwordResetFailed: {
        hasFailed: 'Your request to reset your account password\nhas failed. Please request a new password reset',
        linkExpired: 'Link Expired',
        resetPasswordButton: 'Reset Password'
    },
    passwordResetNew: {
        buttonReset: 'Reset',
        buttonSignIn: 'Sign in',
        buttonSignUp: 'Sign up',
        changePassword: 'Fill-in the new password',
        changeYour: 'Change Your Password',
        email: {
            placeholder: 'Email'
        },
        expiredPassword: 'Your password has expired,<br />please update it',
        header: 'To reset your password, enter<br />the Email address you signed up with',
        passwordReset: 'Password Reset',
        thank:
            'Thank you. <0>An email has been sent to you.</0> Please follow the email instructions in order to complete your password reset.'
    },
    passwordSent: {
        beenReset: 'Your password has been reset.',
        beenSent: 'An email containing your new password\nhas been sent to you.',
        footer: 'Please use this new password to sign in to your account.'
    },
    recovery: {
        MS365: {
            failedTooltip:
                'Backup of {{serviceName}} on the selected date has failed. See the Activity page for details.',
            infoTitles: {
                entity: 'Email',
                lastBackup: 'Last Backup',
                name: 'User Name',
                size: 'Total Size',
                status: 'Backup Status'
            },
            newExchangeRecoveryPageText:
                'The new <0>Exchange Recovery page</0> has a next-gen UI with browse backup functionality. Find and recover what you are looking for in a couple of clicks!',
            selectionActions: {
                clearSelected: 'Clear Selected',
                selectAll: 'Select All'
            },
            succeededTooltip: 'Backup of {{serviceName}} on the selected date has succeeded.',
            taskStatus: {
                completed: 'Completed',
                deferred: 'Deferred',
                inprogress: 'In Progress',
                notstarted: 'Not Started',
                waitingonothers: 'Waiting On Others'
            },
            zeroItems: 'No {{title}} to display for the selected backup date'
        },
        actions: {
            export: 'Export',
            restore: 'Restore',
            search: 'Advanced Search'
        },
        activeBackupAccounts: 'Active Backups',
        browseBackup: 'Browse Backup for “{{- name}}”',
        browseMyAccount: {
            description: 'Browse within the snapshots of your account',
            title: 'Browse My Account'
        },
        chooseDateModal: {
            okText: 'Confirm',
            title: 'Choose the Backup Date'
        },
        descriptionLabel: {
            BOX:
                'Listed below are the accounts within your {{source}} account backed up by {{companyName}}, ready for restore and download.',
            DDB:
                'Listed below are the tables within your {{source}} account backed up by {{companyName}}, ready for restore and download.',
            DROPBOX:
                'Listed below are the accounts within your {{source}} account backed up by {{companyName}}, ready for restore and download.',
            GDRIVETEAM:
                'Listed below are the active team drives within your {{source}} administrator backed up by {{companyName}}, ready for restore and download.',
            GOOGLEAPPS:
                'Listed below are the active accounts within your {{source}} domain backed up by {{companyName}}, ready for restore and download.',
            MS365:
                'Listed below are the active accounts within your {{source}} domain backed up by {{companyName}}, ready for restore and download.',
            MS365GROUPS:
                'Listed below are the active groups within your {{source}} domain backed up by {{companyName}}, ready for restore and download.',
            ONEDRIVE:
                'Listed below are the sites within your {{source}} domain backed up by {{companyName}}, ready for restore and download.',
            SDB:
                'Listed below are the domains within your {{source}} account backed up by {{companyName}}, ready for restore and download.',
            SHAREPOINT:
                'Listed below are the sites within your {{source}} domain backed up by {{companyName}}, ready for restore and download.',
            SHAREPOINT_MULTI:
                'Listed below are the sites within your {{source}} domain backed up by {{companyName}}, ready for restore and download.'
        },
        fullAccountRecovery: {
            description: 'Restores your account to a previous state',
            title: 'Full Account Recovery'
        },
        modals: {
            cancel: {
                description: 'Are you sure you want to cancel the restore/download task?',
                title: 'Confirm Cancellation'
            },
            changeDate: {
                cancel: 'Cancel',
                description:
                    'Changing the backup date will discard your current items selection.<br />Do you want to continue?',
                ok: 'Continue',
                title: 'Change Your Selected Backup Date'
            },
            exit: {
                cancel: 'Cancel',
                description: 'Leaving this page will discard your current selection. Do you want to continue?',
                ok: 'Continue',
                title: 'Exit Selective Recovery'
            },
            recoveryModals: {
                confirmTitle: 'Are you sure?',
                secondSubmitDialog: {
                    DROPBOX: {
                        REPLACE:
                            'This restore will be in-place. Files with the same version will be skipped and duplicated otherwise.'
                    },
                    MS365GROUPS: {
                        BYPASS:
                            'This restore will bypass existing live data in the SharePoint / OneDrive site. Continue?',
                        DUPLICATE:
                            'This restore will duplicate existing live data in the SharePoint / OneDrive site. Continue?',
                        REPLACE:
                            'This restore will be in-place and overwrite live data in the SharePoint / OneDrive site. Continue?'
                    },
                    ONEDRIVE: {
                        BYPASS:
                            'This restore will bypass existing live data in the SharePoint / OneDrive site. Continue?',
                        DUPLICATE:
                            'This restore will duplicate existing live data in the SharePoint / OneDrive site. Continue?',
                        REPLACE:
                            'This restore will be in-place and overwrite live data in the SharePoint / OneDrive site. Continue?'
                    },
                    SHAREPOINT: {
                        BYPASS:
                            'This restore will bypass existing live data in the SharePoint / OneDrive site. Continue?',
                        DUPLICATE:
                            'This restore will duplicate existing live data in the SharePoint / OneDrive site. Continue?',
                        REPLACE:
                            'This restore will be in-place and overwrite live data in the SharePoint / OneDrive site. Continue?'
                    },
                    SHAREPOINT_MULTI: {
                        BYPASS:
                            'This restore will bypass existing live data in the SharePoint / OneDrive site. Continue?',
                        DUPLICATE:
                            'This restore will duplicate existing live data in the SharePoint / OneDrive site. Continue?',
                        REPLACE:
                            'This restore will be in-place and overwrite live data in the SharePoint / OneDrive site. Continue?'
                    }
                }
            }
        },
        myRecoveries: {
            title: 'My Recoveries'
        },
        noBackups: 'No past backups to recover',
        noResults: {
            descriptionAbove: 'To view requested items,',
            descriptionBelow: 'Enter your search above and click GO',
            title: 'No Results Yet!'
        },
        notifications: {
            notPermitted: 'You don’t have permission for this operation'
        },
        recovery: 'Recovery',
        recoveryInfo:
            'From the archive list below select the relevant items, then click on "restore" to perform a non-destructive restore directly to the online service, or "download" to create a zip extract of the data.',
        searchForItem: {
            description: 'Find and restore a specific item from a backup',
            title: 'Search For Item'
        },
        wizard: {
            accessDeniedError:
                'Access was denied. Please check the settings of your cloud storage and try again to authorize.',
            advancedOptions: {
                EXPORT_ONLY: {
                    description:
                        'Export a complete Exchange mailbox or only certain sub services.\n The selected sub services will be completely recovered.',
                    info: 'Extract the data as a single PST file'
                },
                FULL_MAILBOX_RECOVERY: {
                    EXPORT: 'Export',
                    RESTORE: 'Restore',
                    chooseRecoveryOptions: 'Choose Recovery Options:',
                    description:
                        'Recover a complete Exchange mailbox or only certain Sub Services.\nThe selected sub services will be completely recovered.',
                    exportTooltip: 'Select "Export" to extract the data as a single PST file',
                    label: 'Full Mailbox Recovery',
                    restoreTooltip: 'Select “Restore” to perform recovery directly to the online service'
                },
                RESTORE_ONLY: {
                    description:
                        'Restore a complete Exchange mailbox or only certain sub services.\n The selected sub services will be completely recovered.',
                    info: 'Perform a non-destructive restore directly to the online service'
                },
                SELECTIVE_MAILBOX_RECOVERY: {
                    description: 'Browse & Search Exchange mailbox backups and select which items to recover.',
                    label: 'Selective Mailbox Recovery'
                },
                title: 'Advanced Recovery Options for'
            },
            authTimeoutError: 'Timeout exceeded. Please, try again.',
            confirmation: {
                activity: 'When {{action}} will be activated, you can check the status on the jobs page',
                button: {
                    authorize: 'Authorize',
                    export: 'Export',
                    restore: 'Restore'
                },
                emailNotification: 'A notification Email with a download link will be sent to you upon completion.',
                format: '{{format}}. format',
                labels: {
                    archive: '{{action}} from archive of:',
                    date: 'Backup Date:',
                    format: 'Export as:',
                    restoreType: 'Restore type:',
                    storage: 'Selected Storage Center:',
                    subServices: 'The {{action}} Request for:',
                    target: 'Restore to:'
                },
                modal: {
                    subtitle: 'Restore will overwrite existing live data',
                    text: 'Are you sure you want to proceed?',
                    title: 'Warning'
                },
                restoreEmailNotification: 'A notification Email will be sent to you upon completion.',
                restoreSuccessNotification: {
                    description:
                        '{{recovery}} was activated.<br />You can check the status on the<br /><0>Jobs Page</0>',
                    title: 'Full Mailbox Recovery'
                },
                restoreType: {
                    BYPASS: 'Bypass',
                    NON_DESTRUCTIVE: 'Non-destructive',
                    REPLACE: 'Replace'
                },
                selectiveRestoreSuccessNotification: {
                    title: 'Selective Mailbox Recovery'
                },
                title: 'Please Confirm'
            },
            exportTargetSelection: {
                azureSettings: {
                    exportBucketName: 'Container Name',
                    exportToKey: 'Account Name',
                    exportToSecretKey: 'Account Key'
                },
                settings: {
                    compatibleS3Endpoint: 'Endpoint',
                    exportBucketName: 'Bucket Name',
                    exportToKey: 'Access Key',
                    exportToRegion: 'Data Center',
                    exportToSecretKey: 'Secret Key'
                },
                subtitle: 'Export in ZIP format'
            },
            fullMailboxDateConfigure: {
                LAST_SUCCESSFUL_BACKUP: {
                    label: 'Last Backup'
                },
                SPECIFIC_BACKUP_DATE: {
                    label: 'Specific Backup Date'
                },
                dateConfiguration: {
                    calendarLabel: 'Backup date',
                    description: 'Browse the calendar and select a specific backup date',
                    label: 'Choose the date of the backup to be used for Recovery:',
                    retentionTooltip: 'The full backup status is not displayed for backups older than 2 years',
                    statusPartial: 'Partially Succeeded Backup',
                    statusRetention: 'Backup Date',
                    statusSuccess: 'Successful Backup',
                    subsourceIconTooltip: '{{subsource}} - {{status}}',
                    tooltipDisabled: 'No successful backup for selected mailbox'
                },
                title: 'Full Mailbox {{option}} for'
            },
            jobLabel: {
                placeholder: 'Enter Job Label',
                subtitle: '(optional) ',
                title: 'Job Label ',
                tooltip:
                    'You can add a custom name to the Restore/Export Jobs for enhanced flexibility which can be viewed in Jobs Page as well.',
                validation: 'Maximum 50 characters'
            },
            mailboxSelection: {
                label: 'The list below shows active mailboxes backed up and ready for Recovery',
                searchPlaceholder: 'Search',
                title: 'Choose the Mailbox for Recovery'
            },
            restoreDestinationSelection: {
                anotherAccount: 'Restore to another account',
                originalAccount: 'Restore to the original account',
                placeholder: 'Enter a name or e-mail address',
                restoreDestination: 'Restore Destination',
                tooltip: 'You can restore the data to the different account.'
            },
            selectiveRecovery: {
                title: 'Selective Mailbox {{option}} for'
            },
            subServiceSelection: {
                excludedTooltip: 'Sub service was excluded<br />from the backup',
                exportFormatNote: 'For full Mailbox Export we use Outlook compatible PST. format',
                exportFormatNoteSelective:
                    'By default we use Outlook compatible PST. format, to change the format please click on the Settings icon above. ',
                format: {
                    PST: 'Outlook compatible .PST',
                    alternative: 'Standard in .{{alternativeFormat}}',
                    title: 'Export format:'
                },
                optionallyIncludeTitle: 'Optionally include:',
                subtitle: 'Sub Services selection and configuration',
                subtitleTooltip: 'Add or remove Sub Service from the Recovery list below'
            },
            targets: {
                AMAZON_S3: 'My Amazon S3',
                AMAZON_S3_COMPATIBLE: 'My AWS S3 compatible',
                AZURE: 'My Azure Blob',
                BOX: 'My Box.com',
                CLOUDALLY_S3: 'For download',
                DROPBOX: 'My Dropbox',
                GOOGLE_CLOUD_PLATFORM: 'My Google Cloud Platform'
            }
        }
    },
    recoveryStatus: {
        inprocess: 'In process',
        inprocessCount: 'In process ({{current}} / {{total}})',
        pending: 'In process',
        readyForDownload: 'Ready'
    },
    responseErrors: {
        accessDeny: 'Access denied.',
        alreadyActivated: 'Activation status already set',
        assessDenied: 'Access denied.',
        currentIpNotListed:
            "You can't perform this action because the list of trusted IP addresses must include your IP address",
        duplicateDescription: 'Trusted IP address with the same description already exists',
        duplicateList: 'Trusted IP address already exists',
        duplicateRange: 'Trusted IP address range already exists',
        emailAlreadyExist: 'Email already exist',
        failedToUpdateRule: 'Failed to update rule',
        fieldIsNullOrEmpty: 'The field can not be empty',
        incorrectCurrentPassword: 'Incorrect current password.',
        incorrectPass: 'Incorrect password.',
        incorrectTwoFaCode: 'Incorrect Two-Factor authentication code',
        incorrectTwoFaRecoveryCode: 'Incorrect Two-Factor recovery code',
        invalidCountry: 'Wrong Country code',
        invalidCredential: 'Invalid Email or password.',
        invalidCustomStorage:
            'We could not access the cloud storage location using the credentials provided. Please check the credentials and try again. If the issue persists, please contact our support team.',
        ipNotParsed: 'Invalid IP address format, please fix IP address syntax and try again',
        ipRangeInvalid: 'Invalid IP address range, please fix your input and try again',
        ipRuleRequestInvalid: 'The request to create an IP rule could not be processed.',
        ipRuleRequestMisshaped: 'Wrong IP format',
        noAddressesInList: 'The List of IP address can not be empty',
        noRestrictionsFound: 'User have no restrictions to activate',
        passLengthError: 'The password must be between 8 and 60 characters long.',
        resellerExists: 'Partner already exists',
        ruleNotFound: 'Unable to delete rule',
        tooManyIpAddressesInList: 'No more than 1000 addresses can be added in one rule',
        tooManyIpRules:
            'You have reached the limit of trusted IP addresses. If you need to increase your limit, please contact support.',
        userAlreadyExist: 'User already exists.',
        userBlocked: 'Too many login failures, this account will be locked for 10 minutes.',
        userSuspended: 'This account is suspended, please contact Support'
    },
    restoreOptions: {
        addPrefix: 'Add "restored..." to Outlook folders and calendars created',
        addTag: 'Add "restored..." tag to items',
        createSnapshot: 'Create a snapshot of the restore endpoint prior to data upload',
        label: 'Restore Options'
    },
    restoreType: {
        bypass: {
            description:
                'Restored content will be merged with existing data.<br /><br /><1>Deleted items and folders will be restored from the snapshot.</1><1>Existing items will be skipped.</1><br /><0>Use this option:</0> to restore deleted data.',
            label: 'Bypass restore'
        },
        label: 'Restore Type',
        nonDestructive: {
            description:
                'Existing data will remain as-is, and the restored data will be in a dedicated folder, preserving the original folder structure.<br /><0>Use this option:</0> to keep restored data separate, enabling manual drag & drop of items to relevant folders.',
            label: 'Non-destructive restore'
        },
        replace: {
            description:
                'Restored content will be merged with existing data.<br /><br /><1>Deleted items and folders will be restored from the snapshot.</1><1>Existing items will be overwritten with the snapshot version.</1><br /><0>Use this option:</0> to restore or repair deleted / modified items.',
            label: 'Replace restore'
        }
    },
    screenResolution: {
        alert:
            'This app is best viewed with a larger screen. Some features might not display properly with a mobile device.'
    },
    securityAudit: {
        activity: {
            APISETTINGSCHANGED: 'API settings changed',
            API_SETTINGS_CHANGED: 'API Settings Changed',
            CHANGEACCOUNTDATA: 'Settings Changed',
            DEACTIVATEUSER: 'User deactivated',
            NOTIFICATION: 'Notification',
            NOTIFYDELETEDATA: 'Backup Deleted',
            PERMISSIONSCHANGED: 'Permissions changed',
            SIGNIN: 'Sign-in'
        },
        status: {
            COMPLETED: 'Completed',
            EXCEPTION: 'Exception',
            FAILED: 'Failed'
        }
    },
    securityPage: {
        action: 'Change password',
        emailIndicator: 'Email & Password',
        enforcePasswordPolicies: 'Enforce The Following Password Policies On All Users',
        enforcePasswordPoliciesPP: 'Enforce The Following Password Policies On All Partner Portal Users',
        indicatorActive: 'Active',
        indicatorFailed: 'Failed',
        ipRestrictions: 'IP Restrictions',
        password: 'Password',
        passwordChangeFailed: "Password hasn't been changed. Please contact Support",
        passwordChanged: 'Password changed successfully',
        personalPasswordPolicies: 'Personal Password Policies',
        saml: {
            URL: 'Upload URL',
            URLDescription: 'URL to the SAML Identity Provider Metadata file.',
            URLError: 'Invalid URL, please fix URL syntax and try again',
            activate: 'Activate',
            choose: 'Choose file',
            file: 'Upload File',
            metadataSubTitle: 'Please upload SAML metadata XML file generated by Okta',
            metadataTitle: 'Identity Provider Metadata File',
            modalBody: 'Activating SAML Authentication will disable Email & Password Authentication',
            modalTitle: 'Are you sure you want to activate SAML Authentication with Okta?',
            modalWarning:
                'Please verify all the users in this account are also configured in Okta, so they will be able to login to CloudAlly after SAML activation.',
            noFile: 'No file chosen.',
            okta: 'Okta',
            provider: 'SAML provider',
            title: 'SAML',
            upload: 'Upload'
        },
        samlIndicator: 'SAML',
        settingChanged: 'Settings changed successfully',
        title: '<0>Change your </0><1>{{companyName}}</1><0> account password</0>',
        twoFaAuth: {
            activate: '<0>You can also use <1>Two Factors Authentication </1><2>Next</2></0>',
            activateAction: 'Activate 2FA',
            checkbox: 'Enforce Two-Factor Authentication on all users',
            checkboxPP: 'Enforce Two-Factor Authentication on all Partner Portal users',
            deactivateAction: 'Deactivate 2FA',
            next: 'Next',
            switcherTooltip: 'Applicable when using your e-mail and password',
            text: 'You can also use Two-Factor Authentication',
            title: 'Two-Factor Authentication'
        }
    },
    serviceNames: {
        BOX: 'Box',
        DDB: 'DynamoDB',
        DROPBOX: 'Dropbox',
        DROPBOX_BUSINESS: 'Dropbox',
        DROPBOX_PROFESSIONAL: 'Dropbox Professional',
        DYNAMICS365: 'Microsoft Dynamics 365',
        GACCOUNTS: 'Google Account',
        GCALENDAR: 'Google (Calendars)',
        GCONTACTS: 'Google (Contacts)',
        GDOC: 'Google (Documents)',
        GDRIVETEAM: 'Google Shared Drives',
        GMAIL: 'Google (Email)',
        GOOGLEAPPS: 'Google Workspace',
        GSITES: 'Google (Sites)',
        GTASK: 'Google (Tasks)',
        IMAP: 'IMAP',
        MS365: 'MS Exchange',
        MS365GROUPS: 'MS Groups / Teams',
        MS365GROUPS_FULL: 'Microsoft Groups / Teams',
        MS365GROUP_CALENDAR: 'MS Groups (Calendars)',
        MS365GROUP_CONVERSATIONS: 'MS Groups (Conversation)',
        MS365GROUP_META: 'MS Groups (Metadata)',
        MS365GROUP_SITE: 'MS Groups (Site)',
        MS365TEAM_APPS: 'MS Groups (Apps)',
        MS365TEAM_CHANNELS: 'MS Groups (Channels)',
        MS365_FULL: 'Microsoft Exchange',
        O365CALENDAR: 'MS Exchange (Calendars)',
        O365CONTACTS: 'MS Exchange (Contacts)',
        O365MAIL: 'MS Exchange (Email)',
        O365NOTES: 'MS Exchange (Notes)',
        O365TASKS: 'MS Exchange (Tasks)',
        ONEDRIVE: 'MS OneDrive',
        ONEDRIVE_FULL: 'Microsoft OneDrive',
        SDB: 'SimpleDB',
        SFC: 'Salesforce',
        SHAREPOINT_MULTI: 'MS SharePoint',
        SHAREPOINT_MULTI_FULL: 'Microsoft SharePoint',
        YMAIL: 'Yahoo Mail',
        all: 'All'
    },
    settings: {
        PP: {
            tiles: {
                account: {
                    caption: 'Change your account settings',
                    name: 'Partner Account',
                    secondaryName: 'Account'
                },
                integration: {
                    caption: 'Manage integrations with tools and services',
                    name: 'Integrations'
                },
                management: {
                    caption: 'Manage other users',
                    name: 'User Management'
                },
                security: {
                    caption: 'Manage security settings',
                    name: 'Security'
                }
            }
        },
        endUsers: {
            betaTooltip:
                'This feature is in beta. Feel free to try this early version, and send us your feedback and suggestions for improvement',
            details:
                'End User Recovery allows all users in your Microsoft domain who have Exchange backups, to log in with their Microsoft credentials, access their own backups, and recover their data.',
            disableDescription:
                'Upon confirmation, end users will no longer be able to sign in with their Microsoft credentials, access their own backups and recover their data.',
            disableTitle: 'Are you sure you want to disable End User Recovery?',
            enableDescription:
                'Upon confirmation, all end users will be able to sign in with their Microsoft credentials, access their own backups and recover their data.',
            enableTitle: 'Are you sure you want to enable End User Recovery?',
            successDisableMessage: 'End user recovery has been disabled',
            successEnableMessage: 'End user recovery has been enabled',
            title: 'End User Recovery'
        },
        menu: {
            account: {
                caption: 'Manage account settings',
                name: 'Account'
            },
            integrations: {
                caption: 'Manage integrations with tools and services',
                name: 'Integrations'
            },
            notifications: {
                caption: 'Manage system notifications',
                name: 'Notifications'
            },
            security: {
                caption: 'Manage security settings',
                name: 'Security'
            },
            users: {
                caption: 'Manage other users',
                name: 'User Management',
                zixName: 'User Permissions'
            }
        },
        users: {
            addNew: 'Add new user',
            noPermissions: 'Please contact your administrator to get access privileges',
            text:
                'You can remove the credentials anytime if you prefer to use only your {{companyName}} sign-in credentials',
            title: 'The following external credentials are currently linked to your {{companyName}} account'
        }
    },
    sideMenu: {
        buttons: {
            accounts: 'Accounts',
            activity: 'Activity',
            activityNew: 'Activity',
            backups: 'Homepage',
            billing: 'Billing',
            changePassword: 'Account Password',
            changeSettings: 'Account Settings',
            dashboard: 'Dashboard',
            jobs: 'Jobs',
            learningHub: 'Learning Hub',
            partnerZone: 'Partner Zone',
            payments: 'Billing',
            recovery: 'Recovery New',
            restore: 'Recovery',
            security: 'Security',
            securityAudit: 'Security Audit',
            settings: 'Settings',
            support: 'Support',
            supportMail: '{{supportEmail}}',
            supportPhone: '{{supportPhone}}',
            supportTicket: 'Open Support Ticket',
            tutorials: 'Guides & Tutorials',
            twoFaSetAccount: 'Two-Factor Authentication',
            users: 'User Management'
        },
        collapseButton: {
            collapse: 'Collapse Sidebar',
            expand: 'Expand Sidebar'
        }
    },
    sign: {
        agreement:
            "By creating an account, you agree to our <0>Terms of Use</0> and <1>Privacy Policy</1>.<br /> We'll occasionally send you account related emails.",
        comingSoon: 'Coming Soon',
        forgotPassword: 'Forgot your password?',
        haveAccounts: 'Already have an account?',
        in: {
            error: 'Internal server error. Try again or contact Support',
            partnerPortal: {
                fillCode: 'Enter Two-Factor Authentication code',
                fillRecoveryCode: 'Enter Two-Factor Recovery code',
                lostDevice: 'Lost your device?',
                recover: 'Recover',
                title: 'Sign In to your CloudAlly Partner Account'
            },
            portal: 'Sign In to your Account',
            submitText: 'Sign In',
            twoFaInfo: 'Open the Two - Factor Authentication app on your device to view your authentication code',
            with: {
                email: 'Sign In with your email address',
                service: 'Sign In with {{service}}'
            }
        },
        noAccounts: "Don't have an account?",
        services: {
            azure: 'Microsoft',
            google: 'Google',
            okta: 'Okta'
        },
        up: {
            advantages: {
                noCard: 'No Credit Card',
                setup: 'Unbelievably easy to use!',
                trial: 'Free Trial'
            },
            customersTitle:
                '<0>16,000+</0> customers in over 50 countries trust our<br> SaaS Data Protection Platform to protect their<br> critical business data.',
            noTrial: 'Welcome to {{CompanyName}}',
            poweredBy: 'powered by',
            separator: 'or',
            signUpText: 'Sign up',
            submitText: 'Submit',
            thankYou: {
                clickActivationLink:
                    'Please click on the activation link contained<br /> in the email you receive, to activate your account.',
                emailSent: 'Email was sent',
                resentEmailLink: '<0>Click here</0> if you haven’t received the email',
                resentTime: 'You can resend the activation link in {{time}} seconds.',
                sendActivationLink: 'An email containing your activation link has been sent to <0>{{email}}</0>',
                title: 'Thank you for signing up to {{companyName}}'
            },
            titles: {
                first: '#1 SaaS Data<0>Protection</0>Platform',
                second: 'Never Face <0>Data<br> Loss</0> Again!',
                secondSubtitle:
                    '<0>16,000+</0> customers in over 50 countries trust our SaaS Data Protection Platform to protect their critical business data.',
                subtitle: 'Secure Backup | Smart Solution',
                thirdSubtitle:
                    'Backup & Recovery solution for <0>Microsoft 365</0>,<br> <0>Google Workspace</0>, <0>Salesforce</0>, <0>Box</0> and <0>Dropbox</0>'
            },
            trial: '14 Day Free Trial',
            with: {
                service: 'Continue with {{service}}'
            }
        }
    },
    statuses: {
        activity: {
            archived: 'Archived',
            cancelled: 'Cancelled',
            failed: 'Failed',
            partial: 'Partially Succeeded',
            skipped: 'Skipped',
            success: 'Succeeded'
        }
    },
    table: {
        actions: {
            SubscribeAllAnnual: 'Subscribe All Annual',
            SubscribeAllMonthly: 'Subscribe All Monthly',
            addNew: 'Add new',
            cancel: 'Cancel',
            details: 'Show Details',
            download: 'Download',
            subscribeAll: 'Subscribe All'
        },
        billing: {
            billingForecast: 'Billing Forecast',
            noBackups: 'Your Billing Forecast Will Be Here Once You Activate Backups',
            overallSubscribeStatus: {
                allBillsOnTrack: 'All bills on track',
                failures: 'Failures',
                successful: 'Successful',
                withExceptions: 'With exceptions'
            },
            subscribeStatus: {
                overdue: 'Overdue',
                trial: 'Trial account',
                upcoming: 'Current'
            },
            subscribeType: {
                annual: 'Annual',
                annualPrices: 'Annual prices',
                monthly: 'Monthly',
                monthlyPrices: 'Monthly prices',
                trial: 'Trial'
            }
        },
        buttons: {
            delete: 'Delete',
            export: 'Download',
            restore: 'Restore'
        },
        columns: {
            MSLicensing: 'MS Licensing',
            accountName: 'Account Name',
            accountStatus: 'Status',
            accounts: 'Accounts',
            accountsStatus: 'Status',
            action: 'Action',
            actions: 'Actions',
            activity: 'User Activity',
            activityNew: 'Activity',
            address: 'Address',
            amount: 'Amount',
            amountDue: 'Amount Due',
            author: 'Author',
            authors: 'Authors',
            autoDiscoveryNotification: 'Auto Discovery<br />Notification',
            backup: 'Backup',
            backupActions: 'Backup actions',
            backupDate: 'Backup date',
            backupSize: 'Backup Size',
            backupStatus: 'Backup Status',
            backups: 'Backups',
            billStatus: 'Status',
            billingAmount: 'Amount',
            billingStatus: 'Billing Status',
            billingStatuses: 'Billing Statuses',
            collapse: '',
            comment: 'Description',
            date: 'Date',
            dateAndTime: 'Date and Time',
            description: 'Description',
            discount: 'Discount',
            displayName: 'Display Name',
            documentNumber: 'Reference Number',
            download: 'Download',
            downloadInvoice: 'Download Invoice',
            dueDate: 'Due Date',
            effectiveDate: 'Effective Date',
            email: 'Email',
            emailInvoice: 'Email Invoice',
            emails: 'Emails',
            end: 'End',
            endDate: 'End Date',
            entities: 'Entities',
            entity: 'Account/Site/Table',
            event: 'Event',
            exception: 'Exceptions<br />Notification',
            faAuthentication: '2FA Authentication',
            firstBackup: 'First Backup',
            folder: 'Folder',
            foldersName: 'Folders',
            forecast: 'Next Payment Forecast',
            from: 'From',
            fullName: 'Name',
            fullUrl: 'Name',
            issueDate: 'Issue Date',
            item: 'Item',
            lastBackup: 'Last Backup',
            lastModified: 'Last modified',
            licensing: 'Licensing',
            name: 'Name',
            organizer: 'Organizer',
            originalQty: 'Total Quantity',
            partnerId: 'PartnerId',
            paymentMethod: 'Payment Method',
            perMonth: ' / Month',
            perYear: ' / Year',
            performedBy: 'Performed By',
            phone: 'Phone',
            price: 'Unit Price',
            quantity: 'Billable Quantity',
            recovery: 'Recovery<br />Notification',
            renewalDate: 'Renewal Date',
            sentDate: 'Backup date',
            service: 'Service',
            serviceAccount: 'Account',
            size: 'Size',
            smartAlerts: 'Smart Alerts',
            source: 'Service',
            start: 'Start',
            startDate: 'Start Date',
            status: 'Status',
            subService: 'Sub Service',
            subSource: 'Subsource',
            subject: 'Subject',
            subscriptionType: 'Subscription',
            summary: 'Summary',
            summaryReport: 'Summary Report',
            tags: 'Tags',
            task: 'Task',
            taskName: 'Task Name',
            taskStatus: 'Task Status',
            title: 'Name',
            totalAmount: 'Total Amount',
            totalAnnual: 'Total Annual Fee',
            totalMonthly: 'Total Monthly Fee',
            trialAccountsReport: 'Trial Accounts Report',
            type: 'Type',
            updated: 'Updated At',
            userStatus: 'Account Status',
            username: 'Username'
        },
        dashboard: {
            addNewAccount: 'Add New Account',
            directAccounts: 'Direct Accounts',
            distributorProvidesEnterCredentials: 'Distributor provides the access credentials',
            editAccountName: 'Edit Account Name',
            filterBar: {
                clearTag: 'Clear Filters'
            },
            filteredTitle: '{{total}} of {{totalNoFilters}} Total Accounts',
            partnerMustEnterCredentials: 'The partner must enter credentials to access the custom storage',
            partnerProvidesEnterCredentials: 'Partner provides the access credentials',
            partnerProvidesEnterCredentialsTooltip:
                'The partner will be prompted to provide credentials on the first sign in.',
            storageSettingsHeader: 'Storage Settings',
            subscriptionSettingsHeader: 'Subscription Settings',
            taskStatus: {
                complete: 'Successful',
                failed: 'Failures',
                noAccounts: 'No Accounts Yet',
                noTasks: 'No Tasks Yet'
            },
            title: '{{totalNoFilters}} Total Accounts',
            userMustEnterCredentials: 'Give the user access for entering credentials to access the custom storage',
            userMustEnterCredentialsTooltip:
                'On the first authentication, the user will receive a message to enter the storage credentials.',
            userSettingsHeader: 'User Settings'
        },
        date: {
            noDate: 'N/A'
        },
        directAccounts: {
            backupStatus: {
                BACKUP_DETAILS:
                    '{{successCount}} Successful, {{partialCount}} Partially Failed, {{failedCount}} Failed',
                FAILED: 'Failed',
                NO_BACKUPS: 'No Backups',
                PARTIALLY_FAILED: 'Partially Failed',
                SUCCESSFUL: 'Successful'
            },
            billingStatus: {
                OVERDUE: 'Overdue',
                TRIAL: 'Trial',
                UPCOMING: 'Current'
            },
            subscriptionCanceled: {
                cancelConfirmation: "Type 'Cancel' for confirmation",
                header: 'Cancel Subscription and Delete All Data',
                subscriptionDeleted: 'Subscription for the account {{accountName}} has been canceled.',
                subscriptionDeletion:
                    'Subscription for the account <b>{{accountName}}</b> will be canceled immediately. <br/> All of the data will be kept for 7 days, during which time you can resume your subscription and regain access to your data.<br /><br /> ',
                subscriptionDeletionTitle: 'Subscription has been canceled'
            },
            taskStatus: {
                COMPLETE: 'All Tasks Completed',
                FAILED: '<0>{{failureBackupCount}} Failed,</0> {{successfulBackupCount}} Completed',
                NO_TASKS: 'No tasks'
            },
            trialCanceled: {
                accountDeletion:
                    ' After the grace period, all data will be permanently deleted and the account will be removed from the list of accounts.',
                cancelGrace: 'Cancel With Grace Period',
                cancelImmediately: 'Cancel Immediately',
                graceCancel: 'Cancel trial account with grace period',
                graceCancelTip:
                    'All of the data in the {{accountName}} account will be marked as deleted. The data will be kept for a {{graceTrialDays}} day grace period, during which time you can subscribe the account and restore the data.<br /><br />',
                header: 'Cancel Trial Account',
                immediateCancel: 'Cancel trial account immediately',
                immediateCancelTip:
                    'All of the data in the {{accountName}} account will be permanently deleted. The account will be removed from the list of accounts.<br /> <br />This action is immediate and cannot be undone.',
                title: 'How would you like the account to be canceled?'
            },
            userStatus: {
                ACTIVE: 'Active',
                BLOCKED: 'Blocked',
                DELETED: 'Deleted',
                NOTACTIVE: 'Not active',
                TRIAL: 'Trial',
                UNSUBSCRIBED: 'Unsubscribed'
            }
        },
        directAccountsFilters: {
            ALL: 'All',
            accountStatus: {
                ANNUAL_SUBSCRIBED: 'Annual Subscribed',
                INACTIVE: 'Inactive',
                MONTHLY_SUBSCRIBED: 'Monthly Subscribed',
                TRIAL_CANCELLED: 'Trial Canceled',
                TRIAL_EXPIRED: 'Trial Expired',
                TRIAL_EXPIRING: 'Trial Expiring',
                TRIAL_STARTED: 'Trial Started',
                UNSUBSCRIBED: 'Subscription canceled'
            },
            backupStatus: {
                FAILED: 'Failed',
                NO_BACKUPS: 'No Backups',
                PARTIALLY_FAILED: 'Partially Failed',
                SUCCESSFUL: 'Successful'
            },
            billingStatus: {
                CURRENT: 'Current',
                DUE: 'Due',
                NONE: 'None',
                OVERDUE: 'Overdue'
            },
            buttons: {
                accountStatus: 'Account Status',
                backupStatus: 'Backup Status',
                billingStatus: 'Billing Status',
                subscribe: 'Subscribe'
            },
            confirmationTitle: {
                multiple: '{{count}} selected accounts will be subscribed to the {{type}} plan, please confirm.',
                single: '{{count}} selected account will be subscribed to the {{type}} plan, please confirm.'
            },
            searchQueryPlaceholder: 'Search by account name or email',
            subscribe: {
                ANNUAL: 'Annual',
                MONTHLY: 'Monthly'
            }
        },
        footer: {
            itemPagination: 'Showing {{start}}-{{end}} of {{total}}',
            noTotalItemPagination: 'Showing {{start}}-{{end}}',
            of: 'of',
            page: 'Page',
            showTotal: '{{start}}-{{end}} of {{total}} items'
        },
        messages: {
            SFC: {
                restore: {
                    disable: 'Restore option will be disabled as you choose 2 or more rows with the same ID.',
                    disableNoIndexed:
                        'Restore option is disabled since some of chosen objects unable to be restored (grayed out)',
                    enable: 'You can restore your items now'
                }
            },
            common: {
                noRecords: 'No records to view'
            },
            subscription: {
                all: {
                    failed: 'Subscribe failed, check your payment details and try again',
                    succeed: 'All accounts have been subscribed'
                },
                annual: '{{name}} was successfully set to annual subscription',
                failed: 'Subscribe failed, check your payment details and try again',
                monthly: '{{name}} was successfully added to your monthly subscription',
                selected: '{{success}} selected accounts was successfully subscribed.',
                single: {
                    failed: 'Subscribe failed, check your payment details and try again',
                    succeed: 'The account has been subscribed'
                }
            }
        },
        rows: {
            fields: {
                addresses: 'Address(es)',
                company: 'Company',
                content: 'Content',
                description: 'Description',
                emails: 'Email(s)',
                job: 'Job Title',
                message: 'Message',
                phones: 'Phone(s)',
                recipients: 'Recipients'
            },
            noInfo: 'No Additional Information Found'
        },
        summaryTaskStatus: {
            complete: 'All Tasks Completed',
            failed: ' {{failureBackupCount}} Failed, {{successfulBackupCount}} Completed',
            noTasks: 'No tasks'
        },
        taskType: {
            export: 'Export',
            restore: 'Restore'
        },
        types: {
            document: 'Document',
            folder: 'Folder',
            folderNotIndex: 'Not indexed folder (drill down is not available)',
            metadata: 'Metadata'
        },
        userStatus: {
            active: 'Active',
            blocked: 'Blocked',
            deleted: 'Deleted',
            notactive: 'Not active',
            trial: 'Trial Account',
            unsubscribed: 'Unsubscribed'
        }
    },
    toast: {
        statuses: {
            error: 'Error',
            info: 'Info',
            success: 'Success',
            warning: 'Warning'
        }
    },
    twoFaAuthPage: {
        actions: {
            disable: 'disable',
            get: 'Get Recovery Codes',
            next: 'next',
            save: 'Save Recovery Codes',
            verify: 'verify'
        },
        codeVerified: 'Two-Factor Authentication code has been verified',
        disable2FA: 'Two-Factor Authentication has been disabled',
        disableFailed: 'Failed to disable Two-Factor Authentication',
        enabledCodes:
            "Recovery codes allow you to access your account in the event that you lose your Two-Factor device and don't have access to Google Authenticator app",
        enabledRecommend: 'We recommend to save the codes with a password manager such as',
        enabledTitle: 'Two-Factor Authentication is enabled',
        instruction1:
            'Download any software-based authenticator application, e.g., Google, Microsoft or Duo Mobile(for Android, IOS and Blackberry).',
        instruction2:
            'Open the authenticator application and add your {{companyName}} account by scanning the QR code to the right. If you cant use a QR code then enter this text code: {{code}}',
        lockOutRemind: "Don't get locked out of your {{0}} account!",
        recoveryCodeAdvice:
            'We recommend to save the codes with a password manager such as <0>LastPass</0>, <1>1Password</1> or <2>Keeper</2>,',
        recoveryCodeText:
            "Recovery codes allow you to access your account in the event that you lose your Two-Factor device and don't have access to Google Authenticator app",
        recoveryCodeTitle: "Don't get locked out of your CloudAlly Account!",
        verifyFailed: 'The Two-Factor code you have provided is invalid',
        verifyPlaceholder: 'Type in the verification code'
    },
    userManagement: {
        accounts: 'Accessible Accounts',
        actions: {
            add: 'Add',
            cancel: 'Cancel',
            del: 'Delete',
            edit: 'Edit',
            save: 'Save'
        },
        createPasswordModal: {
            done: 'Done',
            passwordLabel: 'Password',
            passwordPlaceholder: 'Create a password',
            title: 'Create Password'
        },
        credentials: 'Credentials',
        email: 'Email',
        emailTooltip:
            'A confirmation message has been sent to <0>{{email}}</0>. Follow the link in the message to confirm the new address.',
        name: 'Display Name',
        operations: 'Operations',
        password: 'Password',
        permissions: {
            activateBackupTooltip: 'Allows the creation of new backup tasks',
            activate_backup: 'Activate new backup tasks',
            billingNotifications: 'View billing notification messages',
            billingNotificationsTooltip: 'Allows display of billing notification messages',
            cancel_backup: 'Cancel backup tasks',
            deleteBackupTooltip: 'Allows the deletion of current backup tasks',
            delete_backup: 'Delete backup tasks',
            delete_items: 'Delete backup Items - GDPR Right to Erasure',
            editBackupTooltip: 'Allows the modification of current backup tasks',
            edit_backup: 'Modify backup tasks',
            execute_backup: 'Execute backup tasks',
            exploreBackups: 'View backups tasks',
            exportBackups: 'Export backups',
            exportUsageReport: 'Export usage report',
            general: 'General',
            manageEndUserRecovery: 'Manage end user recovery',
            managePaymentsTooltip:
                'Allows display of the Billing page from which you can change payment details and view current and past invoices',
            manageUserInfo: 'View Account setting page',
            manageUserInfoTooltip:
                'Allows display of the Account settings page from which you can change the account name, email address and also allows unsubscribing from the service',
            manage_notifications: 'Manage Notifications',
            manage_payments: 'View billing page and manage payment details',
            pause_backup: 'Pause backup tasks',
            ppDashboardNewAccounts: 'Add new Accounts',
            ppDashboardSubscribe: 'Subscribe Accounts',
            ppManagePaymentTooltip:
                'Allows display of the Billing page from which you can change payment details, view current and past invoices and also display of billing statuses at the Dashboard',
            ppManageUserInfo: 'View Partner Portal settings page',
            ppManageUserInfoTooltip:
                'Allows display of the Partner Portal settings page from which you can change the account name and contact details',
            ppViewDashboard: 'View dashboard',
            ppViewDashboardTooltip: 'Allows display of the Partner Portal Dashboard',
            ppViewSecurityAudit: 'View security audit page',
            previewBackups: 'Preview backed up data',
            restoreBackups: 'Restore backups',
            showAllSupportTickets: 'Show all your support tickets',
            show_all_support_tickets: 'Show all support tickets',
            tasks: 'Backup Tasks',
            viewAccountActivityTooltip: 'Allows display of the System Activity page and Jobs page',
            viewBackupTooltip: 'Allows display of the Backups page which shows all the created backup tasks',
            viewSupportTooltip: 'Allows display of the Support page which is opened in a new tab',
            view_account_security_audit_page: 'View account security audit page',
            view_accounts_activity: 'View account activity',
            view_backup: 'View backups page',
            view_security_audit_page: 'View security audit page',
            view_support: 'View support page'
        },
        ppOperations: 'Partner Portal Operations',
        ppPortalOperations: 'Account Operations',
        resendEmailConfirmation: 'Resend Email Confirmation',
        role: 'Role',
        rolePlaceholder: 'Select role',
        roles: {
            administrator: 'Administrator',
            custom: 'Custom'
        },
        services: 'Services',
        type: 'Type',
        typePlaceholder: 'Select type',
        waitForResend: 'Resend link will appear again in <0>{{timer}}</0> seconds'
    },
    userManagementToast: {
        accountsDontChoosed: 'Please choose at least one Accessible Account for this user.',
        addFailed: 'Failed to add credential',
        credentialAdded: 'User has been added succesfully',
        credentialRemoved: 'User {{0}} has been removed successfully',
        editConfirmation:
            'By changing the permissions of this user you might disrupt the access of your partner, are you sure you want to continue?',
        permissionsUpdateFailed: 'Failed to change permissions',
        permissionsUpdated: 'Permissions changed successfully',
        removeConfirmation: 'Are you certain you want to delete this user?',
        removeFailed: 'Failed to remove credential',
        removeTechnicalConfirmation:
            'By deleting this user your partner will not be able to access your account, are you sure?'
    },
    validation: {
        amex: 'American Express can only be used with USD as billing currency',
        atLeastOneNumAndChar: 'The password must have at least one capital, one lower and one number',
        dateRange: 'Payment Day should be between 1 and 31',
        dontRepeatPasswords: 'New password should not match with the old one',
        expiredDate: 'This field should be between 1 and 365',
        incorrectPhone: 'Phone number format is incorrect',
        invalidIpAddressFormat: 'Invalid IP address format, please fix IP address syntax and try again',
        invalidIpAddressNumbers: 'Invalid IP address, please specify a number between 0 and 255',
        invalidIpAddressRange: 'Invalid IP address range, please fix your input and try again',
        invalidOneDriveUrl: 'Invalid OneDrive URL. The URL must be <tenant>-my.sharepoint.com',
        invalidSharePointUrl: 'Invalid SharePoint URL. The URL must be <tenant>.sharepoint.com',
        ipAddressDescriptionExists: 'Trusted IP address with the same description already exists',
        ipAddressExists: 'Trusted IP address already exists',
        ipAddressRangeExists: 'Trusted IP address range already exists',
        maxLength: 'Must be less than {{length}} characters long',
        maxJobLabelLength: 'Please use less than 50 characters for Job Label',
        maxPassword: 'The password length supports at most {{0}} chars',
        minLength: 'Must be at least {{0}} characters long',
        minPassword: 'The password must be at least {{0}} characters long',
        notMatchedPassword: 'Password does not match',
        onlyPositiveInt: 'Invalid value specified. Only positive numbers are allowed.',
        requiredField: 'Required field',
        specialCharValidation: 'The password cannot contain the following symbols: < > +',
        trailingOrLeadingSpace: 'The password cannot have leading or trailing spaces',
        validEmailAddress: 'Please enter a valid Email address.'
    }
}
