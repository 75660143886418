import React, {useCallback, useEffect, useState} from 'react'
import {Form} from 'antd'
import {useHistory} from 'react-router'
import {Trans, useTranslation} from 'react-i18next'
import find from 'lodash/find'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import ErrorIcon from 'ca-common/icons/PaymentDetails/ErrorIcon.svg'
import {isMonthlyManualBilling} from 'ca-common/utils/billing'
import {
    ZOOZ_SUBSCRIBE_RESPONSE_STATUS,
    PAYMENT_TYPE,
    TITLE,
    PAGES,
    CURRENCIES,
    CURRENCY,
    FULFILLED,
    PAGE,
    PAYMENT_TYPE_UPPER_CASE,
    CREDIT_CARD_STATUS,
    PAYMENT_METHOD,
    SUBMIT_CARD_ERROR,
    AMEX_WITHOUT_USD_ERROR,
    VENDOR_CURRENCY_ERROR,
    VENDOR,
    ZOOZ_SUBSCRIBE_ERROR
} from 'ca-common/constants'
import {CAFormPage} from 'ca-common/ui-lib/components/FormPage'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {BillingNotification} from 'ca-common/components/Billing/Payment/BillingNotification'
import {usePaymentOS} from 'ca-common/components/Billing/Payment/CardDetails/usePaymentOS'
import type {PaymentDetailsType} from 'ca-common/components/Billing/Payment/types'
import {CardDetailsPage} from 'ca-common/components/Billing/Payment/CardDetails'
import {PaymentDetails} from 'ca-common/components/Billing/Payment/PaymentDetails'
import {Cards} from 'ca-common/components/Billing/Payment/Cards'
import {getPosPublicKey} from 'src/newcore/redux/modules/billing'
import {setZoozSubscribeMonthly} from 'src/newcore/redux/modules/billing'
import {updateUserInfo, setUnsubscribe, userInfoSelector, fetchUserInfo} from 'src/newcore/redux/modules/account'
import {
    getZoozPaymentMethods,
    setBillingDetails,
    getUsersBilling,
    setZoozSubscribe,
    updateUsersBilling,
    updatePaymentMethod,
    removeZoozPaymentMethod,
    getNextPaymentDate,
    getPaymentMethod
} from 'src/newcore/redux/modules/billing'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {StyledAlert, StyledPreferncesTitle} from './StyledPayment'
import {useCurrentCurrency} from 'src/newcore/utils/useCurrentCurrency'

export const Payment = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const history = useHistory()
    const [activateCardLoader, setactivateCardLoader] = useState(false)
    const [deleteCardLoader, setdeleteCardLoader] = useState(false)
    const [isOpenCardAdding, setIsOpenCardAdding] = useState(true)
    const [showAmexCurrencyError, setShowAmexCurrencyError] = useState(false)
    const [submittingDetails, setsubmittingDetails] = useState(false)
    const initCurrency = useCurrentCurrency()
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const zoozPaymentMethods = useAppSelector((state: AppState) => state.billing.zoozPaymentMethods)
    const paymentMethod = useAppSelector((state: AppState) => state.billing.paymentMethod)
    const {zoozSubscribeStatus} = useAppSelector((state: AppState) => ({
        zoozSubscribeStatus: state.billing.zoozSubscribe.status
    }))

    const [paymentDetailsForm] = Form.useForm<PaymentDetailsType>()
    const watchedCurrency = Form.useWatch('currency', paymentDetailsForm) as CURRENCIES

    const createToken = usePaymentOS(dispatch, getPosPublicKey)

    const submitCardData = () => {
        const cardholderName = paymentDetailsForm.getFieldValue('cardholderName')
        return createToken(cardholderName)
    }

    const checkIsPaymentDetailsValid = useCallback(async () => {
        try {
            await paymentDetailsForm.validateFields()
            return true
        } catch {
            return false
        }
    }, [paymentDetailsForm])

    const isCardsVisible =
        zoozPaymentMethods.status === FULFILLED &&
        zoozPaymentMethods.response.paymentMethods.length > 0 &&
        paymentMethod.status === FULFILLED &&
        !isMonthlyManualBilling(paymentMethod.response.paymentProvider, paymentMethod.response.subscriptionType)

    const unsubscribe = async () => {
        try {
            await dispatch(setUnsubscribe({}))
            await dispatch(updateUserInfo({isSubscribed: false}))

            if (paymentMethod.status === FULFILLED) {
                await dispatch(
                    updatePaymentMethod({
                        isSubscribe: false
                    })
                )
            }
            openSuccessNotification(t('accountPage:unsubscribeMessage', [companyInfo.name]))
        } catch {
            openErrorModal(t('accountPage:unsubscribeFailed'))
        }
    }

    const onDeleteCard = async (paymentMethodToken: string, isLastCard?: boolean) => {
        try {
            await dispatch(removeZoozPaymentMethod({paymentMethodToken}))
            await dispatch(getZoozPaymentMethods())
            openSuccessNotification(t('billing:zoozMessages:cardWasDeleted'))
            setIsOpenCardAdding(false)
            if (isLastCard) await unsubscribe()
        } catch (error: any) {
            openErrorModal(`${error?.status}`)
        }
    }

    const submitPaymentDetails = useCallback((values: PaymentDetailsType) => {
        setsubmittingDetails(true)
        return dispatch(setBillingDetails(values))
            .unwrap()
            .then(() => {
                setsubmittingDetails(false)
                dispatch(getZoozPaymentMethods())
                dispatch(fetchUserInfo({}))
                openSuccessNotification(t('billing:toasts:setPaymentDetails:success'))
            })
            .catch((response: {status: string}) => {
                setsubmittingDetails(false)
                openErrorModal(response?.status || t('billing:toasts:setPaymentDetails:error'))
            })
    }, [])
    const createBillingMethod = (data: {vendor: string; token: string}, values: PaymentDetailsType) => {
        const {vendor, token} = data
        const subscriptionType = PAYMENT_TYPE_UPPER_CASE.MONTHLY
        return dispatch(
            setZoozSubscribe({
                ...values,
                watchedCurrency,
                vendor,
                paymentMethodToken: token,
                subscriptionType
            })
        )
            .unwrap()
            .then(() => {
                setIsOpenCardAdding(false)
                setsubmittingDetails(false)
                openSuccessNotification(t('billing:zoozMessages:cardWasAdded'))
                dispatch(getZoozPaymentMethods())
                dispatch(fetchUserInfo({refreshRequired: true}))
                dispatch(updateUsersBilling({paymentType: t('table:billing:subscribeType:monthly')}))
                dispatch(getNextPaymentDate({refresh: 'true'}))
                dispatch(
                    updatePaymentMethod({
                        subscriptionType: t('table:billing:subscribeType:monthly'),
                        isSubscribe: true
                    })
                )
                dispatch(getPaymentMethod())
            })
            .catch((error: any) => {
                setsubmittingDetails(false)
                if (error.data?.currency?.includes('0x32')) {
                    return Promise.reject({statusCode: VENDOR_CURRENCY_ERROR})
                }
                return Promise.reject({statusCode: ZOOZ_SUBSCRIBE_ERROR, description: error.status})
            })
    }
    const handleOnClick = (values: PaymentDetailsType) => {
        return submitCardData()
            .then((data: any) => {
                if (data.vendor === VENDOR.AMEX && watchedCurrency !== CURRENCIES.USD) {
                    return Promise.reject({statusCode: AMEX_WITHOUT_USD_ERROR})
                }

                return createBillingMethod(data, values)
            })
            .catch((err: any) => {
                let errorMessage: string | JSX.Element = t('billing:zoozMessages:fillFormFields')
                const supportEmail = 'support@cloudally.com'
                if (err.statusCode === SUBMIT_CARD_ERROR) {
                    errorMessage = err.description
                } else if (err.statusCode === AMEX_WITHOUT_USD_ERROR) {
                    errorMessage = (
                        <Trans
                            i18nKey="billing:zoozMessages:amex"
                            values={{supportEmail: companyInfo != null ? companyInfo.supportEmail : null}}
                        >
                            <br />
                            <a href={`mailto:${companyInfo != null ? companyInfo.supportEmail : null}`}>
                                Support Email
                            </a>
                        </Trans>
                    )

                    setShowAmexCurrencyError(true)
                } else if (err.statusCode === VENDOR_CURRENCY_ERROR) {
                    errorMessage = (
                        <Trans i18nKey="billing:zoozMessages:amex" values={{supportEmail}}>
                            <br />
                            <a href={`mailto:${supportEmail}`}>Support Email</a>
                        </Trans>
                    )

                    setShowAmexCurrencyError(true)
                }
                setsubmittingDetails(false)
                return Promise.reject(errorMessage)
            })
    }
    const activateCard = useCallback(
        async (paymentMethodToken: string) => {
            if (!checkIsPaymentDetailsValid()) {
                openErrorModal(t('billing:zoozMessages:fillFormFields'))
                return
            }

            const cardMethod = find(zoozPaymentMethods.response?.paymentMethods || [], {
                paymentMethodToken
            })

            const paymentDetailsValues = paymentDetailsForm.getFieldsValue()

            dispatch(
                setZoozSubscribe({
                    ...paymentDetailsValues,
                    paymentMethodToken,
                    vendor: cardMethod?.vendor || ''
                })
            )
                .unwrap()
                .then(() => {
                    openSuccessNotification(t('billing:zoozMessages:cardWasActivated'))
                    setIsOpenCardAdding(false)
                    dispatch(getZoozPaymentMethods())
                    dispatch(fetchUserInfo({refreshRequired: true}))
                    dispatch(updateUsersBilling({paymentType: PAYMENT_TYPE.MONTHLY}))
                    dispatch(getNextPaymentDate({refresh: 'true'}))
                    dispatch(updatePaymentMethod({subscriptionType: PAYMENT_TYPE.MONTHLY, isSubscribe: true}))
                    dispatch(getPaymentMethod())
                })
                .catch((error: any) => {
                    if (error.statusCode === ZOOZ_SUBSCRIBE_RESPONSE_STATUS.PARAMS_NOT_VALID) {
                        return openErrorModal(t('billing:zoozMessages:fillFormFields'))
                    } else if (error.statusCode === ZOOZ_SUBSCRIBE_RESPONSE_STATUS.BAD_SUBSCRIBTION) {
                        return openErrorModal(t('billing:zoozMessages:cardIsExpired'))
                    }
                    return openErrorModal(t('billing:zoozMessages:subscriptionFailed'))
                })
        },
        [checkIsPaymentDetailsValid, zoozPaymentMethods, paymentDetailsForm]
    )

    useEffect(() => {
        if (paymentMethod.status !== FULFILLED) return

        if (
            paymentMethod.status === FULFILLED &&
            paymentMethod.response.isSubscribe &&
            paymentMethod.response.subscriptionType &&
            paymentMethod.response.subscriptionType === PAYMENT_TYPE.ANNUAL
        ) {
            history.replace(`/${PAGES.BILLING}/${PAGES.ANNUAL_SUBSCRIPTION}`)
        }

        dispatch(getZoozPaymentMethods()).then((response: any) => {
            const data = response.payload

            if (data.paymentMethods.length) {
                setIsOpenCardAdding(false)
            }
        })
        dispatch(getUsersBilling({limit: 20}))
    }, [paymentMethod.status])

    const completeSubscription = async (values: PaymentDetailsType) => {
        setsubmittingDetails(true)
        if (await checkIsPaymentDetailsValid()) {
            handleOnClick(values).catch(err => openErrorModal(err))
        } else {
            openErrorModal(t('billing:zoozMessages:fillFormFields'))
            setsubmittingDetails(false)
        }
    }
    if (!userInfo) {
        return <></>
    }
    return (
        <div>
            {FULFILLED === zoozPaymentMethods.status && !paymentMethod.response?.isSubscribe && (
                <StyledPreferncesTitle>
                    {t('forms:paymentDetails:paymentPreferences:paymentPreferencesChoice')}
                </StyledPreferncesTitle>
            )}
            {!paymentMethod.response?.isSubscribe && (
                <StyledAlert
                    type="REMINDER"
                    title={t('billing:trialUserMessage:monthly:title')}
                    message={t('billing:trialUserMessage:monthly:message')}
                    link={{
                        name: t('billing:trialUserMessage:monthly:link'),
                        url: `/${PAGES.BILLING}/${PAGES.ANNUAL_SUBSCRIPTION}${history.location.search}`
                    }}
                />
            )}
            {paymentMethod.status === FULFILLED && paymentMethod.response?.cardStatus === CREDIT_CARD_STATUS.FAILED && (
                <BillingNotification
                    notificationIcon={ErrorIcon}
                    notificationHeader={t('forms:paymentDetails:paymentFailure:title')}
                    notifictionText={t('forms:paymentDetails:paymentFailure:text')}
                    isPaymentError={true}
                />
            )}
            {FULFILLED === zoozPaymentMethods.status ? (
                <CAFormPage>
                    {isCardsVisible && (
                        <Cards
                            isPP={false}
                            paymentMethods={zoozPaymentMethods.response.paymentMethods}
                            userInfo={userInfo}
                            zoozSubscribeStatus={zoozSubscribeStatus}
                            deleteCard={onDeleteCard}
                            activateCard={activateCard}
                            addCard={() => setIsOpenCardAdding(true)}
                            isLastCard={!(zoozPaymentMethods?.response?.paymentMethods?.length > 1)}
                            activateCardLoader={activateCardLoader}
                            deleteCardLoader={deleteCardLoader}
                            cardStatus={paymentMethod.response?.cardStatus}
                        />
                    )}
                    {isOpenCardAdding && (
                        <CardDetailsPage
                            isPP={false}
                            zoozSubscribeStatus={zoozSubscribeStatus}
                            closeAddBlock={() => setIsOpenCardAdding(false)}
                            companyInfo={companyInfo}
                            checkIsPaymentDetailsValid={checkIsPaymentDetailsValid}
                            currency={watchedCurrency}
                            setShowAmexCurrencyError={setShowAmexCurrencyError}
                            getPaymentDetailsValues={paymentDetailsForm.getFieldsValue}
                            subscriptionType={PAYMENT_TYPE_UPPER_CASE.MONTHLY}
                            form={paymentDetailsForm}
                            dispatch={dispatch}
                            getPosPublicKey={getPosPublicKey}
                            setZoozSubscribe={setZoozSubscribe}
                            getZoozPaymentMethods={getZoozPaymentMethods}
                            updateUsersBilling={updateUsersBilling}
                            updatePaymentMethod={updatePaymentMethod}
                            getNextPaymentDate={getNextPaymentDate}
                            getPaymentMethod={getPaymentMethod}
                            isSubscribed={paymentMethod.response?.isSubscribe}
                        />
                    )}
                    <PaymentDetails
                        isPP={false}
                        setPaymentDetails={
                            paymentMethod.response?.isSubscribe ? submitPaymentDetails : completeSubscription
                        }
                        paymentCurrency={zoozPaymentMethods.response.currency || CURRENCIES.USD}
                        currencyEditable={zoozPaymentMethods.response.currencyEditable}
                        paymentMethod={paymentMethod}
                        paymentMethods={zoozPaymentMethods.response.paymentMethods}
                        showAmexCurrencyError={showAmexCurrencyError}
                        setShowAmexCurrencyError={setShowAmexCurrencyError}
                        form={paymentDetailsForm}
                        submitting={submittingDetails}
                        annualSubsPaymentMethod={PAYMENT_METHOD.CREDIT_CARD_PAYMENT}
                        initialValues={{
                            ...(zoozPaymentMethods.response.billingDetails || {}),
                            subscriptionDate: undefined,
                            currency: CURRENCY.some(i => i.value === initCurrency)
                                ? initCurrency
                                : zoozPaymentMethods.response.currency || CURRENCIES.USD,
                            customerTitle: zoozPaymentMethods.response.billingDetails.customerTitle || TITLE[0].value
                        }}
                        setZoozSubscribeMonthly={setZoozSubscribeMonthly}
                        subscriptionType={PAYMENT_TYPE.MONTHLY}
                        isSubscribed={paymentMethod.response?.isSubscribe}
                        isCreditCardPaymentMethod
                    />
                </CAFormPage>
            ) : (
                <Spinner modifier={PAGE} />
            )}
        </div>
    )
}
