import _ from 'lodash'
import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import i18n from 'i18next'

import {INTEGRATION_TYPE} from 'ca-common/constants'

const PureUserBreadcrumb = ({name, match}) => <NavLink to={match.url}>{name}</NavLink>

const singleMapStateToProps = (state, props) => {
    const id = props.match.params.id
    const request = state.credentials

    return {
        name:
            request.status === 'fulfilled' && request.response ? _.find(request.response.data, ['id', id])?.email : null
    }
}

export const SingleUserManagementBreadcrumb = connect(singleMapStateToProps)(PureUserBreadcrumb)

const mapStateToProps = state => {
    const userInfo = state.userInfo

    return {
        name:
            userInfo.status === 'fulfilled' && userInfo.response.integrationType === INTEGRATION_TYPE.ZIX
                ? i18n.t('settings:menu:users:zixName')
                : i18n.t('settings:menu:users:name')
    }
}

export const UserManagementBreadcrumb = connect(mapStateToProps)(PureUserBreadcrumb)
