import React, {useEffect} from 'react'
import {Trans} from 'react-i18next'

import GrayPerson from 'ca-common/icons/GrayPerson.svg'
import Dynamics from 'ca-common/icons/source/Dynamics.svg'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {wizardDataSelector} from 'src/newcore/redux/modules/wizard'
import {createAppUser} from 'src/newcore/features/DynamicsWizard/lib/api'

import {DynamicsHeader} from 'src/newcore/features/DynamicsWizard/atoms'
import {
    StyledContent,
    StyledBorder,
    StyledPersonIcon,
    StyledDynamicIcon,
    StyledReloadWrapperAzure,
    StyledReloadWrapperUser,
    StyledCheckCircleFilled,
    StyledSyncOutlined,
    StyledExclamationCircleFilled,
    StyledErrorMessage,
    StyledDisabledSyncOutlined
} from './StyledApplicationUserCreationStatus'

export const ApplicationUserCreationStatus = () => {
    const wizardData = useAppSelector(wizardDataSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!wizardData.isUserAuthenticationFailed) {
            createAppUser(wizardData.environment, dispatch)
        }
    }, [])
    return (
        <>
            <DynamicsHeader
                isForAuth={wizardData.isUserAuthenticationFailed}
                hideDescription={wizardData.appUserCreationError}
                friendlyName={wizardData.environment?.friendlyName}
            />
            <StyledContent>
                <StyledBorder>
                    <StyledPersonIcon component={GrayPerson} />
                    <StyledDynamicIcon component={Dynamics} />
                    <StyledReloadWrapperAzure>
                        {wizardData.isUserAuthenticationFailed ? (
                            <StyledExclamationCircleFilled />
                        ) : (
                            <StyledCheckCircleFilled />
                        )}
                        <Trans i18nKey="forms:backupAuth:text:DynamicsAppAuthorization" />
                    </StyledReloadWrapperAzure>
                    <StyledReloadWrapperUser>
                        {wizardData.appUserCreated ? (
                            <StyledCheckCircleFilled />
                        ) : wizardData.appUserCreationError ? (
                            <StyledExclamationCircleFilled />
                        ) : wizardData.isUserAuthenticationFailed ? (
                            <StyledDisabledSyncOutlined disabled />
                        ) : (
                            <StyledSyncOutlined spin />
                        )}
                        <Trans i18nKey="forms:backupAuth:text:DynamicsCreateUser" />
                    </StyledReloadWrapperUser>
                </StyledBorder>
                {wizardData.isUserAuthenticationFailed && (
                    <StyledErrorMessage>
                        <Trans i18nKey="forms:backupAuth:text:DynamicsOauthErrorInfo" />
                    </StyledErrorMessage>
                )}
                {wizardData.appUserCreationError && (
                    <StyledErrorMessage>
                        <Trans i18nKey="forms:backupAuth:text:DynamicsCreateUserErrorInfo" />
                    </StyledErrorMessage>
                )}
            </StyledContent>
        </>
    )
}
