import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {Button} from 'src/ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'src/ca-common/components/Modal'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {ApplicationUserCreation} from 'src/newcore/features/DynamicsWizard/organisms/CreateApplicationUser/ApplicationUserCreation'
import {authenticate} from 'src/newcore/features/DynamicsWizard/lib/api'
import {STEPS} from 'src/newcore/features/DynamicsWizard/lib/DynamicsSteps'

import {
    previousStep,
    closeWizard,
    changeProgress,
    wizardStepSelector,
    wizardDataSelector,
    changeStep,
    setWizardTitle
} from 'src/newcore/redux/modules/wizard'

export const CreateApplicationUser = () => {
    const {t} = useTranslation()
    const currentStep = useAppSelector(wizardStepSelector)
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector)

    const backStepHandler = () => {
        if (wizardData.environment.isGracePeriod) {
            dispatch(previousStep())
            dispatch(changeProgress(currentStep - 1))
            dispatch(setWizardTitle(t('forms:backupAuth:text:DynamicsRecentlyDeletedTitle')))
        } else {
            dispatch(changeProgress(STEPS.chooseEnvironment))
            dispatch(changeStep(STEPS.chooseEnvironment))
        }
    }

    const history = useHistory()
    const onCancel = () => {
        dispatch(closeWizard())
        history.replace(history.location.pathname)
    }

    return (
        <>
            <ApplicationUserCreation />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button
                        type="primary"
                        onClick={() => {
                            authenticate(wizardData.environment, wizardData.alias)
                        }}
                    >
                        {t('forms:common:actions:authenticate')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={backStepHandler} />
            </ModalFooter>
        </>
    )
}
