export enum STEPS {
    chooseEnvironment = 0,
    recentlyDeletedTask = 1,
    createApplicationUser = 2,
    createApplicationUserStatus = 3
}

export const DYNAMICS_WIZARD_CONFIG = {
    hiddenTabBarItems: [`${STEPS.recentlyDeletedTask}`]
}
