import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Button, Col, Row} from 'antd'

import {BackupName} from 'src/newcore/molecules'
import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupAuthTemplate} from '../../types/BackupAuthTemplate'

import {
    BackupAuthBody,
    StyledBackupAuthForm,
    StyledErrorMsg
} from 'src/newcore/features/BackupAuth/templates/StyledTemplates'

export const Dynamics = ({
    source,
    backupSettings,
    onSubmit,
    onCancel,
    loading,
    dynamicsAuthenticationFailed,
    form
}: BackupAuthTemplate): JSX.Element => {
    const {t} = useTranslation()
    return (
        <StyledBackupAuthForm
            initialValues={{alias: backupSettings.alias, account: backupSettings.account}}
            layout="horizontal"
            form={form}
            onFinish={values => onSubmit(values)}
        >
            <Header source={source} titleText={t('forms:backupAuth:text:DynamicsInfo')} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName source={source} />
                    </Col>
                    <Col>{t('forms:backupAuth:text:DynamicsOauthInfo')}</Col>
                    {dynamicsAuthenticationFailed && (
                        <StyledErrorMsg>{t('forms:backupAuth:text:DynamicsOauthErrorInfo')}</StyledErrorMsg>
                    )}
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                    {t('forms:common:actions:authenticateInDynamics')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
