import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const CreditCardErrorIcon = styled(Icon)`
    width: ${variables.gutterMd};
    height: ${variables.gutterLg};
    padding: 1.33px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: ${variables.gutterMd};
    margin-left: -${variables.gutterXs};
`
