import styled from 'styled-components'
import {Checkbox, Select} from 'antd'

import variables from 'ca-common/variables'

export const StyledRestoreHeader = styled.div`
    margin-top: ${variables.gutterSm};
    font-weight: 600;
    color: ${variables.text};
`

export const StyledTypeDescription = styled.div`
    margin-top: 12px;
    width: 260px;
    margin-bottom: ${variables.gutterLg};

    > span {
        display: list-item;
        margin-left: ${variables.gutterSm};
        list-style: outside;
    }
`

export const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 12px;
    && {
        margin-left: 0;
    }

    &&:first-of-type {
        margin-top: ${variables.gutterXs};
    }

    &&:last-of-type {
        margin-bottom: 36px;
    }
`

export const StyledSelect = styled(Select)`
    margin-top: ${variables.gutterXs};
`
