import {TODO_ANY} from 'ca-common/types'

export enum SOURCE {
    SDB = 'SDB',
    DDB = 'DDB',
    YMAIL = 'YMAIL',
    MS365 = 'MS365',
    GOOGLEAPPS = 'GOOGLEAPPS',
    GDRIVETEAM = 'GDRIVETEAM',
    GACCOUNTS = 'GACCOUNTS',
    SFC = 'SFC',
    SHAREPOINT = 'SHAREPOINT',
    SHAREPOINT_MULTI = 'SHAREPOINT_MULTI',
    ONEDRIVE = 'ONEDRIVE',
    IMAP = 'IMAP',
    BOX = 'BOX',
    DROPBOX = 'DROPBOX',

    GMAIL = 'GMAIL',
    GDOC = 'GDOC',
    GSITES = 'GSITES',
    GTASK = 'GTASK',
    GCONTACTS = 'GCONTACTS',
    GCALENDAR = 'GCALENDAR',
    O365TASKS = 'O365TASKS',
    O365CALENDAR = 'O365CALENDAR',
    O365MAIL = 'O365MAIL',
    O365CONTACTS = 'O365CONTACTS',
    O365NOTES = 'O365NOTES',
    MS365GROUPS = 'MS365GROUPS',
    MS365GROUP_CONVERSATIONS = 'MS365GROUP_CONVERSATIONS',
    MS365GROUP_META = 'MS365GROUP_META',
    MS365GROUP_CALENDAR = 'MS365GROUP_CALENDAR',
    MS365GROUP_SITE = 'MS365GROUP_SITE',
    MS365TEAM_CHANNELS = 'MS365TEAM_CHANNELS',
    MS365TEAM_APPS = 'MS365TEAM_APPS',
    DYNAMICS365 = 'DYNAMICS365'
}
// type SOURCE_INFO_TYPE = {
//     [Property in keyof typeof SOURCE]: {
//         icon?: string
//         displayName: string
//         hasSubAccounts?: boolean
//         indexed?: boolean
//         canRestore?: boolean
//         cost?: number
//         shortName?: string
//         masterSource?: string | string[]
//         hasBulkActivation?: boolean
//     }
// }

export const SOURCE_INFO = {
    SDB: {
        icon: 'service_simpledb',
        displayName: 'SimpleDB',
        hasSubAccounts: true,
        indexed: false,
        canRestore: true,
        cost: 14
    },
    DDB: {
        icon: 'service_dynamodb',
        displayName: 'DynamoDB',
        hasSubAccounts: true,
        indexed: false,
        canRestore: true,
        cost: 13
    },
    YMAIL: {
        icon: 'service_yahoo',
        displayName: 'Yahoo Mail',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 13
    },
    MS365: {
        icon: 'service_officeexchange',
        displayName: 'Microsoft Exchange',
        shortName: 'MS Exchange',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        hasBulkActivation: true,
        cost: 0
    },
    MS365GROUPS: {
        icon: 'service_officegroups',
        displayName: 'Microsoft Groups / Teams',
        shortName: 'MS Groups / Teams',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 1
    },
    GOOGLEAPPS: {
        icon: 'service_gsuits',
        displayName: 'Google Workspace',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 6,
        hasBulkActivation: true
    },
    GDRIVETEAM: {
        icon: 'service_gtdrive',
        displayName: 'Google Shared Drives',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 7
    },
    GACCOUNTS: {
        icon: 'service_google',
        displayName: 'Google Account',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 11
    },
    SFC: {
        icon: 'service_salesforce',
        displayName: 'Salesforce',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 8
    },
    SHAREPOINT: {
        icon: 'service_sharepoint',
        displayName: 'SharePoint',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 2
    },
    SHAREPOINT_MULTI: {
        icon: 'service_sharepoint',
        displayName: 'SharePoint',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        hasBulkActivation: true,
        cost: 3
    },
    ONEDRIVE: {
        icon: 'service_onedrive',
        displayName: 'OneDrive',
        hasBulkActivation: true,
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 4
    },
    IMAP: {
        icon: 'service_imap',
        displayName: 'IMAP',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 11
    },
    BOX: {
        icon: 'service_box',
        displayName: 'Box',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 8
    },
    DROPBOX: {
        icon: 'service_dropbox',
        displayName: 'Dropbox',
        hasSubAccounts: true,
        indexed: true,
        canRestore: true,
        cost: 10
    },
    DYNAMICS365: {
        icon: 'service_dynamics',
        displayName: 'Microsoft Dynamics 365',
        hasSubAccounts: false,
        indexed: true,
        canRestore: true,
        cost: 5
    },

    GMAIL: {displayName: 'Emails', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},
    GDOC: {displayName: 'Documents', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},
    GSITES: {displayName: 'Sites', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},
    GTASK: {displayName: 'Tasks', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},
    GCONTACTS: {displayName: 'Contacts', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},
    GCALENDAR: {displayName: 'Calendar', masterSource: [SOURCE.GOOGLEAPPS, SOURCE.GACCOUNTS]},

    O365TASKS: {displayName: 'Tasks', masterSource: SOURCE.MS365},
    O365CALENDAR: {displayName: 'Calendars', masterSource: SOURCE.MS365},
    O365MAIL: {displayName: 'Emails', masterSource: SOURCE.MS365},
    O365CONTACTS: {displayName: 'Contacts', masterSource: SOURCE.MS365},
    O365NOTES: {displayName: 'Notes', masterSource: SOURCE.MS365},

    MS365GROUP_CONVERSATIONS: {displayName: 'Conversations', masterSource: SOURCE.MS365GROUPS},
    MS365GROUP_META: {displayName: 'Metadata', masterSource: SOURCE.MS365GROUPS},
    MS365GROUP_CALENDAR: {displayName: 'Calendar', masterSource: SOURCE.MS365GROUPS},
    MS365GROUP_SITE: {displayName: 'Site', masterSource: SOURCE.MS365GROUPS},
    MS365TEAM_CHANNELS: {displayName: 'Channels', masterSource: SOURCE.MS365GROUPS},
    MS365TEAM_APPS: {displayName: 'Apps', masterSource: SOURCE.MS365GROUPS}
} as TODO_ANY

export const END_USER_SERVICE = ['MS365']
