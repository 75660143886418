import styled from 'styled-components'
import Icon from '@ant-design/icons/lib/components/Icon'

import variables from 'ca-common/variables'

export const StyledContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`
export const StyledBorder = styled.div`
    border: 1px dashed ${variables.navyBorder};
    border-radius: 50%;
    margin-bottom: 40px;
    position: relative;
`

export const StyledPersonIcon = styled(Icon)`
    font-size: 160px;
    padding: 40px;
`

export const StyledDynamicIcon = styled.div`
    font-size: 52px;
    position: absolute;
    left: 138px;
    top: 9px;
`
