import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {Spin} from 'antd'

import {Button} from 'src/ca-common/ui-lib/atoms'
import {BackLink, CancelLink, LinkWrapper, ModalFooter} from 'src/ca-common/components/Modal'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {reinstate, reCreateNewTask} from 'src/newcore/features/DynamicsWizard/lib/api'
import {RecentlyDeletedTaskSource} from 'src/newcore/features/OneClickActivation/atoms'
import {StyledContent, StyledParagraph} from 'src/newcore/features/DynamicsWizard/templates/StyledDeletedEnvironment'

import {
    previousStep,
    closeWizard,
    changeProgress,
    wizardStepSelector,
    wizardDataSelector,
    changeData,
    setWizardTitle,
    changeConfig
} from 'src/newcore/redux/modules/wizard'
import {SOURCE} from 'src/ca-common/common/enum'

export const RecentlyDeletedEnvironment = () => {
    const {t} = useTranslation()
    const currentStep = useAppSelector(wizardStepSelector)
    const dispatch = useAppDispatch()
    const wizardData = useAppSelector(wizardDataSelector)
    const nextStepHandler = () => {
        dispatch(changeData({deletedTaskLoading: true}))
        if (wizardData.recentlyDeletedTask) {
            reinstate(dispatch)
        } else {
            reCreateNewTask(dispatch)
        }
    }
    useEffect(() => {
        dispatch(
            changeConfig({
                hiddenTabBarItems: []
            })
        )
    }, [])
    const backStepHandler = () => {
        dispatch(setWizardTitle(''))
        dispatch(changeData({isBackStep: true}))
        dispatch(previousStep())
        dispatch(changeProgress(currentStep - 1))
    }

    const history = useHistory()
    const onCancel = () => {
        dispatch(closeWizard())
        history.replace(history.location.pathname)
    }

    const changeHandler = (source: any, value: boolean) => {
        dispatch(changeData({recentlyDeletedTask: value}))
    }
    return (
        <Spin spinning={wizardData.deletedTaskLoading}>
            <StyledContent>
                <StyledParagraph>{t('forms:backupAuth:text:DynamicsRecentlyDeleted')}</StyledParagraph>
                <RecentlyDeletedTaskSource
                    source={SOURCE.DYNAMICS365}
                    isForReinstate={wizardData.recentlyDeletedTask}
                    onChange={changeHandler}
                    sourceTitle={wizardData.environment?.friendlyName}
                ></RecentlyDeletedTaskSource>
            </StyledContent>

            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button
                        type="primary"
                        disabled={wizardData.recentlyDeletedTask === undefined}
                        onClick={nextStepHandler}
                    >
                        {t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
                <BackLink onClick={backStepHandler} />
            </ModalFooter>
        </Spin>
    )
}
