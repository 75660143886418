import React, {Component, Fragment} from 'react'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import {withTranslation} from 'react-i18next'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {get} from 'lodash'
import queryString from 'query-string'
import {withRouter} from 'react-router'

import {Button, ButtonIconWrapper} from 'ca-common/ui-lib/atoms/Button'
import {LinkWrapper, ModalFooter, BackLink} from 'ca-common/components/Modal'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import Activate from 'ca-common/icons/backupAction/ActivateOneColor.svg'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'

import {
    getParsedEmails,
    bulkCSVActivate,
    appendParsedEmails,
    clearUploadedCSVEmails,
    clearUploadedCSVToken
} from 'src/newcore/redux/modules/backups/uploadCSV'

import {StyledCheckboxGroup, InfiniteContainer, ModalContentWrapper} from './StyledEmailList'
import {clearWizard, previousStep, setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {ButtonIcon} from 'src/newcore/components/BulkActivation/StyledBulkActivation'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'
import {pullBackupEntities} from 'src/newcore/redux/modules/backups/backupEntities/entities'
import {trackBulkActivation, TypesOfRules} from 'src/newcore/components/BulkActivation/trackBulkActivation'
import {EVENT_NAMES} from 'src/newcore/utils/mixpanelEvents'

export const ROWS_UPLOADED_CSV_EMAILS = 50

@withTranslation()
class EmailListRaw extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checkedList: [],
            unchekedList: []
        }
    }

    componentDidMount() {
        const {actions, uploadedCSVToken, t} = this.props

        actions
            .getParsedEmails({
                cacheToken: uploadedCSVToken.data.token
            })
            .then(response => {
                const {data, total} = response.payload

                this.setState({
                    checkedList: data
                })

                actions.setWizardDescription(t('backups:bulkActivation:accountsToActivate', {count: total}))
            })
    }

    componentDidUpdate(prevProps) {
        const {uploadedCSVEmails, uploadedCSVToken, actions, t} = this.props

        if (
            uploadedCSVToken.status === 'fulfilled' &&
            uploadedCSVToken.data.token !== get(prevProps.uploadedCSVToken.data, 'token', undefined) &&
            uploadedCSVEmails.status !== 'fulfilled'
        ) {
            actions
                .getParsedEmails({
                    cacheToken: uploadedCSVToken.data.token
                })
                .then(response => {
                    const {data, total} = response.payload

                    this.setState({
                        checkedList: data
                    })
                    actions.setWizardDescription(t('backups:bulkActivation:accountsToActivate', {count: total}))
                })
        }
        if (
            prevProps.uploadedCSVEmails.page !== uploadedCSVEmails.page &&
            uploadedCSVEmails.page !== 1 &&
            uploadedCSVEmails.status === 'fulfilled'
        ) {
            this.setState(state => {
                return {
                    checkedList: this.filterCheckedList(state, uploadedCSVEmails.data)
                }
            })
        }
    }

    render() {
        const {uploadedCSVEmails, t} = this.props
        const {checkedList} = this.state
        const getEmailIsPending = uploadedCSVEmails.status === 'pending'

        return (
            <Fragment>
                <ModalContentWrapper>
                    <Spin spinning={uploadedCSVEmails.status !== 'fulfilled'}>
                        <InfiniteContainer>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={1}
                                useWindow={false}
                                hasMore={uploadedCSVEmails.page < uploadedCSVEmails.totalPages}
                                loadMore={this.handleOnScroll}
                            >
                                <StyledCheckboxGroup
                                    options={uploadedCSVEmails.data}
                                    value={checkedList}
                                    onChange={this.handleCheckboxGroup}
                                />
                            </InfiniteScroll>
                        </InfiniteContainer>
                    </Spin>
                </ModalContentWrapper>
                <ModalFooter>
                    <LinkWrapper>
                        <Button type="link" onClick={this.clearDataAndCloseModal}>
                            {t('forms:common:actions:cancel')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.handleActivate}
                            disabled={getEmailIsPending}
                            loading={getEmailIsPending}
                        >
                            <ButtonIconWrapper>
                                {!getEmailIsPending && <ButtonIcon component={Activate} />}
                                <span>{t('backups:uploadCSV:activateButton')}</span>
                            </ButtonIconWrapper>
                        </Button>
                    </LinkWrapper>
                    <BackLink onClick={this.backToPreviousTab} />
                </ModalFooter>
            </Fragment>
        )
    }

    filterCheckedList = (state, data) => {
        return data.filter(i => !state.unchekedList.includes(i))
    }

    handleCheckboxGroup = checkedList => {
        const {uploadedCSVEmails, actions, t} = this.props

        actions.setWizardDescription(
            t('backups:bulkActivation:accountsToActivate', {
                count: checkedList.length
            })
        )
        this.setState(state => ({
            checkedList,
            unchekedList: uploadedCSVEmails.data.reduce((result, current) => {
                if (checkedList.includes(current)) {
                    const indexInResult = result.indexOf(current)

                    if (indexInResult > -1) {
                        result.splice(indexInResult, 1)
                    }

                    return result
                }

                if (result.includes(current)) {
                    return result
                }

                return [...result, current]
            }, state.unchekedList)
        }))
    }

    handleActivate = () => {
        const {unchekedList} = this.state
        const {actions, uploadedCSVToken, taskSettings, t} = this.props
        trackBulkActivation(EVENT_NAMES.PERFORM_ONE_TIME_ACTIVATION, taskSettings?.source, TypesOfRules.IMPORT_CSV)

        actions
            .bulkCSVActivate({
                cacheToken: uploadedCSVToken.data.token,
                excludeEmails: unchekedList,
                taskId: taskSettings.taskId
            })
            .then(data => {
                const succeed = data.payload?.succeed
                const total = data.payload?.total

                openSuccessNotification(t('backups:activatedSuccessfully', {succeed, total}))
            })
            .catch(() => {
                openErrorNotification(t('backups:MS365Groups:activationFailed'))
            })
            .finally(() => {
                this.clearDataAndCloseModal()

                this.setState({
                    unchekedList: []
                })
            })
    }

    handleOnScroll = () => {
        const {actions, uploadedCSVToken, uploadedCSVEmails} = this.props

        if (uploadedCSVEmails.page < uploadedCSVEmails.totalPages) {
            actions.appendParsedEmails({
                cacheToken: uploadedCSVToken.data.token,
                page: uploadedCSVEmails.page + 1
            })
        }
    }

    clearDataAndCloseModal = () => {
        const {actions, taskSettings, location} = this.props
        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        actions.clearUploadedCSVEmails()
        actions.clearUploadedCSVToken()
        actions.clearWizard()
        actions.pullBackupEntities({
            taskId: taskSettings.taskId,
            rows: PAGE_SIZE,
            page: DEFAULT_PAGE,
            ...getRequestParamsFromUrl(queryParams)
        })
    }

    backToPreviousTab = () => {
        const {actions} = this.props

        actions.clearUploadedCSVEmails()
        actions.previousStep()
    }
}

const mapStateToProps = state => {
    const {token, emails} = state.backups.uploadCSV

    return {
        uploadedCSVEmails: {
            status: emails.status,
            ...emails.response
        },
        uploadedCSVToken: {
            status: token.status,
            ...token.response
        },
        taskSettings: state.backups.settings.response
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getParsedEmails,
            bulkCSVActivate,
            appendParsedEmails,
            clearUploadedCSVEmails,
            clearUploadedCSVToken,
            previousStep,
            clearWizard,
            setWizardDescription,
            pullBackupEntities
        },
        dispatch
    )
})

export const EmailList = withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailListRaw))
