import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {RESTORE_OPTIONS, RESTORE_TYPE} from 'ca-common/common/enum'
import {checkIsRestoreOptionEnabled} from 'ca-common/features/Flags'
import {SubsourcesBooleanState, WizardRestoreOptions} from 'src/newcore/features/RecoveryWizard/lib'

export const useRestoreOptions = (
    selectedSubServices: SubsourcesBooleanState | null
): {
    restoreOptions: WizardRestoreOptions
    setRestoreOptions: Dispatch<SetStateAction<WizardRestoreOptions>>
} => {
    const [restoreOptions, setRestoreOptions] = useState<WizardRestoreOptions>({})

    useEffect(() => {
        const isSelected = selectedSubServices && Object.values(selectedSubServices).filter(Boolean).length

        const getAllowedOptions = () =>
            Object.values(RESTORE_OPTIONS)
                .filter(i => checkIsRestoreOptionEnabled(i))
                .reduce((a, c) => {
                    return {...a, [c]: true}
                }, {})

        if (selectedSubServices !== null) {
            setRestoreOptions(
                isSelected
                    ? {
                          ...getAllowedOptions(),
                          restoreType: RESTORE_TYPE.NON_DESTRUCTIVE
                      }
                    : {}
            )
        }
    }, [selectedSubServices])

    return {restoreOptions, setRestoreOptions}
}
