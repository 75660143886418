import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

import {Button} from 'src/ca-common/ui-lib/atoms'
import {CancelLink, LinkWrapper, ModalFooter} from 'src/ca-common/components/Modal'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {ChooseDynamicsEnvironment} from 'src/newcore/features/DynamicsWizard/organisms/EnvironmentSelection/ChooseDynamicsEnvironment'
import {authenticate} from 'src/newcore/features/DynamicsWizard/lib/api'
import {STEPS} from 'src/newcore/features/DynamicsWizard/lib/DynamicsSteps'
import {
    closeWizard,
    wizardDataSelector,
    changeStep,
    changeProgress,
    setWizardTitle,
    changeConfig
} from 'src/newcore/redux/modules/wizard'

export const SelectionOfEnvironment = () => {
    const {t} = useTranslation()
    const wizardData = useAppSelector(wizardDataSelector)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(
            changeConfig({
                hiddenTabBarItems: [`${STEPS.recentlyDeletedTask}`]
            })
        )
        dispatch(setWizardTitle(''))
    }, [])

    const nextStepHandler = () => {
        if (wizardData.environment?.isGracePeriod) {
            authenticate(wizardData.environment, wizardData.alias)
        } else {
            dispatch(setWizardTitle(''))
            dispatch(changeProgress(STEPS.createApplicationUser))
            dispatch(changeStep(STEPS.createApplicationUser))
        }
    }

    const history = useHistory()
    const onCancel = () => {
        dispatch(closeWizard())
        history.replace(history.location.pathname)
    }

    return (
        <>
            <ChooseDynamicsEnvironment />
            <ModalFooter>
                <LinkWrapper>
                    <CancelLink onClick={onCancel}>{t('modals:buttons:cancel')}</CancelLink>
                    <Button
                        type="primary"
                        disabled={!wizardData.isAllEnvsBackedUp && wizardData.environment === undefined}
                        onClick={nextStepHandler}
                    >
                        {wizardData.isAllEnvsBackedUp
                            ? t('forms:common:actions:reAuthenticateInDynamics')
                            : t('modals:buttons:next')}
                    </Button>
                </LinkWrapper>
            </ModalFooter>
        </>
    )
}
