import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {Form} from 'antd'

import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'
import {BackupAuthModal} from 'src/newcore/components/Modals'
import {AuthTemplateSelector} from './templates'

type BackupAuthType = Omit<BackupAuthTemplate, 'onSubmit' | 'loading'> & {
    onSubmit: (values: Record<string, unknown>) => Promise<any>
    visible: boolean
    dynamicsAuthenticationFailed: any
    alias?: string
}

export const BackupAuth = ({
    visible,
    onCancel,
    onSubmit,
    backupSettings,
    source,
    companyName,
    isCloudally,
    dynamicsAuthenticationFailed
}: BackupAuthType): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [backupAuthForm] = Form.useForm<BackupAuthType>()
    const history = useHistory()

    const handleSubmit = (values: Record<string, unknown>) => {
        setLoading(true)
        onSubmit(values)
            .catch((err: any) => console.log(err))
            .finally(() => setLoading(false))
    }

    const handleCancel = () => {
        onCancel()
        history.replace(history.location.pathname)
        backupAuthForm.resetFields()
    }

    return (
        <BackupAuthModal open={visible} onCancel={handleCancel}>
            <AuthTemplateSelector
                visible={visible}
                isCloudally={isCloudally}
                source={source}
                backupSettings={backupSettings}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                loading={loading}
                companyName={companyName}
                dynamicsAuthenticationFailed={dynamicsAuthenticationFailed}
                form={backupAuthForm}
            />
        </BackupAuthModal>
    )
}
