import React from 'react'
import {Row, Col} from 'antd'
import {useTranslation} from 'react-i18next'

import {Button} from 'ca-common/ui-lib/atoms'

import {Header, Footer} from 'src/newcore/features/BackupAuth/organisms'
import {BackupName} from 'src/newcore/molecules'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {StyledBackupAuthForm, BackupAuthBody} from '../StyledTemplates'

export const GoogleAccount = ({source, backupSettings, onSubmit, onCancel, form}: BackupAuthTemplate) => {
    const {t} = useTranslation()

    return (
        <StyledBackupAuthForm
            initialValues={{alias: backupSettings.alias}}
            form={form}
            layout="horizontal"
            onFinish={values => onSubmit(values)}
        >
            <Header source={source} />
            <BackupAuthBody>
                <Row gutter={16}>
                    <Col>
                        <BackupName />
                    </Col>

                    <Col>
                        <p>{t('forms:backupAuth:text:GoogleAccountInfo')}</p>
                    </Col>
                </Row>
            </BackupAuthBody>
            <Footer onCancel={onCancel}>
                <Button type="primary" htmlType="submit">
                    {t('forms:backupAuth:text:GoogleAccountSubmittingText')}
                </Button>
            </Footer>
        </StyledBackupAuthForm>
    )
}
