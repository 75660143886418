import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CloseOutlined} from '@ant-design/icons'

import {
    StyledAlert,
    StyledLink,
    StyledTitle,
    StyledDate,
    StyledMessage,
    StyledReminderIcon,
    StyledWarningIcon,
    StyledLinkContainer,
    StyledRightContainer,
    StyledAlertButton,
    StyledTooltipIcon,
    StyledTooltip,
    StyledAfterLinkMessage,
    StyledInfoIcon
} from './StyledAlert'

export type AlertType = 'ERROR' | 'REMINDER' | 'WARNING' | 'INFO'
export type AlertProps = {
    title: string
    message: string
    afterLinkMessage?: string
    type?: AlertType
    link?: {
        name: string
        url: string
    }
    updated?: string
    tooltipMessage?: string
    onClose?: () => void
    className?: string
    isPaymentError?: boolean
}

export const Alert = ({
    title,
    message,
    afterLinkMessage,
    link,
    updated,
    type = 'ERROR',
    tooltipMessage,
    onClose,
    className,
    isPaymentError
}: AlertProps) => {
    const [visible, setVisible] = useState(true)
    const {t} = useTranslation()
    const handleOnClose = () => {
        setVisible(false)
        if (onClose) onClose()
    }
    return visible ? (
        <StyledAlert type={type} className={className} isPaymentError={Boolean(isPaymentError)}>
            {type === 'ERROR' || type === 'WARNING' ? (
                <StyledWarningIcon />
            ) : type === 'INFO' ? (
                <StyledInfoIcon />
            ) : (
                <StyledReminderIcon />
            )}
            <StyledTitle type={type} isPaymentError={Boolean(isPaymentError)}>
                {title}:
            </StyledTitle>
            <StyledMessage>{message}</StyledMessage>
            {link && (
                <StyledLinkContainer isPaymentError={Boolean(isPaymentError)}>
                    <StyledLink to={link.url}>{link.name}</StyledLink>
                </StyledLinkContainer>
            )}
            <StyledAfterLinkMessage>{afterLinkMessage}</StyledAfterLinkMessage>
            <StyledRightContainer>
                {updated && (
                    <>
                        {tooltipMessage && (
                            <StyledTooltip
                                title={tooltipMessage}
                                getPopupContainer={triggerNode => triggerNode.parentElement!}
                            >
                                <StyledTooltipIcon />
                            </StyledTooltip>
                        )}
                        <StyledDate>
                            {t('common:uiLib:alert:date')}
                            {updated}
                        </StyledDate>
                    </>
                )}
                {type === 'REMINDER' && (
                    <StyledAlertButton type="text" size="small" icon={<CloseOutlined />} onClick={handleOnClose} />
                )}
            </StyledRightContainer>
        </StyledAlert>
    ) : null
}
