import React, {useMemo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {openInfoModal} from 'ca-common/utils/modals'
import {ACCESS_LEVEL, INTEGRATION_TYPE, PAGES} from 'ca-common/constants'
import {JumbotronLink} from 'ca-common/ui-lib/molecules/JumbotronLink'
import {updateQueryParams} from 'ca-common/redux/modules/navigation'
import {MainMenu} from 'ca-common/ui-lib/components/MainMenu'

import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

import {getPages} from './Config'

export const SettingsPage = () => {
    const {t} = useTranslation()
    const userInfo = useAppSelector(userInfoSelector)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (userInfo?.permissions.accessLevel === ACCESS_LEVEL.NONE && !userInfo?.enforceTwoStepAuthOnAllUsers) {
            openInfoModal(t(`settings:users:noPermissions`))
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateQueryParams())
    }, [])

    const renderSettings = useMemo(() => {
        const renderPages = getPages().filter(value => {
            return value.allow
        })
        const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX

        const getSettingPageTitle = (name: string) => {
            if (name === PAGES.USERS && isZix) {
                return t(`settings:menu:${name}:zixName`)
            }
            return t(`settings:menu:${name}:name`)
        }

        return renderPages.map((section, index) => (
            <JumbotronLink
                key={index}
                title={getSettingPageTitle(section.name)}
                icon={section.icon}
                link={`${PAGES.SETTINGS}/${section.name}`}
                description={t(`settings:menu:${section.name}:caption`)}
                tile
                isMultiLine={section.isMultiLine}
            />
        ))
    }, [])

    return <MainMenu>{renderSettings}</MainMenu>
}
