import _ from 'lodash'

import {ACCESS_LEVEL, INTEGRATION_TYPE, PAGES, PARTNER_PAGES, USER_STATUS} from 'ca-common/constants'
import {BILLING_VIEW} from 'ca-common/common/enum'
import {isNotAnyExternalOrOkta} from 'ca-common/utils/billing'
import {hasBillingPage, isPartnerReseller} from './partner'
import {getEnabledServices} from './usersManagement'

export function validatePageAccess({page, userInfo, companyInfo = {}, isForSubscribeLink}) {
    const {
        permissions,
        status,
        integrationType,
        billingView,
        isExternalManagment,
        isSpecialPartnerAccount,
        isMsEndUser
    } = userInfo

    switch (page) {
        case PAGES.BACKUPS:
            return checkPermission('PAGE.BACKUPS.ACCESS', permissions)
        case PAGES.BACKUPS_NEW:
            return checkPermission('PAGE.BACKUPS.ACTIVATE', permissions)
        case PAGES.DASHBOARD: {
            const dashboardPath = permissions.isPartnerPortal
                ? 'PAGE.DASHBOARD.ACCESS'
                : 'PAGE.RESTORE_AND_DOWNLOAD.ACCESS'
            return checkPermission(dashboardPath, permissions)
        }
        case PAGES.JOBS: {
            const enabledServices = getEnabledServices(permissions.groups.BACKUP)
            return checkPermission('PAGE.ACCOUNT_ACTIVITY.ACCESS', permissions) && enabledServices.length > 0
        }
        case PAGES.SETTINGS: {
            return !isMsEndUser
        }
        case PAGES.RECOVERY:
            return checkPermission('PAGE.RESTORE_AND_DOWNLOAD.ACCESS', permissions)
        case PAGES.RESTORE:
        case PAGES.RESTORE_ITEMS:
        case PAGES.RESTORE_SEARCH:
        case PAGES.RESTORE_SEARCH_RESULTS:
        case PAGES.RESTORE_SEARCH_RESULTS_V2:
        case PAGES.RESTORE_SINGLE_SERVICE_SNAPSHOT_RESULTS:
        case PAGES.RESTORE_SNAPSHOT_RESULTS:
            return checkPermission('PAGE.RESTORE_AND_DOWNLOAD.ACCESS', permissions) && !isMsEndUser
        case PAGES.COMPARE:
            return checkPermission('PAGE.RESTORE_AND_DOWNLOAD.COMPARE', permissions)
        case PAGES.REPLICATE:
            return checkPermission('PAGE.RESTORE_AND_DOWNLOAD.REPLICATE', permissions)
        case PAGES.SMART_ALERTS:
            return checkPermission('PAGE.RESTORE_AND_DOWNLOAD.NOTIFY', permissions)
        case PAGES.ACCOUNT_ACTIVITY:
            return (
                (validatePageAccess({page: PAGES.ACCOUNT_SECURITY_AUDIT, userInfo, companyInfo, isForSubscribeLink}) ||
                    validatePageAccess({page: PAGES.SYSTEM_ACTIVITY, userInfo, companyInfo, isForSubscribeLink})) &&
                !isMsEndUser
            )
        case PAGES.ACCOUNT_SECURITY_AUDIT:
            return checkPermission('PAGE.ACCOUNT_SECURITY_AUDIT.ACCESS', permissions)
        case PAGES.SYSTEM_ACTIVITY:
            return checkPermission('PAGE.ACCOUNT_ACTIVITY.ACCESS', permissions)
        case PAGES.ANNUAL_SUBSCRIPTION:
        case PAGES.PAYMENTS_DETAILS:
            if (
                !permissions?.isPartnerPortal &&
                billingView === BILLING_VIEW.SHOW_TOTAL_WITHOUT_DISCOUNT &&
                userInfo.partnerID
            ) {
                return false
            }
            return validatePageAccess({page: PAGES.BILLING, userInfo, companyInfo, isForSubscribeLink})
        case PAGES.STATUS:
            return (
                validatePageAccess({page: PAGES.BILLING, userInfo, companyInfo, isForSubscribeLink}) &&
                status !== USER_STATUS.TRIAL
            )
        case PAGES.BILLING:
        case PAGES.PAYMENTS: {
            const paymentPath = permissions?.isPartnerPortal ? 'PAGE.PP_PAYMENTS.ACCESS' : 'PAGE.PAYMENTS.ACCESS'
            let isWLPermitted = true

            if (permissions?.isPartnerPortal) {
                isWLPermitted = isForSubscribeLink ? false : hasBillingPage(userInfo)
            } else {
                if (page === PAGES.BILLING && !isNotAnyExternalOrOkta(companyInfo, userInfo)) {
                    return false
                }
                if (isExternalManagment) {
                    isWLPermitted = false
                } else {
                    if (isForSubscribeLink) {
                        isWLPermitted = status === USER_STATUS.TRIAL || status === USER_STATUS.UNSUBSCRIBED
                    } else {
                        isWLPermitted = billingView !== BILLING_VIEW.ZERRO_TOTAL
                    }
                }
            }

            return isWLPermitted && checkPermission(paymentPath, permissions)
        }
        case PAGES.HISTORY:
            if (validatePageAccess({page: PAGES.BILLING, userInfo, companyInfo, isForSubscribeLink})) {
                return !billingView
            }
            return false
        case PAGES.CHANGE_PASSWORD:
        case PAGES.ACCOUNT: {
            const accountPath = permissions.isPartnerPortal ? 'PAGE.PP_ACCOUNT.ACCESS' : 'PAGE.ACCOUNT.ACCESS'

            return integrationType !== INTEGRATION_TYPE.ZIX && checkPermission(accountPath, permissions)
        }
        case PAGES.USERS:
            if (integrationType !== INTEGRATION_TYPE.ZIX && companyInfo.externalUserManagment) {
                return false
            }
            return checkPermission('PAGE.USERS.ACCESS', permissions)
        case PAGES.SECURITY:
            if (permissions.isPartnerPortal) {
                return true
            }
            return (
                !companyInfo.externalUserManagment &&
                !isSpecialPartnerAccount &&
                integrationType !== INTEGRATION_TYPE.ZIX
            )
        case PAGES.SUPPORT:
        case PAGES.SUPPORT_TICKET:
        case PAGES.TUTORIALS:
        case PAGES.SUPPORT_MAIL:
        case PAGES.SUPPORT_PHONE:
            if (permissions.isPartnerPortal) {
                return (
                    !(isPartnerReseller(userInfo) && companyInfo.wl) &&
                    checkPermission('PAGE.SUPPORT.ACCESS', permissions)
                )
            }

            if (integrationType === INTEGRATION_TYPE.ZIX) {
                return checkPermission('PAGE.SUPPORT.ACCESS', permissions)
            }

            if (companyInfo.whiteLable && !isExternalManagment && companyInfo?.supportLink) {
                return checkPermission('PAGE.SUPPORT.ACCESS', permissions)
            }

            if (!companyInfo.whiteLable && !isExternalManagment) {
                return checkPermission('PAGE.SUPPORT.ACCESS', permissions)
            }
            return false
        case PAGES.NOTIFICATIONS: {
            const notificationsPath = permissions.isPartnerPortal
                ? 'PAGE.PP_MANAGE_NOTIFICATIONS.ACCESS'
                : 'PAGE.MANAGE_NOTIFICATIONS.ACCESS'

            return checkPermission(notificationsPath, permissions)
        }
        case PARTNER_PAGES.NAMES.PARTNER_ZONE:
        case PARTNER_PAGES.NAMES.LEARNING_HUB:
            return !isPartnerReseller(userInfo) || !companyInfo.wl
        case PAGES.ACTIVITY_SECURITY: {
            const securityAuditPath = permissions.isPartnerPortal
                ? 'PAGE.PP_SECURITY_AUDIT.ACCESS'
                : 'PAGE.SECURITY_AUDIT.ACCESS'

            return checkPermission(securityAuditPath, permissions)
        }
        case PAGES.OBJECTS:
            return true
        case PAGES.INTEGRATION:
            return userInfo.permissions.accessLevel === ACCESS_LEVEL.FULL
        default:
            return false
    }
}

export function checkPermission(path, permissions) {
    try {
        if (permissions.accessLevel === ACCESS_LEVEL.FULL) {
            return true
        }
        if (permissions.accessLevel === ACCESS_LEVEL.NONE) {
            return false
        }
        const [GROUP, SERVICE, PERMISSION] = _.split(path, '.', 3)

        return permissions.groups[GROUP][SERVICE].permissions[PERMISSION].enabled
    } catch (e) {
        return false
    }
}

export function isPagePermitted(action, permissions) {
    return checkPermission(`PAGE.${action}`, permissions)
}
