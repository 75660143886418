import styled from 'styled-components'
import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'

export const StyledSection = styled.div<{isSplunk: boolean}>`
    margin-right: 4%;
    margin-top: ${props => (props.isSplunk ? '0px' : '2%')};
    height: ${props => (props.isSplunk ? 'auto' : '43px')};
`

export const StyledText = styled.span`
    font-family: ${variables.accentFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 700;
    line-height: 27px;
    cursor: pointer;
`

export const StyledButton = styled(Button)`
    padding: 4.5px 15.65px;
`
