import styled from 'styled-components'
import variables from 'ca-common/variables'

export const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${variables.gutterMd};
    min-height: 88px;
`

export const StyledStepsWrapper = styled.div`
    display: flex;
`

export const StyledTitle = styled.div`
    font-family: ${variables.publicPageFont};
    display: flex;
    align-items: center;
    font-size: 18px;
    color: ${variables.text};
    line-height: 18px;

    h2 {
        margin: 0;
        margin-right: ${variables.gutterXs};
        font-size: inherit;
        font-weight: normal;
    }

    span {
        color: ${variables.navyBlue};
    }
`

export const StyledStep = styled.div<{active?: boolean; unavailable?: boolean}>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 10px;
    border: 1px solid
        ${props => (props.active && variables.navyBlue) || (props.unavailable && variables.blue) || variables.navyBlue};
    background-color: ${props =>
        (props.active && variables.white) || (props.unavailable && variables.blue) || variables.navyBlue};
    cursor: ${props => (props.unavailable ? 'not-allowed' : 'pointer')};
`
