import styled from 'styled-components'
import {Modal, Button} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

export const StyledDeleteModal = styled(Modal)``

export const StyledTitle = styled.div`
    font-family: ${variables.accentFont};
    color: ${variables.text};
    font-size: ${variables.headingFontSize};
    font-weight: 400;
    display: inline-flex;
    width: 100%;

    svg {
        fill: none;
    }
`

export const StyledDescription = styled.div`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.text};
`

export const StyledFooter = styled.div``

export const StyledButton = styled(Button)`
    border: none !important;

    .ant-btn:hover {
        border: none !important;
    }
`
export const StyledIcon = styled(Icon)`
    font-size: 30px;
`

export const StyledText = styled.div`
    margin-top: 0.5%;
`

export const StyledImage = styled.div`
    margin-right: 2%;
`
