import React from 'react'
import {Trans} from 'react-i18next'
import Icon from '@ant-design/icons'

import {SplunkSetupHelpLink} from 'ca-common/features/Integrations/config'
import FAQ from 'ca-common/icons/FAQ.svg'

import {StyledLink} from './StyledSplunkSetup'

export const SplunkSetup = () => {
    return (
        <StyledLink href={SplunkSetupHelpLink} target="_blank">
            <Icon component={FAQ} />
            <Trans i18nKey="integrations:splunkModal:splunkSetup" />
        </StyledLink>
    )
}
