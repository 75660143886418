import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledContent = styled.div`
    font-family: ${variables.publicPageFont};
    padding: ${variables.gutterMd};
    min-height: 354px;
    border-top: 2px solid ${variables.lightGray};
`
export const StyledParagraph = styled.p`
    margin-bottom: ${variables.gutterLg};
    width: 500px;
    font-weight: 300;
`
