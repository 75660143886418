export enum EVENT_NAMES {
    VIEW_RECOVERY_PAGE = 'View Recovery Page',
    VIEW_BILLING_HISTORY = 'View Billing History',
    BILLING_HISTORY_DOWNLOAD_DOCUMENT = 'Billing History Download Document',
    RECOVERY_CHANGE_DATE = 'Recovery Change Backup Date',
    RECOVERY_SUB_SERVICE_CARD_SELECTED = 'Recovery Sub Service Card Selected',
    RECOVERY_LINK_TO_OLD_SEARCH_PRESSED = 'Recovery Link To Old Search Pressed',
    RECOVERY_SUBSERVICE_CARD_MENU_OPTION_SELECTED = 'Recovery Sub Service Card Menu Option Selected',
    RECOVERY_PAGE_ITEM_EXPANDED = 'Recovery Page Item Expanded',
    SELECTIVE_RECOVERY_MESSAGE_LINK_PRESSED = 'Selective Recovery Message Link Pressed',
    FULL_RECOVERY_MESSAGE_LINK_PRESSED = 'Full Recovery Message Link Pressed',
    ENTITY_BACKUP_ACTIVATION = 'Activating Entity Backups',
    EXCHANGE_SELECTIVE_RESTORE = 'Exchange Selective Restore',
    EXCHANGE_SELECTIVE_EXPORT = 'Exchange Selective Export',
    EXPORT_USAGE_REPORT = 'Export Usage Report',
    SALESFORCE_COMPARE_WIZARD_OPEN = 'Salesforce Compare Wizard Opened',
    SALESFORCE_COMPARE = 'Salesforce Compare',
    SALESFORCE_COMPARE_VIEW_RESULTS = 'Salesforce Compare View Results',
    SALESFORCE_REPLICATION_WIZARD_LAUNCH = 'Salesforce Replication Wizard Launch',
    SALESFORCE_DATA_REPLICATION = 'Salesforce Data Replication',
    SALESFORCE_REPLICATION_OF_SELECTED_RECORDS = 'Salesforce Replication Of Selected Records',
    SALESFORCE_SANDBOX_ANONYMIZATION = 'Salesforce Sandbox Anonymization',
    SALESFORCE_ANONYMIZATION_TEMPLATE = 'Salesforce Anonymization Template',
    RUN_BULK_ACTIVATION = 'Run bulk activation',
    PERFORM_ONE_TIME_ACTIVATION = 'Perform one-time activation',
    CREATE_AN_ACTIVATION_RULE = 'Create an activation rule',
    CANCEL_BULK_ACTIVATION_LAST_STEP = 'Cancel bulk activation last step',
    CONFIRM_AN_ACTIVATION_RULE_CREATION = 'Confirm an activation rule creation',
    REFUSAL_TO_CONFIRM_ACTIVATION_RULES_CREATION = 'Don’t confirm an activation rule creation',
    DEACTIVATION_RULE = 'Deactivation rule',
    GOOGLE_WORKSPACE_NO_ACCESS_ORG_UNIT = 'Google Workspace Bulk Activation No Access To Org Unit',
    BACKUP_FREQUENCY_CHANGED = 'Backup Frequency Changed',
    //Usage of tags
    CREATE_NEW_TAG = 'Create new tag',
    TAG_ASSIGNED = 'Tag assigned',
    TAG_UNASSIGNED = 'Tag unassigned',
    //One click activation
    OCA_WIZARD_OPENED = 'MS365 Quick Activation Wizard Opened',
    OCA_ACTIVATION = 'MS365 Quick Activation ',
    OCA_CANCEL_LOADING_PAGE = 'MS365 Quick Activation Cancel Loading Page',
    DOWNLOAD_UNPAID_INVOICE = 'Download Unpaid Invoice',
    RESEND_UNPAID_INVOICE = 'Resend Unpaid Invoice',
    PAY_INVOICE_BUTTON = 'Pay Invoice Button Pressed'
}
