import React, {useMemo} from 'react'

import type {IconComponent} from 'ca-common/types/antdTypes'

import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'

import {StyledIcon, StyledLink, Footer, Content, Title} from './StyledJumbotronLink'

type LinkProps = {
    to: string
    target: string
    pureLink: boolean
}

type JumbotronLinkProps = {
    title?: string
    icon?: IconComponent | JSX.Element | SVGAElement
    description?: JSX.Element[] | string
    tile?: boolean
    link?: string
    isMultiLine?: boolean
} & Partial<Omit<LinkProps, 'to'>>

export const JumbotronLink = (props: JumbotronLinkProps) => {
    const {title, icon, description, tile, link: to, target, pureLink, isMultiLine} = props

    const content = useMemo(() => {
        if (typeof icon === 'function') {
            return <StyledIcon component={icon} isMultiLine={isMultiLine} />
        }

        if (typeof icon === 'object') {
            return icon
        }

        return null
    }, [icon])

    const {Wrapper, wrapperProps} = useMemo(() => {
        return {
            Wrapper: to ? StyledLink : React.Fragment,
            wrapperProps: to
                ? {
                      to,
                      target,
                      pureLink
                  }
                : ({} as LinkProps)
        }
    }, [to, target, pureLink])

    return (
        <Wrapper {...wrapperProps}>
            <Jumbotron centered tile={tile}>
                {title && <Title>{title}</Title>}
                <Content isMultiLine={isMultiLine}>{content}</Content>
                <Footer>
                    {typeof description === 'string' ? <p>{description}</p> : <div>{description?.map(i => i)}</div>}
                </Footer>
            </Jumbotron>
        </Wrapper>
    )
}
