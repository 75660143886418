import React, {useEffect, useState} from 'react'
import Icon, {InfoCircleOutlined} from '@ant-design/icons'
import {Divider, Drawer, Tooltip} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {useTranslation, Trans} from 'react-i18next'
import _ from 'lodash'

import Compare from 'ca-common/icons/Compare.svg'
import Replicate from 'ca-common/icons/Replicate.svg'
import SmartAlert from 'ca-common/icons/smartAlerts/SmartAlert.svg'
import {WIZARD_TYPE, PAGES} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {validatePageAccess} from 'ca-common/utils/permissions'

import {CompareSnapshots} from 'src/newcore/components/CompareSnapshots'
import {openWizard, setWizardTitle, setWizardDescription, openWizardWithConfig} from 'src/newcore/redux/modules/wizard'
import {StyledActionButton, StyledActionsWrapper, StyledButtonWrapper, StyledHeadings} from './StyledActions'
import {WizardModal} from 'src/newcore/components/Wizard'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'
import {getSFCAlertsObjects} from '../../../SmartAlertsWizard/redux'

const SmartAlertsWizardConfig = {
    destroyInactiveTabPane: false,
    autoDismissProgress: true
}

const SFCAdditionalActionsRaw = ({taskSettings, userInfo, actions}) => {
    const {t} = useTranslation()

    const [visible, setVisible] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    const [noBackups, setNoBackups] = useState(true)

    useEffect(() => {
        setNoBackups(!taskSettings.lastBackup)
    }, [taskSettings])

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer)
    }

    const showCompareSnapshots = () => {
        setVisible(true)
        track(EVENT_NAMES.SALESFORCE_COMPARE_WIZARD_OPEN, {})
    }

    const handleCancel = () => {
        setVisible(false)
    }
    const openSeedingWizard = () => {
        actions.openWizard(WIZARD_TYPE.SEEDING)
        track(EVENT_NAMES.SALESFORCE_REPLICATION_WIZARD_LAUNCH, {})
    }

    const openSmartAlertWizard = () => {
        track('Smart Alerts Wizard Opened', {
            Service: 'Salesforce',
            'Initiated From': 'Backup Task Header Button'
        })
        actions.openWizardWithConfig({type: WIZARD_TYPE.SMART_ALERT, config: SmartAlertsWizardConfig})
    }

    return (
        <StyledActionsWrapper>
            <Tooltip title={noBackups && t('forms:backupSettings:disabledButtons:compare:tooltip')}>
                <StyledButtonWrapper disabled={noBackups}>
                    <StyledActionButton
                        type="primary"
                        onClick={showCompareSnapshots}
                        disabled={!validatePageAccess({page: PAGES.COMPARE, userInfo}) || noBackups}
                    >
                        <Icon component={Compare} />
                        {t('compareSnapshot:title')}
                    </StyledActionButton>
                </StyledButtonWrapper>
            </Tooltip>
            <Tooltip title={noBackups && t('forms:backupSettings:disabledButtons:replicate:tooltip')}>
                <StyledButtonWrapper disabled={noBackups}>
                    <StyledActionButton
                        type="primary"
                        onClick={openSeedingWizard}
                        disabled={!validatePageAccess({page: PAGES.REPLICATE, userInfo}) || noBackups}
                    >
                        <Icon component={Replicate} />
                        {t('seeding:title')}
                    </StyledActionButton>
                </StyledButtonWrapper>
            </Tooltip>
            <Tooltip title={noBackups && t('forms:backupSettings:disabledButtons:smartAlerts:tooltip')}>
                <StyledButtonWrapper disabled={noBackups}>
                    <StyledActionButton
                        type="primary"
                        onClick={openSmartAlertWizard}
                        disabled={!validatePageAccess({page: PAGES.SMART_ALERTS, userInfo}) || noBackups}
                    >
                        <Icon component={SmartAlert} />
                        {t('smartAlerts:title')}
                    </StyledActionButton>
                </StyledButtonWrapper>
            </Tooltip>
            <Button onClick={toggleDrawer}>
                <InfoCircleOutlined />
                {t('backups:howItWorks')}
            </Button>
            <CompareSnapshots visible={visible} handleCancel={handleCancel} />
            <WizardModal />
            <Drawer
                title={t('backups:howItWorks')}
                placement="right"
                closable={false}
                onClose={toggleDrawer}
                visible={showDrawer}
                width={300}
            >
                <StyledHeadings>
                    <Icon component={Compare} /> {t('compareSnapshot:title')}
                </StyledHeadings>
                <p>{t('compareSnapshot:description')}</p>
                <Divider />
                <StyledHeadings>
                    <Icon component={Replicate} /> {t('seeding:title')}
                </StyledHeadings>
                <p>{t('seeding:description')}</p>
                <Divider />
                <StyledHeadings>
                    <Icon component={SmartAlert} /> {t('smartAlerts:title')}
                </StyledHeadings>
                <Trans i18nKey="smartAlerts:description" />
            </Drawer>
        </StyledActionsWrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getSFCAlertsObjects,
            openWizard,
            openWizardWithConfig,
            setWizardTitle,
            setWizardDescription
        },
        dispatch
    )
})

const mapStateToProps = state => ({
    userInfo: state.userInfo.response,
    taskSettings: _.get(state.backups.settings, 'response', {})
})

export const SFCAdditionalActions = withRouter(connect(mapStateToProps, mapDispatchToProps)(SFCAdditionalActionsRaw))
