import {rtkQueryBaseApi} from 'ca-common/utils/rtkQueryBaseApi'

import {get, post} from 'src/newcore/utils/rest'

const splunkCheckBoxValues = ['SYSTEM_ACTIVITY', 'SECURITY_AUDIT', 'PP_AUDIT']

type IntegrationResponse = {
    endpoint: string
    id: string
    scope: string[]
    tag: string
    token: string
    type: string
}[]

const getDataValue = (data: Record<string, any>) => {
    const finalValue = {...data}
    splunkCheckBoxValues.forEach(e => {
        delete finalValue[e]
    }) // remove entry for checkbox array
    return finalValue
}

export const getIntegrationStatusApi = rtkQueryBaseApi.injectEndpoints({
    endpoints: build => ({
        getIntegrationStatus: build.query<IntegrationResponse, string>({
            query: id => ({
                url: 'getIntegrations',
                method: post
            }),
            transformResponse: (response: IntegrationResponse) => {
                const checkBoxValue: Record<string, any> = {}
                const updatedResponse: IntegrationResponse = []
                response.forEach((res, key) => {
                    const {id, endpoint, scope, tag, token, type} = res
                    scope?.forEach(item => {
                        checkBoxValue[item] = scope.includes(item)
                    })
                    const updatedValue = {...res, ...checkBoxValue}
                    updatedResponse.push(updatedValue)
                })
                return updatedResponse
            }
        }),
        addIntegrationType: build.mutation<any, any>({
            query: data => ({
                url: 'createHECIntegration',
                method: post,
                data: getDataValue(data)
            }),
            transformResponse: (res: any) => {
                return res
            }
        }),
        deleteIntegrationType: build.mutation<any, any>({
            query: data => ({
                url: `deleteIntegration`,
                method: post,
                data: getDataValue(data)
            }),
            transformResponse: (res: any) => {
                return res
            }
        }),
        editIntegrationType: build.mutation<any, any>({
            query: data => ({
                url: `updateHECIntegration`,
                method: post,
                data: getDataValue(data)
            }),
            transformResponse: (res: any) => {
                return res
            }
        }),
        fetchApiKeyDetails: build.query({
            query: () => ({
                url: 'getApiToken',
                method: post
            }),
            transformResponse: (res: any) => {
                return res
            }
        }),
        generateApiKey: build.mutation({
            query: () => ({
                url: 'generateApiToken',
                method: post
            }),
            transformResponse: (res: any) => {
                return res
            }
        }),
        deleteApiKey: build.mutation({
            query: () => ({
                url: 'deleteApiToken',
                method: post
            }),
            transformResponse: (res: any) => {
                return res
            }
        })
    })
})

export const {
    useGetIntegrationStatusQuery,
    useAddIntegrationTypeMutation,
    useDeleteIntegrationTypeMutation,
    useEditIntegrationTypeMutation,
    useGenerateApiKeyMutation,
    useFetchApiKeyDetailsQuery,
    useDeleteApiKeyMutation
} = getIntegrationStatusApi
