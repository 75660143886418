import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledIntegrationCard = styled.div`
    padding: ${variables.gutterSm} ${variables.headingFontSize} ${variables.gutterSm} ${variables.headingFontSize};
    align-items: center;
    justify-content: center;
    background-color: ${variables.white};
    box-shadow: 0px 0px ${variables.gutterMd} 0px ${variables.boxShadow};
`
export const StyledApiKeyDetails = styled.div<{apiKeyExists: boolean}>`
    margin-top: ${variables.fontSizeSecondary};
    box-shadow: 0px 0px ${variables.gutterMd} 0px ${variables.boxShadow};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.gutterSm};
    display: inline-block;
    background-color: ${props => (props.apiKeyExists ? `${variables.white}` : `${variables.offGrey}`)};
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => (props.apiKeyExists ? '81px' : '')};
    margin-bottom: ${variables.gutterXs};
    .ant-table-title,
    .ant-table-thead > tr > th {
        border-bottom: none;
        padding: ${variables.btnBorderRadiusBase} ${variables.fontSizeSecondary};
        color: ${props => (props.apiKeyExists ? `${variables.DarkGrey}` : `${variables.asphaltLight}`)};
        font-family: ${variables.mainFont};
        background: ${props => (props.apiKeyExists ? `${variables.white}` : `${variables.offGrey}`)};
        font-weight: 600;
    }
    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td {
        font-weight: 600;
        background: ${variables.offGrey};
        padding: ${variables.gutterSm};
    }
    .ant-table-title,
    .ant-table-tbody > tr > td {
        border-bottom: none;
        padding: ${variables.btnBorderRadiusBase} ${variables.fontSizeSecondary};
        color: ${props => (props.apiKeyExists ? `${variables.navyBlue}` : `${variables.DarkGrey}`)};

        font-family: ${variables.mainFont};
        font-size: ${variables.fontSizeBase};
        font-weight: 400;
    }

    .ant-table-pagination.ant-pagination {
        display: none;
    }
    .ant-table-wrapper {
        clear: both;
        max-width: 90%;
        margin-left: 8%;

        @media screen and (max-width: ${variables.screenFullHD}) and (min-width: ${variables.screenLg}) {
            margin-top: ${props => (props.apiKeyExists ? '-6%' : '0%')};
        }
        @media screen and (min-width: ${variables.screenFullHD}) {
            margin-top: ${props => (props.apiKeyExists ? '-5%' : '0%')};
        }
        opacity: ${props => (props.apiKeyExists ? 'inherit' : '75%')};
    }
`

export const StyledLogo = styled.div`
    display: inline-block;
    padding-bottom: 2%;
`
export const StyledDeleteButton = styled.div<{isDeleteImgLoaded: boolean}>`
    display: flex;
    flex-direction: row-reverse;
    margin-top: ${props => (props.isDeleteImgLoaded ? `-${variables.gutterLg}` : '-60px')};
    img {
        cursor: pointer;
    }
`

export const StyledTitle = styled.span`
    font-weight: 500;
    font-size: ${variables.headingFontSize};
    font-family: ${variables.accentFont};
    color: ${variables.text};
`
export const StyledKeyButton = styled.span<{apiKeyExists: boolean}>`
    display: flex;
    flex-direction: row-reverse;
    margin-top: -${variables.gutterMd};
    .ant-btn-primary {
        font-weight: 700;
        white-space: pre;
        font-family: ${variables.accentFont};
        background: ${props => (props.apiKeyExists ? `${variables.navyBorder}` : `${variables.navyBlue}`)};
        color: ${props => (props.apiKeyExists ? `${variables.navyAsphalt}` : '')};
        &:hover {
            background: ${props => (props.apiKeyExists ? `${variables.navyBorder}` : `${variables.white}`)};
            color: ${props => (props.apiKeyExists ? `${variables.navyAsphalt}` : `${variables.navyBlue}`)};
            border: ${props => (props.apiKeyExists ? '' : `${variables.white}`)};
        }
    }
`
export const StyledApiButtonWrapper = styled.div`
    font-size: ${variables.gutterSm};
    color: ${variables.navyBlueBorder};
    font-weight: 600;
    padding-top: ${variables.headingFontSize};
    font-family: ${variables.accentFont};
`
export const StyledDetails = styled.div`
    color: ${variables.DarkGrey};

    font-size: ${variables.subTitleFontSize};
    font-family: ${variables.mainFont};
    font-weight: 400;
    a {
        font-weight: 600;
        text-decoration: underline;
    }
`
export const StylesDocumentationIcon = styled.a`
    margin-left: ${variables.borderRadiusBase};
`
export const StyledCopiedData = styled.div`
    width: 250px;
    height: ${variables.gutterLg};
    border: 1px solid ${variables.systemGreen};
    border-radius: ${variables.borderRadiusBase};
    padding: ${variables.borderRadiusBase} ${variables.gutterXs} ${variables.borderRadiusBase} ${variables.gutterXs};
    background: ${variables.lightgreen};
    top: 400px;
    left: 566px;
    position: absolute;
    text-align: center;
`
export const StyledCopyIcon = styled.span`
    margin-left: 2px;
    img {
        cursor: pointer;
    }
`
export const StyledSpinner = styled.div`
    margin-top: 12%;
`
export const StyledTableSpinner = styled.div`
    position: absolute;
    left: 45%;
    top: 80%;
`
