import i18n from 'i18next'

export const ACTIVITY_TYPE = {
    SECURITY_AUDIT: 'SECURITY_AUDIT',
    SYSTEM_ACTIVITY: 'SYSTEM_ACTIVITY'
}

export enum ACTIVITY_STATUSES {
    COMPLETED = 'COMPLETED',
    EXCEPTION = 'EXCEPTION',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED'
}

export enum ACTIVITY_NAMES {
    DELETEACCOUNTDATA = 'DELETEACCOUNTDATA',
    BACKUP = 'BACKUP',
    RESTORE = 'RESTORE',
    EXPORT = 'EXPORT',
    COMPARE = 'COMPARE'
}

export enum ACTIVITY_STATUS {
    COMPLETED = 'Success',
    FAILED = 'Failed',
    EXCEPTION = 'Partial',
    CANCELLED = 'Cancelled'
}

export const SYSTEM_ACTIVITY_STATUS = {
    COMPLETED: 'Success',
    FAILED: 'Failed',
    CANCELLED: 'Cancelled'
}

export const SECURITY_AUDIT_FILTER = {
    DELETE_BACKUP: 'DELETE_BACKUP',
    USER_DEACTIVATED: 'USER_DEACTIVATED',
    NEW_USER: 'NEW_USER',
    SIGNIN: 'SIGNIN',
    PERMISSIONS_CHANGED: 'PERMISSIONS_CHANGED',
    API_SETTINGS_CHANGED: 'API_SETTINGS_CHANGED',
    NOTIFICATION: 'NOTIFICATION'
}

export enum SECURITY_AUDIT_STATUS {
    NOTIFYDELETEDATA = 'NOTIFYDELETEDATA',
    DEACTIVATEUSER = 'DEACTIVATEUSER',
    CHANGEACCOUNTDATA = 'CHANGEACCOUNTDATA',
    SIGNIN = 'SIGNIN',
    PERMISSIONSCHANGED = 'PERMISSIONSCHANGED',
    NOTIFICATION = 'NOTIFICATION',
    API_SETTINGS_CHANGED = 'APISETTINGSCHANGED'
}

export const SECURITY_AUDIT_FILTER_OPTIONS = [
    {
        value: SECURITY_AUDIT_FILTER.DELETE_BACKUP,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.NOTIFYDELETEDATA}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.USER_DEACTIVATED,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.DEACTIVATEUSER}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.NEW_USER,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.CHANGEACCOUNTDATA}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.SIGNIN,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.SIGNIN}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.PERMISSIONS_CHANGED,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.PERMISSIONSCHANGED}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.API_SETTINGS_CHANGED,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.API_SETTINGS_CHANGED}`)
    },
    {
        value: SECURITY_AUDIT_FILTER.NOTIFICATION,
        label: i18n.t(`securityAudit:activity:${SECURITY_AUDIT_STATUS.NOTIFICATION}`)
    }
]

export const SYSTEM_AUDIT_ACTIVITY = {
    DELETEACCOUNTDATA: 'Delete Backup',
    BACKUP: 'Backup',
    RESTORE: 'Restore',
    EXPORT: 'Download',
    NOTIFICATION: 'Notification',
    COMPARE: 'Compare'
}

export const SYSTEM_AUDIT_STATUS_OPTIONS = [
    {
        value: ACTIVITY_STATUSES.COMPLETED,
        label: i18n.t(`securityAudit:status:${ACTIVITY_STATUSES.COMPLETED}`)
    },
    {
        value: ACTIVITY_STATUSES.FAILED,
        label: i18n.t(`securityAudit:status:${ACTIVITY_STATUSES.FAILED}`)
    }
]
