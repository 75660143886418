import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import {withTranslation, Trans} from 'react-i18next'
import queryString from 'query-string'

import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {getPerformActionRequest} from 'ca-common/utils/backup/backupActions'
import {getRequestParamsFromUrl} from 'ca-common/utils/backup/backupEntities'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'

import {pullBackupEntities} from 'src/newcore/redux/modules/backups/backupEntities'
import {performAction} from 'src/newcore/redux/modules/backups/backupActions'
import {PAGE_SIZE} from 'src/newcore/components/BackupEntities/TableColumnsConfig'

import {Default} from './Default'
import {ConfirmationList} from './ConfirmationList'
import {Run} from './Run'

import {StyledConfirmAction} from './StyledConfirmAction'

@withTranslation()
class ConfirmActionRaw extends React.Component {
    static width = 500

    state = {
        loading: false
    }

    handleActionPerform = (action, excludeEntities, cacheToken) => {
        const {
            t,
            actions,
            location,
            selectedAll,
            selectedRowKeys,
            resetRowSelection,
            taskSettings: {taskId},
            entities: {scrollPage},
            toggleConfirmVisible,
            userInfo,
            companyName
        } = this.props
        const {isSubscribed, trialDaysLeft} = userInfo
        const queryParams = queryString.parse(location.search, {arrayFormat: 'bracket'})

        this.setState({loading: true})

        const performActionRequest = getPerformActionRequest(
            action,
            taskId,
            selectedAll,
            selectedRowKeys,
            getRequestParamsFromUrl(queryParams),
            excludeEntities,
            cacheToken
        )

        const getBackupEntitiesRequest = {
            rows: PAGE_SIZE,
            page: scrollPage,
            taskId,
            ...getRequestParamsFromUrl(queryParams)
        }

        actions
            .performAction(performActionRequest)
            .then(() => {
                actions.pullBackupEntities(getBackupEntitiesRequest)
                if (action === TASK_ACTION.DELETE) {
                    openSuccessNotification(
                        <Trans i18nKey={'backups:modal:delete:gracePeriodNotification:text'} />,
                        t('backups:modal:delete:gracePeriodNotification:title')
                    )
                } else {
                    openSuccessNotification(t(`backups:backupActions:${action}:responseSuccess`))
                }
            })
            .catch(() =>
                isSubscribed || trialDaysLeft > 0
                    ? openErrorNotification(t(`backups:backupActions:${action}:responseFailed`))
                    : openErrorNotification(t('backups:unsubscribedErrorMessage', {companyName}))
            )
            .finally(() => {
                resetRowSelection()
                toggleConfirmVisible()
                this.setState({loading: false})
            })
    }

    render() {
        const {action, entities, confirmModalVisible, selectedAll, selectedRowKeys, toggleConfirmVisible} = this.props
        const {loading} = this.state

        return (
            <StyledConfirmAction
                open={confirmModalVisible}
                onCancel={toggleConfirmVisible}
                closable={false}
                footer={false}
                destroyOnClose={true}
                width={ConfirmActionRaw.width}
                bodyStyle={{padding: 0}}
            >
                {(() => {
                    switch (action) {
                        case TASK_ACTION.RUN:
                            return (
                                <Run
                                    action={action}
                                    loading={loading}
                                    selectedAll={selectedAll}
                                    selectedRowKeys={selectedRowKeys}
                                    onCancel={toggleConfirmVisible}
                                    onSubmit={this.handleActionPerform}
                                />
                            )
                        case TASK_ACTION.DELETE:
                        case TASK_ACTION.PAUSE:
                            return (
                                <ConfirmationList
                                    action={action}
                                    loading={loading}
                                    selectedAll={selectedAll}
                                    selectedRowKeys={selectedRowKeys}
                                    onCancel={toggleConfirmVisible}
                                    onSubmit={this.handleActionPerform}
                                />
                            )
                        default:
                            return (
                                <Default
                                    action={action}
                                    entities={entities}
                                    loading={loading}
                                    selectedAll={selectedAll}
                                    selectedRowKeys={selectedRowKeys}
                                    onCancel={toggleConfirmVisible}
                                    onSubmit={this.handleActionPerform}
                                />
                            )
                    }
                })()}
            </StyledConfirmAction>
        )
    }
}

const mapStateToProps = state => ({
    taskSettings: state.backups.settings.response,
    entities: state.backups.backupEntities.entities.response,
    userInfo: state.userInfo.response,
    companyName: state.companyInfo.response.name
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            performAction,
            pullBackupEntities
        },
        dispatch
    )
})

export const ConfirmAction = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmActionRaw))
