import React, {useCallback, useState} from 'react'

import {openErrorNotification} from 'ca-common/utils/toasts'
import {JOBS_STATUSES} from 'ca-common/common/enum'

import {
    CancelButton,
    DownloadReportButton,
    JobDetailedInfo,
    JobFooter,
    JobHeader
} from 'src/newcore/features/Jobs/molecules'
import {ActivityLogButton} from 'src/newcore/features/Jobs/atoms'
import {JobFields} from 'src/newcore/features/Jobs/types'
import {getActivityFriendly} from 'src/newcore/features/Jobs/lib'
import {getServiceNameMixpanel, track} from 'src/newcore/utils/mixpanel'

import {JobTile} from './StyledJob'

type JobProps = {
    job: JobFields
    activityLogPermitted: boolean
    jobCanceling: any
    cancelPermitted: boolean
    downloadReportPermitted: boolean
    isMsEndUser?: boolean
}

export const Job = ({
    job,
    jobCanceling,
    cancelPermitted,
    activityLogPermitted,
    downloadReportPermitted,
    isMsEndUser
}: JobProps): JSX.Element => {
    const {
        taskType,
        source,
        alias,
        account,
        startTime,
        endTime,
        size,
        initiator,
        totalItems,
        destination,
        status,
        backupDate,
        taskId,
        progressData,
        backupTaskId,
        storageType,
        jobLabel
    } = job

    const [canceling, setCanceling] = useState(false)

    const sendAnalytics = () => {
        track('Cancel Job', {
            Activity: getActivityFriendly(taskType),
            Service: getServiceNameMixpanel(source)
        })
    }

    const cancelTask = useCallback(async () => {
        try {
            setCanceling(true)
            await jobCanceling({taskId})
        } catch (error: any) {
            openErrorNotification(error?.status)
        } finally {
            setCanceling(false)
            sendAnalytics()
        }
    }, [taskId])

    return (
        <JobTile data-inlinemanual="InlineManual-jobspage-jobCardInfo">
            <JobHeader
                taskType={taskType}
                source={source}
                alias={alias}
                account={account}
                status={status}
                jobLabel={jobLabel}
                progressData={progressData}
            />
            <JobDetailedInfo
                backupDate={backupDate}
                startTime={startTime}
                size={size}
                destination={destination}
                initiator={initiator}
                totalItems={totalItems}
                taskType={taskType}
            />
            <JobFooter
                activityLog={
                    !isMsEndUser ? (
                        <ActivityLogButton
                            account={account}
                            taskType={taskType}
                            backupTaskId={backupTaskId}
                            startTime={startTime}
                            status={status}
                            permitted={activityLogPermitted}
                            source={source}
                        />
                    ) : undefined
                }
                downloadReport={
                    <DownloadReportButton
                        alias={alias}
                        account={account}
                        source={source}
                        taskId={taskId}
                        taskType={taskType}
                        status={status}
                        endTime={endTime}
                        downloadReportPermitted={downloadReportPermitted}
                        storageType={storageType}
                    />
                }
                cancel={
                    <CancelButton
                        taskType={taskType}
                        source={source}
                        account={account}
                        cancelTask={cancelTask}
                        canceling={canceling}
                        permitted={cancelPermitted}
                        inProcess={status === JOBS_STATUSES.IN_PROCESS || status === JOBS_STATUSES.PENDING}
                    />
                }
            />
        </JobTile>
    )
}
