import {SOURCE} from 'src/ca-common/common/enum'

import {post} from 'src/newcore/utils/rest'
import {changeData, clearWizard} from 'src/newcore/redux/modules/wizard'
import {Environment} from 'src/newcore/features/DynamicsWizard/lib/types'

const GET_DYNAMICS_ENVIRONMENT = 'getDynamicsEnvironments'
const CREATE_TASK = 'createTask'
const CREATE_APP_USER = 'createAppUserDynamics'
const CREATE_DYNAMIC_TASK = 'createDynamicsTask'
const ACTIVATE_APP_USER = 'activateApplicationUser'
const DYNAMICS_CONTINUE_OATH_TASK = 'dynamics365ContinueCreateOAuthTask'
const HOMEPAGE = '/'

export const getAllEnvironments = (dispatch: any, isBack: boolean, env: string) => {
    dispatch(changeData({isLoading: true}))
    post(GET_DYNAMICS_ENVIRONMENT, {isBack})
        .then((response: any) => {
            const environments = response.dynamicsEnvironmentInfoList
            dispatch(changeData({allEnvironments: environments}))
            let isAllEnvsBackedUp = true
            environments?.map((environment: Environment) => {
                if (!environment.isPresent) {
                    isAllEnvsBackedUp = false
                }
                if (isBack && environment.friendlyName === env) {
                    dispatch(changeData({environment: environment}))
                }
            })
            if (isAllEnvsBackedUp) {
                dispatch(changeData({isAllEnvsBackedUp}))
            }
            dispatch(changeData({isLoading: false, isBackStep: false}))
        })
        .catch(e => console.log(e))
}

export const authenticate = (environment: Environment, alias: string) => {
    post(CREATE_TASK, {
        source: SOURCE.DYNAMICS365,
        fields: [
            {id: 'alias', val: alias},
            {id: 'env', val: environment.friendlyName},
            {id: 'resource', val: environment.apiUrl},
            {id: 'envId', val: environment.environmentId}
        ]
    })
        .then((response: any) => {
            window.location.href = response.redirectUrl
        })
        .catch(e => console.log(e))
}

export const createAppUser = (environment: string, dispatch: any) => {
    post(CREATE_APP_USER, {envUrl: environment})
        .then((response: any) => {
            dispatch(changeData({appUserCreated: response.appUserCreated}))
        })
        .catch(e => {
            dispatch(changeData({appUserCreationError: true}))
        })
}

export const createTask = (dispatch: any) => {
    post(CREATE_DYNAMIC_TASK, {source: SOURCE.DYNAMICS365})
        .then((response: any) => {
            dispatch(clearWizard())
            window.location.href = response.redirectUrl
        })
        .catch(e => console.log(e))
}

export const reinstate = (dispatch: any) => {
    activateApplicationUser()
    post(DYNAMICS_CONTINUE_OATH_TASK, {creationType: 'UNDO_DATA_DELETION'})
        .then(response => {
            if (response.created) {
                dispatch(changeData({deletedTaskLoading: false}))
                dispatch(clearWizard())
                window.location.href = HOMEPAGE
            }
        })
        .catch(e => console.log(e))
}

export const reCreateNewTask = (dispatch: any) => {
    activateApplicationUser()
    post(DYNAMICS_CONTINUE_OATH_TASK, {creationType: 'NEW_TASK_AND_REMOVE_GRACE_PERIOD'})
        .then(response => {
            if (response.created) {
                dispatch(changeData({deletedTaskLoading: false}))
                dispatch(clearWizard())
                window.location.href = HOMEPAGE
            }
        })
        .catch(e => console.log(e))
}

export const activateApplicationUser = () => {
    post(ACTIVATE_APP_USER, {}).catch(e => console.log(e))
}
