import styled from 'styled-components'
import variables from 'ca-common/variables'
import Icon from '@ant-design/icons'

export const StyledLoader = styled.div`
    height: 326px;
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    width: 450px;
    margin: 0 auto;
    padding-top: 25%;
    text-align: center;
`

export const StyledSection = styled.div`
    width: 75%;
    padding-left: 25%;
    margin-top: 5%;
`

export const StyledIcon = styled(Icon)`
    font-size: 28px;

    svg {
        fill: none;
    }

    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`
