/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const Source = {
    SDB: 'SDB',
    DDB: 'DDB',
    YMAIL: 'YMAIL',
    MS365: 'MS365',
    GOOGLEAPPS: 'GOOGLEAPPS',
    GDRIVETEAM: 'GDRIVETEAM',
    GACCOUNTS: 'GACCOUNTS',
    SFC: 'SFC',
    SHAREPOINT: 'SHAREPOINT',
    SHAREPOINT_MULTI: 'SHAREPOINT_MULTI',
    ONEDRIVE: 'ONEDRIVE',
    IMAP: 'IMAP',
    BOX: 'BOX',

    GMAIL: 'GMAIL',
    GDOC: 'GDOC',
    GSITES: 'GSITES',
    GTASK: 'GTASK',
    GCONTACTS: 'GCONTACTS',
    GCALENDAR: 'GCALENDAR',
    O365TASKS: 'O365TASKS',
    O365CALENDAR: 'O365CALENDAR',
    O365MAIL: 'O365MAIL',
    O365CONTACTS: 'O365CONTACTS'
}
