import React, {useCallback, useMemo} from 'react'
import {Modal} from 'antd'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import _ from 'lodash'

import {WIZARD_TYPE} from 'ca-common/constants'

import {WizardContent} from './WizardContent'
import {clearWizard, closeWizard, openWizardWithConfig, changeData} from 'src/newcore/redux/modules/wizard'
import {SAFirstStep, SASecondStep, SAThirdStep} from 'src/newcore/features/SmartAlertsWizard/templates'
import {First, Second, Third} from '../BulkActivation/Steps'
import {clearMS365Data} from 'src/newcore/redux/modules/backups/bulkActivation/MS365Groups'
import {clearGoogleData} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU'
import {clearExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {clearActiveDirectoryData} from 'src/newcore/redux/modules/backups/bulkActivation/ActiveDirectory'
import {getSeedingSteps} from 'src/newcore/components/Seeding/seedingUtils'
import {getRecoverySteps} from 'src/newcore/features/RecoveryWizard/lib'
import {getOneClickActivationSteps, OCAServicesSelectionStep} from 'src/newcore/features/OneClickActivation'
import {useAppDispatch} from 'src/newcore/components/Root'
import {getDynamicsTaskCreationSteps} from 'src/newcore/features/DynamicsWizard/lib'

const MODAL_WIDTH = 916

const WizardModalRaw = ({wizard, actions}) => {
    const appDispatch = useAppDispatch()

    const handleCancel = callback => {
        actions.closeWizard()
        setTimeout(() => {
            appDispatch(clearWizard())
        }, 500)
        if (_.isFunction(callback)) {
            callback()
        }
    }

    const clearStore = useCallback(() => {
        actions.clearMS365Data()
        actions.clearGoogleData()
        appDispatch(closeWizard())
        actions.clearActiveDirectoryData()
    }, [actions])

    const steps = useMemo(() => {
        switch (wizard.type) {
            case WIZARD_TYPE.SEEDING:
                return getSeedingSteps(wizard)
            case WIZARD_TYPE.RECOVERY:
                return getRecoverySteps(wizard)
            case WIZARD_TYPE.SMART_ALERT:
                return [SAFirstStep, SASecondStep, SAThirdStep]
            case WIZARD_TYPE.BULK:
                return [First, Second, Third]
            case WIZARD_TYPE.ONE_CLICK_ACTIVATION:
                return getOneClickActivationSteps()
            case WIZARD_TYPE.MS_BACKUP:
                return [OCAServicesSelectionStep]
            case WIZARD_TYPE.DYNAMICS365:
                return getDynamicsTaskCreationSteps()
            default:
                return null
        }
    }, [wizard.type, wizard.data])

    return (
        <Modal
            bodyStyle={{padding: 0}}
            open={!!wizard.type}
            onCancel={handleCancel}
            width={wizard.config.modalWidth || MODAL_WIDTH}
            closable={false}
            destroyOnClose={true}
            footer={null}
            maskClosable={wizard.config?.maskClosable !== false}
            centered
        >
            <WizardContent
                wizard={wizard}
                clearWizard={() => appDispatch(clearWizard())}
                handleCancel={() => handleCancel(clearStore)}
                steps={steps}
            />
        </Modal>
    )
}

const mapStateToProps = state => ({
    wizard: state.wizard
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            closeWizard,
            clearWizard,
            clearMS365Data,
            clearExcludeEmails,
            clearActiveDirectoryData,
            openWizardWithConfig,
            changeData,
            clearGoogleData
        },
        dispatch
    )
})

export const WizardModal = connect(mapStateToProps, mapDispatchToProps)(WizardModalRaw)
