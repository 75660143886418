import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledSubtitle = styled.div`
    font-family: ${variables.mainFont};
    font-style: normal;
    font-size: ${variables.fontSizeBase};
    color: ${variables.text};
    margin-bottom: ${variables.gutterXs};
    display: flex;
    justify-content: space-between;
    .ca-form-group {
        width: 306px;
    }
    .ca-form-group__error {
        left: 0 !important;
        width: 300px;
    }
`

export const StyledValidation = styled.div`
    text-align: right;
    font-weight: 400;
    color: ${variables.navyAsphalt};
`

export const StyledSpan = styled.span`
    font-weight: 400;
    font-style: italic;
    color: ${variables.navyAsphalt};
`
