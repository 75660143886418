import React from 'react'
import {Tooltip} from 'antd'

import {DeepTaskStatus} from 'ca-common/ui-lib/atoms/DeepTaskStatus'
import {SourceIcon} from 'ca-common/components/SourceIcon'
import {Task} from 'src/newcore/features/Homepage/types'
import {IconBorderList, TaskTitle, SourceName, TextWrapper, TitleColumn, RowWrapper} from './StyledBackupTitleList'
import {ConnectStatus} from 'src/newcore/features/Homepage/atoms/ConnectStatus'
import {TaskNameWrapper, EditName} from 'src/newcore/features/Homepage/atoms/TaskView'
import {PausedStatus} from 'src/newcore/features/Homepage/atoms/PausedStatus'
import {isTaskPaused} from 'src/newcore/features/Homepage/utils/utils'
import {SOURCE} from 'src/ca-common/common/enum'

type BackupTitleListProps = {
    toggleVisible: () => void
    friendlyName: string
} & Task

export const BackupTitleList = ({toggleVisible, friendlyName, task}: BackupTitleListProps): JSX.Element => {
    const {source, alias, authenticated, deepTaskStatus, status} = task
    return (
        <TitleColumn data-inlinemanual="InlineManual-homepage-taskInfo">
            <ConnectStatus isAuthenticated={authenticated} />
            <IconBorderList>
                <SourceIcon source={source} />
            </IconBorderList>
            <TextWrapper>
                <TaskNameWrapper>
                    <Tooltip title={alias}>
                        <TaskTitle>{alias}</TaskTitle>
                    </Tooltip>
                    <EditName
                        onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                            e.preventDefault()
                            toggleVisible()
                        }}
                    />
                </TaskNameWrapper>
                <SourceName>
                    {friendlyName} {source === SOURCE.DYNAMICS365 && ` | ${task.dynamicsEnvironment}`}
                </SourceName>
                <RowWrapper>
                    <DeepTaskStatus status={deepTaskStatus} />
                    {isTaskPaused(status) && <PausedStatus />}
                </RowWrapper>
            </TextWrapper>
        </TitleColumn>
    )
}
