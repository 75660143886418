import React from 'react'
import {PopoverContentRow} from '../../atoms/PopoverContentRow'
import {formatUTCDateTime} from 'ca-common/utils/datetime'
import {Content, Wrapper} from './StyledPopoverContent'
import {SOURCE} from 'ca-common/common/enum'
import {ACTIVITY_STATUS} from 'ca-common/features/ActivityInfinityTable'
import {RestorePopoverProps, RestorePopoverDescription} from 'src/newcore/features/ActivityTable/types'

export const RestorePopoverContent = ({
    description,
    source,
    activityStatus
}: RestorePopoverProps<RestorePopoverDescription>): JSX.Element => {
    const {
        countEntities,
        numberOfFailedMD,
        numberOfFailedRecords,
        performedBy,
        restoreSource,
        restoreDestination,
        snapshotDate,
        jobLabel
    } = description

    const labelWidth = source === SOURCE.SFC ? 210 : 120

    return (
        <Content>
            {jobLabel && (
                <Wrapper>
                    <PopoverContentRow labelWidth={labelWidth} label="jobLabel" rowText={jobLabel} />
                </Wrapper>
            )}
            <Wrapper>
                <PopoverContentRow
                    labelWidth={labelWidth}
                    label="snapshotDate"
                    rowText={formatUTCDateTime(snapshotDate)}
                />
                <PopoverContentRow labelWidth={labelWidth} label="numberOfRecords" rowText={countEntities} />
            </Wrapper>
            {(restoreSource || restoreDestination) && (
                <Wrapper>
                    <PopoverContentRow labelWidth={labelWidth} label="restoreSource" rowText={restoreSource} />
                    <PopoverContentRow
                        labelWidth={labelWidth}
                        label="restoreDestination"
                        rowText={restoreDestination}
                    />
                </Wrapper>
            )}
            {(numberOfFailedRecords || numberOfFailedMD) && (
                <Wrapper>
                    <PopoverContentRow labelWidth={labelWidth} label="failedRecords" rowText={numberOfFailedRecords} />
                    <PopoverContentRow labelWidth={labelWidth} label="failedMetadata" rowText={numberOfFailedMD} />
                </Wrapper>
            )}
            <PopoverContentRow labelWidth={labelWidth} label="initiator" rowText={performedBy} />
        </Content>
    )
}
