import React, {useEffect} from 'react'
import {Form, Input, Tooltip} from 'antd'

import {i18n} from 'ca-common/locales'
import variables from 'ca-common/variables'
import Info from 'ca-common/icons/Info.svg'
import CloseIcon from 'ca-common/icons/closeIconRed.svg'
import ErrorFilled from 'ca-common/icons/errorFilled.svg'
import {splunkFormCheckboxPartner, splunkFormCheckbox, validEmail} from 'ca-common/features/Integrations/config'

import {
    StyledFormItem,
    StyledCheckbox,
    StyledSection,
    StyledText,
    StyledDetails,
    StyledSelection,
    StyledBlock,
    StyledBlockText,
    StyledInfo,
    StyledIcon,
    StyledInfoIcon,
    StyledErrorIcon
} from './StyledSplunkForm'

type SplunkFormProps = {
    splunkForm: any
    onValuesChange: (changedFields: Record<string, any>, allFields: Record<string, any>) => void
    initialFormData: Record<string, any>
    onSubmitIntegration: () => any
    isSplunkLoading: boolean
    isSubmitError: string
    isPartnerPortal: boolean
}

const endpointValidator = (rule: Record<string, any>, endpoint: string) => {
    if (endpoint) {
        if (!validEmail.test(endpoint)) {
            return Promise.reject(
                <>
                    <StyledErrorIcon component={ErrorFilled} />
                    {i18n.t('integrations:splunkModal:urlFormatError')}
                </>
            )
        } else {
            return Promise.resolve()
        }
    } else {
        return Promise.resolve()
    }
}

const emptyValidator = (rule: Record<string, any>, token: string) => {
    if (token && token.trim() === '') {
        return Promise.reject()
    } else {
        return Promise.resolve()
    }
}

const tooltipOverlayInnerStyle = {
    fontSize: variables.fontSizeSecondary,
    width: '329px',
    fontFamily: variables.publicPageFont,
    fontWeight: 400,
    padding: '14px',
    'margin-left': '24%',
    'margin-top': '-4%'
}

export const SplunkForm = (props: SplunkFormProps) => {
    const {
        splunkForm,
        onValuesChange,
        initialFormData,
        onSubmitIntegration,
        isSplunkLoading,
        isSubmitError,
        isPartnerPortal
    } = props
    const checkBoxValue = isPartnerPortal ? splunkFormCheckboxPartner : splunkFormCheckbox

    useEffect(() => {
        !isSplunkLoading && splunkForm.resetFields()
    }, [isSplunkLoading])

    const eventCollectorNode = () => {
        return (
            <StyledBlock>
                <StyledBlockText>{i18n.t('integrations:splunkModal:eventCollector')}</StyledBlockText>
                <StyledSection>
                    <StyledText>{i18n.t('integrations:splunkModal:verify')}</StyledText>
                    <Tooltip
                        placement="bottom"
                        title={i18n.t('integrations:splunkModal:certificateTooltip')}
                        overlayInnerStyle={tooltipOverlayInnerStyle}
                    >
                        <StyledDetails>{i18n.t('integrations:splunkModal:certificate')}</StyledDetails>
                        <StyledInfoIcon component={Info} />
                    </Tooltip>
                </StyledSection>
            </StyledBlock>
        )
    }

    return (
        <Form
            form={splunkForm}
            colon={false}
            initialValues={initialFormData}
            onFieldsChange={onValuesChange}
            onFinish={onSubmitIntegration}
        >
            <StyledFormItem
                name="endpoint"
                label={eventCollectorNode()}
                rules={[{required: true, message: ''}, {validator: endpointValidator}]}
            >
                <Input placeholder={i18n.t('integrations:splunkModal:eventCollectorPlaceholder')} />
            </StyledFormItem>
            <StyledFormItem
                name="token"
                label={i18n.t('integrations:splunkModal:splunkToken')}
                rules={[{required: true, message: ''}, {validator: emptyValidator}]}
            >
                <Input placeholder={i18n.t('integrations:splunkModal:splunkTokenPlaceholder')} />
            </StyledFormItem>
            <StyledFormItem name="tag" label={i18n.t('integrations:splunkModal:eventSource')}>
                <Input placeholder={i18n.t('integrations:splunkModal:eventSourcePlaceholder')} />
            </StyledFormItem>
            <StyledSelection>
                {checkBoxValue.map((value, key) => {
                    return (
                        <StyledFormItem name={value.name} valuePropName="checked" key={key}>
                            <StyledCheckbox key={key}>{value.label}</StyledCheckbox>
                        </StyledFormItem>
                    )
                })}
            </StyledSelection>
            {isSubmitError && (
                <StyledInfo>
                    <StyledIcon component={CloseIcon} />
                    {isSubmitError}
                </StyledInfo>
            )}
        </Form>
    )
}
