import styled from 'styled-components'
import {Form, Checkbox} from 'antd'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

const {Item: FormItem} = Form

export const StyledFormItem = styled(FormItem)`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    color: ${variables.text} !important;

    .ant-form-item-row {
        display: block;
    }

    .ant-form-item-label > label {
        flex-direction: row-reverse !important;
        color: ${variables.black} !important;
    }

    .ant-form-item-label {
        margin-left: -${variables.gutterXs};
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px !important;
    }

    label[for='endpoint'] {
        margin-bottom: ${variables.gutterXs};
        text-align: left;
        height: 100%;

        &:before {
            margin-bottom: 35px;
        }
    }

    label[for='token'] {
        &:before {
            margin-bottom: 4px;
        }
    }

    .ant-tooltip-inner {
        width: 400px;
    }

    .ant-form-item-explain-error {
        font-family: ${variables.mainFont};
        font-size: ${variables.fontSizeSecondary};
        font-style: italic;
        color: ${variables.red};
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-width: 2px;
    }

    .ant-form-item-explain-connected {
        margin-top: 1%;
        margin-bottom: 1%;
    }
`

export const StyledCheckbox = styled(Checkbox)`
    display: flex;
    margin-bottom: ${variables.gutterXs};
    width: fit-content;
`

export const StyledSection = styled.div`
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeSecondary};
    margin-top: 4%;
`

export const StyledText = styled.span`
    color: ${variables.navyAsphalt};
`

export const StyledDetails = styled.span`
    color: ${variables.navyBlue};
    cursor: pointer;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
    }
`

export const StyledSelection = styled.div`
    .ant-form-item {
        margin-bottom: 0px !important;
    }
`

export const StyledBlock = styled.div``

export const StyledBlockText = styled.span``

export const StyledInfo = styled.div`
    font-family: ${variables.mainFont};
    font-size: ${variables.fontSizeBase};
    font-style: italic;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: ${variables.red};
    display: inline-flex;
    gap: 3px;
`

export const StyledIcon = styled(Icon)`
    margin-top: 3px;
`

export const StyledInfoIcon = styled(Icon)`
    margin-left: 3px;
`

export const StyledErrorIcon = styled(Icon)`
    margin-right: 1%;
`
