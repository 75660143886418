import React, {useState} from 'react'
import Icon from '@ant-design/icons'

import QuestionMark from 'ca-common/icons/QuestionMark.svg'

import {HelpDrawer} from 'ca-common/features/BaseHeader/molecules/HelpDrawer'
import {StyledHelpButton} from './StyledHelpButton'

export const HelpButton = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const toggleDrawer = () => {
        setIsDrawerOpen(prev => !prev)
    }

    return (
        <>
            <StyledHelpButton
                onClick={toggleDrawer}
                borderWidth={1}
                icon={<Icon component={QuestionMark} />}
                shape="circle"
                size="middle"
                isDrawerOpen={isDrawerOpen}
            />
            <HelpDrawer onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen} />
        </>
    )
}
