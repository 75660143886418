import React, {useState} from 'react'
import {Input} from 'antd'

import {SingleFooterButton} from 'ca-common/features/Integrations/atoms/SingleFooterButton'
import {MultipleButtonFooter} from 'ca-common/features/Integrations/atoms/MultipleButtonFooter'
import {MultipleEntityText} from 'ca-common/features/Integrations/atoms/MultipleEntityText'
import {SplunkLoader} from 'ca-common/features/Integrations/atoms/SplunkLoader'
import {SplunkForm} from 'ca-common/features/Integrations/molecules/SplunkForm'
import {SplunkSetup} from 'ca-common/features/Integrations/atoms/SplunkSetup'
import {IntegrationServices} from 'ca-common/features/Integrations/config'

import {StyledModal, StyledTitle, StyledButton} from './StyledMultipleEntityModal'

type ModalProps = {
    modalVisible: boolean
    closeModal: () => any
    modalData: Record<string, any>
    partnerInfo?: Record<string, any>
    selectDeletion: () => any
    splunkForm: Record<string, any>
    onSubmitIntegration: () => any
    initialFormData: Record<string, any>
    onFormValueChange: (changedFields: Record<string, any>, allFields: Record<string, any>) => void
    isButtonDisable: boolean
    isSplunkLoading: boolean
    isSubmitError: string
    isEditable: boolean
    isPartnerPortal: boolean
    portalAllowLink?: boolean
}

export const MultipleEntityModal = (props: ModalProps) => {
    const {
        modalVisible,
        closeModal,
        modalData,
        partnerInfo,
        selectDeletion,
        splunkForm,
        onSubmitIntegration,
        initialFormData,
        onFormValueChange,
        isButtonDisable,
        isSplunkLoading,
        isSubmitError,
        isEditable,
        isPartnerPortal,
        portalAllowLink
    } = props
    const {title, width, crossIcon, name, type} = modalData

    const [isSplunkDisabled, setIsSplunkDisabled] = useState(false)

    const isSplunk = type === IntegrationServices.SPLUNK

    return (
        <StyledModal
            centered
            closable={false}
            width={width}
            open={modalVisible}
            destroyOnClose={true}
            onCancel={() => closeModal()}
            isSplunk={isSplunk}
            title={
                <StyledTitle>
                    {title}
                    {!isSplunkLoading && isSplunk && <SplunkSetup />}
                </StyledTitle>
            }
            footer={
                !isSplunk || isSplunkLoading ? (
                    <SingleFooterButton onCancel={() => closeModal()} type={type} />
                ) : (
                    <MultipleButtonFooter
                        onCancel={() => closeModal()}
                        onSubmit={() => splunkForm.submit()}
                        isSplunkDisabled={isButtonDisable}
                        selectDeletion={() => selectDeletion()}
                        isEditable={isEditable}
                    />
                )
            }
        >
            {isSplunkLoading ? (
                <SplunkLoader />
            ) : !isSplunk ? (
                <MultipleEntityText
                    title={name}
                    partnerInfo={partnerInfo}
                    type={type}
                    isPartnerPortal={isPartnerPortal}
                    portalAllowLink={portalAllowLink}
                />
            ) : (
                <SplunkForm
                    splunkForm={splunkForm}
                    onValuesChange={onFormValueChange}
                    initialFormData={initialFormData}
                    onSubmitIntegration={onSubmitIntegration}
                    isSplunkLoading={isSplunkLoading}
                    isSubmitError={isSubmitError}
                    isPartnerPortal={isPartnerPortal}
                />
            )}
        </StyledModal>
    )
}
