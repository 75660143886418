import Icon from '@ant-design/icons'
import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledBackupTaskAction = styled.div`
    display: flex;
`

export const TaskActionIcon = styled(Icon)`
    font-size: 24px;
    margin-right: 13px;
    color: ${variables.asphalt};
`
