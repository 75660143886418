import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox, Col, Form, InputNumber, Row, Switch, Tooltip} from 'antd'

import Info from 'ca-common/icons/Info.svg'
import {ACCESS_LEVEL} from 'ca-common/constants'
import {Button} from 'ca-common/ui-lib/atoms'
import {AppState, useAppSelector} from 'src/newcore/components/Root'

import {StyledFooter, StyledIcon} from './StyledSecurity'
import {StyledEnforceTwoStep, StyledExpirationForm, StyledExpiredPasswordRow} from './StyledExpirationForm'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

type ExpirationPageFormType = {
    isOkta: boolean
    onSubmit: any
    initialValues: Record<string, any>
}

export const ExpirationPageForm = (props: ExpirationPageFormType) => {
    const {t} = useTranslation()
    const {onSubmit, initialValues, isOkta} = props
    const [expirationPageForm] = Form.useForm<ExpirationPageFormType>()
    const userInfo = useAppSelector(userInfoSelector)
    const {passwordExpiredDays, enforceTwoStepAuthOnAllUsers} = userInfo || {}

    const [isButtonDisabled, setButtonDisabled] = useState(true)
    const [isSwitchOn, setSwitchOn] = useState(Boolean(passwordExpiredDays))
    const [isCheckboxChecked, setCheckboxChecked] = useState(Boolean(enforceTwoStepAuthOnAllUsers))
    const [lastSavedSwitchOn, setLastSavedSwitchOn] = useState(Boolean(passwordExpiredDays))
    const [lastSavedCheckboxChecked, setLastSavedCheckboxChecked] = useState(Boolean(enforceTwoStepAuthOnAllUsers))

    useEffect(() => {
        setButtonDisabled(true) // Keep buttons disabled on prop change
    }, [Boolean(passwordExpiredDays), Boolean(enforceTwoStepAuthOnAllUsers)])

    const handleCancelClick = () => {
        // Reset to the last saved state (optional: can keep current state)
        setSwitchOn(lastSavedSwitchOn)
        setCheckboxChecked(lastSavedCheckboxChecked)
        setButtonDisabled(true) // Disable buttons after cancel
        expirationPageForm.resetFields()
    }

    const handleSwitchChange = (checked: any) => {
        setSwitchOn(checked)
        checkButtonState(checked, isCheckboxChecked) // Check if button should be enabled
    }

    const handleCheckboxChange = (e: any) => {
        setCheckboxChecked(e.target.checked)
        checkButtonState(isSwitchOn, e.target.checked) // Check if button should be enabled
    }

    const checkButtonState = (switchState: boolean, checkboxState: boolean) => {
        setButtonDisabled(switchState === lastSavedSwitchOn && checkboxState === lastSavedCheckboxChecked)
    }

    const onSave = (e: any) => {
        const submitResponse = onSubmit(e)

        setLastSavedSwitchOn(isSwitchOn)
        setLastSavedCheckboxChecked(isCheckboxChecked)
        setButtonDisabled(true)
    }

    return (
        <StyledExpirationForm onFinish={onSave} colon={false} initialValues={initialValues} form={expirationPageForm}>
            {userInfo?.permissions.accessLevel === ACCESS_LEVEL.FULL && (
                <Row>
                    <Col>
                        <StyledEnforceTwoStep>
                            <Form.Item
                                name={'enforceTwoStepAuthOnAllUsers'}
                                label={t('securityPage:twoFaAuth:checkbox')}
                                colon={false}
                                htmlFor={'enforceTwoStepAuthOnAllUsers'}
                                valuePropName="checked"
                            >
                                <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                            </Form.Item>
                        </StyledEnforceTwoStep>

                        {!isOkta && (
                            <Tooltip title={t('securityPage:twoFaAuth:switcherTooltip')}>
                                <StyledIcon component={Info} />
                            </Tooltip>
                        )}
                    </Col>
                </Row>
            )}
            {!userInfo?.enforceSecuritySettings && (
                <StyledExpiredPasswordRow>
                    <Col>
                        <Form.Item
                            name="expiredPassword"
                            label={t('forms:changePasswordExpired:fields:expiredPassword:label')}
                        >
                            <Switch checked={isSwitchOn} onChange={handleSwitchChange} />
                        </Form.Item>
                        {isSwitchOn && (
                            <Form.Item name="days" label={t('forms:changePasswordExpired:fields:days:label')}>
                                <InputNumber min={1} max={365} />
                            </Form.Item>
                        )}
                    </Col>
                </StyledExpiredPasswordRow>
            )}

            <StyledFooter>
                <Button htmlType="button" onClick={handleCancelClick} disabled={isButtonDisabled}>
                    {t('forms:common:actions:cancel')}
                </Button>
                <Button type={'primary'} htmlType="submit" disabled={isButtonDisabled}>
                    {t('forms:common:actions:save')}
                </Button>
            </StyledFooter>
        </StyledExpirationForm>
    )
}
