import styled from 'styled-components'
import {Modal, Button} from 'antd'
import variables from 'ca-common/variables'

export const StyledModal = styled(Modal)<{isSplunk: boolean}>`
    .ant-modal-content {
        border-radius: ${props => (props.isSplunk ? '0px' : variables.gutterXs)};
        box-shadow: 0px ${variables.fontSizeSecondary} 28px 0px rgba(32, 40, 128, 0.16);
    }

    .ant-form-item {
        margin-bottom: ${variables.gutterXs};
    }

    .ant-modal-header {
        padding: ${variables.fontSizeBase};
        border-bottom: 3px solid ${variables.greyLight};
        border-radius: ${variables.gutterXs} ${variables.gutterXs} 0 0;
    }

    .ant-modal-footer {
        padding: 4.3px ${variables.fontSizeSecondary};
        border-top: 2px solid ${variables.greyLight};
    }

    .ant-modal-body {
        padding: ${variables.gutterSm} ${variables.gutterMd};
    }
`
export const StyledTitle = styled.div`
    font-family: ${variables.accentFont};
    color: ${variables.text};
    font-size: ${variables.headingFontSize};
    font-weight: 400;
`

export const StyledButton = styled(Button)``
