import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import {useTranslation} from 'react-i18next'
import {openErrorModal} from 'ca-common/utils/modals'
import {SOURCE} from 'ca-common/common/enum'
import {DEFAULT_PAGE, PAGE_SIZE} from 'src/ca-common/constants'
import {AppState, useAppDispatch, useAppSelector} from 'src/newcore/components/Root/newAppStore'
import {OUAccountItem} from './OUAccountItem'
import {
    getAccountsWithOU,
    GoogleAccountsWithOUResponse,
    clearAccountsWithOU
} from 'src/newcore/redux/modules/backups/bulkActivation/GoogleOU/GoogleAccountsWithOU'
import {setExcludeEmails} from 'src/newcore/redux/modules/backups/bulkActivation/excludeEmails'
import {setWizardDescription} from 'src/newcore/redux/modules/wizard'
import {AccountHeader, AccountListWrapper, StyledHeaderCell} from './OUAccountStyled'

export const OUAccountList = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [bulkActivationList, setBulkActivationList] = useState<any[]>([])
    const taskSettings = useAppSelector((state: AppState) => state.backups.settings.response)
    const selectedGroups = useAppSelector((state: AppState) => state.backups.bulkActivation.GoogleOUs.selectedOU)
    const {bulkAccountsStatus, bulkAccountsData} = useAppSelector((state: AppState) => ({
        bulkAccountsStatus: state.backups.bulkActivation.GoogleOUs.GoogleAccountsWithOU.status,
        bulkAccountsData: state.backups.bulkActivation.GoogleOUs.GoogleAccountsWithOU
            ?.response as GoogleAccountsWithOUResponse
    }))
    const excludeEmails = useAppSelector((state: AppState) => state.backups.bulkActivation.excludeEmails)
    const handleScroll = () => {
        if (bulkAccountsStatus === 'fulfilled' && bulkAccountsData?.page < bulkAccountsData?.totalPages) {
            fetchAccountsWithOUs(bulkAccountsData.page + 1)
        }
    }

    const fetchAccountsWithOUs = (page: any) => {
        dispatch(
            getAccountsWithOU({
                taskId: taskSettings?.taskId,
                rows: PAGE_SIZE,
                page,
                cacheToken: page !== DEFAULT_PAGE ? bulkAccountsData.cacheToken : undefined,
                groups: selectedGroups
            })
        )
            .then((data: any) => {
                setBulkActivationList([...bulkActivationList, ...data?.payload?.data])
                dispatch(
                    setWizardDescription(
                        t('backups:bulkActivation:accountsToActivate', {
                            count: data?.payload?.total || 0 - excludeEmails.length
                        })
                    )
                )
            })
            .catch(err => openErrorModal(err.status))
    }

    const onClick = (mail: any) => {
        dispatch(setExcludeEmails(mail))
    }

    useEffect(() => {
        dispatch(clearAccountsWithOU())
        fetchAccountsWithOUs(DEFAULT_PAGE)
    }, [])

    const page = bulkAccountsData?.page || 0
    const totalPages = bulkAccountsData?.totalPages || 0

    return (
        <AccountListWrapper data-hj-suppress>
            <AccountHeader>
                <StyledHeaderCell width="185px">{t('backups:bulkActivation:accountListHeaders:name')}</StyledHeaderCell>
                <StyledHeaderCell width="205px">
                    {t('backups:bulkActivation:accountListHeaders:email')}
                </StyledHeaderCell>
                {taskSettings?.source === SOURCE.ONEDRIVE && (
                    <StyledHeaderCell width="255px">
                        {t('backups:bulkActivation:accountListHeaders:site')}
                    </StyledHeaderCell>
                )}
                <StyledHeaderCell width="180px">
                    {t('backups:bulkActivation:accountListHeaders:group')}
                </StyledHeaderCell>
            </AccountHeader>
            <InfiniteScroll
                initialLoad={false}
                pageStart={DEFAULT_PAGE}
                useWindow={false}
                hasMore={page < totalPages}
                loadMore={handleScroll}
            >
                <Spin spinning={bulkAccountsStatus === 'pending'}>
                    {bulkActivationList.map((account: any) => {
                        const active = !excludeEmails.includes(account.mail)
                        return (
                            <OUAccountItem
                                {...account}
                                active={active}
                                key={account.mail}
                                onClick={() => onClick(account.mail)}
                            />
                        )
                    })}
                </Spin>
            </InfiniteScroll>
        </AccountListWrapper>
    )
}
