import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {Button} from 'antd'

import variables from 'ca-common/variables'

export const StyledFooter = styled.div`
    font-family: ${variables.accentFont};
`

export const StyledText = styled.span`
    margin-right: 8%;
    cursor: pointer;
    font-family: ${variables.accentFont};
    font-size: ${variables.fontSizeBase};
    color: ${variables.navyBlueBorder};
    font-weight: 700;
`

export const StyledDeleteButton = styled.div`
    float: left;
    color: ${variables.navyBlue};
    cursor: pointer;
    display: inline-block;
`

export const StyledIcon = styled(Icon)`
    svg {
        fill: none !important;
    }

    font-size: ${variables.subTitleFontSize};
    margin-right: ${variables.borderRadiusBase};
`

export const StyledSection = styled.div`
    width: 50%;
    display: inline-block;
    .ant-btn-primary {
        width: 66px;
        padding: 0px;
    }
`

export const StyledButton = styled(Button)``

export const StyledFooterText = styled.span`
    float: left;
    color: ${variables.navyBlue};
    cursor: pointer;
    display: inline-block;
    margin-top: -1px;
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.accentFont};
    font-weight: 700;
`

export const StyledAlignButton = styled.div`
    position: absolute;
    bottom: 2%;
`
