import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import {Dispatch} from '@reduxjs/toolkit'
import {useRouteMatch} from 'react-router'
import Back from 'ca-common/icons/back.svg'
import Person from 'ca-common/icons/Person.svg'
import {AlertProps} from 'ca-common/ui-lib/atoms/Alert'
import variables from 'ca-common/variables'
import {DropDownButton} from 'ca-common/ui-lib/atoms/DropDownButton'
import {DropdownContent} from 'ca-common/features/BaseHeader/organisms/DropdownContent'
import {HelpButton} from 'ca-common/features/BaseHeader/organisms/HelpButton'
import type {Url, Email, Name, CompanyInfoType} from 'ca-common/types'
import {CREDENTIALS_TYPES} from 'ca-common/constants'
import {
    AlertContainer,
    LogoWrapper,
    SensitiveAccount,
    SensitiveAccountWrapper,
    StyledActions,
    StyledAlert,
    StyledHeader,
    StyledHeaderSeparator,
    StyledHeaderTitle,
    StyledHeaderWrapper,
    StyledPanel,
    StylesLogoLink,
    UsernameWrapper,
    StyledIcon,
    BackButtonLink
} from './StyledHeader'

interface BaseHeaderProps {
    userName: Name
    userEmail: Email
    settingsUrl: Url
    signOutFunction: Dispatch
    isZix: boolean
    isWL?: boolean
    notification: AlertProps | null
    title: string
    isMsEndUser?: boolean
    credentialType?: string
}

interface PortalHeaderProps extends BaseHeaderProps {
    logo: {
        url: Url
        name: Name
        redirectUrl: Url
    }
    isFinra: boolean
    isPrimary: boolean
    companyInfo: CompanyInfoType
    trialMessage: JSX.Element | null
}

interface PPHeaderProps extends BaseHeaderProps {
    logo: {
        url: Url
        name: Name
    }
}

type CABaseHeaderProps = PortalHeaderProps | PPHeaderProps

function isPortalHeader(props: PortalHeaderProps | PPHeaderProps): props is PortalHeaderProps {
    return (props as PortalHeaderProps).isFinra !== undefined
}

export const CABaseHeader = (props: CABaseHeaderProps) => {
    const {t} = useTranslation()
    const {
        logo,
        userName,
        userEmail,
        settingsUrl,
        signOutFunction,
        isZix,
        notification,
        isWL,
        isMsEndUser,
        credentialType
    } = props
    const {url} = useRouteMatch()
    const hideNotificationLink = Boolean(
        notification?.link?.url && url.includes(notification.link.url) && !notification.isPaymentError
    )
    const withNotification = !!notification
    const isPortal = isPortalHeader(props)
    const validateWlZixUser = () => {
        return isZix && credentialType === CREDENTIALS_TYPES.ZIX ? true : !isWL
    }
    return (
        <StyledHeaderWrapper>
            <StyledHeader>
                {isZix && isPortal && (
                    <BackButtonLink type="link" size="large" href={props.companyInfo.siteUrl}>
                        <StyledIcon component={Back} />
                    </BackButtonLink>
                )}
                <LogoWrapper>
                    <StylesLogoLink href={isPortal ? props.logo.redirectUrl : '/'}>
                        <img src={logo.url} alt={logo.name} />
                    </StylesLogoLink>
                </LogoWrapper>
                <StyledPanel>
                    {userEmail && validateWlZixUser() && !isMsEndUser && (
                        <StyledHeaderTitle>
                            <StyledHeaderSeparator />
                            <p>{props.title}</p>
                        </StyledHeaderTitle>
                    )}

                    {withNotification && !isMsEndUser && (
                        <AlertContainer>
                            <StyledAlert
                                {...notification}
                                onClose={() => notification.onClose && notification.onClose()}
                                link={hideNotificationLink ? undefined : notification?.link}
                            />
                        </AlertContainer>
                    )}
                    <StyledActions withNotification={withNotification}>
                        {isPortal && (
                            <>
                                {props.isFinra && (
                                    <SensitiveAccountWrapper>
                                        <Tooltip title={t('header:finra:tooltip')}>
                                            <SensitiveAccount>
                                                <b>{t('header:finra:protected')}</b>
                                            </SensitiveAccount>
                                        </Tooltip>
                                        <StyledHeaderSeparator />
                                    </SensitiveAccountWrapper>
                                )}
                            </>
                        )}
                        {(isPortal && !isMsEndUser && props.trialMessage) ?? null}

                        {!isWL && !isZix && isPortal && <HelpButton />}
                        <UsernameWrapper>
                            <DropDownButton
                                dropdownRender={
                                    <DropdownContent
                                        userName={userName}
                                        userEmail={userEmail}
                                        isPrimary={'isPrimary' in props ? props.isPrimary : false}
                                        isZix={isZix}
                                        isPortal={isPortal}
                                        settingsUrl={settingsUrl}
                                        signOutFunction={signOutFunction}
                                        isMsEndUser={isMsEndUser}
                                    />
                                }
                                color={variables.white}
                                buttonText={
                                    <Tooltip
                                        title={userName}
                                        placement="topRight"
                                        getPopupContainer={t => t.parentElement as HTMLElement}
                                        mouseEnterDelay={0.7}
                                    >
                                        <span>{userName}</span>
                                    </Tooltip>
                                }
                                width={186}
                                icon={Person}
                                ghost
                                placement="bottomRight"
                                getPopupContainer={t => t.parentElement as HTMLElement}
                            />
                        </UsernameWrapper>
                    </StyledActions>
                </StyledPanel>
            </StyledHeader>
        </StyledHeaderWrapper>
    )
}
