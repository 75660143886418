import styled from 'styled-components'

import variables from 'ca-common/variables'
import {Source} from 'src/ca-common/config'

export const Wrapper = styled.div`
    display: flex;
    gap: ${variables.gutterLg};

    .ant-form-item {
        margin: 0;
        width: 128px;
    }

    .ant-form-item-label {
        font-weight: 600;
    }
`
export const NotificationWrapper = styled.div<{flexCondition?: any}>`
    display: inline;
    margin-left: 62%;
    font-size: ${variables.fontSizeSecondary};
    font-weight: 500;
    font-family: ${variables.publicPageFont};
    color: ${variables.ligthBlack};
    line-height: 16.8px;

    @media screen and (min-width: ${variables.notificationMin}) and (max-width: ${variables.notificationScreen}) {
        margin-left: 0%;
    }

    @media screen and (min-width: ${variables.notificationScreen}) and (max-width: ${variables.screenFullHD}) {
        margin-left: 62%;
        margin-top: ${props => (props.flexCondition === Source.MS365 ? '-65px' : '-30px')};
    }
`
