import React, {useState, useEffect, useCallback} from 'react'
import {Tabs, Form} from 'antd'
import {Trans} from 'react-i18next'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'

import {openSuccessNotification} from 'ca-common/utils/toasts'
import {
    IntegrationList,
    splunkFormInitialData,
    validEmail,
    splunkCheckBoxValues,
    IntegrationServices,
    IntegrationPortalList,
    MIN_CHECKBOX,
    splunkFormInputs
} from 'ca-common/features/Integrations/config'
import {Integration} from 'ca-common/features/Integrations/molecules/Integration'
import {MultipleEntityModal} from 'ca-common/features/Integrations/molecules/MultipleEntityModal'
import {DeleteModal} from 'ca-common/features/Integrations/molecules/DeleteModal'
import {selectVisibleOption, formValueUpdate, isRestAPIVisible} from 'ca-common/features/Integrations/utils'
import {IntegrationsType} from 'ca-common/features/Integrations/type'
import {TODO_ANY} from 'ca-common/types'

import {StyledTabs} from './StyledIntegrations'
import {ApiIntegration} from '../ApiIntegrations/ApiIntegration'

const {TabPane} = Tabs

type IntegrationsProps = {
    partnerInfo?: Record<string, any>
    getIntegrationStatus: TODO_ANY
    addIntegration: TODO_ANY
    deleteIntegration: TODO_ANY
    editIntegration: TODO_ANY
    isPartnerPortal: boolean
    fetchApiKeys: TODO_ANY
    generateApiKeys: TODO_ANY
    deleteApiKeys: TODO_ANY
    portalAllowLink?: boolean
    isWL?: boolean
    accountInfo?: Record<string, any>
}

export const Integrations = (props: IntegrationsProps) => {
    const {
        partnerInfo,
        getIntegrationStatus,
        addIntegration,
        deleteIntegration,
        editIntegration,
        isPartnerPortal,
        portalAllowLink,
        isWL,
        accountInfo,
        ...rest
    } = props

    const [splunkForm] = Form.useForm()
    let viewList = isPartnerPortal ? IntegrationList : IntegrationPortalList
    const showRestAPI = isRestAPIVisible(isPartnerPortal ? partnerInfo?.partnerType : accountInfo?.partnerType)
    viewList = isWL || !showRestAPI ? viewList?.filter(items => items.menuTitle !== IntegrationServices.API) : viewList
    const [integrationData, setIntegrationData] = useState(viewList)
    const [modalData, setModalData] = useState({})
    const [isActive, setIsActive] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [footerData, setFooterData] = useState()
    const {data, refetch, isLoading: isGetLoading} = getIntegrationStatus()
    const [addIntegrationType, {isLoading: isAddLoading}] = addIntegration()
    const [deleteIntegrationType, {isLoading: isDeleteLoading}] = deleteIntegration()
    const [isEditable, setIsEditable] = useState(false)
    const [editIntegrationType, {isLoading: isEditLoading}] = editIntegration()
    const [initialFormData, setInitialFormData] = useState({})
    const [isButtonDisable, setIsButtonDisable] = useState(true)
    const [isSubmitError, setIsSubmitError] = useState('') // error from add/edit
    const [modifiedValue, setModifiedValue] = useState({})
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if (data && data.length !== 0) {
            viewList.forEach((value, key) => {
                value.items?.forEach((val, k) => {
                    data.forEach((item: Record<string, any>, i: number) => {
                        if (item.type === val.type) {
                            val.isActive = true
                            val.isFailed = item.isFailed
                            val.lastFailure = item.lastFailure
                        }
                        if (item.type === IntegrationServices.SPLUNK) {
                            // updating splunk form
                            const updatedValue = data?.find(
                                ({type}: {type: string}) => type === IntegrationServices.SPLUNK
                            )
                            setModifiedValue(updatedValue)
                            setInitialFormData(updatedValue)
                            updatedValue && setIsEditable(true)
                        }
                    })
                })
            })
            setIntegrationData(viewList)
        } else {
            setIsEditable(false)
            setInitialFormData(splunkFormInitialData)
        }
    }, [data])

    useEffect(() => {
        !modalVisible && setIsSubmitError('')
    }, [modalVisible])

    const selectIntegration = (type: string) => {
        setModalData(selectVisibleOption(type))
        setModalVisible(true)
    }

    const closeModal = () => {
        setModalVisible(false)
        setIsButtonDisable(true)
        isEditable ? setInitialFormData(modifiedValue) : setInitialFormData(splunkFormInitialData)
    }

    const closeDeleteModal = () => {
        setDeleteModalVisible(false)
    }

    const selectDeletion = () => {
        setDeleteModalVisible(true)
    }

    const onDeleteSubmit = async () => {
        const updatedData = updateFormData()
        const deleteRes: TODO_ANY = await deleteIntegrationType(updatedData)
        if (!deleteRes.error) {
            setDeleteModalVisible(false)
            openSuccessNotification(
                <Trans i18nKey="integrations:splunkNotifications:deleteSuccessDescription" />,
                <Trans i18nKey="integrations:splunkNotifications:deleteTitle" />
            )
            await refetch()
            viewList.forEach((value, key) => {
                value.items?.forEach((val, k) => {
                    if (val.type === IntegrationServices.SPLUNK) {
                        val.isActive = false
                        val.isFailed = false
                    }
                })
            })
            setIntegrationData(viewList)
            setInitialFormData(splunkFormInitialData)
        }
        setModalVisible(false)
        setIsEditable(false)
    }

    const checkboxSelection = () => {
        // check if anyone checkbox selected
        const currentValue = splunkForm.getFieldsValue()
        const minSelection =
            [currentValue.SYSTEM_ACTIVITY, currentValue.SECURITY_AUDIT, currentValue.PP_AUDIT].filter(Boolean).length >=
            MIN_CHECKBOX
        return minSelection
    }

    const valueUpdate = (changedFields: Record<string, any>) => {
        const value = formValueUpdate(changedFields, splunkFormInputs, initialFormData)
        setShowLoader(value)
    }

    const onFormValueChange = (changedFields: Record<string, any>, allFields: Record<string, any>) => {
        const isRequiredFieldsTouched =
            isEditable || isSubmitError
                ? splunkForm.isFieldsTouched()
                : splunkForm.isFieldTouched('endpoint') && splunkForm.isFieldTouched('token')
        const isFormValid = allFields.every((field: Record<string, any>) => field.errors.length === 0)
        const checkboxValue = checkboxSelection()
        const disableValue = isFormValid && isRequiredFieldsTouched && checkboxValue
        setIsButtonDisable(!disableValue)
        if (disableValue) {
            valueUpdate(changedFields) // check if loader is to be shown
        }
    }

    const updateFormData = () => {
        const formValues: Record<string, any> = splunkForm.getFieldsValue()
        const isData = data && data.length !== 0
        const updatedData = isData && data.find(({type}: {type: string}) => type === IntegrationServices.SPLUNK)
        const scopeValue: string[] = []
        splunkCheckBoxValues.forEach(value => {
            formValues[value] && scopeValue.push(value)
        })
        formValues.scope = scopeValue
        formValues.id = (isData && updatedData?.id) || undefined
        formValues.token = formValues.token.trim()
        formValues.tag = formValues.tag !== undefined ? formValues.tag.trim() : formValues.tag
        setInitialFormData(formValues)
        return formValues
    }

    const onSubmitIntegration = async () => {
        const formData = updateFormData()
        const res: TODO_ANY = isEditable ? await editIntegrationType(formData) : await addIntegrationType(formData)
        const description = isEditable
            ? 'integrations:splunkNotifications:updateSuccessDescription'
            : 'integrations:splunkNotifications:addSuccessDescription'
        if (res.error) {
            const errorReceived = res.error?.data?.status || res.error?.data
            setIsSubmitError(errorReceived)
        } else {
            refetch()
            setModalVisible(false)
            setIsButtonDisable(true)
            openSuccessNotification(<Trans i18nKey={description} />, <Trans i18nKey="modals:title:success" />)
        }
    }

    return (
        <>
            {isGetLoading ? (
                <Spinner modifier="page" />
            ) : (
                <>
                    <MultipleEntityModal
                        modalVisible={modalVisible}
                        modalData={modalData}
                        closeModal={() => closeModal()}
                        partnerInfo={partnerInfo}
                        selectDeletion={() => selectDeletion()}
                        splunkForm={splunkForm}
                        onSubmitIntegration={onSubmitIntegration}
                        initialFormData={initialFormData}
                        onFormValueChange={onFormValueChange}
                        isButtonDisable={isButtonDisable}
                        isSplunkLoading={(isAddLoading || isEditLoading) && showLoader}
                        isSubmitError={isSubmitError}
                        isEditable={isEditable}
                        isPartnerPortal={isPartnerPortal}
                        portalAllowLink={portalAllowLink}
                    />
                    <DeleteModal
                        deleteModalVisible={deleteModalVisible}
                        closeDeleteModal={closeDeleteModal}
                        onDeleteSubmit={() => onDeleteSubmit()}
                        isDeleteLoading={isDeleteLoading}
                    />

                    <StyledTabs>
                        {integrationData.map(data => (
                            <TabPane tab={data.menuTitle} key={data.index}>
                                {data.menuTitle === IntegrationServices.API ? (
                                    <ApiIntegration isPartnerPortal={isPartnerPortal} {...rest} />
                                ) : (
                                    data.items?.map((value, key) => (
                                        <Integration value={value} selectIntegration={selectIntegration} key={key} />
                                    ))
                                )}
                            </TabPane>
                        ))}
                    </StyledTabs>
                </>
            )}
        </>
    )
}
