import React, {useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {UserInfoDataType} from 'ca-common/types'
import {getUrlParams} from 'ca-common/utils/url'
import {FEATURE_FLAGS} from 'ca-common/features/Flags/constants'
import {isProduction} from 'ca-common/utils/runtime'

export const useFeatureFlag = (target: FEATURE_FLAGS) => {
    const userInfo = useSelector((state: any) => state.userInfo?.response || state.partnerInfo?.reponse)
    const isEnabledByUserInfo = userInfo?.[(target as string) as keyof UserInfoDataType] === true

    const location = useLocation()

    const isEnabledInDev = useMemo(() => {
        if (isProduction && process.env.ENVIRONMENT === 'prod') {
            return false
        }

        const isEnabledByTargetInLocalStorage = Boolean(localStorage.getItem(target))

        const urlSearchParams = getUrlParams(location.search)
        const isEnabledByTargetInURL = urlSearchParams?.[target] === 'true' || false

        isEnabledByTargetInLocalStorage &&
            console.log(`DevMode.LocalStorage.${target}: `, isEnabledByTargetInLocalStorage)

        isEnabledByTargetInURL && console.log(`DevMode.URL.${target}: `, isEnabledByTargetInURL)

        return isEnabledByTargetInLocalStorage || isEnabledByTargetInURL
    }, [process.env.NODE_ENV, location.search])

    return useMemo(() => {
        return isEnabledByUserInfo || isEnabledInDev
    }, [target, isEnabledByUserInfo, isEnabledInDev])
}

type withFeatureFlagProps = {
    target: FEATURE_FLAGS
}
export function withFeatureFlag<T>(WrappedComponent: React.ComponentType<T & withFeatureFlagProps>) {
    return (props: T & withFeatureFlagProps) => {
        const {target} = props

        const isFeatureFlagEnabled = useFeatureFlag(target)

        return isFeatureFlagEnabled ? <WrappedComponent {...props} /> : null
    }
}

export const FeatureFlag = (props: React.PropsWithChildren<withFeatureFlagProps>) => {
    const {target, children} = props

    const isFeatureFlagEnabled = useFeatureFlag(target)

    return !isFeatureFlagEnabled ? null : children
}
