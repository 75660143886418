import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledLink = styled.a`
    font-size: ${variables.fontSizeSecondary};
    font-weight: 400;
    font-family: ${variables.publicPageFont};
    text-decoration: underline !important;
    float: right;

    svg {
        fill: none !important;
        margin-right: ${variables.borderRadiusBase};
        font-size: 15px;
    }
`
