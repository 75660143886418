import {Trans} from 'react-i18next'
import React from 'react'

import {Environment, StyledHeader} from './StyledDynamicsHeader'

type DynamicsHeaderProps = {
    friendlyName?: string
    isForAuth?: boolean
    hideDescription?: boolean
}

export const DynamicsHeader = (props: DynamicsHeaderProps) => {
    const {friendlyName, isForAuth, hideDescription} = props
    return (
        <StyledHeader>
            <h2>
                <Trans i18nKey="forms:backupAuth:text:DynamicsCreateUser" />
            </h2>
            {!hideDescription && (
                <p>
                    <Trans
                        values={{env: friendlyName}}
                        i18nKey={`forms:backupAuth:text:DynamicsCreateUserInfo:${
                            isForAuth ? 'pressAuth' : 'pressDone'
                        }`}
                    >
                        <Environment />
                    </Trans>
                </p>
            )}
        </StyledHeader>
    )
}
