import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Table, Tooltip, Spin} from 'antd'
import {TODO_ANY} from 'ca-common/types'
import Delete from 'ca-common/images/Integrations/Delete.svg'
import APILinkArrow from 'ca-common/images/Integrations/APILinkArrow.svg'
import Copy from 'ca-common/images/Integrations/Copy.svg'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {PAGE} from 'ca-common/constants'
import Success from 'ca-common/images/Integrations/Success.svg'
import SecretKey from 'ca-common/images/Integrations/SecretKey.svg'
import {ApiKeyDeleteModal} from 'ca-common/features/Integrations/molecules/DeleteModal/ApiKeyDeleteModal'
import {getCompareDateFromLocalNew, API_DATE_FORMAT} from 'ca-common/utils/datetime'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {columns, TIMER_MS} from '../ApiKeyDetailsCommon'
import {ClientKeyDetailsModal} from '../ApiKeyDetailsModal'
import {
    StyledIntegrationCard,
    StyledTitle,
    StyledDetails,
    StyledLogo,
    StyledDeleteButton,
    StyledKeyButton,
    StyledApiKeyDetails,
    StyledApiButtonWrapper,
    StyledCopiedData,
    StyledSpinner,
    StylesDocumentationIcon,
    StyledCopyIcon,
    StyledTableSpinner
} from './StyledApiIntegration'

type Props = {
    fetchApiKeys: TODO_ANY
    generateApiKeys: TODO_ANY
    deleteApiKeys: TODO_ANY
    isPartnerPortal: boolean
}
export const ApiIntegration = ({fetchApiKeys, generateApiKeys, deleteApiKeys, isPartnerPortal}: Props): JSX.Element => {
    const {t} = useTranslation()
    const [modalVisible, setModalVisible] = useState(false)
    const [apiKeyExists, setApiKeyExists] = useState(false)
    const [modalData, setModalData] = useState({})
    const [apiDetails, setApiDetails] = useState<any[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [secretCopied, setSecretCopied] = useState(false)
    const [copiedMessage, setCopiedMessage] = useState('')
    const [showSecret, setShowSecret] = useState(false)
    const {data, refetch, isLoading, isError} = fetchApiKeys({})
    const [deleteAPiKeys, {isLoading: deleteLoader}] = deleteApiKeys({})
    const [generateApiKey, {isLoading: generateApiLoader}] = generateApiKeys()
    const [secretKeyImgLoaded, setSecretKeyImgLoaded] = useState(false)
    const [successImgLoaded, setSuccessImgLoaded] = useState(false)
    const [deleteImgLoaded, setDeleteImgLoaded] = useState(false)
    const closeModal = async () => {
        setModalVisible(false)
        openSuccessNotification(t('integrations:apiDetails:clientDetails:modal:success'))
        await refetch()
    }
    const showClientKeyDetails = async () => {
        setModalVisible(true)
        setShowSecret(false)
        const response: TODO_ANY = await generateApiKey({})
        if (response && response.length !== 0) {
            const apiKeyData = isPartnerPortal ? response.data : response.data[0]
            setModalData({secretId: apiKeyData.clientId, secretData: apiKeyData.clientSecret})
        }
    }
    const locale = {
        emptyText: t('integrations:apiDetails:noApiKeyDesc')
    }
    const onDelete = async () => {
        deleteAPiKeys({})
            .unwrap()
            .then(async () => {
                openSuccessNotification(t('integrations:apiDetails:clientDetails:modal:delete:success'))
                setApiKeyExists(false)
                await refetch()
            })
        setShowDeleteModal(false)
    }
    useEffect(() => {
        getApiKeyDetails()
    }, [data])
    const handleCopy = (secret: string) => () => {
        navigator.clipboard
            .writeText(secret)
            .then(() => {
                setSecretCopied(true)

                setCopiedMessage(t('integrations:apiDetails:clientDetails:clientIdCopied'))
            })
            .catch(() => {
                setCopiedMessage(t('integrations:apiDetails:clientDetails:copyFailure'))
            })
        const timer = setTimeout(() => {
            setSecretCopied(false)
        }, TIMER_MS)

        return () => clearTimeout(timer)
    }
    const getApiKeyDetails = () => {
        setApiKeyExists(false)
        const apiKeyDetails = []
        if (data && data.length !== 0 && !isError) {
            const apiKeyData = isPartnerPortal ? data : data[0]
            const apiKeys = {
                ...apiKeyData,
                clientId: (
                    <>
                        {apiKeyData?.clientId}{' '}
                        <StyledCopyIcon>
                            <img src={Copy} onClick={handleCopy(apiKeyData?.clientId)} />
                        </StyledCopyIcon>
                    </>
                ),
                createdOn:
                    apiKeyData?.createdOn === 0 ? (
                        <Tooltip
                            title={t('integrations:apiDetails:clientDetails:createdOnNaTooltipText')}
                            placement={'bottom'}
                        >
                            {t('integrations:apiDetails:notAvailable')}
                        </Tooltip>
                    ) : (
                        getCompareDateFromLocalNew(apiKeyData?.createdOn, API_DATE_FORMAT)
                    )
            }
            apiKeyDetails.push(apiKeys)
            setApiDetails(apiKeyDetails)
            setApiKeyExists(true)
        }
    }

    return (
        <>
            {!isLoading ? (
                <>
                    <ClientKeyDetailsModal
                        modalVisible={modalVisible}
                        modalData={modalData}
                        closeModal={closeModal}
                        loading={generateApiLoader}
                        showSecret={showSecret}
                        setShowSecret={setShowSecret}
                    />
                    <ApiKeyDeleteModal
                        visible={showDeleteModal}
                        onCancel={() => {
                            setShowDeleteModal(false)
                        }}
                        onDelete={onDelete}
                        deleteLoader={deleteLoader}
                    />
                    <StyledIntegrationCard>
                        <StyledTitle>{t('integrations:apiDetails:restAPi')}</StyledTitle>
                        <StyledDetails>
                            {isPartnerPortal
                                ? t('integrations:apiDetails:apiDescriptionPP')
                                : t('integrations:apiDetails:apiDescription')}
                            <br />
                            {t('integrations:apiDetails:referTo')}
                            <a href={t('integrations:apiDetails:apiDocumentationLink')} target="_blank">
                                {t('integrations:apiDetails:apiDocumentation')}
                            </a>
                            <StylesDocumentationIcon
                                href={t('integrations:apiDetails:apiDocumentationLink')}
                                target="_blank"
                            >
                                <img src={APILinkArrow} />
                            </StylesDocumentationIcon>
                            {t('integrations:apiDetails:descDetails')}
                        </StyledDetails>
                    </StyledIntegrationCard>
                    <StyledApiButtonWrapper>
                        {t('integrations:apiDetails:restApiKey')}
                        <StyledKeyButton apiKeyExists={apiKeyExists}>
                            <Button type="primary" onClick={() => showClientKeyDetails()} disabled={apiKeyExists}>
                                {t('integrations:apiDetails:keyButtonText')}
                            </Button>
                        </StyledKeyButton>
                    </StyledApiButtonWrapper>
                    <StyledApiKeyDetails apiKeyExists={apiKeyExists}>
                        {apiKeyExists && (
                            <StyledLogo>
                                <img
                                    src={SecretKey}
                                    alt="key"
                                    onLoadStart={() => {
                                        setSecretKeyImgLoaded(false)
                                    }}
                                    onLoad={() => {
                                        setSecretKeyImgLoaded(true)
                                    }}
                                />
                            </StyledLogo>
                        )}
                        {secretKeyImgLoaded && deleteImgLoaded ? (
                            <Table
                                columns={columns}
                                dataSource={apiKeyExists ? apiDetails : []}
                                locale={locale}
                                rowKey={record => record.clientId}
                            />
                        ) : !apiKeyExists ? (
                            <Table
                                columns={columns}
                                dataSource={apiKeyExists ? apiDetails : []}
                                locale={locale}
                                rowKey={record => record.clientId}
                            />
                        ) : (
                            <StyledTableSpinner>
                                <Spin />
                            </StyledTableSpinner>
                        )}

                        {apiKeyExists && (
                            <StyledDeleteButton isDeleteImgLoaded={deleteImgLoaded && secretKeyImgLoaded}>
                                <img
                                    src={Delete}
                                    alt="delete"
                                    onClick={() => setShowDeleteModal(true)}
                                    onLoadStart={() => {
                                        setDeleteImgLoaded(false)
                                    }}
                                    onLoad={() => {
                                        setDeleteImgLoaded(true)
                                    }}
                                />
                            </StyledDeleteButton>
                        )}
                    </StyledApiKeyDetails>
                </>
            ) : (
                <StyledSpinner>
                    <Spinner modifier={PAGE} />
                </StyledSpinner>
            )}
            {secretCopied && (
                <StyledCopiedData>
                    <img
                        src={Success}
                        alt="success"
                        onLoadStart={() => {
                            setSuccessImgLoaded(false)
                        }}
                        onLoad={() => {
                            setSuccessImgLoaded(true)
                        }}
                    />
                    {!successImgLoaded ? <Spin /> : copiedMessage}
                </StyledCopiedData>
            )}
        </>
    )
}
