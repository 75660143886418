import styled from 'styled-components'

import variables from 'ca-common/variables'

export const StyledHeader = styled.div`
    font-family: ${variables.publicPageFont};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    margin-bottom: ${variables.gutterLg};

    h2 {
        color: ${variables.brandDark};
        font-size: 22px;
        line-height: 140%;
        font-weight: 600;
        margin-top: -48px;
    }

    p {
        font-weight: 300;
        text-align: center;
        margin: 0;
    }
`

export const Environment = styled.span`
    font-weight: 400;
`
