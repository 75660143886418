import React from 'react'
import {
    BillingNotificationWrapper,
    BillingNotificationHeader,
    BillingNotificationText,
    StyledBillingNotification,
    StyledBillingNotificationIcon
} from './StyledBillingNotification'

type BillingNotificationProps = {
    notificationIcon: any
    notificationHeader: string
    notifictionText: string
    isPaymentError?: boolean
}
export const BillingNotification = (props: BillingNotificationProps) => {
    const {notificationIcon, notificationHeader, notifictionText, isPaymentError} = props

    return (
        <BillingNotificationWrapper>
            <StyledBillingNotification>
                <StyledBillingNotificationIcon component={notificationIcon}></StyledBillingNotificationIcon>
                <BillingNotificationHeader isPaymentError={isPaymentError}>
                    {notificationHeader}
                </BillingNotificationHeader>
            </StyledBillingNotification>
            <BillingNotificationText>{notifictionText}</BillingNotificationText>
        </BillingNotificationWrapper>
    )
}
