import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Modal} from 'antd'

import {BackupTaskAction} from 'ca-common/components/BackupTaskAction'
import {TASK_ACTION} from 'ca-common/common/enum/TaskAction'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {SOURCE} from 'ca-common/common/enum'
import variables from 'ca-common/variables'

import {
    StyledHeader,
    StyledFooter,
    StyledModal,
    StyledHeaderWithIcon,
    StyledLink,
    StyledText
} from './StyledActionDeleteConfirmation'

export const ActionDeleteConfirmation = ({
    name,
    account,
    onDelete,
    onCancel,
    visible,
    supportEmail,
    source,
    environment
}) => {
    const {t} = useTranslation()
    return (
        <Modal width={500} height={221} open={visible} footer={null} closable={false} centered>
            <StyledModal>
                {SOURCE.SHAREPOINT_MULTI === source ? (
                    <StyledHeader>
                        <Trans i18nKey="backups:modal:delete:sharePointText" values={{supportEmail}}>
                            <StyledLink href={`mailto:${supportEmail}`}>Support Email</StyledLink>
                        </Trans>
                    </StyledHeader>
                ) : (
                    <StyledHeaderWithIcon action={TASK_ACTION.DELETE}>
                        <BackupTaskAction action={TASK_ACTION.DELETE} withTitle={false} />
                        <StyledText>
                            {source === SOURCE.DYNAMICS365
                                ? t('backups:modal:delete:dynamics', {name, account, environment})
                                : t('backups:modal:delete:text', {name, account})}
                        </StyledText>
                    </StyledHeaderWithIcon>
                )}
                <StyledFooter>
                    <Button htmlType="button" onClick={onCancel} style={{border: 'none', boxShadow: 'none'}}>
                        {t('backups:modal:delete:buttonCancel')}
                    </Button>
                    <Button type="primary" htmlType="button" color={variables.red} onClick={onDelete}>
                        {t('backups:modal:delete:buttonDelete')}
                    </Button>
                </StyledFooter>
            </StyledModal>
        </Modal>
    )
}
