import React, {useEffect} from 'react'
import {updateIMPlayer} from 'ca-common/features/InlineManual'

import {withPulling} from 'src/newcore/hoc/withPulling'
import {TileView} from 'src/newcore/features/Homepage/templates/TileView'
import {ListView} from 'src/newcore/features/Homepage/templates/ListView'
import {BACKUPS_VIEW} from 'src/newcore/features/Homepage/utils/constants'
import type {TaskObj} from 'src/newcore/features/Homepage/types'

type ViewsProps = {
    backupView: string
    tasks: TaskObj[]
}

export const Views = withPulling((props: ViewsProps) => {
    const {backupView, tasks} = props
    useEffect(() => {
        if (tasks?.length) {
            updateIMPlayer()
        }
    }, [tasks])

    return (
        <>
            {backupView === BACKUPS_VIEW.TILE && <TileView tasks={tasks} />}
            {backupView === BACKUPS_VIEW.LIST && <ListView tasks={tasks} />}
        </>
    )
})
