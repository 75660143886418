import React from 'react'
import {Select} from 'antd'
import styled, {css} from 'styled-components'
import Icon from '@ant-design/icons'

import variables from 'ca-common/variables'

import type {SelectProps} from './Select'

export const SelectGlobalStyle = css`
    //Select dropdown
    .ant-select-dropdown {
        overflow: visible;
        box-shadow: none;
        padding: 13px 0;
        background: transparent;
        z-index: 4;

        & > div {
            box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
                0 3px 6px -4px rgba(0, 0, 0, 0.12);
            padding: 8px 0;
            background: ${variables.white};
        }

        .rc-virtual-list-scrollbar-show {
            width: 4px !important;
            display: block !important;

            .rc-virtual-list-scrollbar-thumb {
                background: ${variables.asphaltLight} !important;
                border-radius: 5px !important;
            }
        }

        .ant-select-item:hover,
        .ant-select-item-option-active,
        .ant-select-item-option-selected {
            background-color: ${variables.interaction20};
            color: ${variables.navyBlue};
        }

        :before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: ${variables.white};
            transform: rotate(45deg);
        }

        &.ant-select-dropdown-placement-bottomLeft:before {
            left: 10%;
            top: 6px;
        }
        &.ant-select-dropdown-placement-bottomRight:before {
            right: 10%;
            top: 6px;
        }
        &.ant-select-dropdown-placement-topRight:before {
            right: 10%;
            bottom: 6px;
        }
        &.ant-select-dropdown-placement-topLeft:before {
            left: 10%;
            bottom: 6px;
        }
    }
`

export const Option = Select.Option

export const StyledSelectIcon = styled(Icon)<{isInverted?: boolean}>`
    position: absolute;
    left: ${variables.gutterXs};
    font-size: 22px;
    vertical-align: middle;
    pointer-events: none;

    color: ${props => {
        if (props.disabled) {
            return variables.asphaltLight
        }

        return variables.navyBlue
    }};
`
export const StyledInvertedSelectedIcon = styled(StyledSelectIcon)`
    color: ${props => {
        if (props.disabled) {
            return variables.asphaltLight
        }

        return variables.white
    }};
`

export const StyledSelect = styled(
    ({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selectWidth,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        prefixIcon,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        placeholderWeight,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isInverted,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clear,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        borderWidth,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        searchValue,
        ...props
    }: SelectProps): JSX.Element => <Select {...props} searchValue={searchValue} />
)`
    width: ${props => props.selectWidth}px !important;
    input {
        ${props => (props.showSearch ? '' : 'visibility: hidden;')};
        cursor: text !important;
        ${({mode, prefixIcon, isInverted}) => {
            if (mode === 'multiple') {
                if (isInverted && prefixIcon) return 'padding-right: 60px !important;'
                if (isInverted || prefixIcon) return 'padding-right: 35px !important;'
            }
        }};
    }

    &&&&& .ant-select-selector {
        height: 32px;
        display: flex;
        align-items: flex-start;

        .ant-select-selection-overflow {
            align-items: center;
            height: 100%;
        }
    }

    &.ant-select-focused {
        background: ${variables.blueLight};

        & + ${StyledSelectIcon} {
            color: ${props => {
                if (props.disabled) {
                    return variables.asphaltLight
                }

                if (props.isInverted) {
                    return variables.navyBlue
                }
            }};
        }

        &&&&& .ant-select-selector,
        &&&&& .ant-select-selection {
            background: transparent;

            & + .ant-select-arrow,
            .ant-select-selection__placeholder,
            .ant-select-selection__clear,
            .ant-select-selection-selected-value,
            .ant-select-selection-placeholder,
            .ant-select-selection-search,
            .ant-select-clear,
            .ant-select-selection-item {
                color: ${props => {
                    if (props.disabled) {
                        return variables.asphaltLight
                    }

                    if (props.isInverted) {
                        return variables.navyBlue
                    }
                }};
            }
        }
    }

    &&&&& .ant-select-selector,
    &&&&& .ant-select-selection {
        border: ${props => (typeof props.borderWidth === 'number' ? props.borderWidth : 2)}px solid
            ${props => (props.disabled ? variables.asphaltLight : variables.navyBlue)} !important;
        border-radius: 24px;
        background: ${props => {
            if (props.disabled) {
                return variables.blueLight
            }

            if (props.isInverted) {
                return variables.navyBlue
            }

            return 'transparent'
        }};

        cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')} !important;

        ${props =>
            props.clear &&
            css`
                box-shadow: none !important;
                border-color: transparent !important;
            `}

        :hover {
            ${props => !props.clear && `background: ${variables.blueLight}`};
            & + .ant-select-arrow,
            .ant-select-selection__placeholder,
            .ant-select-selection__clear,
            .ant-select-selection-selected-value,
            .ant-select-selection-placeholder,
            .ant-select-selection-search,
            .ant-select-clear,
            .ant-select-selection-item,
            & + ${StyledSelectIcon} {
                color: ${props => {
                    if (props.disabled) {
                        return variables.asphaltLight
                    }

                    if (props.isInverted) {
                        return variables.navyBlue
                    }
                }};
            }
        }
    }

    &:hover + ${StyledSelectIcon} {
        color: ${props => {
            if (props.disabled) {
                return variables.asphaltLight
            }

            if (props.isInverted) {
                return variables.navyBlue
            }
        }};
    }

    .ant-select-selection__placeholder {
        margin-top: -11px;
    }

    .ant-select-selection__placeholder,
    .ant-select-selection__rendered,
    .ant-select-selection-placeholder,
    .ant-select-selection-search,
    .ant-select-selection-item {
        padding-left: ${props => (props.prefixIcon ? '20px' : 0)} !important;
    }

    .ant-select-selection--single {
        .ant-select-search__field {
            padding-right: ${variables.gutterMd};
        }
    }

    .ant-select-arrow,
    .ant-select-clear {
        width: 14px;
        height: 14px;
        right: 14px;
    }

    .ant-select-selection__clear,
    .ant-select-clear {
        background: ${variables.blueLight};
    }

    .ant-select-arrow,
    .ant-select-selection__placeholder,
    .ant-select-selection__clear,
    .ant-select-selection-selected-value,
    .ant-select-selection-placeholder,
    .ant-select-selection-search,
    .ant-select-clear,
    .ant-select-selection-item,
    & + ${StyledSelectIcon} {
        color: ${props => {
            if (props.disabled) {
                return variables.asphaltLight
            }

            if (props.isInverted) {
                return variables.white
            }

            return variables.navyBlue
        }};
        font-family: ${variables.accentFont};
        font-weight: ${props => props.placeholderWeight};

        :hover {
            color: ${props => {
                if (props.disabled) {
                    return variables.asphaltLight
                }

                if (props.isInverted) {
                    return variables.navyBlue
                }
            }};
        }
    }

    .ant-select-arrow-loading {
        height: 20px;
    }

    .ant-select-selection-selected-value {
        font-weight: 600;
    }

    .ant-select-selection__choice {
        background: ${variables.navyBlue};
        color: ${variables.white};
        border-radius: 24px;
    }

    ${({mode, prefixIcon, searchValue}) =>
        mode === 'multiple' &&
        css`
            .ant-select-selection-search {
                padding-left: 0 !important;
                width: auto !important;
            }

            .ant-select-selection-overflow-item-suffix {
                margin-left: ${prefixIcon ? '20px' : 0};
            }

            .ant-select-selection-overflow-item-rest + .ant-select-selection-overflow-item-suffix {
                margin-left: ${prefixIcon ? '25px' : '5px'};
            }

            .ant-select-selection-overflow-item-rest > .ant-select-selection-item {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                background-color: inherit;
                border: none;
                font-weight: 600;

                & .ant-select-selection-item-content {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-right: 0;
                }
            }

            &.ant-select-focused .ant-select-selection-item-content span:first-child {
                visibility: ${searchValue ? 'hidden' : 'visible'};
            }
        `}
`

export const SelectWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`
