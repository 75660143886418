import React, {useState} from 'react'

import {Trans} from 'react-i18next'

import {
    BackupRadarLink,
    IntegrationSupportLink,
    IntegrationServices,
    AutoTaskLink
} from 'ca-common/features/Integrations/config'
import {isPartnerReseller} from 'ca-common/utils/partner'

import {StyledBlock, StyledText, StyledInstructions, StyledLink} from './StyledMultipleEntityText'

type MultipleEntityProps = {
    title: string
    partnerInfo?: Record<string, any>
    type: string
    isPartnerPortal: boolean
    portalAllowLink?: boolean
}

export const MultipleEntityText = (props: MultipleEntityProps) => {
    const {title, partnerInfo, type, isPartnerPortal, portalAllowLink} = props
    const {whiteLabel, partnerType} = partnerInfo || {}
    const isLinkAllowed = isPartnerPortal ? !(whiteLabel && isPartnerReseller(partnerType)) : portalAllowLink
    const entityDetails =
        type === IntegrationServices.BACKUP_RADAR ? 'integrations:backupRadarDetails' : 'integrations:autoTaskDetails'
    const entityLink = type === IntegrationServices.BACKUP_RADAR ? BackupRadarLink : AutoTaskLink
    const entityAffirmation =
        type === IntegrationServices.BACKUP_RADAR
            ? 'integrations:backupRadarModal:affirmation'
            : 'integrations:autoTaskModal:affirmation'

    return (
        <StyledBlock>
            <StyledText>
                <Trans i18nKey="integrations:multipleEntityTitle" values={{name: title}} />
            </StyledText>
            <StyledInstructions>
                <Trans i18nKey={entityDetails} values={{name: title}}>
                    <StyledLink href={entityLink} target="_blank" isPartnerPortal={isPartnerPortal}>
                        0
                    </StyledLink>
                    {isLinkAllowed && (
                        <StyledLink href={IntegrationSupportLink} target="_blank" isPartnerPortal={isPartnerPortal}>
                            1
                        </StyledLink>
                    )}
                </Trans>
            </StyledInstructions>
            <StyledText>
                <Trans i18nKey={entityAffirmation} />
            </StyledText>
        </StyledBlock>
    )
}
