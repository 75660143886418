import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox, Form, Modal, Tooltip, Select} from 'antd'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

import type {UserInfoDataType, PaymentMethod} from 'ca-common/types'
import {generateCardLabelName} from 'ca-common/utils/text'
import ErrorIcon from 'ca-common/icons/PaymentDetails/ErrorIcon.svg'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {CREDIT_CARD_STATUS} from 'ca-common/constants'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'

import {FieldsWrapper, FormFooter, RowWithOneItem} from '../StyledForm'
import {CreditCardErrorIcon} from './StyledCards'

type CardsProps = {
    isPP: boolean
    paymentMethods: PaymentMethod[]
    activateCard: (paymentMethodToken: string) => void
    deleteCard: (paymentMethodToken: string, isLastCard?: boolean) => Promise<void>
    zoozSubscribeStatus: string
    addCard: () => void
    userInfo?: UserInfoDataType
    isLastCard?: boolean
    isFinra?: boolean
    companyName?: string
    partnerInfo?: any
    activateCardLoader: boolean
    deleteCardLoader: boolean
    cardStatus?: string
}

export const Cards = (props: CardsProps) => {
    const {
        isPP,
        activateCard,
        deleteCard,
        paymentMethods,
        zoozSubscribeStatus,
        addCard,
        userInfo,
        isLastCard,
        isFinra,
        companyName,
        partnerInfo,
        activateCardLoader,
        deleteCardLoader,
        cardStatus
    } = props
    const {t} = useTranslation()
    const [form] = Form.useForm<{paymentMethodToken: string}>()
    const watchedPaymentMethodToken = Form.useWatch('paymentMethodToken', form)

    const [isOpenedDeletingModal, setIsOpenedDeletingModal] = useState(false)
    const [isApproveAccountTerminate, setIsApproveAccountTerminate] = useState(false)
    const [isDeletingCard, setIsDeletingCard] = useState(false)

    const {activeCardPaymentMethodToken, paymentMethodsOptions} = useMemo(() => {
        let activeCardPaymentMethodToken: undefined | string

        const paymentMethodsOptions = paymentMethods.map(paymentMethod => {
            if (!activeCardPaymentMethodToken && paymentMethod.active) {
                activeCardPaymentMethodToken = paymentMethod.paymentMethodToken
            }

            return {
                value: paymentMethod.paymentMethodToken,
                label: generateCardLabelName(paymentMethod)
            }
        })

        return {activeCardPaymentMethodToken, paymentMethodsOptions}
    }, [paymentMethods])

    const onCloseDeletingModal = useCallback(() => {
        setIsApproveAccountTerminate(false)
        setIsOpenedDeletingModal(false)
    }, [])

    const initialValues = {paymentMethodToken: activeCardPaymentMethodToken}

    const isDeleteCardBtnDisabled = useMemo(() => watchedPaymentMethodToken === activeCardPaymentMethodToken, [
        activeCardPaymentMethodToken,
        watchedPaymentMethodToken
    ])

    const isActivateCardBtnDisabled = useMemo(() => {
        if (zoozSubscribeStatus === 'pending') {
            return true
        }

        if (
            watchedPaymentMethodToken === activeCardPaymentMethodToken &&
            ((isPP && !partnerInfo?.isSubscribed) || (!isPP && !userInfo?.isSubscribed))
        ) {
            return false
        }

        return watchedPaymentMethodToken === activeCardPaymentMethodToken
    }, [watchedPaymentMethodToken, activeCardPaymentMethodToken, isPP ? partnerInfo : userInfo, zoozSubscribeStatus])
    const onDeleteCard = useCallback(async () => {
        if (isLastCard && !isOpenedDeletingModal) {
            return setIsOpenedDeletingModal(true)
        }

        const paymentMethodToken = form.getFieldValue('paymentMethodToken')

        setIsDeletingCard(true)
        await deleteCard(paymentMethodToken, isLastCard)
        setIsDeletingCard(false)
        onCloseDeletingModal()
    }, [isLastCard, isOpenedDeletingModal, form, deleteCard])

    const onActivateCard = useCallback(() => {
        const paymentMethodToken = form.getFieldValue('paymentMethodToken')
        activateCard(paymentMethodToken)
    }, [form, activateCard])

    const onIsApproveAccountTerminateChange = useCallback((e: CheckboxChangeEvent) => {
        setIsApproveAccountTerminate(e.target.checked)
    }, [])

    return (
        <div>
            <JumbotronToggle title={t('forms:cards:name')}>
                <Form initialValues={initialValues} form={form}>
                    <FieldsWrapper>
                        <RowWithOneItem>
                            <Form.Item name="paymentMethodToken" label={t('forms:common:fields:cards:label')}>
                                <Select
                                    showSearch={false}
                                    options={paymentMethodsOptions}
                                    placeholder={t('forms:common:fields:cards:placeholder')}
                                />
                            </Form.Item>
                            {!isPP && cardStatus === CREDIT_CARD_STATUS.FAILED && (
                                <CreditCardErrorIcon component={ErrorIcon} />
                            )}
                        </RowWithOneItem>
                    </FieldsWrapper>
                </Form>
                <FormFooter>
                    <Button type="primary" onClick={addCard}>
                        {t('billing:zoozActions:addCard')}
                    </Button>
                    <Button
                        type="primary"
                        disabled={isActivateCardBtnDisabled}
                        onClick={onActivateCard}
                        loading={activateCardLoader}
                    >
                        {t('billing:zoozActions:activateCard')}
                    </Button>
                    <Button disabled={isDeleteCardBtnDisabled} onClick={onDeleteCard} loading={deleteCardLoader}>
                        {t('billing:zoozActions:deleteCard')}
                    </Button>
                </FormFooter>
            </JumbotronToggle>
            <Modal
                okText={t('billing:cardDeleteConfirmation:unsubscribe')}
                open={isOpenedDeletingModal}
                onCancel={onCloseDeletingModal}
                maskClosable={false}
                onOk={onDeleteCard}
                destroyOnClose
                closable={false}
                okButtonProps={{danger: true, disabled: !isApproveAccountTerminate, loading: isDeletingCard}}
                cancelButtonProps={{disabled: isDeletingCard}}
            >
                <p>{t('billing:cardDeleteConfirmation:annotationOfUnsubscribe')}</p>
                <p>{t('billing:cardDeleteConfirmation:annotationOfCancel')}</p>
                <Tooltip title={isFinra && t('finra:accountTooltip')}>
                    <Checkbox
                        onChange={onIsApproveAccountTerminateChange}
                        checked={isApproveAccountTerminate}
                        disabled={isDeletingCard || isFinra}
                    >
                        {t('accountPage:terminate:checkbox', [companyName])}
                    </Checkbox>
                </Tooltip>
            </Modal>
        </div>
    )
}
