import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import moment from 'moment'
import {get, isEmpty, size} from 'lodash'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {checkPermission} from 'ca-common/utils/permissions'
import {TODO_ANY} from 'ca-common/types'
import {getUrlParams} from 'ca-common/utils/url'
import {WIZARD_TYPE} from 'ca-common/constants'
import {ExportUsageReportButton} from 'ca-common/components/ExportUsageReportButton'
import {openErrorNotification, openSuccessNotification} from 'ca-common/utils/toasts'
import {SOURCE} from 'ca-common/common/enum'

import {WizardModal} from 'src/newcore/components/Wizard'
import {useOCAWizard} from 'src/newcore/features/OneClickActivation'
import {useOCAEnabled} from 'ca-common/features/Flags'

import {clearBackupTasks, exportUsage, getBackupTasks, getBackupTasksPull, updateLocalBackupTasks} from './redux'
import {SearchField} from './atoms/SearchField'
import {useFilteredData} from './hooks/useFilteredData'
import {toggleBackupsView} from './hooks/toggleBackupsView'
import {useIsMobile} from './hooks/useIsMobile'
import {CountBackupTitle} from './atoms/CountBackupsTitle'
import {AddNew} from './atoms/AddNew'
import {ViewToggle} from './atoms/ViewToggle'
import {Subtitle} from './atoms/Subtitle'
import {EndUserCards} from './organisms/EndUserCards'
import {BACKUPS_VIEW} from './utils/constants'
import {Views} from './templates/Views'
import {StyledEmail, Toolbar, ToolbarWithBorder} from './StyledHomepage'
import {AppDispatch, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {
    openWizardWithConfig,
    changeStep,
    changeProgress,
    changeData,
    setWizardTitle
} from 'src/newcore/redux/modules/wizard'
import {STEPS, DYNAMICS_MODAL_WIDTH, DYNAMICS_WIZARD_CONFIG} from 'src/newcore/features/DynamicsWizard/lib'
import {EVENT_NAMES, track} from 'src/newcore/utils/mixpanel'
import {NewHome} from '../NewHomePage/NewHomepage'
import {getBackupEntities} from 'src/newcore/features/RecoveryWizard/redux'

type HomepageProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const Homepage = (props: HomepageProps): JSX.Element => {
    const {actions, backupTasks, userInfo} = props
    const {t} = useTranslation()

    const [filteredData, setFilter] = useFilteredData(backupTasks?.response || [])
    const [backupView, setBackupView] = toggleBackupsView()
    const isMobile = useIsMobile()
    const isOCAEnabled = useOCAEnabled()

    const allowAddNew = checkPermission('PAGE.BACKUPS.ACTIVATE', get(userInfo, 'permissions'))

    const location = useLocation()
    const {
        modalName,
        stateId,
        isDynamicsTask,
        isFinalStep,
        env,
        alias,
        authenticationFailed,
        isGracePeriod
    } = getUrlParams(location.search)
    const dispatch = useAppDispatch()

    useOCAWizard({
        showWizard: modalName === 'OneClickActivation' && isOCAEnabled,
        stateId: stateId as string
    })

    useEffect(() => {
        actions.getBackupTasks({})

        return () => {
            actions.clearBackupTasks()
        }
    }, [])

    useEffect(() => {
        if (isDynamicsTask) {
            dispatch(actions.changeData({alias: alias}))
            dispatch(
                actions.openWizardWithConfig({
                    type: WIZARD_TYPE.DYNAMICS365,
                    config: {
                        ...DYNAMICS_WIZARD_CONFIG,
                        autoDismissProgress: true,
                        destroyInactiveTabPane: false,
                        modalWidth: DYNAMICS_MODAL_WIDTH
                    }
                })
            )
        }

        if (isFinalStep) {
            dispatch(actions.changeProgress(STEPS.createApplicationUserStatus))
            dispatch(actions.changeStep(STEPS.createApplicationUserStatus))
            dispatch(
                actions.changeData({
                    environment: {
                        friendlyName: env
                    }
                })
            )
            dispatch(actions.changeData({isUserAuthenticationFailed: authenticationFailed, isBackStep: true}))
        }
        if (isGracePeriod) {
            dispatch(changeData({deletedTaskLoading: false}))
            dispatch(setWizardTitle(t('forms:backupAuth:text:DynamicsRecentlyDeletedTitle')))
            dispatch(actions.changeProgress(STEPS.recentlyDeletedTask))
            dispatch(actions.changeStep(STEPS.recentlyDeletedTask))
            dispatch(
                actions.changeData({
                    environment: {
                        friendlyName: env
                    }
                })
            )
        }
    }, [isDynamicsTask, isFinalStep])

    if (backupTasks.status !== 'fulfilled') {
        return <Spinner modifier="page" />
    }

    if (isEmpty(backupTasks.response)) {
        return <NewHome {...props} isBackup={false} />
    }

    const pullConfig = (() => ({
        pull: () => {
            dispatch(getBackupTasksPull({})).then((response: TODO_ANY) => {
                const data = get(response, 'payload', [])

                actions.updateLocalBackupTasks({data, full: true})
            })
        },
        short: moment.duration(1, 'minutes'),
        long: moment.duration(5, 'minutes'),
        stop: moment.duration(20, 'minutes')
    }))()

    const onConfirmExportUsage = async () => {
        track(EVENT_NAMES.EXPORT_USAGE_REPORT, {})
        return exportUsage()
            .then(() => {
                openSuccessNotification(
                    <Trans i18nKey="exportUsage:notification" values={{email: userInfo?.email}}>
                        <StyledEmail />
                    </Trans>
                )
            })
            .catch(error => {
                openErrorNotification(error?.message)
            })
    }

    const clickEndUserCard = (id?: string, recoveryEntityId?: string) => {
        actions.getBackupEntities({
            taskId: id,
            rows: 10000,
            page: 1,
            source: SOURCE.MS365,
            entitiesIds: [recoveryEntityId]
        })
    }

    return (
        <>
            {userInfo?.isMsEndUser ? (
                <Subtitle />
            ) : (
                <>
                    <Toolbar>
                        <SearchField
                            disabled={backupTasks.status !== 'fulfilled'}
                            searchData={setFilter}
                            placeholder={t(`homepage:searchPlaceholder`)}
                        />
                        {!isMobile && <ViewToggle backupView={backupView} setBackupView={setBackupView} />}
                    </Toolbar>
                    <ToolbarWithBorder>
                        <CountBackupTitle count={size(filteredData)} />
                        {allowAddNew && <AddNew />}
                        {(!userInfo?.isSpecialPartnerAccount ||
                            checkPermission('PAGE.EXPORT_USAGE_REPORT.ACCESS', userInfo?.permissions)) && (
                            <ExportUsageReportButton email={userInfo?.email} onConfirm={onConfirmExportUsage} />
                        )}
                    </ToolbarWithBorder>
                </>
            )}
            {userInfo?.isMsEndUser ? (
                <EndUserCards tasks={filteredData} clickEndUserCard={clickEndUserCard} />
            ) : (
                <Views
                    backupView={isMobile ? BACKUPS_VIEW.TILE : backupView}
                    pullConfig={pullConfig}
                    tasks={filteredData}
                />
            )}
            <WizardModal />
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    backupTasks: state.backups.listTasksNew,
    userInfo: state.userInfo.response
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            getBackupTasks,
            getBackupTasksPull,
            updateLocalBackupTasks,
            clearBackupTasks,
            getBackupEntities,
            openWizardWithConfig,
            changeStep,
            changeProgress,
            changeData
        },
        dispatch
    )
})

export const CAHomepage = connect(mapStateToProps, mapDispatchToProps)(Homepage)
