import React from 'react'

import {SOURCE} from 'ca-common/common/enum'
import type {BackupAuthTemplate} from 'src/newcore/features/BackupAuth/types/BackupAuthTemplate'

import {
    MS365,
    MS365GROUPS,
    Yahoo,
    SDB,
    Box,
    DDB,
    GoogleAccount,
    IMAP,
    SFC,
    Dropbox,
    TeamDrive,
    GSuite,
    OneDrive,
    Sharepoint,
    Dynamics
} from '.'

export const AuthTemplateSelector = (props: BackupAuthTemplate): JSX.Element | null => {
    const {source} = props

    switch (source) {
        case SOURCE.MS365:
            return <MS365 {...props} />
        case SOURCE.MS365GROUPS:
            return <MS365GROUPS {...props} />
        case SOURCE.YMAIL:
            return <Yahoo {...props} />
        case SOURCE.SDB:
            return <SDB {...props} />
        case SOURCE.BOX:
            return <Box {...props} />
        case SOURCE.DDB:
            return <DDB {...props} />
        case SOURCE.GACCOUNTS:
            return <GoogleAccount {...props} />
        case SOURCE.IMAP:
            return <IMAP {...props} />
        case SOURCE.SFC:
            return <SFC {...props} />
        case SOURCE.DROPBOX:
            return <Dropbox {...props} />
        case SOURCE.GDRIVETEAM:
            return <TeamDrive {...props} />
        case SOURCE.GOOGLEAPPS:
            return <GSuite {...props} />
        case SOURCE.ONEDRIVE:
            return <OneDrive {...props} />
        case SOURCE.SHAREPOINT:
        case SOURCE.SHAREPOINT_MULTI:
            return <Sharepoint {...props} />
        case SOURCE.DYNAMICS365:
            return <Dynamics {...props} />
        default:
            return null
    }
}
