import styled from 'styled-components'
import {Radio} from 'antd'

import variables from 'ca-common/variables'
import {RadioGroup} from 'src/ca-common/ui-lib/components/RadioBox'

export const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    font-family: ${variables.publicPageFont};

    h2 {
        color: ${variables.text};
        font-size: 22px;
        line-height: 140%;
        font-weight: 600;
        margin-bottom: ${variables.gutterXs};
        margin-top: -48px;
    }

    p {
        font-weight: 300;
        margin-bottom: 0;
        font-size: ${variables.fontSizeBase};
    }

    div {
        width: 700px;
        text-align: center;
        color: ${variables.red};
        margin-top: ${variables.gutterXs};
        font-size: ${variables.subTitleFontSize};
    }
`
export const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-bottom: ${variables.gutterXxl};
    .ant-radio-disabled {
        display: none;
        & + span {
            padding-left: 0;
        }
    }

    .ant-radio-inner {
        border-color: ${variables.navyBlue};
    }
`

export const StyledContent = styled.div<{isSelected?: boolean}>`
    border: solid 1px ${variables.navyBorder};
    width: 314px;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 2px;
    font-size: 14px !important;
    margin-bottom: ${variables.gutterSm};
    background-color: ${props => (props.isSelected ? variables.interaction : variables.white)};
`
export const StyledRadio = styled(Radio)`
    flex: 1;
`
export const StyledBackupContent = styled.span`
    color: ${variables.systemGreen};
    font-weight: 300;

    .anticon-check-circle {
        margin-right: 4px;
    }
`
const Scrollbar = styled.div`
    height: 270px;
    ::-webkit-scrollbar {
        width: 10px;
        background-color: ${variables.navyBorder};
        border: 1px solid ${variables.navyBorder};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${variables.navyAsphalt};
        border: 1px solid ${variables.navyAsphalt};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${variables.navyBorder};
        border: 1px solid ${variables.navyBorder};
        border-radius: 10px;
    }
`

export const StyledDynamicsListDiv = styled(Scrollbar)`
    margin: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
`

export const StyledModalContainer = styled.div`
    height: 350px;
    display: flex;
    flex-direction: column;
`
