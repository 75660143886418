import styled, {css} from 'styled-components'
import variables from 'ca-common/variables'

export const ModalFooter = styled.div<{isLineHidden?: boolean}>`
    font-family: ${variables.publicPageFont};
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-family: ${variables.publicPageFont};
    align-items: center;
    padding: ${variables.gutterSm} ${variables.gutterMd};
    border-top: ${props => (props.isLineHidden ? '0px' : `1px solid ${variables.blue} `)};

    .ant-btn-primary {
        font-weight: 700;
        line-height: 16px;
    }
`

export const BackLinkWrapper = styled.div`
    color: ${variables.navyBlue};
    font-weight: 700;
    cursor: pointer;

    .anticon {
        margin-right: ${variables.gutterSm};
    }

    &:hover {
        text-decoration: underline;
    }
`

export const CancelLink = styled.div`
    color: ${variables.navyBlue};
    font-weight: 700;
    margin-right: ${variables.gutterXs};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export const LinkWrapper = styled.div`
    display: flex;
    gap: ${variables.gutterXs};
    align-items: center;
`

export const ModalCenterContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: ${variables.gutterSm} auto 0;
    padding-bottom: ${variables.gutterSm};
    width: 540px;
    height: 340px;
    flex: 1;
`
export const CenteredContent = styled.div`
    width: 600px;
    min-height: 280px;
    margin: 92px auto 71px auto;
`

export const ModalCenterWrapper = styled.div`
    margin: auto;
    padding: 0 ${variables.gutterMd};
    width: 540px;
`

export const ModalFlexWrapper = styled.section<{minHeight?: number; isSelective?: boolean}>`
    display: flex;
    padding: 0 ${variables.gutterMd};
    min-height: ${props => `${props.minHeight || 340}px`};
    width: 100%;
    ${props =>
        props.isSelective
            ? css`
                  gap: 68px;
              `
            : css`
                  justify-content: space-between;
              `}
`

export const ModalWrapper = styled.section<{minHeight?: number}>`
    margin: auto;
    padding: 0 ${variables.gutterMd};
    min-height: ${props => `${props.minHeight || 340}px`};
`

export const ModalFlexColumnWrapper = styled.section<{minHeight?: number}>`
    display: flex;
    flex-direction: column;
    padding: 0 ${variables.gutterMd};
    min-height: ${props => `${props.minHeight || 340}px`};
    width: 100%;
`

export const ModalContentWrapper = styled.div`
    margin: 0 auto;
    padding-bottom: ${variables.gutterSm};
    position: relative;
    flex: 1;
    height: 340px;
    overflow: auto;
`
