import React from 'react'
import {Trans} from 'react-i18next'
import {Button} from 'antd'

import WarningHighlight from 'ca-common/icons/WarningHighlight.svg'

import {
    StyledDeleteModal,
    StyledTitle,
    StyledDescription,
    StyledFooter,
    StyledButton,
    StyledIcon,
    StyledImage,
    StyledText
} from './StyledDeleteModal'

type deleteModalProps = {
    closeDeleteModal: () => any
    deleteModalVisible: boolean
    onDeleteSubmit: () => any
    isDeleteLoading: boolean
}

export const DeleteModal = (props: deleteModalProps) => {
    const {deleteModalVisible, closeDeleteModal, onDeleteSubmit, isDeleteLoading} = props

    return (
        <StyledDeleteModal
            centered
            closable={true}
            width={722}
            open={deleteModalVisible}
            destroyOnClose={true}
            onCancel={() => closeDeleteModal()}
            title={
                <StyledTitle>
                    <StyledImage>
                        <StyledIcon component={WarningHighlight} />
                    </StyledImage>
                    <StyledText>
                        <Trans i18nKey="integrations:splunkDeleteModal:header" />
                    </StyledText>
                </StyledTitle>
            }
            footer={
                <StyledFooter>
                    <StyledButton onClick={() => closeDeleteModal()}>
                        <Trans i18nKey="modals:buttons:cancel" />
                    </StyledButton>
                    <Button type="primary" htmlType="submit" onClick={() => onDeleteSubmit()} loading={isDeleteLoading}>
                        <Trans i18nKey="modals:buttons:delete" />
                    </Button>
                </StyledFooter>
            }
        >
            <StyledDescription>
                <Trans i18nKey="integrations:splunkDeleteModal:description" />
            </StyledDescription>
        </StyledDeleteModal>
    )
}
