import styled from 'styled-components'
import Icon from '@ant-design/icons'
import variables from 'ca-common/variables'

export const BillingNotificationWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: ${variables.borderRadiusBase} ${variables.fontSizeBase} ${variables.borderRadiusBase} ${variables.gutterSm};
    padding-top: ${variables.fontSizeXs};
    padding-bottom: ${variables.fontSizeXs};
    background: white;
    border-radius: ${variables.borderRadiusBase};
    border: 1px ${variables.navyBlue} solid;
    justify-content: center;
    align-items: center;
    gap: ${variables.gutterXs};
    display: inline-flex;
    margin-bottom: ${variables.fontSizeSecondary};
`
export const StyledBillingNotification = styled.div`
    justify-content: flex-start;
    align-items: center;
    gap: ${variables.borderRadiusBase};
    display: flex;
    padding-top: 1px;
`
export const BillingNotificationHeader = styled.div<{isPaymentError?: boolean}>`
    color: ${props => (props.isPaymentError ? ' #FF0E0E' : `${variables.navyBlue}`)};
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.publicPageFont};
    font-weight: 400;
    line-height: 19.6px;
    word-wrap: break-word;
`
export const StyledBillingNotificationIcon = styled(Icon)`
    width: ${variables.gutterSm};
    height: ${variables.gutterSm};
    padding: 1.33px;
    justify-content: center;
    align-items: center;
    display: flex;
`
export const BillingNotificationText = styled.div`
    justify-content: flex-start;
    align-items: center;
    gap: ${variables.borderRadiusBase};
    display: flex;
    font-weight: 300;
`
