import React from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm, formValueSelector, change} from 'redux-form'
import {withTranslation} from 'react-i18next'
import {Tooltip} from 'antd'
import Icon from '@ant-design/icons'

import {FORMS} from 'ca-common/constants'
import Info from 'ca-common/icons/Info.svg'
import LabeledInput from 'ca-common/ui-lib/components/ReduxFormElements/LabeledInput'
import {ReactSelect} from 'ca-common/ui-lib/components/ReduxFormElements/ReactSelect'
import {CAFormPage, CAFormPageFooter} from 'ca-common/ui-lib/components/FormPage'
import CAButton from 'ca-common/ui-lib/components/Button'
import {requiredValidate} from 'ca-common/utils/validation'
import {CACheckBox, Input} from 'ca-common/ui-lib/components/ReduxFormElements'
import {STORAGE_TYPE} from 'ca-common/common/enum'
import {jobLabelValidate} from 'ca-common/utils/validation'

import {StyledSubtitle, StyledSpan, StyledValidation} from './StyledExportModal'

class ExportSFCModalForm extends React.Component {
    componentWillUpdate(nextProps) {
        if (this.props.storageType !== nextProps.storageType) {
            this.props.dispatch(change(FORMS.EXPORT_MODAL, 'storageType', nextProps.storageType))
        }
    }

    render() {
        const {
            props: {
                customStorage,
                onReject,
                exportToRegion,
                storageType,
                handleSubmit,
                t,
                showIncludeDeletedOption = false
            }
        } = this

        return (
            <CAFormPage>
                <form onSubmit={handleSubmit}>
                    <StyledValidation>{t(`recovery:wizard:jobLabel:validation`)}</StyledValidation>
                    <StyledSubtitle>
                        <div>
                            {t(`recovery:wizard:jobLabel:title`)}
                            <StyledSpan>{t(`recovery:wizard:jobLabel:subtitle`)}</StyledSpan>
                            <Tooltip title={t(`recovery:wizard:jobLabel:tooltip`)}>
                                <Icon component={Info} />
                            </Tooltip>
                        </div>
                        <Field
                            name="jobLabel"
                            component={Input}
                            type="text"
                            validate={[jobLabelValidate]}
                            placeholder={t('forms:export:fields:jobLabel:placeholder')}
                            label={t('forms:export:fields:jobLabel:label')}
                        />
                    </StyledSubtitle>

                    <Field
                        name="storageType"
                        component={ReactSelect}
                        options={customStorage}
                        placeholder={t('forms:export:fields:storageType:placeholder')}
                        label={t('forms:export:fields:storageType:label')}
                    />
                    {(STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3 ||
                        STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3_COMPATIBLE ||
                        STORAGE_TYPE[storageType] === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM ||
                        STORAGE_TYPE[storageType] === STORAGE_TYPE.AZURE) && (
                        <div>
                            <Field
                                name="exportBucketName"
                                type="text"
                                component={LabeledInput}
                                label={t('forms:export:fields:exportBucketName:label')}
                                htmlFor="exportBucketName"
                            />
                            <Field
                                name="exportToKey"
                                type="text"
                                component={LabeledInput}
                                label={t('forms:export:fields:exportToKey:label')}
                                htmlFor="exportToKey"
                            />
                            <Field
                                name="exportToSecretKey"
                                type="password"
                                component={LabeledInput}
                                label={t('forms:export:fields:exportToSecretKey:label')}
                                htmlFor="exportToSecretKey"
                            />
                        </div>
                    )}
                    {(STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3_COMPATIBLE ||
                        STORAGE_TYPE[storageType] === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM ||
                        STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3) && (
                        <Field
                            name="exportToRegion"
                            component={ReactSelect}
                            htmlFor="exportToRegion"
                            placeholder={t('forms:export:fields:exportToRegion:placeholder')}
                            label={t('forms:export:fields:exportToRegion:label')}
                            options={exportToRegion}
                        />
                    )}
                    {STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3_COMPATIBLE && (
                        <Field
                            name="compatibleS3Endpoint"
                            component={LabeledInput}
                            htmlFor="compatibleS3Endpoint"
                            placeholder={t('forms:awsS3Compatible:fields:s3endpoint:placeholder')}
                            label={t('forms:awsS3Compatible:fields:s3endpoint:label')}
                        />
                    )}

                    {showIncludeDeletedOption && (
                        <Field
                            name="includeDeleted"
                            component={CACheckBox}
                            type="checkbox"
                            label={t('forms:export:fields:includeDeleted:label')}
                        />
                    )}
                    {t('modals:exportModal:postfix')}
                    <CAFormPageFooter>
                        <CAButton modifier={'primary'} type="submit">
                            {t('modals:buttons:yes')}
                        </CAButton>
                        <CAButton onClick={onReject} modifier={'secondary'} type="button">
                            {t('modals:buttons:no')}
                        </CAButton>
                    </CAFormPageFooter>
                </form>
            </CAFormPage>
        )
    }
}

const validate = values => {
    const errors = {}
    const {storageType, exportBucketName, exportToKey, exportToSecretKey, exportToRegion} = values

    if (
        STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3 ||
        STORAGE_TYPE[storageType] === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM ||
        STORAGE_TYPE[storageType] === STORAGE_TYPE.AZURE
    ) {
        errors.storageType = requiredValidate(storageType)
        errors.exportBucketName = requiredValidate(exportBucketName)
        errors.exportToKey = requiredValidate(exportToKey)
        errors.exportToSecretKey = requiredValidate(exportToSecretKey)
    }
    if (
        STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3 ||
        STORAGE_TYPE[storageType] === STORAGE_TYPE.AMAZON_S3_COMPATIBLE ||
        STORAGE_TYPE[storageType] === STORAGE_TYPE.GOOGLE_CLOUD_PLATFORM
    ) {
        errors.exportToRegion = requiredValidate(exportToRegion)
    }

    return errors
}

const selector = formValueSelector(FORMS.EXPORT_MODAL)

function mapStateToProps(state) {
    return {
        storageType: selector(state, 'storageType'),
        backupedItems: state.restore.backupedItems
    }
}
export const ExportSFCModal = connect(mapStateToProps)(
    reduxForm({
        form: FORMS.EXPORT_MODAL,
        shouldValidate: () => true,
        validate
    })(withTranslation(['forms', 'modals'])(ExportSFCModalForm))
)
