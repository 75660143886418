import React from 'react'
import {Button} from 'antd'

import {i18n} from 'ca-common/locales'
import {IntegrationServices} from 'ca-common/features/Integrations/config'

import {StyledSection, StyledText, StyledButton} from './StyledSingleFooterButton'

type FooterProps = {
    onCancel?: () => any
    type?: string
}

export const SingleFooterButton = (props: FooterProps) => {
    const {onCancel, type} = props
    const isSplunk = type === IntegrationServices.SPLUNK

    return (
        <StyledSection isSplunk={isSplunk}>
            {isSplunk ? (
                <StyledText onClick={() => onCancel && onCancel()}>{i18n.t('modals:buttons:cancel')}</StyledText>
            ) : (
                <StyledButton type="primary" onClick={() => onCancel && onCancel()}>
                    {i18n.t('modals:buttons:close')}
                </StyledButton>
            )}
        </StyledSection>
    )
}
